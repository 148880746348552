export const GENETIC_FINDINGS_FUSIONS = [
    {
        Header: 'Fusioned genes',
        accessor: "fusioned_genes",
        filter: 'fuzzyText',
    },
    {
        Header: 'Left gene',
        accessor: "left_gene",
        filter: 'fuzzyText',
    }, 
    {
        Header: 'Right gene',
        accessor: "right_gene",
        filter: 'fuzzyText',
    },
    {
        Header: 'Left breakpoint',
        accessor: "left_breakpoint",
        filter: 'fuzzyText',
    },
    {
        Header: 'Right breakpoint',
        accessor: "right_breakpoint",
        filter: 'fuzzyText',
    },
    {
        Header: 'Clinical significance*',
        accessor: "significance",
        filter: 'fuzzyText',
    },
    {
        Header: 'Origin',
        accessor: "origin",
        filter: 'fuzzyText',
    },
    {
        Header: 'Junction read count',
        accessor: "junction_read_count",
        filter: 'fuzzyText',
    },
    
    
];