import React, { useMemo, useState, useCallback, useEffect, useRef } from "react";
import { filters } from "./utils/filters";
import DefaultColumnFilter from "./Filters/DefaultColumnFilter";
import {
  useBlockLayout,
  useColumnOrder,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import useOptionFilter from "./hooks/useOptionFilter";
import {
  IconButton,
  Pagination,
  Stack,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Chip,
  Box,
  Tooltip
} from "@mui/material";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { addTooltipRow } from "./utils/addTooltipRow";
import SimpleTable from "./SimpleTable";
import styled from "@emotion/styled";
import ButtonGroupCustom from "./buttons/ButtonGroupCustom";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import TagDialog from "../../../scenes/dashboard/components/genomics-dashboard/components/results/components/toolbar/tag_dialog/TagDialog";
import { variantFinder } from "../../../scenes/dashboard/components/genomics-dashboard/components/results/components/variant-table/gpap-table/variant_finder";
import { renderToStringAlt } from "../../../scenes/instand/reports/create-report/shared-components/renderToString.js";
import BootstrapTooltip from "../../../gpap-lib/components/BootstrapTooltip";
import { useSelector } from "react-redux";
import { CNVPreviousTags } from "./buttons/CNVPreviousTags";
import IGVLinks from "../../../scenes/dashboard/components/genomics-dashboard/components/results/components/variant-table/gpap-table/components/IGVLinks";
import Typography from "@mui/material/Typography";
import "../../../scenes/dashboard/components/genomics-dashboard/components/variant-tabs/components/variant-info/variant-info.css";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function TableComponentForVariants({
  columns,
  data,
  type,
  nestedColumns,
  buttons,
  states,
  tooltipCompact,
  renderFeatures,
  chipFull,
  total,
  isSNVSelected
}) {

  const [expandedRows, setExpandedRows] = useState({});
  const [copied, setCopied] = useState(false);

  const DATA_TYPE = "CNV";
  const filterTypes = useMemo(() => filters(), []);

  // get from REDUX

  // TODO:return selected_row;

  const selected_table_row = useSelector(state => state.selected_table_rows.find(s => s.data_type === DATA_TYPE));

  const dataRef = useRef(data); // Ref to keep track of previous data

  const handleCopy = () => {
    if (selected_variant?.length > 0) {
      const textToCopy = `${selected_variant[0].SV_chrom}-${selected_variant[0].SV_start}-${selected_variant[0].SV_end}`;
      navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); 
    }
  };


  // the selected_variant object;
  const selected_variant = (selected_table_row !== undefined) ? selected_table_row.data_row : [];
  const samples = selected_variant[0]?.samples_somatic || [selected_variant[0]?.Experiment_ID] || [];


  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const sortNum = React.useCallback((rowA, rowB, columnId) => {
    const valA = rowA.values[columnId];
    const valB = rowB.values[columnId];
    const numA = parseFloat(valA);
    const numB = parseFloat(valB);


    if (!isNaN(numA) && !isNaN(numB)) {

      return numA > numB ? 1 : numA < numB ? -1 : 0;
    } else {

      return ('' + valA).localeCompare('' + valB, undefined, { numeric: true });
    }
  }, []);


  const chromosomeSort = useCallback((rowA, rowB, columnId) => {

    const getOrder = (chrom) => {
      if (chrom === 'X') return 24;
      if (chrom === 'Y') return 25;
      return parseInt(chrom, 10);
    };

    const valA = getOrder(rowA.values[columnId]);
    const valB = getOrder(rowB.values[columnId]);

    return valA > valB ? 1 : (valA < valB ? -1 : 0);
  }, []);

  const formatNumber = useCallback((number, decimals) => {
    if (number !== null && number !== undefined) {
      const strNumber = number.toString();
      const decimalPointIndex = strNumber.indexOf('.');

      if (decimalPointIndex !== -1) {

        const decimalPartLength = strNumber.length - decimalPointIndex - 1;
        if (decimalPartLength > decimals) {

          return number.toFixed(decimals);
        }
        return number.toString();
      }
      return number.toString();
    }
    return "";
  }, []);
  const geneSort = useCallback((rowA, rowB, columnId) => {
    const extractFirstGene = (geneValue) => {
      if (!geneValue) return '';
      const geneString = String(geneValue);
      const genes = geneString.split(/,|\s/).filter(Boolean);
      return genes[0].toUpperCase();
    };

    const valA = extractFirstGene(rowA.values[columnId]);
    const valB = extractFirstGene(rowB.values[columnId]);

    return valA.localeCompare(valB);
  }, []);

  const numColumns = useMemo(() => {
    return columns.map(group => {
      const newColumns = group.columns.map(column => {
        if (column.accessor === "Internal_Freq") {
          return {
            ...column,
            sortType: sortNum,
            Cell: ({ value }) => (
              <BootstrapTooltip title={value.toString()}>
                <span style={{
                  textDecoration: 'underline',
                  textDecorationStyle: 'dotted',
                  textDecorationColor: '#BDBDBD',
                  textUnderlineOffset: '3px'
                }}>{formatNumber(value, 6)}</span>
              </BootstrapTooltip>
            )
          };
        } else if (column.accessor === "Gene_name") {
          return {
            ...column,
            sortType: geneSort,
          };
        } else if (column.accessor === "SV_chrom") {
          return {
            ...column,
            sortType: chromosomeSort,
          };
        } else {
          const isNumeric = data.every(item =>
            item[column.accessor] !== undefined &&
            !isNaN(parseFloat(item[column.accessor])) &&
            isFinite(item[column.accessor])
          );
          if (isNumeric) {
            return {
              ...column,
              sortType: sortNum,
              Cell: ({ cell }) => {
                const { content, truncated } = addTooltipRow(cell, tooltipCompact, data);
                return (
                  <BootstrapTooltip title={truncated ? content : ""}>
                    <span>{cell.value}</span>
                  </BootstrapTooltip>
                );
              }
            };
          } else {
            return column;
          }
        }
      });

      return {
        ...group,
        columns: newColumns,
      };
    });
  }, [columns, sortNum, chromosomeSort, geneSort, data]);

  // Effect to update the table data only if the data changes
  useEffect(() => {
    if (data !== dataRef.current) {
      // Update table data
      dataRef.current = data; // Update ref to new data
    }
  }, [data]);

  const getAdditionalInfo = async (row) => {
    return !row.isExpanded;

  };
  const isFlagged = (row) => {
    let dataRow = row.original ? row.original : row;

    if (!dataRow || !dataRow.SV_chrom) {
      return false;
    }

    let annotations = undefined;
    let tagged_variants = states.all_tagged_variants;

    let variant_found_cnv = variantFinder(dataRow, annotations, tagged_variants, "CNV");
    if (variant_found_cnv !== undefined && variant_found_cnv.length !== 0) {
      return true;
    }

    let variant_found_somatic = variantFinder(dataRow, annotations, tagged_variants, "SNV_Somatic");
    if (variant_found_somatic !== undefined && variant_found_somatic.length !== 0) {
      return true;
    }

    return false;
  };

  const isCausative = (row) => {
    let dataRow = row.original ? row.original : row;

    if (!dataRow || !dataRow.SV_chrom) {
      return false;
    }
    let annotations = undefined;
    let tagged_variants = states.all_tagged_variants;

    let matched_variant = variantFinder(dataRow, annotations, tagged_variants, "CNV");

    return matched_variant?.[0]?.status === "Causative";

  };


  const dataWithIsFlagged = useMemo(() => {
    return data.map((row) => ({
      ...row,
      isFlagged: isFlagged(row),
      isCausative: isCausative(row),
    }));
  }, [data, states.all_tagged_variants]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state: { pageIndex, pageSize, selectedRowIds, sortBy, filters: activeFilters },
    resetResizing,
    rows,
  } = useTable(
    {
      columns: numColumns,
      data: dataWithIsFlagged,
      defaultColumn,
      filterTypes,
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          { id: 'SV_chrom', desc: false },
          { id: 'SV_start', desc: false },
          { id: 'SV_end', desc: false },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useBlockLayout,
    useResizeColumns,
    useOptionFilter(columns, type, states, setExpandedRows, dataWithIsFlagged)
  );

  useEffect(() => {
    gotoPage(0);
  }, [activeFilters, gotoPage]);

  useEffect(() => {

    // at the moment of creating a new query, "Run Query", take predfined gene list and get data from server

    // filter with gene list updated

    if (isSNVSelected) {
      // reset selected CNV
      states.setIdExpanded({ id: null, expanded: false, selected: false });
      states.setRowIdSelected(null);
      // empty redux store for cnv
      // reset CNV row only

      states.setSelected(null);

    }


  }, [isSNVSelected])


  useEffect(() => {
    if (pageIndex === pageCount - 1) {
      //window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [pageIndex, pageCount]);



  const firstPageRows = page;


  return (
    <>
      <Stack direction="column" spacing={2}>
        {selected_variant?.length > 0 ? (
          <Box
            sx={{
              backgroundColor: "#f5f5f5",
              width: "23%",
              padding: "10px",
              borderRadius: "6px",
              "&:hover": { backgroundColor: "#ebebeb" },
            }}
          >
            <Typography sx={{ fontWeight: "bold" }} className="ref">Chr: {selected_variant[0].SV_chrom}</Typography>
            <Typography sx={{ fontWeight: "bold" }} className="ref">Start: {selected_variant[0].SV_start}</Typography>
            <Typography sx={{ fontWeight: "bold" }} className="ref">End: {selected_variant[0].SV_end}</Typography>

            <Tooltip title={copied ? "Copied!" : "Copy to clipboard"}>
              <IconButton onClick={handleCopy} size="small">
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
          
        ) : (
          <Typography>No variant selected</Typography>
        )}

        <Stack direction="row" spacing={2}>

          <TagDialog
            annotate_variants={(label, array, type) => console.log(type)}  // edit. See how to add tagged variants to the store
            tagged_variants={states.all_tagged_variants}
            getAllTaggedVariants={states.getAllTaggedVariants}
            dataType={DATA_TYPE}
          />
          <CNVPreviousTags tagged_variants={states.all_tagged_variants} />
          <IGVLinks samples={samples} dataType={DATA_TYPE} />
          {/* <SamplesPopOver samples = { getSamples() } data_type={"somatic"}/>  */}
          <ButtonGroupCustom
            allColumns={allColumns}
            getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
            resetResizing={resetResizing}
            buttons={buttons}
            states={states}
            renderFeatures={renderFeatures}
          />
        </Stack>
      </Stack>
      <br />

      <TableContainer>
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <React.Fragment key={headerGroup.id}>
                <TableRow
                  sx={{

                    backgroundColor: headerGroupIndex === 0 ? '#3E69A9' : '#EAEAEA'
                  }}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, columnIndex) => (
                    <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}
                      sx={{
                        textAlign: "center",
                        borderRight: '1px solid #C4C4C4', '&:last-child': { borderRight: '0' }, position: 'sticky', top: 0, zIndex: 1, padding: "2px"
                      }}>
                      <div style={{ color: headerGroupIndex === 0 ? 'white' : 'inherit' }}>
                        {column.tooltip ? (
                          <BootstrapTooltip title={column.tooltip} forceUnderline={true}>
                            {column.render("Header")}
                          </BootstrapTooltip>

                        ) : (
                          column.render("Header")
                        )}
                        {column.id !== 'expansion' && (
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <IconButton>
                                  <ArrowUpward />
                                </IconButton>
                              ) : (
                                <IconButton>
                                  <ArrowDownward />
                                </IconButton>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        )}

                      </div>

                    </TableCell>
                  ))}
                </TableRow>
                {headerGroup.headers.some((column) => column.canFilter) ? (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <TableCell {...column.getHeaderProps()}
                        sx={{
                          display: 'flex!important',
                          textAlign: "center",
                          padding: "8px!important",
                          alignItems: 'center!important',
                          justifyContent: 'left!important',
                          position: 'sticky', top: 0, zIndex: 1,
                        }}    >
                        {column.canFilter && column.filterType && column.filterType.type !== 'none' ? column.render('Filter') : null}
                      </TableCell>
                    ))}
                  </TableRow>
                ) : null}
              </React.Fragment>
            ))}
            <TableRow style={{ display: "none" }}>
              <TableCell
                colSpan={visibleColumns.length}
                style={{ textAlign: "left", }}
              ></TableCell>
            </TableRow>
          </TableHead>

          <TableBodyCustom
            rowId={states.idExpanded.id}
            {...getTableBodyProps()}
          >

            {(firstPageRows.length !== 0 && total !== -1)
              ? firstPageRows.map((row, i) => {

                prepareRow(row);
                return (
                  <>
                    <TableRowCustom
                      className={`row${row.id}`}
                      {...row.getRowProps()}
                      rowId={states.idExpanded.id}
                      states={states}
                      expanded={states}
                      onClick={() => {

                        // Check if the clicked row is already selected
                        if (states.idExpanded.id === row.id && states.idExpanded.selected) {
                          // Deselect the row
                          row.toggleRowExpanded(false);
                          states.setIdExpanded({ id: null, expanded: false, selected: false });
                          states.setRowIdSelected(null);
                          // empty redux store for cnv
                          states.setSelected(null);
                        }
                        else {
                          // Close any currently expanded row
                          if (states.idExpanded.id !== null) {
                            const currentExpandedRow = firstPageRows.find(
                              (r) => r.id === states.idExpanded.id
                            );
                            if (currentExpandedRow) {
                              currentExpandedRow.toggleRowExpanded(false);
                            }
                          }

                          // Expand the clicked row
                          getAdditionalInfo(row).then((res) => {
                            if (res) {
                              row.toggleRowExpanded(true);
                              states.setIdExpanded({ id: row.id, expanded: true, selected: true });
                              states.setRowIdSelected(row.id);
                              states.setSelected(row.original);
                            }
                          });
                        }
                      }}
                      onMouseEnter={() => {
                        document.querySelector(`.row${row.id}`).style.backgroundColor = "#EAEAEA";
                        document.querySelector(`.row${row.id}`).style.cursor = "pointer";
                      }}
                      onMouseLeave={() => {
                        document.querySelector(`.row${row.id}`).style.backgroundColor = "#fff";
                      }}
                    >
                      {row.cells.map((cell) => {
                        const { truncated, content } = addTooltipRow(
                          cell,
                          tooltipCompact,
                          data
                        );

                        return (
                          <TableCell {...cell.getCellProps()}>

                            <BootstrapTooltip
                              title={truncated ? `${cell.value}` : ""}
                              isTruncated={truncated}
                            >
                              <div style={truncated ? { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' } : {}}>

                                {(() => {
                                  const matchedData = chipFull
                                    ?.flatMap((item) => item.cells)
                                    .find((data) => data.data === cell.value);
                                  if (matchedData) {
                                    return (
                                      <Chip
                                        size="small"
                                        label={content}
                                        sx={{
                                          backgroundColor: matchedData.color,
                                          color: matchedData.textColor
                                        }}
                                      />
                                    )
                                  }
                                  if (typeof cell.column.Header === "function") {
                                    return content;
                                  }

                                  return (
                                    <div>
                                      {cell.value ? (
                                        content
                                      ) : (
                                        <>N/A</>
                                      )}
                                    </div>
                                  );

                                })()}
                              </div>
                            </BootstrapTooltip>
                          </TableCell>
                        );
                      })}
                    </TableRowCustom>

                    {row.isExpanded && expandedRows[row.id]?.expanded ? (
                      <TableRow>
                        <TableCell sx={{ backgroundColor: "#F9F9F9" }} colSpan={visibleColumns.length}>
                          {expandedRows[row.id]?.data ? (
                            <SimpleTable
                              columns={nestedColumns}
                              data={expandedRows[row.id].data}
                              states={states}
                            />
                          ) : (

                            <div>Loading...</div>
                          )}
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </>
                );
              })
              : <TableRowNoData
                message={renderToStringAlt("No variants have passed the selected filters")}
                submessage={renderToStringAlt("To display variants, try using less restrictive filters or expanding the gene list")}
                colSpan={5}
              />
            }
          </TableBodyCustom>
        </MaUTable>
      </TableContainer>

      <br />

      <div style={{ display: "flex", alignItems: "center", height: "50px", justifyContent: "space-between" }}>

        <TablePagination
          labelRowsPerPage={renderToStringAlt("Rows per page:")}
          component="div"
          rowsPerPageOptions={[10, 25]}
          count={rows.length}
          rowsPerPage={pageSize}
          page={pageIndex}
          onPageChange={(event, newPage) => gotoPage(newPage)}
          onRowsPerPageChange={(event) => setPageSize(Number(event.target.value))}
          style={{ '& .MuiTablePagination-actions': { display: 'none' } }}
        />

        <Pagination
          count={pageCount}
          page={pageIndex + 1}
          onChange={(event, value) => gotoPage(value - 1)}
          showFirstButton
          showLastButton
        />

      </div>
    </>
  );
}


export default TableComponentForVariants;

const TableRowCustom = styled(TableRow)`
height: 50px;
  & > td {
    display: flex!important;
    align-items: center;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      //height: 40px;
      vertical-align: middle;
    }
  }
`;

// Styled component for the no data message
const NoDataMessageCell = styled(TableCell)`
  /*display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;*/
  color: grey;
  font-size: 16px;
  /*
  padding: 20px;
  */
  width: 100%;
  font-weight: bold;
`;

const NoDataSubMessageCell = styled(TableCell)`
  /*display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;*/
  color: grey;
  font-size: 16px;
  /*
  padding: 20px;
  */
  width: 100%;
`;

const TableRowNoData = ({ message, submessage, colSpan }) => (

  <TableRow style={{ background: "#fafafa" }}>
    <TableCell colSpan={20} align="center" style={{ padding: "24px" }}>
      <Typography
        variant="body1"
        style={{
          fontSize: '16px',
          color: 'gray',
          textAlign: 'left',
          fontWeight: "bold"
        }}
      >
        {message}
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontSize: '16px',
          color: 'gray',
          textAlign: 'left',
        }}
      >
        {submessage}
      </Typography>
    </TableCell>
  </TableRow>




  /*<TableRowCustom>
          <NoDataMessageCell colSpan={colSpan}>
              {message}
          </NoDataMessageCell>
          <NoDataSubMessageCell colSpan={colSpan}>
              {submessage}
          </NoDataSubMessageCell>
      </TableRowCustom>*/
);


const TableBodyCustom = styled(TableBody)`
  ${(props) => {
    return (
      props.rowId &&
      `.row${props.rowId} > td {
    background-color: #C6DBEF !important;
  }`
    );
  }}
`;