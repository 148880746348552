

import React from "react";




export const significance_opts_germline = [
    // specific for germline
    {value: "Pathogenic", label: "Pathogenic", id: "P"},
    {value: "Likely pathogenic", label: "Likely pathogenic",  id: "LP"},
    {value: "Uncertain significance", label: "Uncertain significance",  id: "U"},
    {value: "Likely benign", label: "Likely benign", id: "LB"},
    {value: "Benign", label: "Benign", id: "B"},
    // common
    {value: "Drug response", label: "Drug response", id: "D"},
    {value: "Association", label: "Association", id: "A"},
    {value: "Risk factor", label: "Risk factor", id: "R"},
    {value: "Protective", label: "Protective", id: "PR"},
    {value:  "Other", label:  "Other", id:"OT"},
];


export const significance_opts_somatic = [
    // specific for somatic
    {value: "Oncogenic", label: "Oncogenic", id: "P"},
    {value: "Likely oncogenic", label: "Likely oncogenic",  id: "LP"},
    {value: "Uncertain significance", label: "Uncertain significance",  id: "U"},
    {value: "Likely benign", label: "Likely benign", id: "LB"},
    {value: "Benign", label: "Benign", id: "B"},
    // common
    {value: "Drug response", label: "Drug response", id: "D"},
    {value: "Association", label: "Association", id: "A"},
    {value: "Risk factor", label: "Risk factor", id: "R"},
    {value: "Protective", label: "Protective", id: "PR"},
    {value:  "Other", label:  "Other", id:"OT"},
];


export const inheritance_opts = [
    {value: "Autosomal dominant", label: "Autosomal dominant"},
    {value: "Autosomal recessive", label:  "Autosomal recessive"},
    {value: "X-linked", label:  "X-linked"},
    {value: "X-linked dominant", label:  "X-linked dominant"},
    {value: "X-linked recessive", label:  "X-linked recessive"},
    {value: "Y-linked inheritance", label:  "Y-linked inheritance"},
    {value: "Mitochondrial inheritance", label:  "Mitochondrial inheritance"},
    {value: "Oligogenic inheritance", label:  "Oligogenic inheritance"},
    {value: "Digenic inheritance", label:  "Digenic inheritance"},
    {value:  "Autosomal dominant with paternal imprinting", label:   "Autosomal dominant with paternal imprinting"},
    {value:  "Autosomal dominant with maternal imprinting", label:   "Autosomal dominant with maternal imprinting"},
    {value:  "Unknown", label:   "Unknown"},
    {value:  "De novo", label:   "De novo"},

];

export const zygosity_opts = [
    {value: "Heterozygous", label:"Heterozygous"},
    {value: "Homozygous", label: "Homozygous"},
    {value: "Hemizygous", label:  "Hemizygous"},
];


export const related_to_opts = [
    {value: "Primary disorder", label:  "Primary disorder"},
    {value: "Secondary findings", label:  "Secondary findings"},
    {value: "Pharmacogenomics", label:  "Pharmacogenomics"},
    {value: "Polygenic risk score", label:  "Polygenic risk score"},
]

export const variant_type_opts = [
    {value: "SNV-InDel", label: "SNV-InDel"},
    {value: "Duplication", label:"Duplication"},
    {value: "Deletion", label:  "Deletion"},
    {value: "Inversion", label:  "Inversion"},
    {value: "Short tandem repeat", label:  "Short tandem repeat"},
    {value: "Translocation", label: "Translocation"},
]

export const cnv_variant_type_opts = [
    {value: "Duplication", label:"Duplication"},
    {value: "Deletion", label:  "Deletion"},
    {value: "Inversion", label:  "Inversion"},
    {value: "Insertion", label:  "Insertion"},
    {value: "Short tandem repeat", label:  "Short tandem repeat"},
    {value: "Translocation", label: "Translocation"},
]



export const origin_opts = [
    {value: "germline", label:  "germline"},
    {value: "somatic", label:  "somatic"},
    {value: "de_novo", label:  "de novo"},
    {value: "maternal", label:  "maternal"},
    {value: "paternal", label:  "paternal"},
    {value: "inherited", label:  "inherited"},
    {value: "unknown", label:  "unknown"},
];


export const origin_fusion_opts = [ 
    {value: "germline", label:  "germline"},
    {value: "somatic", label:  "somatic"},
    {value: "gene fusion", label:  "gene fusion"},
    {value: "de_novo", label:  "de novo"},
    {value: "unknown", label:  "unknown"},
]


