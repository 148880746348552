import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Checkbox, Divider, FormControlLabel, FormGroup, Grid, IconButton, Collapse, Typography } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import DeleteIcon from '@mui/icons-material/Delete';
import GPAP_Tooltip from "../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import { GPAP_Button_Light } from "../../../../../../../../gpap-lib/components/Button_Collection";
import GQ_SettingsInfo from "../../../clinical-info/GQ_SettingsInfo";
import MappedText from '../../../clinical-info/mapText';
import CheckBoxesComponent from "./CheckBoxesComponent/CheckBoxesComponent";
import SettingsBox from "../GQ_Form/SettingsBox";
import GPAP_Dialog from "../../../../../../../../gpap-lib/components/GPAP_Dialog";
import { IndexCell, SampleCell } from "./genotype-row/RowCells";
import * as Sample_Actions from "../../../../reducers/sample_actions";
import * as Patient_Actions from "../../../clinical-info/patient_actions";


class GenotypeRow extends Component {


    constructor(props){
        super(props);
        this.state = {
           open:false,
           checked: false,
           openDialog:false
        };

        this.setOpen = this.setOpen.bind(this);
        this.getAffectedBox = this.getAffectedBox.bind(this);
        this.deleteSample = this.deleteSample.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.restoreExperiments = this.restoreExperiments.bind(this);
        this.handleClose = this.handleClose.bind(this);

    }

    componentDidMount() {

        let { sample_case } = this.props;

        let finalVal = this.props.samples.find(s => s.sample_id === sample_case.id).affectedStatus;

        this.setState({
            checked: finalVal
        });

    }

    setOpen(){
        this.setState({open:!this.state.open})
    }


    getAffectedBox = () => {
        let { sample_case, gt_parameters } = this.props;

        //If somatic we dont need affected status
        if(gt_parameters.inheritance_key === "somatic"){
            return null
        }


        if(gt_parameters.inheritance_key === "custom" || gt_parameters.label.includes("compound")){

            return <FormGroup>
                <FormControlLabel   label={<MappedText text="Affected"/>}
                                    control={<Checkbox
                                    checked={this.state.checked}
                                    onChange={e => {
                                        this.setState({checked: e.target.checked })
                                        this.props.samples_actions.setAffectedStatus(sample_case.id, e.target.checked)
                                    }}
                                    />}
                />
            </FormGroup>
        }
        else{
            return (sample_case.affected) ? <CheckCircleTwoToneIcon color={"primary"}/> : <RadioButtonUncheckedIcon color={"primary"}/>

        }


    }

    deleteSample = (sample_id) =>{


        if(this.props.samples.length>1){
            this.props.samples_actions.delete_sample(sample_id);
            // TODO: We should delete the patient and add it back if the restore Experiments is clicked.
            //this.props.patient_actions.remove_patient(pheno_id);
        }
        else{
            this.openDialog();
        }

    };


    restoreExperiments() {

        // TODO: to fix this. This is not restoring the samples correctly.
        this.props.refreshExperiments();
        this.handleClose();
    }

    handleClose(){

        this.setState({openDialog: false})

    }

    openDialog(){
        this.setState({openDialog: true})
    }


    renderDialog(){

        return <GPAP_Dialog
            open = {this.state.openDialog}
            handleClose = {this.handleClose}
            title={"You need at least one experiment"}
            text={"You need at least one experiment to create an analysis. If you deleted an experiment by mistake, click on restore experiment"}
         /*   onExitText={"Restore Experiments"}
            onExitAction={this.restoreExperiments}*/
            onConfirmText={"Ok, keep this experiment"}
            onConfirmAction={this.handleClose}
            type="warning"

        />
    }

    render() {
        let {samples, sample_case, editable, gt, gt_parameters, settings_id} = this.props;
        let {open} = this.state;
        let self = this;

        let isCohortOrSA = (sample_case?.id === "ALL_SAMPLES");


        return (<Fragment>
              {this.renderDialog()}
              <SampleCell sample_case={sample_case}
                          isCohortOrSA={isCohortOrSA}
              />
              {(!isCohortOrSA) ?<IndexCell sample_case={sample_case}/> : null }
              {(!isCohortOrSA) ?<Grid item lg={1}>{this.getAffectedBox()}</Grid> : null}
              <CheckBoxesComponent
                  samples={samples}
                  sample_id={sample_case.id}
                  setting={gt}
                  inheritance_key = {gt_parameters.inheritance_key}
                  inheritance_type={gt_parameters.label}
                  editable={editable}/>
              <Grid item lg={2}>
                  <GPAP_Tooltip
                      title={<MappedText text={"set Genotype Settings"}/>} >
                      <GPAP_Button_Light
                          size={"small"}
                          style={{margin: "5px"}}
                          aria-label="tune"
                                           onClick={() => self.setOpen(!open)}
                                           aria-controls={sample_case.case + "_sliderbox"}
                                           aria-expanded={open}
                                           startIcon={ <TuneIcon fontSize="small"/>}>
                          <MappedText text="Quality Settings" />
                      </GPAP_Button_Light>

                  </GPAP_Tooltip>
                  <GQ_SettingsInfo
                      sample = {sample_case}
                      inheritance_key = {gt_parameters.inheritance_key}
                      inheritance_type={gt_parameters.label}/>
              </Grid>
              <Grid item lg={3}>
                  <IconButton edge="end" aria-label="delete">
                      <DeleteIcon onClick={()=> this.deleteSample(sample_case.id, sample_case.report_id)}/>
                  </IconButton>
              </Grid>
              <Grid item lg={12}>
                  <Collapse in={open}>
                      <div id={sample_case.case + "_sliderbox"} className={"sliderbox"}>
                          <SettingsBox
                              inheritance_key = {gt_parameters.inheritance_key}
                              gt_parameters={gt_parameters}
                              sample_id={sample_case.id}
                              sample_case={sample_case.case}/>
                      </div>
                  </Collapse>
              </Grid>
            {
                (gt === undefined || (gt.length === 0 && gt_parameters.inheritance_key!== "custom") ) ?  <Grid item lg={12}>
                    <Typography><MappedText text={"No genotype configured. The sample will not be included in the analysis"}/> </Typography>
                </Grid>
                    : null
            }
            <Grid item lg={12}>
                <Divider/>
            </Grid>

        </Fragment>)

    }

}

//state here is store;
// map the store to the props of MainPanel component;
function getSamples (state) {
    if(state.sample_list.length!==0)
    {
        return {
            studySettings: state.studySettings,
            studyBucket: state.studyBucket,
            samples: state.sample_list.samples,
        }
    }
    else{
        return {samples:[]}
    }
}

const mapDispatchToProps = (dispatch) => ({
    samples_actions: bindActionCreators(Sample_Actions, dispatch),
    patient_actions: bindActionCreators(Patient_Actions, dispatch)
});



export default connect(getSamples,mapDispatchToProps)(GenotypeRow);

