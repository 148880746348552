

import React from "react";

function getTechnicalLimitations(analysisType){


    let technicalLimitations= {"WES":"NA","panels":"NA","WGS":"NA", "Whole exome sequencing": "NA"};
    

    if ( typeof analysisType !=="string"){
        return technicalLimitations;
    } else {
        if (analysisType === "somatic_fusion_analysis") {
          technicalLimitations = {
            "WES": "Detection of somatic gene fusions from mRNA sequencing is influenced by multiple factors, including expression levels, purity of the sample and RNA quality. Low or inconsistent gene expression may reduce the sensitivity of fusion detection, particularly for fusions involving genes with low transcript abundance. Identified fusions have not been validated by an independent analysis.",
            "Whole exome sequencing": "Detection of somatic gene fusions from mRNA sequencing is influenced by multiple factors, including expression levels, purity of the sample and RNA quality. Low or inconsistent gene expression may reduce the sensitivity of fusion detection, particularly for fusions involving genes with low transcript abundance. Identified fusions have not been validated by an independent analysis.",
            "panels": "Detection of somatic gene fusions from mRNA sequencing is influenced by multiple factors, including expression levels, purity of the sample and RNA quality. Low or inconsistent gene expression may reduce the sensitivity of fusion detection, particularly for fusions involving genes with low transcript abundance. Identified fusions have not been validated by an independent analysis.",
            "WGS": "Detection of somatic gene fusions from mRNA sequencing is influenced by multiple factors, including expression levels, purity of the sample and RNA quality. Low or inconsistent gene expression may reduce the sensitivity of fusion detection, particularly for fusions involving genes with low transcript abundance. Identified fusions have not been validated by an independent analysis."
          };
        } else if (analysisType.includes("somatic")) {
        technicalLimitations = {
            "WES" : "Variant detection in samples with lower tumour content is less sensitive. In case of a low tumour purity (below 20%) likelihood of failing to detect potential variants increases. The internal assessment of specificity and sensitivity of variant detection protocols using the KAPA HyperExome capture kit from Roche® is of 99.54% and 99.69% respectively for single nucleotide variants and of 70.62% and 89.99% for small insertions / deletions (indels) of up to 10 nucleotides. Other types of genetic variants such as repeat expansions, copy number variants, structural variants or variations in regions of high homology (pseudogenes and homologues) may not be detected. This approach neither captures deep intronic variants nor intergenic regions nor variants in promoter or regulatory regions of the gene. Consecutive variants forming haplotypes are not collapsed to multiple nucleotide variants (MNVs) and are reported as individual events. Variants have not been validated by an independent analysis.",
            "Whole exome sequencing": "Variant detection in samples with lower tumour content is less sensitive. In case of a low tumour purity (below 20%) likelihood of failing to detect potential variants increases. The internal assessment of specificity and sensitivity of variant detection protocols using the KAPA HyperExome capture kit from Roche® is of 99.54% and 99.69% respectively for single nucleotide variants and of 70.62% and 89.99% for small insertions / deletions (indels) of up to 10 nucleotides. Other types of genetic variants such as repeat expansions, copy number variants, structural variants or variations in regions of high homology (pseudogenes and homologues) may not be detected. This approach neither captures deep intronic variants nor intergenic regions nor variants in promoter or regulatory regions of the gene. Consecutive variants forming haplotypes are not collapsed to multiple nucleotide variants (MNVs) and are reported as individual events. Variants have not been validated by an independent analysis.",

            "panels": "Variant detection in samples with lower tumour content is less sensitive. In case of a low tumour purity (below 20%) likelihood of failing to detect potential variants increases. The internal assessment of specificity and sensitivity of variant detection protocols using the KAPA HyperExome capture kit from Roche® is of 99.54% and 99.69% respectively for single nucleotide variants and of 70.62% and 89.99% for small insertions / deletions (indels) of up to 10 nucleotides. Other types of genetic variants such as repeat expansions, copy number variants, structural variants or variations in regions of high homology (pseudogenes and homologues) may not be detected. This approach neither captures deep intronic variants nor intergenic regions nor variants in promoter or regulatory regions of the gene. Consecutive variants forming haplotypes are not collapsed to multiple nucleotide variants (MNVs) and are reported as individual events. Variants have not been validated by an independent analysis.",

            "WGS": "Variant detection in samples with lower tumour content is less sensitive. In case of a low tumour purity (below 20%) likelihood of failing to detect potential variants increases. The internal assessment of specificity and sensitivity of variant detection protocols using whole genome sequencing is of 99.79% and 99.94% respectively for single nucleotide variants,  99.38% and 99.04% for small insertions / deletions (indels) of up to 10 nucleotides and of 99% and 42% for copy numbers variants with a minimum resolution of 5kb. Other types of genetic variants such as repeat expansions, large structural rearrangements or variations in regions of high homology (pseudogenes and homologues) may not be detected. Consecutive variants forming haplotypes are not collapsed to multiple nucleotide variants (MNVs) and are reported as individual events. Variants have not been validated by an independent analysis."
        };
    } else if (analysisType === "germline") {
        technicalLimitations = {
            "WES" :"The internal assessment of specificity and sensitivity of variant detection protocols using the KAPA HyperExome capture kit from Roche® is of 99.54% and 99.69% respectively for single nucleotide variants and of 70.62% and 89.99% for small insertions / deletions (indels) of up to 10 nucleotides. Other types of genetic variants such as repeat expansions, copy number variants, structural variants or variations in regions of high homology (pseudogenes and homologues) may not be detected. This approach neither captures deep intronic variants nor intergenic regions nor variants in promoter or regulatory regions of the gene. Consecutive variants forming haplotypes are not collapsed to multiple nucleotide variants (MNVs) and are reported as individual events. Variants have not been validated by an independent analysis. ",
            "Whole exome sequencing":"The internal assessment of specificity and sensitivity of variant detection protocols using the KAPA HyperExome capture kit from Roche® is of 99.54% and 99.69% respectively for single nucleotide variants and of 70.62% and 89.99% for small insertions / deletions (indels) of up to 10 nucleotides. Other types of genetic variants such as repeat expansions, copy number variants, structural variants or variations in regions of high homology (pseudogenes and homologues) may not be detected. This approach neither captures deep intronic variants nor intergenic regions nor variants in promoter or regulatory regions of the gene. Consecutive variants forming haplotypes are not collapsed to multiple nucleotide variants (MNVs) and are reported as individual events. Variants have not been validated by an independent analysis. ",
            "panels":"The internal assessment of specificity and sensitivity of variant detection protocols using the KAPA HyperExome capture kit from Roche® is of 99.54% and 99.69% respectively for single nucleotide variants and of 70.62% and 89.99% for small insertions / deletions (indels) of up to 10 nucleotides. Other types of genetic variants such as repeat expansions, copy number variants, structural variants or variations in regions of high homology (pseudogenes and homologues) may not be detected. This approach neither captures deep intronic variants nor intergenic regions nor variants in promoter or regulatory regions of the gene. Consecutive variants forming haplotypes are not collapsed to multiple nucleotide variants (MNVs) and are reported as individual events. Variants have not been validated by an independent analysis. ",

            "WGS":"The internal assessment of specificity and sensitivity of variant detection protocols using whole genome sequencing is of 99.79% and 99.94% respectively for single nucleotide variants,  99.38% and 99.04% for small insertions / deletions (indels) of up to 10 nucleotides and of 99% and 42% for copy numbers variants with a minimum resolution of 5kb. Other types of genetic variants such as repeat expansions, large structural rearrangements or variations in regions of high homology (pseudogenes and homologues) may not be detected. Consecutive variants forming haplotypes are not collapsed to multiple nucleotide variants (MNVs) and are reported as individual events. Variants have not been validated by an independent analysis. "
        };
    }

        return technicalLimitations;
        
     }

}
export default getTechnicalLimitations;