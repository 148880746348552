import React from "react";
// MUI Components
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';


export const GeneTableRow = ({isItemSelected, handleClick, row, classes}) => {


    return  <TableRow
            key={row.gene}
            hover
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
            onClick={(event) => handleClick(event, row.gene)}
            className={isItemSelected ? classes.selectedRow : ''}
        >
            <TableCell className={`${classes.pointerCursorCell} ${isItemSelected ? classes.geneNameSelected : ''}`}
                       align="left" component="th" scope="row">{row.gene}</TableCell>
            <TableCell className={classes.pointerCursorCell} align="left">{row.n_var}</TableCell>
            <TableCell className={classes.pointerCursorCell} align="left">{row.n_exp}</TableCell>
        </TableRow>


}