import { Box, Checkbox, Dialog, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ViewColumn } from '@mui/icons-material';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useNavigate from '../../hooks/useNavigate';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { setTable } from '../../../../../reducers/slices/tables/table.slice';
import CaButton from "../../../../../gpap-lib/components/shared-components/CaButton";
import MappedText from '../../../../../scenes/instand/reports/create-report/shared-components/mapText';
import AddIcon from "@mui/icons-material/Add";


const ButtonGroup = ({  
  allColumns,
  getToggleHideAllColumnsProps,
  isClinicalReportsView,
  buttons,
  states,
  reportType,
  renderFeatures,
}) => {
  useEffect(() => {
    if (states.tableName === "dirs") {
    const hideColumn = [
      'tumor_experiment_id',
      'control_experiment_id',
      'creation_date',
      'creator',
      'experiment_id',
      'clinical_referrer',
      'clinical_referrer_contact',
      'hospital_name'
    ];
    
    hideColumn.forEach(column => {
      const columnToHide = allColumns.find(c => c.id === column);
      if (columnToHide) {
        columnToHide.toggleHidden(true);
        columnToHide.getToggleHiddenProps().disabled = true;
      }
    });
  }
}, [allColumns, states.tableName]); 

  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const showColumnsButton = states.customFeatures.some(feature => feature.columnsButton === true);


  const validButtons = Array.isArray(buttons) ? buttons : [];

  const reportsButtons = validButtons.filter(button => 
    button.nameButton === "Reports" || button.nameButton === "Upload"
  );

  const popoverButtons = validButtons.filter(button => 
    button.type === "popover" && states.rowIdSelected
  );

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '3px', width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
          {showColumnsButton && (
            <Box display={'flex'} gap={'10px'}>
              <CaButton 
              onClick={handleDialogOpen} 
              text='Columns'  
              startIcon={<ViewColumn />}  
              variant="outlined" 
              />
            {renderFeatures}

          </Box>)}
          {validButtons.some(button => button.nameButton === 'Create') && ( 
            <CaButton
              variant="contained" 
              text="Create request" 
              startIcon={<AddIcon />}  
              onClick={() => {
                navigate('/create_dir'); 
              }}
              customStyles={{ marginLeft:"970px" }} 
            />
        )}
        </div>
        
       <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginBottom: '10px' }}>
    
        {validButtons.some(button => button.nameButton === 'Upload') && (
          <CaButton
            variant="outlined" 
            text="Upload report"
            startIcon={<CloudUploadIcon />} 
            onClick={() => {
              dispatch(setTable({ option: 'reportType', value: states.reportType }));
              dispatch(setTable({ option: 'isOpenUpload', value: true }));
            }}
            customStyles={{width:"145px"}}
          />
        )}
        

        {validButtons.some(button => button.nameButton === 'Reports') && (
          <CaButton
            variant="contained"
            text="Create report"
            startIcon={<AddIcon />}
            onClick={() => navigate(`/dir_overview/${id}/new_clinical_report`)}
            customStyles={{width:"140px"}}
          />
        )}
        
      </div>
        {popoverButtons.map(button => (
          <BasicPopover
            key={button.nameButton}
            states={states}
            text={button.nameButton}
            render={button.template}
            data={button.data}
          />
        ))}
      </div>
      <Dialog onClose={handleDialogClose} open={dialogOpen}>

      <DialogTitle style={{ textAlign: 'center', marginTop: '30px', marginBottom: '-20px' }}><MappedText text={"Column Hiding"}/></DialogTitle>
      <Box id="column-hiding" my={2}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
      <Checkbox {...getToggleHideAllColumnsProps()} indeterminate style={{marginBottom: '20px' }}  />
      <Typography style={{ fontWeight: 'bold', marginBottom: '20px',fontFamily: 'Roboto!important' }}><MappedText text={"Toggle All"}/></Typography>
      </Box>
      <Box display="flex" flexWrap="wrap">

      {allColumns.map((column, index) => {
      if (index === 0 || index === allColumns.length - 1) {
        return null;
      }
      const displayName = column.Header || (column.id && column.id.charAt(0).toUpperCase() + column.id.slice(1));

      if (!displayName) {
      return null;
      }

      return (

      <Box key={column.id} flex="1" minWidth="50%">
      <Typography style={{ fontFamily: 'Roboto!important' }}>
      <Checkbox {...column.getToggleHiddenProps()} />
      {displayName}
      </Typography>
      </Box>
      );
      })}
      </Box>
      </Box>
      </Dialog>

</>

  );
};
export default ButtonGroup;
