export function post_fusions(urlprefix, token, data, par_id){
    return fetch(urlprefix + "/participant/tag_gene_fusions/" + par_id,
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}

    )
}


export function delete_fusions(urlprefix, token, data, par_id){
    return fetch(urlprefix + "/participant/tag_gene_fusions/" + par_id,
        {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}

    )
}


export function put_fusions(urlprefix, token, data, par_id){
    return fetch(urlprefix + "/participant/tag_gene_fusions/" + par_id,
        {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}

    )
}