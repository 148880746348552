import {standardSettings, controlSettings, tumorSettings} from "./gq_config";



export const getRenderingSettings = (sampleCase, AlternateSettings) => {
         
    // In the case of somatic and in the control sample keep only the relevant field
    if (sampleCase === 'control') {
        return controlSettings;
    }
    
    // In the case of somatic and in the tumor sample keep only the relevant fields
    if (sampleCase === 'tumor') {
        return tumorSettings;
    }

    if(AlternateSettings === undefined){
        standardSettings.find (s => s.name === 'Alternate').config = AlternateSettings;
    }


    return standardSettings;
};