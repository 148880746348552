import React from "react";
import { Grid, Typography } from "@mui/material";
import Fusions_Table from "./Fusions_config";
import { useSelector } from 'react-redux';
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import AlertInfoViews from "../reports/create-report/shared-components/AlertInfo";
import { renderToStringAlt } from "../reports/create-report/shared-components/renderToString";

function Fusions() {
  const token = useSelector(state => state.authorization.token);
  const baseUrl = window.config.apis_configuration.api_nextgpap_server_elastic;
  const url = `${baseUrl}/api/gene_fusion`;

  const samples = useSelector(state => state.generaltable.allDataApis.samples);
  const participant = useSelector(state => state.generaltable.allDataApis.participants);

  let sampleID;

  if(Array.isArray(samples) && samples.length>0){
      sampleID =samples[0].sampleID; // take the first one..
  }
  else{
      sampleID = samples?.sampleID;
  }




  return (
    <Grid container direction={"row"}>
      <Grid item lg={12}>
        <Typography variant={"h5"} style={{  fontWeight: "700" }}><MappedText text="Gene Fusions"/></Typography>
      </Grid>
        <Grid item lg={12}>
        <AlertInfoViews text={renderToStringAlt("These are all the gene fusions our system calculated. They’re not affected by the request’s filters or gene list.")} />
        </Grid>
      <Grid item lg={12}>
        <Fusions_Table
          participant = {participant[0]}
          url = {url}
          token={token}
          sampleID={sampleID}
          enableTag={true}
        />
      </Grid>
    </Grid>
  );
}

export default Fusions;
