import React, { Fragment, useEffect, useState } from "react";

// Redux imports
import { connect } from "react-redux";

// Material-UI imports
import {
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ListItemText,
    Typography,
    List,
    ListItem,
    Divider,
    ListItemIcon,
    CircularProgress,
    Chip, 
    Box
} from "@mui/material";

// Material-UI icon imports
import TuneIcon from '@mui/icons-material/Tune';
import PersonIcon from '@mui/icons-material/Person';

// Custom components and services
import { GPAP_Button_Gray } from "../../../gpap-lib/components/Button_Collection";
import GPAP_Tooltip from "../../../gpap-lib/components/GPAP_Tooltip";
import QueryFilters_View from "./QueryFilters_View";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import { get_all_filter_sets, deleteFilterSet } from "../../../services/api/manage_study";
import { Delete as DeleteIcon } from "@mui/icons-material";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import {IconButton} from "@mui/material";
import GPAP_Dialog from "../../../gpap-lib/components/GPAP_Dialog";
import { useSelector } from 'react-redux';


function FilterSetView(props){


    const [filter_sets, setFilterSets] = useState([]);
    const [openDialogFilters, setOpenDialogFilters]= useState(false);
    const [selectedFilterSet, setSelectedFilterSet]= useState(null);
    const [title, setTitle]= useState(null);
    const [initialized, setInitialized]=useState(false);
    const [loading, setLoading] = useState(false);
    const [errorDialog, setErrorDialog] = useState(false);

    const {groups, preferredName} = useSelector((state) => state.authorization);

    let adminUser = groups.includes("admin");


    useEffect(function(){
        if (!initialized){
            setLoading(true)
            get_all_filter_sets(window.config.apis_configuration.api_nextgpap_server, props.token)
            .then(r => r.json())
            .then(function(json){
                setLoading(false)
                let data = json.data.filter(s => s.filter_set_name !== "");

                data.forEach(function(s){
                    s["label"] = "user";
                })

                let full_list = data;
                setFilterSets(full_list);
                setInitialized(true);

            })
        }

       

    }, [initialized])


    const handleDelete = (item) => {

        let {id} = item;

        deleteFilterSet(window.config.apis_configuration.api_nextgpap_server, props.token, { filter_set_id: id })
            .then(r => {
                if (!r.ok) {
                    throw new Error(`HTTP error! status: ${r.status}`);
                }
                return r.json();
            })
            .then(json => {
                let data = json.message;
                console.log(json);
                setInitialized(false);
            })
            .catch(error => {
                console.error("Error occurred:", error);
                setErrorDialog(true);
                // Handle the error appropriately, e.g., show an error message to the user
            });

    }

    const viewDialogFilters = (filterSet, title) => {
        setSelectedFilterSet(filterSet);
        setTitle(title);
        setOpenDialogFilters(true);
    }

    const handleClose = () => {
        setOpenDialogFilters(false); 
        
    }

    const renderFilterDialog = () => {
        return (
            <Dialog open={openDialogFilters} onClose={handleClose} maxWidth={"lg"}>
                <DialogTitle>{title} {selectedFilterSet ? selectedFilterSet.filter_set_name : ""}</DialogTitle>
                <DialogContent>
                    {selectedFilterSet ? (
                        <div style={{width: "400px"}}>
                            <QueryFilters_View
                                filtersObj={selectedFilterSet}/>
                        </div>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        <MappedText text="Close"/>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }


    return <div>
        {renderFilterDialog()}
        <Grid container spacing={2} direction={"column"}>
            <Grid item xs={12} md={6}>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                    <MappedText  text="Available Filter Sets"/> {(filter_sets.length !== 0 ? "(" + filter_sets.length + ")" : "")}
                </Typography>
                <Typography sx={{ mt: 4, mb: 2 }} variant="body2" color="text.secondary" component="div">
                <MappedText  text="The filters shown below include both built-in filters and those you have created."/>
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                { (loading)
                    ? <CircularProgress/>
                    :  <List
                    sx={{
                        width: '100%',
                        maxWidth: "100%",
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 500,
                        '& ul': { padding: 0 },
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    dense={false}>
                    {filter_sets.map(function(item){
                        return <Fragment>
                            <ListItem>
                                
                                <ListItemText
                                    primary={item.filter_set_name}
                                    secondary={item.description ? item.description : <MappedText text="No description"/>}
                                />
                               
                                {
                                    (item.creator == "admin")
                                     ?  <Box p={1}><Chip label="Default" size="small" > </Chip> </Box>
                                     : null
                                }
                                <GPAP_Button_Gray onClick={() => viewDialogFilters(item.filters, item.filter_set_name)}><MappedText text={"View filters"}/></GPAP_Button_Gray>
                                <ListItemSecondaryAction>
                               {
                                 (adminUser || item.creator === preferredName)
                                 ? <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(item)}>
                                 <DeleteIcon /></IconButton>
                                 : null
                               } 
                            </ListItemSecondaryAction>       
                            </ListItem>
                            <Divider />
                        </Fragment>

                    })}
                </List>
                
                

                }
                   
            </Grid>
        </Grid>
        <GPAP_Dialog
                open={errorDialog}
                handleClose={() => setErrorDialog(false)}
                title={"Delete filter set error"}
                text={`"The Filter Set cannot be deleted because it is used in an existing study. The functionality of deleting a used filter set is not available at the moment."`}
                onExitText={false}
                onConfirmText={"Close"}
                onExitAction={false}
                onConfirmAction={() => setErrorDialog(false)}
                type="error"
            />
    </div>


}

function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
            query: state.query,
        }
    }
}


export default connect(mapStateToProps, null)(FilterSetView);