import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next'; 
import MappedText from './mapText'; 
// export const renderToString = (text) => {
//   return ReactDOMServer.renderToString(
//     <I18nextProvider i18n={i18next}>
//       <MappedText text={text} />
//     </I18nextProvider>
//   );
// };

export const renderToStringAlt = (text) => {
  return i18next.t(text, { defaultValue: text });
}