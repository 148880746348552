import React from 'react';
import { Grid, Typography, TextField, FormGroup, FormControlLabel, Checkbox, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MappedText from "../../../../../../../../../instand/reports/create-report/shared-components/mapText";
import CaButton from "../../../../../../../../../../gpap-lib/components/shared-components/CaButton";
import {
    CustomSelect,
    SelectStandard
} from "../../../../../../../../../../gpap-lib/components/shared-components/renderer";
import {sectionHeader} from "./sectionHeader";

const SubmissionFormFusions = ({
                         isFlagged,
                         deleteFunction,
                         //deleteSpec,
                         dataType,
                         handleTextChange,
                         dataComments,
                         mappedTextIntro, // Unique intro text for each form
                         deleteMappedText, // Unique delete text for each form
                         data,
                         formOptions,
                         onSelectedOption,
                         state,
                         handleCausativeChange,
                     }) => {
    const size = 12;

    return (
        <Grid container direction="column" spacing={2} style={{padding: '16px'}}>
            {isFlagged && (
                <Grid item lg={size} style={{ backgroundColor: '#c6dbef', padding: '10px', borderRadius: '5px' }}>
                    <Box p={1}>
                    <Typography variant="subtitle1">
                        <MappedText text={`${mappedTextIntro} has already been tagged and will be displayed in the `} />
                        <span style={{ fontWeight: 'bold' }}><MappedText text="Report" /></span>
                        <MappedText text=" module. Editing this tag will modify the interpretation comment." />
                    </Typography>
                    </Box>
                    
                </Grid>
            )}
            
            {!isFlagged && (
                <Grid item lg={size}>
                     <Box p={1}>
                     <Typography variant="subtitle1">
                        <MappedText text={`Tagging a ${mappedTextIntro} will make it available to add in a `} />
                        <span style={{ fontWeight: 'bold' }}><MappedText text="Report" /></span>.
                    </Typography>
                     </Box>
                    
                </Grid>
            )}
          <Grid item lg={size}>
                        {sectionHeader(<MappedText text="Fusioned genes" />, false)}
                        <SelectStandard
                            isDisabled={true}
                            name="fusioned_genes"
                            defaultValue={formOptions.genes || ""}
                            style={{ width: '100%', fontSize: '14px' }}
                            options={formOptions.genes}
                            handleChange={onSelectedOption}
                        />
             </Grid>
            <Grid item lg={size}>
                {sectionHeader(<MappedText text="Related to" />, false)}
                <SelectStandard
                    name="variant_type"
                    defaultValue={formOptions.related_to.find((opt) => opt.value === data.related_to)}
                    handleChange={onSelectedOption}
                    style={{ width: '100%', fontSize: '14px' }}
                    options={formOptions.related_to}
                />
            </Grid>
    
           
            <Grid item lg={size}>
                {sectionHeader(<MappedText text="Type of variant" />, false)}
                <SelectStandard
                    name="sv_type"
                    defaultValue={formOptions.variant_type.find((opt) => opt.value === data.variant_type)}
                    handleChange={onSelectedOption}
                    style={{ width: '100%', fontSize: '14px' }}
                    options={formOptions.variant_type}
                />
            </Grid>
    
            <Grid item lg={size} style={{ marginBottom: '16px', overflow: 'visible' }}>
                {sectionHeader(<MappedText text="Clinical Significance" />, false)}
                <SelectStandard
                    name="significance"
                    inputId="uniqueClinSignInputID"
                    defaultValue={formOptions.significance_opts.find((opt) => opt.value === data.significance)}
                    handleChange={onSelectedOption}
                    style={{ width: '100%', fontSize: '14px' }}
                    options={formOptions.significance_opts}
                    MenuProps={{
                        disablePortal: true, // Keeps the menu within the Grid container
                        PaperProps: {
                            style: {
                                zIndex: 1500, // Ensure dropdown is fully above everything
                                backgroundColor: 'white', // Prevents transparency issues
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                            },
                        },
                    }}
                />
            </Grid>

            <Grid item lg={size} >
                {sectionHeader(<MappedText text="Interpretation" />, false)}  
                <TextField
                    fullWidth
                    id="standard-multiline-static"
                    label={""}
                    multiline
                    rows={4}
                    onChange={handleTextChange}
                    defaultValue={dataComments}
                />
            </Grid>
    
            <Grid item lg={size}>
                <Box p={1} style={{ backgroundColor: '#e5f6fd', borderRadius: '5px' }}>
                {sectionHeader(<MappedText text="Is this the Causative Variant?" />, false)}
                <FormGroup row>
                    <FormControlLabel
                        control={<Checkbox checked={state.causative} onChange={handleCausativeChange} name="causative" />}
                        label={<MappedText text="Causative Variant (confirmed as the cause of the primary disorder)" />}
                    />
                </FormGroup>
                </Box>
            </Grid>
    
            {isFlagged && (
                <Grid item lg={size}>
                    <CaButton
                        variant="contained"
                        text={deleteMappedText}
                        aria-label="delete"
                        onClick={() => deleteFunction(dataType)}
                        startIcon={<DeleteIcon />}
                    />
                </Grid>
            )}
        </Grid>
    );
    
};

export default SubmissionFormFusions;
