import createVariantRow from "./createVariantRow";
import { mergeHits } from "./mergeHits";


export function getTableData(variants, summary, annotations, analysisType, samples){

    let data = [];

    if(variants!== undefined){


        const { hits: { hits: variantHits } } = variants; // Destructuring for clarity
       

        const type = summary ? "search_across" : "default"; // Ternary operator cleaned up

        const mergedHits = type === "search_across" ? mergeHits(variantHits) : variantHits;


        mergedHits.forEach(function(hit){

            let obj = hit._source;

            // how many genes in effs;

            let gene_names = [...new Set(hit.fields.effs.map(s => s.gene_name))];

            // filter out undefined;

            gene_names = gene_names.filter(s => s !== undefined);

            gene_names.forEach(function(geneName){

                   let row=  createVariantRow(obj, hit, annotations, analysisType, samples, geneName);
                   data.push(row);

            })


        });
    }

    return data;
}