

import React, {Fragment, useEffect, useState} from 'react';

import {Chip} from '@mui/material';

import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import {getStudy} from "../../variant-table/getAnnotations";

import {
    format_Analysis_To_Update,
    formatQuery,
    formatStudy
} from "../../../../side-panels/study-store/helpers";
import {
    delete_study, deleteAnalysis,
    save_analysis,
    save_study,
    saveQuery, update_analysis,
    update_study,
    updateQuery
} from "../../../../../../../../../services/api/manage_study";
import {bindActionCreators} from "redux";
import * as SidebarActions from "../../../../../../../../../components/SideNavigation/sidebar_actions";
import * as StudyActions from "../../../../../../../reducers/study_actions";

import {connect} from "react-redux";


import GPAP_Dialog from '../../../../../../../../../gpap-lib/components/GPAP_Dialog';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {get_list_data} from "../../../../../../../../../services/api/lists_api";
import MappedText from '../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';
import StudySaveDialog from "./study-save-dialog/StudySaveDialog";
import SaveErrorDialog from "./ErrorDialog";
import TrackedResponsesPopOver from "./TrackedResponsesPopOver";

import addTrackedResponse from "./utils/addTrackedResponse";
import inheritances_keys from "../../../../side-panels/study-store/components/list-material-ui/inheritance_list";
import CaButton from "../../../../../../../../../gpap-lib/components/shared-components/CaButton";
import {SaveAsOutlined} from "@mui/icons-material";
import Badge from "@mui/material/Badge";




const api_next_gpap = window.config.apis_configuration.api_nextgpap_server;


function SaveDialog(props) {

    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorDialog, setErrorDialog] = useState(false);
    const [currentTrackedResponses, setTrackedResponses] = useState([]);
    const [permission, setPermission] = useState("user");
    const [currentStudy, setCurrentStudy] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [tmpList, setTmpLists] = useState([]);
    const [studyDetails, setStudyDetails] = useState({
        studyName: "",
        description: "",
        permission: "user"
    });


    const updateDetails = () => {

        let study_to_save = getStudy(props.studySettings, props.studyBucket);
        const STUDY = study_to_save.study;
        setCurrentStudy(STUDY);

        // if it's a new study, the permission value may be empty.
        let permissionValue = STUDY?.permission ?? "user";

        setStudyDetails({
            studyName: STUDY.studyName,
            description: STUDY.description,
            permission: permissionValue
        });

        setPermission(permissionValue);

    }


    const saveLists = (analyses) => {

        //collect all possible gene lists;
        let query_objects = [];

        analyses.forEach(function (analysis) {
            analysis.queries.forEach(function (query) {
                // predefined gene lists
                // read applied filters...
                let extra_filters = query.extra;
                let id_array = extra_filters.map(g => g.value).filter(s => s !== undefined);

                if (id_array.length !== 0) {

                    query_objects.push(id_array);

                }
            })

        })

        // query the genelists;

        // Create an array of promises using the query_objects and get_list_data function
        let promises = query_objects.map(id_array => get_list_data(props.token, window.config.apis_configuration.api_nextgpap_server, {list_id: id_array})
            .then(y => y.json()));

        // Execute all promises and process the results
        Promise.all(promises).then(results => {
            // Initialize an empty array to store unique lists
            let lists = [];
            // Iterate through each result
            results.forEach(function (el) {
                // Check if the result has 'data'
                if ("data" in el) {
                    // Find if the current data already exists in the 'lists'
                    if (lists.findIndex(r => r.id === el["data"][0].id) === -1) {
                        // Concatenate the new data to the 'lists'
                        lists = lists.concat(el["data"]);
                        // Return the updated 'lists'
                        return lists;
                    }
                }
            });

            // Set the final list of unique data to the state variable

            console.log(lists);
            setTmpLists(lists);
        });

    }

    useEffect(() => {
        setSuccessDialogOpen(success);
    }, [success]);

    useEffect(() => {
        console.log("new query added, fetch lists");
        if (currentStudy?.analyses) {
            saveLists(currentStudy.analyses);
        }

    }, [props.n_queries])

    useEffect(function () {

        updateDetails();
       /* if (!currentStudy) {
            updateDetails();
        }*/


    }, [open])


    const handleOpen = () => {
        setOpen(true)
    };


    const handleClose = () => {
        setOpen(false);
        document.body.style.overflow = 'auto'; 
        //setCurrentStudy(false);
    };

    const handleOpenSuccess = () => {
        setSuccess(true);
    };


    const removeGenesOfPredefinedGeneList = (query) => {

        let query_x = {...query};
        // remove genes that I don't need as I have already a reference to it in the extra field of the query.

        let isOperatorApplied = query.extra.find(s => s.hasOwnProperty("gene_list_operator"));


        if(isOperatorApplied && isOperatorApplied.gene_list_operator === "union")
        {
            let filters = (query_x.filters !== undefined) ? JSON.parse(query_x.filters) : query.filter_set.filters;

            let pred_list_applied = query.extra.find(s => s.type === "Predefined List");

            if (pred_list_applied) {
                console.log(tmpList);

                // Check if resp is truthy and handle accordingly
                if (tmpList.length > 0) {

                    let gene_list = tmpList.find(list => list.id === pred_list_applied.id);
                    let genes = gene_list.data;

                    // exclude all those genes coming from this list;
                    filters.gene = filters.gene.filter(function (el) {
                        return !genes.includes(el);
                    });
                }


            }

            query_x.filters = JSON.stringify(filters);
        }



        return query_x;

    };

    const handleChangePermission = (event) => {
        setPermission(event.target.value);
    }

    const showErrorDialog = () => {

        setErrorDialog(!errorDialog);
        setErrorDialog(true);
        //setCurrentStudy(false);

    }


    const saveAllStudy = async () => {


        let {token} = props;


        let study_current = {...currentStudy};
        study_current.permission = permission;
        study_current.studyName = (studyDetails.studyName !== study_current.studyName) ? studyDetails.studyName : study_current.studyName;
        study_current.description = studyDetails.description;
        let study = getStudy(props.studySettings, props.studyBucket);
        study_current.info = (study.study.info !== undefined) ? study.study.info : "";
        let formatted_study = formatStudy(study_current);

        formatted_study.study_id = study.study.id;

        let trackedResponses = [];
        setTrackedResponses(trackedResponses);

        if (formatted_study.studyName === "") {
            alert(<MappedText text="Please, give a name to your study to save it correctly. "/>)
        }
        else if (study.study.id !== undefined) {

            const action_type = "update";
            // update study
            try {
                const resp = await update_study(api_next_gpap, token, formatted_study);
                const data = await resp.json();

                let { id, message } = data;

                if (resp.ok) {
                    props.studyActions.setStudySettings({ study_id_db: id, study_status: true });

                    trackedResponses = addTrackedResponse(trackedResponses, resp.ok, message , action_type, formatted_study.studyName);
                    let new_analyses = study_current.analyses.filter(analysis_post => analysis_post.id === undefined);
                    let analyses_to_updated = study_current.analyses.filter(analysis_post => analysis_post.id !== undefined);

                    await saveAnalysesOfStudy(new_analyses, id, trackedResponses, token)

                    await updateAnalysesOfStudy(analyses_to_updated, id, trackedResponses, token);
                }
                else {
                    // if study info NOT updated correctly

                    trackedResponses = addTrackedResponse(trackedResponses, resp.ok, message , action_type, formatted_study.studyName);

                }
            } catch (error) {
                // Handle errors here
                trackedResponses = addTrackedResponse(trackedResponses, false, error.statusText , action_type, formatted_study.studyName);


            }

        }
        else {
            const action_type = "save";
            try {
                const resp = await save_study(api_next_gpap, token, formatted_study);
                const data = await resp.json();

                let {id, message} = data;

                if (resp.ok){
                    props.studyActions.setStudySettings({study_id_db: id, study_status: true});
                    props.studyActions.add_study_id_db({study_id: id, localId: study.study.localId});


                    trackedResponses = addTrackedResponse(trackedResponses, resp.ok, message , action_type, formatted_study.studyName);

                    // save analyses
                    await saveAnalysesOfStudy(study_current.analyses, id, trackedResponses, token)

                } else {
                    trackedResponses = addTrackedResponse(trackedResponses, resp.ok, message , action_type, formatted_study.studyName);
                }
            }
            catch (error) {
                trackedResponses = addTrackedResponse(trackedResponses, false, error.statusText , action_type, formatted_study.studyName);

            }
        }

        // check if there were errors found;
        const errors = trackedResponses.filter(s => !s.success);


        if(errors.length === 0){
            handleClose();
            handleOpenSuccess();
            setSuccessDialogOpen(true);
        }
        else{
            setTrackedResponses(trackedResponses);
            showErrorDialog();

        }

        // update studyName
        props.studyActions.change_study_info(
            {
                studyName: study_current.studyName,
                description: study_current.description,
                studyID: study.study.localId,
                permission: study_current.permission
            });

        setPermission(study_current.permission);

    };


    // Filter gt_settings to remove objects that do not have both "dp" and "gq"
    const cleanGtSettings = (analysis_copy) => {
        const newAnalysisCopy = JSON.parse(JSON.stringify(analysis_copy));

        newAnalysisCopy.samples.forEach(sample => {

          let gt_settings = sample?.gt_settings ? sample?.gt_settings : sample.gt;

          sample.gt_settings = gt_settings.filter(gt_setting => {
            return gt_setting.hasOwnProperty('dp') && gt_setting.hasOwnProperty('gq');
          });

        });

        return newAnalysisCopy;
    }



    const saveAnalysesOfStudy = async (analysisList, study_id, trackedResponses, token) => {

        const action_type = "save";
        for (const item of analysisList) {
            if (item.queries.length !== 0) {
                item["study_id"] = study_id;

                // Filter gt_settings to remove objects that do not have "dp" and "gq"
                const cleanedAnalysisItem = cleanGtSettings(item);

                let inheritance = inheritances_keys.find(inheritance => inheritance.key === item.inheritance_mode_id);
                try {
                    const resp = await save_analysis(api_next_gpap, token, cleanedAnalysisItem);
                    const data = await resp.json();

                    // TODO: TO DISCUSS
                    if(resp.ok){
                        let analysis_id = data.id;

                        trackedResponses = addTrackedResponse(trackedResponses, resp.ok, data.message , action_type, "Analysis " + inheritance.label);


                        props.studyActions.add_analysis_id_db({
                            localId: item.localId,
                            study_id: study_id,
                            analysis_id: analysis_id
                        });

                        // save Queries
                        await saveQueriesOfAnalysis(item.queries, analysis_id, trackedResponses, token, study_id, item.queries.length);

                    }
                    else{
                        trackedResponses = addTrackedResponse(trackedResponses, resp.ok, "Queries not saved" , action_type, "Analysis " + inheritance.label);

                    }

                }
                catch (error) {
                    trackedResponses = addTrackedResponse(trackedResponses, false,  "Queries not saved" , action_type, "Analysis " + inheritance.label);

                }
            }
        }


    }

    const updateAnalysesOfStudy = async (analysisList, study_id, trackedResponses, token) => {

        const action_type = "update";

        for (const item of analysisList) {
            // analyses to update;

            let inheritance = inheritances_keys.find(inheritance => inheritance.key === item.inheritance_mode_id);

            if (item.id !== undefined) {
                item["study_id"] = study_id;

                let analysis_to_check = { ...item };
                analysis_to_check.analysis_id = item.id;
                let analysis_copy = format_Analysis_To_Update(analysis_to_check);
                
                // Filter gt_settings to remove objects that do not have "dp" and "gq"
                const cleanedAnalysisCopy = cleanGtSettings(analysis_copy);


                try{
                    const resp = await update_analysis(api_next_gpap, token, cleanedAnalysisCopy);
                    const data = await resp.json();


                    if(resp.ok){

                        trackedResponses = addTrackedResponse(trackedResponses, resp.ok, data.message , action_type, "Analysis " + inheritance.label);
                        let analysis_id = item.id;
                        let queries = item.queries;
                        let queries_not_saved = queries.filter(s => s.query_id === undefined);
                        let saved_queries = queries.filter(s => s.query_id !== undefined);

                        // save new queries
                        await saveQueriesOfAnalysis(queries_not_saved, analysis_id, trackedResponses, token, study_id, queries.length);

                        // update old queries
                        await updateQueriesOfAnalysis(saved_queries, analysis_id, trackedResponses, token);


                    }
                    else{
                        trackedResponses = addTrackedResponse(trackedResponses, resp.ok, "Queries not saved/updated" , action_type, "Analysis " + inheritance.label);

                    }



                }
                catch(error){
                    trackedResponses = addTrackedResponse(trackedResponses, false,  "Queries not saved/updated" , action_type, "Analysis " + inheritance.label);

                }

            }
        }

    }


    const saveQueriesOfAnalysis = async (queries, analysis_id, trackedResponses, token, study_id, total_queries) => {

        const action_type = "save";

        for (let index = 0; index < queries.length; index++) {
            let x = queries[index];
            let query_x = removeGenesOfPredefinedGeneList(x);
            let item_to_post = formatQuery(query_x, analysis_id);

            try{
                const resp = await saveQuery(api_next_gpap, token, item_to_post);
                const data = await resp.json();

                if (resp.ok) {

                    trackedResponses = addTrackedResponse(trackedResponses, resp.ok, data.message , action_type, item_to_post.name);
                    props.studyActions.add_query_id_db({
                        query_id: data.query_id,
                        localId: x.localId,
                        study_id: study_id,
                        analysis_id: x.analysis_id
                    });

                }
                else {
                    trackedResponses = addTrackedResponse(trackedResponses, resp.ok, data.message , action_type, item_to_post.name);

                    // delete analysis and study but also total number of analyses must be checked
                   /* if(total_queries === 1){
                        trackedResponses = revertSaving(analysis_id, study_id, token, trackedResponses, action_type)
                    }*/


                }
            }
            catch(error){

                trackedResponses = addTrackedResponse(trackedResponses, false, error.statusText , action_type, item_to_post.name);
/*
                if(total_queries === 1){

                    trackedResponses = revertSaving(analysis_id, study_id, token, trackedResponses, action_type)
                }
*/

            }

        }

    }

    const revertSaving = async(analysis_id, study_id, token, trackedResponses, action_type) => {

        let body = {analysis_id: analysis_id };
        const deleteResp = await deleteAnalysis(api_next_gpap, token, body);
        let body_study_delete = {study_id: study_id };
        const deleteStudyResp = await delete_study(api_next_gpap, token, body_study_delete)
        trackedResponses = addTrackedResponse(trackedResponses, false, "Study was not saved" , action_type, studyDetails.studyName);

        return trackedResponses;

    }

    const updateQueriesOfAnalysis = async(queries, analysis_id, trackedResponses, token)=> {

        const action_type = "update";
        for (const x of queries) {
            let query_x = removeGenesOfPredefinedGeneList(x);
            let item_to_post = formatQuery(query_x, analysis_id);
            item_to_post.query_id = x.query_id;
            try
            {
                let resp = await updateQuery(api_next_gpap, token, item_to_post);
                const data = await resp.json();

                if (resp.ok) {
                    trackedResponses = addTrackedResponse(trackedResponses, resp.ok, data.message , action_type, item_to_post.name);

                }
                else{
                    trackedResponses = addTrackedResponse(trackedResponses,false, "Query not updated" , action_type, item_to_post.name);
                }
            }
            catch(error){
                trackedResponses = addTrackedResponse(trackedResponses, false, error.statusText , action_type, item_to_post.name);


            }
        }


    }

    const getIfOwner = () => {

        let currentStudy = getStudy(props.studySettings, props.studyBucket);

        if (props.studySettings.study_id_db) {

            // if the study ID didn-t change

            // currentStudy is an object ONLY in SaveDialog
            // props.studySettings.id is the id associated to the study when it's created or loaded again.

            if (currentStudy.study.creator === undefined) {
                if (currentStudy.study.localId === props.studySettings.id) {
                    return true;
                }
            } else {
                return currentStudy.study.creator === props.preferredName;
            }

        } else {
            return true;
        }

        // check if study owner is the same as the logged user;
        // if yes return true
        // studySettings: info of the current study
        // studyBucket: store all the studies you have saved in the session
    }

    const renderSaveButton = () => {
        // rendering of Save Button

        const customStyles = {
            marginTop: "0.5%",
            marginRight: "0.5%",
            marginLeft: "0%",
            float: "left", // Adjust as necessary
        };

        let bool = getIfOwner()

        if (bool) {

            return (
                (props.unsaved > 0)
                    ?
                    /*  move up into render save button*/
                    <span><Badge
                        style={{
                            float: "left",
                            marginTop: "0.5%",
                            marginRight: "0.8%",
                        }}
                        color={"error"}
                        badgeContent={
                            <GPAP_Tooltip title={<MappedText text="You have unsaved queries"/>}>
                                <span>{props.unsaved}</span>
                            </GPAP_Tooltip>
                        }
                    >
                       <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                           <CaButton
                            variant={"contained"}
                            id="save_update"
                                  startIcon={<SaveAsOutlined/>}
                                  text={"Save"}
                                  onClick={handleOpen}
                            sx={{
                                ...customStyles, // Merge custom styles with default styles
                            }}/>
                       </div>
                    </Badge>
                        <TrackedResponsesPopOver trackedResponses = {currentTrackedResponses}/>
                    </span>
                    : <span>
                    <GPAP_Tooltip title={<MappedText text="Save current Study with Analyses and queries"/>}>
                                               <div style={{ display: 'block', float: "left", justifyContent: 'flex-start', alignItems: 'center' }}>
                           <CaButton
                            variant={"contained"}
                            id="save_update"
                                  startIcon={<SaveAsOutlined/>}
                                  text={"Save"}
                                  onClick={handleOpen}
                            sx={{
                                ...customStyles, // Merge custom styles with default styles
                            }}/>
                                                   </div>
                    </GPAP_Tooltip>
                    <TrackedResponsesPopOver trackedResponses = {currentTrackedResponses}/>
                        </span>

            );
        }
        else {
            return (
                <GPAP_Tooltip title={<MappedText text="You are not the creator of the study"/>}>
                    <Chip icon={<VisibilityIcon/>} label="Read only" style={{
                        marginTop: "0.5%",
                        marginRight: "0.5%",
                        marginLeft: "0%",
                        float: "left"
                    }}/>
                </GPAP_Tooltip>
            );
        }


    }





    return (<Fragment>
            {errorDialog ? (
                <SaveErrorDialog errorDialog = {errorDialog}
                                 setErrorDialog={setErrorDialog}
                                 trackedResponses={ currentTrackedResponses }
                />
            ) : null
            }
            <GPAP_Dialog
                open={successDialogOpen}
                handleClose={() => setSuccessDialogOpen(false)}
                title={"Study Saved Successfully"}
                onExitText={false}
                onConfirmText={"Close"}
                onExitAction={false}
                onConfirmAction={() => setSuccessDialogOpen(false)}
                onConfirmId="close-success-dialog"
                type="success"
            />

            {renderSaveButton()}


            {(currentStudy)
                ? <StudySaveDialog
                    handleClose={handleClose}
                    currentStudy={currentStudy}
                    open={open}
                    studyDetails={studyDetails}
                    setStudyDetails={setStudyDetails}
                    handleChangePermission={handleChangePermission}
                    saveAllStudy={saveAllStudy}
                    list_of_studies={props.list_of_studies}
                    permission = {permission}
                />
                : null
            }
        </Fragment>
    )

}





//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.queryContainer !== undefined)
        {

            // check if unsaved or not

            let study = getStudy(state.studySettings, state.studyBucket);
            let all_queries= [];
            study.study.analyses.forEach(function(analysis){
                all_queries = all_queries.concat(analysis.queries)
            })

            let unsaved_bool = all_queries.filter(query => query.query_id === undefined).length;

            return {
                queryList: state.queryContainer.queryList,
                token: state.authorization.token,
                preferredName: state.authorization.preferredName,
                studyBucket: state.studyBucket,
                studySettings: state.studySettings,
                predefined_genes: state.geneList.predefined_genes,
                unsaved: unsaved_bool,
                n_queries: all_queries.length
            }
        }

    }
}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    sidebarActions: bindActionCreators(SidebarActions, dispatch),
    studyActions: bindActionCreators(StudyActions, dispatch)
});


export default connect(mapStateToProps,mapDispatchToProps)(SaveDialog);