import React from 'react';
import { Box, Grid, TextField } from '@mui/material';
import MappedText from './mapText';
import InterpretationTitle from './InterpretationTitle';


const SelectedRowInfoBox = ({ fields, comments, selected, handleOnTextChange, dataType }) => {


    return (
        <Box pt={2}>
            <InterpretationTitle fields={fields} 
                                 dataType={dataType}
                                 selected={selected}/>
            <Box pt={1}>
                <Grid container direction="row">
                    <Grid item lg={12}>
                        <Box pt={1}>
                        <label><MappedText text="Comment"/></label>
                            <TextField
                                id="outlined-multiline-flexible"
                                multiline
                                defaultValue={comments}
                                maxRows={4}
                                style={{ width: '100%' }}
                                disabled={!selected}
                                onChange={handleOnTextChange}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default SelectedRowInfoBox;
