

export const pharmaMatchingRule = (item, targetObject) => {
    return (item.gene === targetObject.gene &&
        item.genotype === targetObject.genotype &&
        item.haplotype === targetObject.haplotype &&
        item.haplotype2 === targetObject.haplotype2 &&
        item.phenotype === targetObject.phenotype
    )
}

export const treatmentMatchingRule = (treatment, treatment_data) => {                         
    return (treatment.gene === treatment_data.gene &&
        treatment.variant === treatment_data.variant &&
        treatment.cancer_type === treatment_data.cancer_type &&
        treatment.therapy === treatment_data.therapy &&
        treatment.evidence_level === treatment_data.evidence_level)
    }

export const fusionMatchingRule = (item, target) => {
    return (item.fusioned_genes === target.fusioned_genes &&
        item.junction_read_count === target.junction_read_count &&
        item.left_gene === target.left_gene &&
        item.right_gene === target.right_gene &&
        item.sample === target.sample
    )}    


