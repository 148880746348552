import React, { useState } from "react";
import { Typography, Slider, Box, IconButton, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as Actions from "../../../../reducers/sample_actions";
import GPAP_InfoIcon from "../../../../../../../../gpap-lib/components/GPAP_InfoIcon";

const GQForm = (props) => {
  const { config, inheritance_key, sample_id, queryKey, inheritance_type, studySettings, actions, label, subtitle, tooltip, defaultValue } = props;

  // Ensure deep copy for initial state to prevent mutations
  const init_value = Array.isArray(defaultValue) 
  ? [...defaultValue] : config.defaultValue;
  const [value, setValue] = useState(init_value);
  const [edit, setEdit] = useState(config.editable ?? true);

  const handleChange = (e, val) => {
    let val_f = val ?? parseInt(e.target.value, 10);
    
    // Prevent setting values outside the defined range
   /*  if (config.min !== undefined && val_f < config.min) val_f = config.min;
    if (config.max !== undefined && val_f > config.max) val_f = config.max; 
    if (isNaN(val_f)) val_f = config.defaultValue;*/

    actions.setGQ_parameter(sample_id, queryKey, val_f, inheritance_key, inheritance_type, studySettings.analysisType);
    setValue(val_f);
  };

  const valueLabelFormat = (text) => (props.name === "Alternate" ? parseInt(text, 10) / 100 : text);

  const GPAP_Slider = () => (
    <Box p={2}>
      <Slider
        onChange={handleChange}
        //defaultValue={defaultValue}
        getAriaValueText={(v) => v}
        valueLabelFormat={valueLabelFormat}
        aria-labelledby={config.label}
        step={config.step}
        valueLabelDisplay="auto"
        marks={config.marks}
        value={value}
      />
    </Box>
  );

  const getTextField = () => {
    const { label , min, max } = config;

    let errorBool = (value < min || value > max || value === "" || value === undefined || isNaN(value))


    return (
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="subtitle1">{label}</Typography>
         { (subtitle) && <Typography variant="subtitle2" color={"text.secondary"}>{subtitle}</Typography>} 
        <TextField
          type="number"
          value={value}
          onChange={handleChange}
          inputProps={{ min, max }}
          variant="outlined"
          size="small"
          error={errorBool}
          helperText={errorBool ? `Value must be between ${min} and ${max}. The default value will be used in this case.` : ""}
        />
      </Box>
    );
  };

  const renderInput = () => (!Array.isArray(config.defaultValue) 
  ? getTextField() 
  : GPAP_Slider());

  return (
    <Box>
      <Typography id="discrete-slider-custom" gutterBottom>
        <Box display="flex" alignItems="center">
          {label}
          <Box ml={1}>
            <GPAP_InfoIcon title={tooltip} />
          </Box>
        </Box>
      </Typography>
      {renderInput()}
    </Box>
  );
};

// Map state to props
const mapStateToProps = (state) => ({
  studySettings: state.studySettings || "",
  token: state.authorization?.token || "",
});

// Map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GQForm);
