// React Imports
import React from "react";

// Material UI Imports (v5)
import { ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, IconButton, Badge } from "@mui/material";
import { makeStyles } from "@mui/styles"; // Note: @mui/styles is a separate package in MUI v5

// Material UI Icons Imports (v5)
import ListIcon from '@mui/icons-material/List';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import WarningIcon from '@mui/icons-material/Warning';

// Custom Components Imports
import GPAP_Tooltip from "../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";

// Material UI Colors Imports (v5)
import green from "@mui/material/colors/green";
import orange from "@mui/material/colors/orange";
import {Box, Typography} from "@mui/material";


// Redux Imports
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(2),
    },
    queryText:{
        fontSize:"13px"
    },
    queryText_selected:{
        fontWeight: "bold",
        fontSize:"13px"
    },
    queryText_secondary:{
        fontSize:"11px"
    },
    customBadge: {
        backgroundColor: "#3182bd",
        color: "white"
    }
}));


function QueryItem(props){

    const classes = useStyles();

    const {current_query, analysisType, key} = props;
    const n_variants = ( props.query.outputs!== undefined && props.query.outputs.length!== 0 )
        ? props.query.outputs[props.query.outputs.length-1].n_variants
        : "NA";
    const query_item = props.query;

    const queryName = (query_item!== undefined && query_item.name!== undefined) ?  query_item.name : "query_" + query_item.query_id;


    const getColor = () => {

        return (query_item.localId === current_query) ? "orange": "gray";

    };

    const getSelected = () => {
        return (query_item.localId === current_query) ? classes.queryText_selected: classes.queryText;
    };


    const getSavedIcon = () => {
        let saved = props.query.query_id!== undefined;

        if(saved){
            return <GPAP_Tooltip title={"Query Saved to DB"} ><span><SaveIcon style={{ color: green[500] }}/></span></GPAP_Tooltip>
        }
        else{
            return <GPAP_Tooltip title={"Query not saved yet"} ><WarningIcon style={{ color: orange[500] }}/></GPAP_Tooltip>
        }

    };

    const getDeleteTool = () => {

        if(props.readOnly){
            return null
        }
        else if(props.readOnly === undefined){
            return <IconButton edge="end" aria-label="run"><DeleteIcon onClick={() => props.queryDelete(query_item)}/></IconButton>
        }
        else{
            return <IconButton edge="end" aria-label="run"><DeleteIcon onClick={() => props.queryDelete(query_item)}/></IconButton>
        }

    }

    const renderActions = () => {


       return <ListItemSecondaryAction>
                {getSavedIcon()}
                {(!props.readOnly)
                    ? <IconButton
                        edge="end"
                        aria-label="run"
                        onClick={() => props.queryViewer(query_item, analysisType)}>
                        <PlayArrowIcon style={{color: getColor()}}/>
                </IconButton>
                    : null
                }
               {getDeleteTool()}
            </ListItemSecondaryAction>


    }




    return <ListItem
        key={key}
        button className={classes.nested}>
        <ListItemIcon>
            <span>
                <Badge
                    overlap="rectangular"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    badgeContent={n_variants}
                    max={999}
                    classes={{badge:classes.customBadge}}
                ><GPAP_Tooltip title={"Number of variants"}>
                    <ListIcon/></GPAP_Tooltip>
                    </Badge>
            </span>
        </ListItemIcon>
        <ListItemText
                classes={{
                    primary: getSelected(),
                    secondary: classes.queryText_secondary,
                }}
                primary={
                    <Box display="flex" alignItems="center">
                    <Typography>{queryName}</Typography>
                    <Typography sx={{ color: "#2171b5", marginLeft: 1 }}>
                        {n_variants} variants
                    </Typography>
                    </Box>
                }
                secondary={
                    query_item.filter_set.filter_set_name !== ""
                    ? query_item.filter_set.filter_set_name
                    : "Custom filters"
                }
                />
        {renderActions()}

    </ListItem>



}



function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return { "current_query": state.studySettings.current_query}
    }
}





export default connect(mapStateToProps,null)(QueryItem);

