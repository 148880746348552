
import React from "react";
import MappedText from "../../../../../../../../../instand/reports/create-report/shared-components/mapText";



function getTitle(tagVisibility, dataType, found, infoObj) {


    const dataTypeTitles = {
        "SNV_Germline": {
            "extra_tag": "Tag SNV Variant",
            "sample_tag": "Update/Delete Tag",
            "no_tag": "New Tag"
        },
        "SNV_Somatic": {
            "extra_tag": "Tag SNV Variant",
            "sample_tag": "Update/Delete Tag",
            "no_tag": "New Tag"
        },
        "Treatment_Somatic": "Tag Clinical Evidence",
        "Pharmacogenomics_Germline": "Tag Haplotype",
        "Fusions": "Tag Gene Fusion" 
    };

    if(infoObj && infoObj.deleted){
        return <MappedText text={"Tag deleted"} />;
    }
    else if(infoObj && infoObj.submitted){
        return <MappedText text={"Tag submitted"} />;
    }
    else if(infoObj && infoObj.updated){
        return <MappedText text={"Tag updated"} />;
    }
    else if (dataType in dataTypeTitles) {
        const titleMapping = dataTypeTitles[dataType];
        if (typeof titleMapping === "string") {
            return <MappedText text={titleMapping} />;
        } else if (tagVisibility in titleMapping) {
            return <MappedText text={titleMapping[tagVisibility]} />;
        }
    } else if (dataType === "CNV") {
        if (found !== undefined && found.length > 0) {
            return <MappedText text={"CNV previously tagged"} />;
        } else {
            return <MappedText text={"Tag CNV Variant"} />;
        }
    }
}


export default getTitle;