import React from "react";
import { renderToStringAlt } from "../../shared-components/renderToString.js";


export default function getDisclaimer(analysisType) {
    let disclaimer;

    if (typeof analysisType !== "string") {
        return disclaimer;
    } else {
        if (analysisType === "germline") {
            disclaimer = [
                <span>
                    {renderToStringAlt("The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to the American College of Medical Genetics (ACMG) recommendations (Richards et al. 2015, Riggs et al. 2020), ClinGen recommendations (Welcome to ClinGen ) and the Human Variation Variant Society (HGVS)")}
                    (<a href="https://varnomen.hgvs.org/" target="_blank" rel="noopener noreferrer">https://varnomen.hgvs.org/</a>).
                    {renderToStringAlt("Genetic counselling and recommendations are based on GeneReviews guidelines (Margaret et al 1993-2020).")}
                </span>
            ];
        } else if (analysisType === "somatic_fusion_analysis") {
            disclaimer = [
                <span>
                    {renderToStringAlt("The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. The conclusion of this report is based solely on the results of the RNA sequencing of the tumour and the received tumour type. Final interpretation of the clinical consequence of this report should therefore always be performed by the treating physician.")}
                </span>
            ];
        } else if (analysisType==="somatic_tumor_only"||analysisType==="somatic_tumor_normal") {
            disclaimer = [
                <span>
                    {renderToStringAlt("The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to ClinGen, CGC and VICC recommendations (Horak et al 2022) and the Human Variation Variant Society (HGVS)")}
                    (<a href="https://varnomen.hgvs.org/" target="_blank" rel="noopener noreferrer">https://varnomen.hgvs.org/</a>).
                    {renderToStringAlt("Only FDA or EMA approved treatments are reported. The conclusion of this report is based solely on the results of the DNA sequencing of the tumour and the received tumour type. Final interpretation of the clinical consequence of this report should therefore always be performed by the treating physician.")}
                </span>
            ];
        }

        return disclaimer;
    }
}