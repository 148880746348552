
import {cnv_variant_type_opts, variant_type_opts} from "../tag_form_options";

export function getVariantType(dataType) {

if (dataType === "CNV") {
    return cnv_variant_type_opts;
}
else if(dataType === "Fusions") {
    return [{value: "Gene Fusion", label: "Gene Fusion"}];
}
else {

    return variant_type_opts;
}

}