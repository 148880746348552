    export const cleanFusionObject = (data, state, patients) => {

        let fusions_obj_final = {...data, causative: state.causative};
        const report_id = patients.patients[0].report_id;
        // rename sv_type in fusion_obj_final with "related_to"
        let interpretation = fusions_obj_final?.comments ?? "";

        let fusion_obj_renaming = {...fusions_obj_final, 
                    interpretation: interpretation};
        delete fusion_obj_renaming.sv_type;
        delete fusion_obj_renaming.comments;

        return {fusion_obj_renaming, report_id}


    }