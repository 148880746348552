



import React, {useEffect,useState} from 'react';
import {Field, initialize, reduxForm} from 'redux-form';
import "../../../css/query-view.css";

import { connect } from 'react-redux'
import {SelectArea, TextFieldForm} from "../../../../../../../../../../gpap-lib/components/shared-components/renderer";

import * as SNVCONFIG from "./SNV_Config";

import {dict_class_snv} from "./SNV_Config";
import { Grid, Box, Typography } from '@mui/material';
import {GPAP_Button_Light} from "../../../../../../../../../../gpap-lib/components/Button_Collection";
import MappedText from '../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';



let SNV_Section = (props) => {

    const { handleSubmit, pristine, reset, submitting, meta } = props;
    

    const resetSection = () => {

        const resetObj = {
            cadd_phred: null,
            revel:null,
            mutationtaster: [],
            siftpred: [],
            polyphen2hvarpred:[],
            OCScore: null,
            onco_classifier:[]
        };

        if(Object.keys(resetObj).length>0){
            props.dispatch(initialize(
                'snv_effects',
                resetObj,
            ));
        }

    };
    const [init_obj_statefull, setinit_obj] = useState({});
    useEffect(function(){

        if(props.default_filters!== undefined){

            if(props.default_filters){
                let obj = {...props.default_filters};

                let keys = Object.keys(obj);

                if(!keys.includes("cadd_phred")){
                    keys.push("cadd_phred");
                    obj["cadd_phred"] = null;
                }

                let init_obj = {};
                keys.forEach(function(key){
                    let options = dict_class_snv[key];
                    if(key === "cadd_phred" || key === "revel" || key === "spliceai_max_ds" || key === "OCScore" ){
                        if(obj[key] !== null){

                            init_obj[key] = (obj[key] !== undefined)
                                ? obj[key]
                                : null ;
                        }
                        else{
                            init_obj[key] = null;
                        }

                    }

                    else{
                        let vals = obj[key];
                        let trues = Object.keys(vals).filter(k => vals[k]);
                        if(options!== undefined){
                            let values = options.filter(opt => trues.includes(opt.value));
                            init_obj[key] = values
                        }
                    }
                });
                if ( JSON.stringify(init_obj_statefull) !== JSON.stringify(init_obj)){
                    console.log("dispatch action");
                  
                  setinit_obj(init_obj);
                if(Object.keys(init_obj).length>1){
                    //console.log("SNV effect updated, what to do ?")
                    props.dispatch(initialize(
                        'snv_effects',
                        init_obj,
                    ));
                }}
            }
            else{
                resetSection()
            }


        }

    },[props.default_filters]);

    const handleChange = (e) => {
        //console.log(e)
    }
    
    let somatic = props.somatic ? props.somatic : false;
    return (
        <div className={"form_section"}>
            <div className={'bar'}>
                <GPAP_Button_Light size="small" disabled={pristine || submitting} onClick={() => resetSection()}>
                    <MappedText text={"Clear Filters"}/>
                </GPAP_Button_Light>
            </div>
            <form onSubmit={handleSubmit}>
                <div className={"form_container"}>
                <SelectArea collection_name={'mutationtaster'} header = 'Mutation Taster' fields = {SNVCONFIG.mutation_taster}/>
                <SelectArea collection_name={'siftpred'} header = {'SIFT'} fields = {SNVCONFIG.siftpred}/>
                <SelectArea collection_name={'polyphen2hvarpred'} header = {'Polyphen2 hvar'} fields = {SNVCONFIG.polyphen2hvarpred}/>
                {somatic && (<SelectArea collection_name={'onco_classifier'} header = {'Onco classifier'} fields = {SNVCONFIG.onco_classifier}/>)}

                <SelectArea collection_name={'intervarclasses'} header = {'InterVAR '} fields = {SNVCONFIG.intervarclasses}/>


                    <Grid item lg={12}>
                        <div className="box-body">
                            <Box p={2}>
                            <Field
                                helperText = <MappedText text={"Phred-scaled CADD score, indicating level of deleteriousness e.g. 20 (predicted top 1% most deleterious)"}/>
                                name={'cadd_phred'}
                                label= <MappedText text={"CADD Prediction"}/>
                                component={TextFieldForm}
                                onChange={handleChange}
                                defaultValue={"cadd_phred"}/>
                            </Box>
                        </div>
                    </Grid>
                    {somatic && ( <Grid item lg={12}>
                        <div className="box-body">
                            <Box p={2}>
                            <Field
                                helperText = {"OCScore"}
                                name={'OCScore'}
                                label= {"OCScore Prediction"}
                                component={TextFieldForm}
                                onChange={handleChange}
                                defaultValue={"OCScore"}/>
                            </Box>
                        </div>
                    </Grid>

                    
                    )}
                    <Grid item lg={12}>
                        <div className="box-body">
                            <Box p={2}>
                            <Field
                                helperText = {"The REVEL score for an individual missense variant can range from 0 to 1, with higher scores reflecting greater likelihood that the variant is disease-causing."}
                                name={'revel'}
                                label= {"Revel Prediction"}
                                component={TextFieldForm}
                                onChange={handleChange}
                                defaultValue={"revel"}/>
                            </Box>
                        </div>
                    </Grid>
                    <Grid item lg={12}>
                        <div className="box-body">
                            <Box p={2}>
                            <Field
                                helperText = {"SpliceAI delta scores, ranging from 0 to 1, indicate the probability that a variant affects splicing within +/- 500 base pairs. Jaganathan et al 2019 proposed cutoffs: 0.2 for high recall, 0.5 as the recommended threshold, and 0.8 for high precision."}
                                name={'spliceai_max_ds'}
                                label= {"SpliceAI Prediction"}
                                component={TextFieldForm}
                                onChange={handleChange}


                                defaultValue={"spliceai_max_ds"}/>
                            </Box>
                        </div>
                    </Grid>
                </div>
            </form>
        </div>
    )
};

const validate = (values, props) => {
    const errors = {};


    if(values.revel!== null){
        if (values.revel < 0 || values.revel > 1) {
            errors.revel = 'Value must be between 0 and 1';
        }
        // Check for leading zero only if the field is touched
        if (values.revel > 0 && /^0(?!\.)/.test(values.revel.toString())) {
            errors.revel = 'Value cannot start with 0 unless it is 0 or a decimal (e.g., 0.5)';
        }
    }


    console.log('Validation errors:', errors); // Log the errors to confirm validation is working

    return errors;
};

export default reduxForm({
    form: 'snv_effects', // a unique identifier for this form,
    initialValues: {
        cadd_phred: null,
        revel:null,
        mutationtaster: [],
        siftpred: [],
        polyphen2hvarpred:[],
        OCScore: null,
        onco_classifier:[],
        intervarclasses:[]
    },
    enableReinitialize : true,
    destroyOnUnmount: true,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
    validate
})(SNV_Section)


// TODO: check how to initialize the form;
SNV_Section = connect(() => ({}))(SNV_Section);