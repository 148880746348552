export const addTooltipRow = (cell, tooltipCompacts, data) => {
  const MAX_LENGTH = 20; 
  let content;
  let truncated = false;
  let dataTooltip;
  let addTooltip = false;
  const originalIndex = cell.row.original?.originalIndex;

  if (tooltipCompacts && Array.isArray(tooltipCompacts)) {
    tooltipCompacts.forEach((tooltipCompact) => {
      const { rowSelect, referenceTable, targetColumn } = tooltipCompact;

      rowSelect.forEach((item) => {
        if (cell?.value === item.value) {
 

          data.forEach((items) => {
            if (items.originalIndex === originalIndex) {
              dataTooltip = items[item.dataSearch];
            }
          });


          const columnName = targetColumn || referenceTable;

          cell.row.allCells.forEach((targetCell) => {
            if (targetCell.column.Header === columnName)
     
              targetCell.value = `${targetCell.value}, Source: ${dataTooltip}`;
          });
        }
      });
    });
  }

  if (Array.isArray(cell?.value)) {
    content = cell?.value.join(", ");
    truncated = content.length > MAX_LENGTH;
  } else {
    truncated = cell?.value?.length > MAX_LENGTH;

    if (truncated && addTooltip) {
      truncated = true;
    }

    content = cell.render("Cell");
  }

  return {
    content,
    truncated,
  };
};
