



import React, {Component} from 'react';

import auth from "../../services/session/Auth";
import "./css/navigation-bar.css"
import {NavLink} from "react-router-dom";


import GPAP_Breadcrumb from "./GPAP_Breadcrumb";
import {connect} from "react-redux";
import NavigationMenu from "./navigation-menu/NavigationMenu";
import Button from "@mui/material/Button";
import { Avatar, Grid } from "@mui/material";

import { IconButton, Menu, MenuItem, ListItemIcon } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

class NavigationBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keycloak: auth.user.keycloak,
            authenticated: auth.user.authenticated,
            anchorEl: null
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    logout() {
        auth.user.keycloak.logout()
            .then(() => { console.log("logout") })
            .catch(e => { console.log(e) });
    }

    handleClick(event) {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleClose() {
        this.setState({ anchorEl: null });
    }

    renderLoginDetails() {
        const { authorization } = this.props;
        const isAuthenticated = authorization?.authenticated;
        const userName = authorization?.preferredName ?? "Guest";

        return (
            <>
                <IconButton
                    onClick={this.handleClick}
                    style={{
                        backgroundColor: "rgba(62, 105, 158, 0.1)",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "50px",
                        padding: "5px"
                    }}
                >
                    <Avatar
                        style={{
                            background: "rgb(69, 117, 180)",
                            color: "white",
                            width: "30px",
                            height: "30px",
                            marginRight: "5px"
                        }}
                    >
                        {userName.charAt(0).toUpperCase()}
                    </Avatar>
                    <SettingsIcon style={{ color: "rgb(69, 117, 180)" }} />
                </IconButton>

                <Menu
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                >
                    <MenuItem disabled>{userName}</MenuItem>

                    {isAuthenticated && (
                        <MenuItem
                            component={NavLink}
                            exact
                            to="/gpap_settings"
                            onClick={this.handleClose}
                        >
                            <ListItemIcon>
                                <SettingsIcon fontSize="small" />
                            </ListItemIcon>
                            Settings
                        </MenuItem>
                    )}

                    <MenuItem
                        onClick={() => {
                            if (isAuthenticated) {
                                this.logout();
                            } else {
                            
                                window.location.href = "/home";
                            }
                            this.handleClose();
                        }}
                    >
                        <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        {isAuthenticated ? "Logout" : "Login"}
                    </MenuItem>
                </Menu>
            </>
        );
    }

    render() {



        let origin = window.location.origin;

        return (
            <div className="pageHeader">
                <Grid container
                      spacing={2}>
                    <Grid item xs={5} >
                        <Grid item >
                            <NavigationMenu
                                title={"Data Submission"}
                                orange={false}
                                items={[ {label:"Phenotypic Data", to:"/phenostore/"},
                                    {label:"Experiments & Files", to:"/submission/"}]}
                            />
                            <NavigationMenu
                                title={"Data Analysis"}
                                orange={true}
                                items={[ {label:"Genomic Analysis", to:"/"},
                                    {label:"Cohort Creation", to:"/cohortapp/"}]}
                            />
                            <NavigationMenu
                                title={"Data Management"}
                                orange={false}
                                items={[ {label:"Browse Phenotypic Participants", to:"/phenobrowse/"},
                                    {label:"Browse Experiments & Files", to:"/management/"}]}
                            />
                        </Grid>
                        <Grid item >
                            <GPAP_Breadcrumb/>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} style={{textAlign: "center"}}>
                        <div>
                            <a href={origin} style={{color: "black"}}>
                                <img src={window.config.static_files + "/img/gpap_logo.svg"} alt="GPAP" style={{width: "250px"}}/>
                            </a>
                        </div>
                    </Grid>

                    <Grid item xs={5}>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                            spacing={2}
                        >
                            <Grid item>
                                <Button href={origin + "/gpap_doc/"}>User Guide</Button>
                            </Grid>
                            <Grid item>
                                <Button>
                                    <NavLink exact to="/contactus">Contact</NavLink>
                                </Button>
                            </Grid>
                            {/* <Grid item>
                                     <LanguageSelector/>
                            </Grid> */}
                            <Grid item>
                                {this.renderLoginDetails()}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    if (state.authorization !== undefined) {
        return { authorization: state.authorization };
    }
}

export default connect(mapStateToProps, [])(NavigationBar);
