import React from "react";


export function Title({ field1, field2, field3, field4, dataType }) {
    const getTitle = () => {
        if (dataType === "variant" || dataType === "fusion") {
            if (field1 && field2) {
                return <b>{field1} ({field2}): {field3}</b>;
            } else {
                return <b>{field1}: {field3}</b>; 
            }
        } 
        else {
                return field2 ? <b>{field1} ({field2})</b> : <b>{field1}</b>; 
            }
        };
    
    return getTitle();
}