export const handleFusionVariant = (variant, Experiment_ID) => {

    return {
        "sample": Experiment_ID,
        "fusioned_genes": variant["FusionName"],
        "junction_read_count": variant["JunctionReadCount"],
        "left_gene": variant["LeftGene"],
        "left_breakpoint": variant["LeftBreakpoint"],
        "right_gene": variant["RightGene"],
        "right_breakpoint": variant["RightBreakpoint"],
        "interpretation": variant["comments"] || "",
        "related_to": "Primary disorder", // This is a default value
        "variant_type": "Gene Fusion",
        "origin": "somatic",
        "significance": "Pathogenic",
    }

};
