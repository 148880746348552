
import React, { Fragment } from 'react';
import { Box, Typography } from '@mui/material';
import {GPAP_Button} from "../../../../../../../../../../gpap-lib/components/Button_Collection";

import MappedText from "../../../../../../../../../instand/reports/create-report/shared-components/mapText";
import CaButton from "../../../../../../../../../../gpap-lib/components/shared-components/CaButton";
import Divider from "@mui/material/Divider";
import {CompleteDiagnosisForm} from "./CompleteDiagnosisForm";
import AfterSubmitContent from "./AfterSubmitContent";


const AfterSubmit = ({ operationType, state, dataType, index_case, setUpdated, setSubmitted, analysisType }) => {

    let text="";
    if(dataType.includes("SNV") || dataType === "CNV"){
        text = "Variant";
    }
    else{
        text="Tag";
    }


    return (
        <Fragment>
            {
                (state.causative && operationType !== "deleted" && index_case !== null)
                    ? <CompleteDiagnosisForm index_case = {index_case}
                                             setUpdated={setUpdated}
                                             setSubmitted={setSubmitted}
                                             analysisType={analysisType}
                    />
                    : <AfterSubmitContent status={state}
                        operationType ={operationType}
                        dataType={dataType}
                        index_case={index_case}
                        setUpdated={setUpdated}
                        setSubmitted={setSubmitted}
                        analysisType={analysisType}
                    />
            }
            {
                (operationType === "deleted" && dataType.includes("SNV")) &&
                <Fragment>
                    <Typography variant="subtitle1">
                        <MappedText text="You also deleted the variant from PhenoStore. If you did it by mistake, please tag again." />
                    </Typography>
                </Fragment>
            }
        </Fragment>
    );
};

export default AfterSubmit;
