import React, { useEffect, useState } from 'react';

// MUI Components
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

// MUI Styles
import { makeStyles } from '@mui/styles';

// Custom Components
import GPAP_Tooltip from "../../../../../../gpap-lib/components/GPAP_Tooltip";
import { GeneTableRow } from './GeneTableRow';

const useStyles = makeStyles({
    table: {
        minWidth: 0,
    },
    selectedRow: {
        backgroundColor: '#cfe2f3', // Light blue background for selected row
        border: '2px solid #4f8abf', // Darker blue border
        '&:hover': {
            backgroundColor: '#a9c7e7', // Slightly darker on hover for visual feedback
            cursor: 'pointer', // Show pointer cursor on hover
        },
    },
    geneNameSelected: {
        color: '#4f8abf',  // Apply the desired color to the gene name when selected
        fontWeight: 500,    // Apply the desired font weight
    },
    pointerCursorCell: {
        cursor: 'pointer', // Apply pointer cursor to all table cells
    },
});

function dynamicSort(property) {
    let sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a, b) {
        if(sortOrder === -1){
            return b[property].localeCompare(a[property]);
        } else {
            return a[property].localeCompare(b[property]);
        }
    }
}

export default function GeneAggTable(props) {
    const classes = useStyles();
    const [row_selected, setSelected] = useState("All");

    useEffect(() => {
        if (props.data.hasOwnProperty("total")) {
            if (props.totalVariants !== props.data.total) {
                // setSelected(-1);
            }
        }
    }, [props.totalVariants]);

    const handleClick = (event, row_name) => {
        const sel_index = row_selected !== row_name;
        let newSelected = -1;
        if (sel_index) {
            newSelected = row_name;
            props.runSearchAcrossQuery(row_name);
        } else {
            newSelected = -1;
        }

        setSelected(newSelected);
    };

    const isSelected = (name) => row_selected === name;

    // Sort the rows
    props.rows.sort(dynamicSort("gene"));

    const createAllGenesRow = () => {

        let {rows, n_experiments} = props;

        let all_genes = rows.map(row => row.gene);
        let sum_variants = 0;
        rows
        //.map(row => row.n_var)
        .forEach( (item) => sum_variants+= parseInt(item.n_var));
       
        const all_row = {
            gene: "All",
            n_var: sum_variants,
            n_exp: n_experiments
        }
        
        console.log("Render all genes row");

        return  <GeneTableRow 
        isItemSelected={isSelected("All")} 
        row={all_row}
        classes={classes}
        handleClick={handleClick}
        />

    }

    
    return (
        <TableContainer component={Paper} style={{maxHeight: "600px"}}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Gene</TableCell>
                        <GPAP_Tooltip title={"# Genomic Positions"}>
                            <TableCell className={classes.pointerCursorCell} align="left"># Pos.</TableCell>
                        </GPAP_Tooltip>
                        <GPAP_Tooltip title={"# Experiments found with variants in this specific gene "}>
                            <TableCell  className={classes.pointerCursorCell} align="left"># Exp.</TableCell>
                        </GPAP_Tooltip>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {createAllGenesRow()}
                    {props.rows.map((row) => {
                        return <GeneTableRow 
                        isItemSelected={isSelected(row.gene)} 
                        row={row}
                        classes={classes}
                        handleClick={handleClick}
                        />
                        ;
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
