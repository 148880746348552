
import {inheritances} from './config_inheritances';

export function getConfigIdByLabel(label) {

    const inheritanceList = inheritances;

    // Loop through the inheritances array
    for (let inheritance of inheritances) {
        // Find the configuration by label name
        const config = inheritance.config.find(item => item.label === label);

        // Return the id if the configuration is found
        if (config) {
            return config.id;
        }
    }
    // Return null if the label is not found
    return null;
}