import React from "react";
import { Addchart } from "@mui/icons-material";
import CaButton from "../../../../../../../../../gpap-lib/components/shared-components/CaButton";
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import { renderToStringAlt } from "../../../../../../../../../scenes/instand/reports/create-report/shared-components/renderToString.js";

const FilterButton = ({ analysisType, sidebarActions }) => {
    const titleBtn = analysisType === "instand" 
        ? renderToStringAlt("Create Query") 
        : renderToStringAlt("New Query");
        
    const tooltip = analysisType === "instand" 
        ? renderToStringAlt("Apply your own filters") 
        : renderToStringAlt("Filters");

    return (
        <GPAP_Tooltip title={tooltip}>
            <CaButton
                text={titleBtn} 
                variant="contained"
                id={"new_query"}
                aria-label="filters"
                onClick={() => sidebarActions.setView({ page: "Query" })}
                startIcon={<Addchart />}
            />
        </GPAP_Tooltip>
    );
};

export default FilterButton;
