import React from 'react';
import { Grid, Typography, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import MappedText from "../../../../../../../../../instand/reports/create-report/shared-components/mapText";
import {TagInfo} from "./TagVisibility";
import {
    CustomSelect,
    SelectStandard
} from "../../../../../../../../../../gpap-lib/components/shared-components/renderer";
import {sectionHeader} from "./sectionHeader";
import {customOptionLabel} from "../customTranscriptLabel";
import {origin_opts} from "../tag_form_options";
import {getOriginOptions} from "../helpers/getOriginOptions";
import { Box } from '@mui/system';

const SubmissionForm = ({
                            formOptions,
                            tagVisibility,
                            deleteVariantData,
                            variant_flagged,
                            data,
                            onSelectedOption,
                            projects,
                            getRowDataType,
                            handleTextChange,
                            handleCausativeChange,
                            state
                        }) => {
    const size = 12;

    // Order transcripts
    const index = formOptions['transcripts'].findIndex((el) => el.canonical === true);
    if (index > -1) {
        const itemToMove = formOptions['transcripts'].splice(index, 1)[0]; // Remove item from its current position
        formOptions['transcripts'].unshift(itemToMove); // Add the item to the beginning of the array
    }

    return (
        <Grid container direction="column">
            <Grid item lg={size}>
                <Typography variant="subtitle1">
                    <MappedText text="When you TAG a variant, the tag will be visible to all users of the platform regardless of the embargo period" />
                </Typography>
                <TagInfo tagVisibility={tagVisibility} deleteVariantData={deleteVariantData} variant_flagged={variant_flagged} />
            </Grid>
            <Grid item lg={size} style={{ marginTop: '2%' }}>
                {sectionHeader(<MappedText text="Sample" />, false)}
                <SelectStandard
                    name="sample"
                    defaultValue={formOptions['samples'].find((opt) => opt.value === data.sample)}
                    handleChange={onSelectedOption}
                    style={{ fontSize: '14px', zIndex: 1000 }} // Correct zIndex as a number
                    options={formOptions['samples']}
                    isDisabled={true}
                />
            </Grid>
            <Grid item lg={size} style={{ marginTop: '2%' }}>
                {sectionHeader(<MappedText text="Related to" />, false)}
                <SelectStandard
                    name="variant_type"
                    defaultValue={formOptions['variant_type'].find((opt) => opt.value === data.variant_type)}
                    handleChange={onSelectedOption}
                    style={{ fontSize: '14px', zIndex: 1000 }} // Correct zIndex as a number
                    options={formOptions['variant_type']}
                />
            </Grid>
            { (!window.config.instand)
                ?
                <Grid item lg={size} style={{ marginTop: '2%' }}>
                {sectionHeader(<MappedText text="Project" />, false)}
                <SelectStandard
                    name="project"
                    defaultValue={Array.isArray(projects) ? projects.find((opt) => opt.value === data.project) : ''}
                    handleChange={onSelectedOption}
                    style={{ fontSize: '14px', zIndex: 1000 }} // Correct zIndex as a number
                    options={projects}
                />
                </Grid>
                : null
            }
            <Grid item lg={size} style={{ marginTop: '2%' }}>
                {sectionHeader(<MappedText text="Gene" />, false)}
                <SelectStandard
                    name="genes"
                    instanceId="uniqueGeneInstanceID" // Unique identifier for the select instance
                    data-testid="uniqueGeneDataTestID" // Unique test identifier for locating the component
                    id="uniqueGeneSelectID" // Unique ID for the component
                    inputId="uniqueGeneInputID" // Unique ID for the input element
                    defaultValue={formOptions['genes'][0]}
                    style={{ fontSize: '14px', zIndex: 1000 }} // Correct zIndex as a number
                    options={formOptions['genes']}
                    handleChange={onSelectedOption}
                />
            </Grid>
            <Grid item lg={size} style={{ marginTop: '2%' }}>
                {sectionHeader(<MappedText text="Type of variant" />, true)}
                <SelectStandard
                    name="sv_type"
                    defaultValue={formOptions['sv_type'].find((opt) => opt.value === data.sv_type)}
                    handleChange={onSelectedOption}
                    style={{ fontSize: '14px', zIndex: 1000 }} // Correct zIndex as a number
                    options={formOptions['sv_type']}
                />
            </Grid>
            <Grid item lg={size} style={{ marginTop: '2%' }}>
                {sectionHeader(<MappedText text="Transcript" />, false)}
                <CustomSelect
                    name="transcript"
                    defaultValue={formOptions['transcripts'].find((opt) => opt.value === data.transcript)}
                    handleChange={onSelectedOption}
                    style={{ fontSize: '14px', zIndex: 1000 }} // Correct zIndex as a number

                    customOptionLabel={customOptionLabel}
                    options={formOptions['transcripts']}
                />
            </Grid>
            <Grid item lg={size} style={{ marginTop: '2%' }}>
                {sectionHeader(<MappedText text="Inheritance" />, true)}
                <SelectStandard
                    name="inheritance"
                    data-testid="inheritance-select"
                    defaultValue={variant_flagged ? formOptions['inheritance_opts'].find((opt) => opt.value === data.inheritance) : ' '}
                    handleChange={onSelectedOption}
                    style={{ fontSize: '14px', zIndex: 1000 }} // Correct zIndex as a number
                    inputId="uniqueInheritanceInputID" // Unique ID for the input element
                    options={formOptions['inheritance_opts']}
                />
            </Grid>
            <Grid item lg={size} style={{ marginTop: '2%' }}>
                {sectionHeader(<MappedText text="Origin" />, false)}
                <SelectStandard
                    name="origin"
                    defaultValue={
                        getRowDataType() === 'SNV_Somatic'
                            ? origin_opts.find((opt) => opt.value === 'somatic')
                            : formOptions['origin'].find((opt) => opt.value === data.origin)
                    }
                    handleChange={onSelectedOption}
                    style={{ fontSize: '14px', zIndex: 1000 }} // Correct zIndex as a number

                    options={getOriginOptions(getRowDataType())}
                />
            </Grid>
            <Grid item lg={size} style={{ marginTop: '2%' }}>
                {sectionHeader(<MappedText text="Zygosity" />, false)}
                <SelectStandard
                    name="zygosity"
                    defaultValue={variant_flagged ? formOptions['zygosity_opts'].find((opt) => opt.value === data.zygosity) : ' '}
                    handleChange={onSelectedOption}
                    style={{ fontSize: '14px', zIndex: 1000 }} // Correct zIndex as a number
                    options={formOptions['zygosity_opts']}
                />
            </Grid>
            <Grid item lg={size} style={{ marginTop: '2%' }}>
                {sectionHeader(<MappedText text="Clinical Significance" />, true)}
                <SelectStandard
                    name="significance"
                    inputId="uniqueClinSignInputID"
                    defaultValue={variant_flagged ? formOptions['significance_opts'].find((opt) => opt.value === data.significance) : ' '}
                    handleChange={onSelectedOption}
                    style={{ fontSize: '14px', zIndex: 1000 }} // Correct zIndex as a number
                    options={formOptions['significance_opts']}
                />
            </Grid>
            <Grid item lg={size} style={{ marginTop: '2%', zIndex: 0 }}>
                    <TextField
                        style={{ width: '100%'  }}
                        id="standard-multiline-static"
                        label={<MappedText text="Interpretation" />}
                        multiline
                        rows={4}
                        onChange={handleTextChange}
                        defaultValue={data.comments}
                    />
            </Grid>
            <Grid item lg={size}>
            <Box p={2} style={{ backgroundColor: '#e5f6fd', borderRadius: '5px' }}> 
                {sectionHeader(<MappedText text="Is this the Causative Variant?" />, false)}
                <FormGroup row>
                    <FormControlLabel
                        control={<Checkbox checked={state.causative} onChange={handleCausativeChange} name="causative" />}
                        label={<MappedText text="Causative Variant (confirmed as the cause of the primary disorder)" />}
                    />
                </FormGroup>
                </Box>
            </Grid>
        </Grid>
    );
};

export default SubmissionForm;
