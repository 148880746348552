

// React Imports
import React, { Fragment, useState } from 'react';

// MUI Imports
import Popover from '@mui/material/Popover'; // MUI v5 Popover
import Typography from '@mui/material/Typography'; // MUI v5 Typography
import Box from '@mui/material/Box'; // MUI v5 Box
import Grid from '@mui/material/Grid'; // MUI v5 Grid
import Link from '@mui/material/Link'; // MUI v5 Link

// MUI Icons Imports
import ListIcon from '@mui/icons-material/List'; // MUI v5 ListIcon

// Custom Component Imports
import GPAP_Tooltip from '../../../../../../../../../../gpap-lib/components/GPAP_Tooltip';
import CaButton from '../../../../../../../../../../gpap-lib/components/shared-components/CaButton';
import MappedText from '../../../../../../../../../instand/reports/create-report/shared-components/mapText';



// example somatic data

/*{
    "filter": "base_qual",
    "ad": 0,
    "dp": 24,
    "gt": "0/0",
    "sample": "AX7172",
    "tool": "mutect"
}*/


const headerColumns = {
    SNV_Germline:  [
        { key: "Experiment ID", text: "Experiment ID"},
        { key: "GT", text: "Genotype"},
        { key: "GQ", text: "Genotype Quality - as determined by GATK Haplotype-Caller"},
        { key: "DP", text: "Depth - number of reads spanning this position"},
        { key: "AAF", text: "Alternate Allele Frequency (proportion of lead supporting the alternative allele)"},
    ],
    SNV_Somatic: [
        { key: "Experiment ID", text: "Experiment ID"},
        { key: "GT", text: "Genotype"},
        { key: "DP", text: "Depth - number of reads spanning this position"},
        { key: "AF", text: "AF"},
        { key: "Tool", text: "Tool"},
    ]}

export default function SamplesPopOver(props) {

    let {data_type} = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const headerConf = headerColumns[data_type]

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    const renderGermline = () => {

        let list = props.germline_samples.sort(function(a,b){ return a.sample.localeCompare(b.sample);});

        return list.map(function(s, index){

            return <Fragment key={index}>
                <Grid item lg={3}>
                    <Link target={"_blank"} href={window.config.apis_configuration.url_datamanagement_single + s.sample}>{s.sample} </Link>
                </Grid>
                <Grid item lg={2}>
                    {s.gt}
                </Grid>
                <Grid item lg={2}>
                    {s.gq}
                </Grid>
                <Grid item lg={2}>
                    {s.dp}
                </Grid>
                <Grid item lg={2}>
                    {(typeof s.ad  === "number") ? s.ad.toFixed(4) : s.ad }
                </Grid>
            </Fragment>


        })


    }

    const renderSomatic = () => {

        return props.somatic_samples.map(function(s, index){

            return <Fragment key={index}>
                <Grid item lg={3}>
                    <Link target={"_blank"} href={window.config.apis_configuration.url_datamanagement_single + s.sample}>{s.sample} </Link>
                </Grid>
                <Grid item lg={2}>
                    {s.gt}
                </Grid>
                <Grid item lg={2}>
                    {s.dp}
                </Grid>
                <Grid item lg={2}>
                    {(typeof s.ad  === "number") ? s.ad.toFixed(4) : s.ad }
                </Grid>
                <Grid item lg={2}>
                    {s.tool}
                </Grid>
            </Fragment>


        })


    }

    const renderSamples = () => {

        if( props.germline_samples!== undefined && props.germline_samples.length!== 0 && Array.isArray(props.germline_samples)){
            return renderGermline()
        }
        else if( props.somatic_samples!== undefined && props.somatic_samples.length!== 0 && Array.isArray(props.somatic_samples)){
            return renderSomatic();
        }
        else{
            return <div><MappedText text={"Samples Information not available"}/></div>
        }


    };

    let buttonColor = (open) ? "orange" : "";

    return (
        <div style={{display:"inline-block",  margin: ".5%"}}>
            <CaButton  aria-describedby={id}
                     variant="outlined"
                     onClick={handleClick}
                     text="Genotypes"
                     startIcon={<ListIcon/>}/>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={2} style={{width: "400px" }}>
                    <Grid container direction={"row"}>
                        {headerConf.map( (d,i) => {

                            let size = (i === 0 ) ? 3 : 2;

                            return <Grid item lg={size} key={i}>
                            <GPAP_Tooltip title = {d.text} placement= "top" >
                                <Typography variant={"subtitle1"} style={{fontWeight: "bold"}}> {d.key} </Typography>
                            </GPAP_Tooltip>
                        </Grid>})}
                        {renderSamples()}
                    </Grid>
                </Box>
            </Popover>
        </div>
    );
}