export const italiano =  {
    "Get in touch": "Mettiti in contatto",
    "Thank you for using our product.": "Grazie per aver usato il nostro prodotto.",
    "Feel free to reach out if you have any questions or comments.": "Sentiti libero di contattare se hai domande o commenti.",
    "Data Platforms & Tools Development Team": "Team di sviluppo di piattaforme e strumenti di dati",
    "Barcelona, Spain": "Barcellona, ​​Spagna",
    "Phenotypic Data": "Dati fenotipici",
    "Experiments & Files": "Esperimenti e file",
    "Genomic Analysis": "Analisi genomica",
    "Cohort Creation": "Creazione di coorte",
    "Browse Phenotypic Participants": "Sfoglia i partecipanti fenotipici",
    "Browse Experiments & Files": "Sfoglia esperimenti e file",
    "Settings": "Impostazioni",
    "Interpretation Requests": "Richieste di interpretazione",
    "Docs": "Documenti",
    "Contact": "Contatto",
    "Bug Report Form": "Modulo di segnalazione di bug",
    "No variants have passed the selected filters": "Nessuna variante ha superato i filtri selezionati",
    "To display variants, try using less restrictive filters or expanding the gene list": "Per visualizzare le varianti, prova a utilizzare filtri meno restrittivi o ad espandere l'elenco genico",
    "Rows per page:": "Righe per pagina:",
    "Column Hiding": "Colonna Nascondo",
    "Toggle All": "Attiva tutto",
    "Equal or less": "Uguale o meno",
    "Search...": "Ricerca...",
    "Select": "Selezionare",
    "Complete view": "Vista completa",
    "Split by genes": "Diviso da geni",
    "Columns": "Colonne",
    "Create request": "Crea richiesta",
    "Upload report": "Rapporto di caricamento",
    "Create report": "Crea report",
    "Haplotypes not available": "Aplotipi non disponibili",
    "Clinical evidences not available": "Prove cliniche non disponibili",
    "Gene Fusions data not available": "Dati delle fusioni geniche non disponibili",
    "Experiment ": "Sperimentare",
    " does not have any data available yet": "non ha ancora dati disponibili",
    "No interpretation requests yet": "Nessuna richiesta di interpretazione ancora",
    "a request and it will show up here.": "Una richiesta e verrà visualizzata qui.",
    "No clinical reports yet": "Nessun rapporto clinico ancora",
    "or upload a report and it will show up here": "o caricare un rapporto e verrà visualizzato qui",
    "No patient reports yet": "Nessun paziente segnala ancora",
    "Upload a report and it will show up here": "Carica un rapporto e verrà visualizzato qui",
    "Tag": "Etichetta",
    "Actions": "Azioni",
    "Access Request": "Richiesta di accesso",
    "Delete Request": "Elimina richiesta",
    "Download": "Scaricamento",
    "Upload": "Caricamento",
    "Link": "Collegamento",
    "Download Report": "Scarica il rapporto",
    "For technical limitations of our servers, we only display the first 1000 participants for a query. Please narrow your search if needed.": "Per i limiti tecnici dei nostri server, visualizziamo solo i primi 1000 partecipanti per una query. Si prega di restringere la ricerca se necessario.",
    "Fam.ID": "Fam.id",
    "Experiment ID": "ID esperimento",
    "Local Exp. ID": "Exp. ID",
    "Num. of Genes": "Numero. di geni",
    "Project": "Progetto",
    "Affected Status": "Stato interessato",
    "Consanguinity": "Consanguineità",
    "Sex": "Sesso",
    "Solved Status": "Stato risolto",
    "Obs. HPOs": "Obs. Hpos",
    "Diagnosis": "Diagnosi",
    "Contact Owner": "Contatta proprietario",
    "Show the variants of the selected participant": "Mostra le varianti del partecipante selezionato",
    "Participant ID": "ID partecipante",
    "Owner": "Proprietario",
    "ERNs": "Erns",
    "Life Status": "Stato di vita",
    "# HPOs": "# Hpos",
    "Date of Birth": "Data di nascita",
    "Date of Death": "Data di morte",
    "Autosomal recessive (both homozygous and compound heterozygous)": "Recessivo autosomico (sia omozigote che composto eterozigote)",
    "homozygous": "omozigote",
    "compound heterozygosity": "eterozigosi composta",
    "Autosomal dominant": "Autosomiale dominante",
    "Autosomal dominant - de novo": "Autosomiale dominante - de novo",
    "De novo": "De novo",
    "AR Consanguineous": "AR consanguineo",
    "X-Linked Recessive ": "Recessivo legato all'X",
    "X-Linked Recessive - Index Case Male": "Recessivo legata all'X - Caso indice maschio",
    "X-Linked Dominant ": "Dominante legato all'X",
    "X-Linked Dominant - Index Case Male": "Dominante legato all'X - Caso indice maschio",
    "X-Linked Dominant - Index Case Female": "Dominante legato all'X - Caso indice femminile",
    "Custom Inheritance ": "Ereditarietà personalizzata",
    "Custom Inheritance": "Ereditarietà personalizzata",
    "Somatic": "Somatico",
    "Ensembl": "Ensembl",
    "gnomAD": "Gnomad",
    "UCSC": "UCSC",
    "NCBI": "NCBI",
    "DGVa": "Dgva",
    "GWAS Central": "Gwas Central",
    "GA4GH": "Ga4gh",
    "VarSome": "Varsome",
    "dbSNP": "dbsnp",
    "OMIM": "Omim",
    "Genetics Home Reference": "Riferimento a casa della genetica",
    "NCBI Gene Reviews": "Recensioni di geni NCBI",
    "PubCaseFinder": "PubCasefinder",
    "PubMed": "PubMed",
    "HGMD": "Hgmd",
    "Entrez": "Entrez",
    "GeneCards": "Genecarde",
    "COSMIC": "COSMICO",
    "ClinVar": "Clinvar",
    "ExAC": "Exac",
    "GTEx": "Gtex",
    "ATLAS": "ATLANTE",
    "WikiPathways": "Wikipathways",
    "Open PHACTS": "Phatti aperti",
    "DisGeNET": "Disgenet",
    "Gene Name": "Nome genetico",
    "Transcript ID": "ID trascrizione",
    "Effect Impact": "Impatto dell'effetto",
    "Consequence": "Conseguenza",
    "Feature Type": "Tipo di funzionalità",
    "HGVS coding": "Codice HGVS",
    "Human Splicing Finder": "Finder di giunzione umano",
    "Amino Acid change": "Cambiamento di aminoacidi",
    "Exon Rank": "Rango esone",
    "Transcript BioType": "Biotipo di trascrizione",
    "CADD Pred": "CADD PRED",
    "Revel Pred": "Revel Pred",
    "SplicaAI Pred": "SPLICAI PRED",
    "SIFT PRED": "Setacciare il pred",
    "SIFT SCORE": "Punteggio setaccia",
    "POLYPHEN2 Pred": "Previsione polifene 2",
    "POLYPHEN Score": "Punteggio polifene",
    "Mutation Taster": "Taster di mutazione",
    "InterVar": "Intervallo",
    "MT Score": "Punteggio MT",
    "phyloP": "Phylop",
    "GERP++": "GERP ++",
    "SiPhy": "Siphy",
    "OCScore": "Occore",
    "onco_classifier": "onco_classifier",
    "Study Name": "Nome studio",
    "Description": "Descrizione",
    "Created on": "Creato su",
    "Created by": "Creato da",
    "Permission": "Autorizzazione",
    "Gene": "Gene",
    "Gene ID": "Gene ID",
    "Gene coding": "Codice genetica",
    "Functional class": "Classe funzionale",
    "Aminoacid length": "Lunghezza aminoacida",
    "Aminoacid": "Aminoacide",
    "Impact": "Impatto",
    "Change": "Modifica",
    "Nucleotide": "Nucleotide",
    "Transcript": "Trascrizione",
    "GT": "Gt",
    "MANE": "CRINIERA",
    "Clin.Sign.": "Clin.sign.",
    "Criteria": "Criteri",
    "ACMG evidences": "Evidenze ACMG",
    "ACMG classification": "Classificazione ACMG",
    "Internal": "Interno",
    "GnomAD": "Gnomad",
    "CADD": "Cadd",
    "Revel": "Divertimento",
    "SpliceAI": "Spiceai",
    "SIFT": "VAGLIARE",
    "Polyphen2.": "Polifene 2.",
    "MT": "Mt",
    "Intervar Evidence": "Evidenza a intervalli",
    "Intervar Autom.": "Intervallo AUTOR.",
    "# Exp.": "# Exp.",
    "Polyphen2": "Polifene 2",
    "Onco evidences": "Evidenze di onco",
    "Classification": "Classificazione",
    "Source": "Fonte",
    "Evidence Level??": "Livello di prova ??",
    "The initial count of SNVs identified during the first execution of this query.": "Il conteggio iniziale degli SNV identificati durante la prima esecuzione di questa query.",
    "This query did not return any variants": "Questa query non ha restituito alcuna variante",
    "No Associations": "Nessuna associazione",
    "We are working to integrate more resources in this section": "Stiamo lavorando per integrare più risorse in questa sezione",
    "Pathways": "Percorsi",
    "HmtDB - Human Mitochondrial Database": "HMTDB - database mitocondriale umano",
    "Human Pluripotent Stem Cell Registry": "Registro delle cellule staminali pluripotenti umane",
    "Pathways Reactome": "Pathways Reactome",
    "Reactome is a free, open-source, curated and peer-reviewed pathway database": "Reactome è un database di percorso gratuito, open source, curato e peer-reviewed",
    "URL": "URL",
    "Disease": "Malattia",
    "Cell Line Name": "Nome della linea cella",
    "Cell Line": "Linea cellulare",
    "Provider": "Fornitore",
    "# variants grouped by chromosome": "# varianti raggruppate per cromosoma",
    "External Databases": "Database esterni",
    "No gene selected": "Nessun gene selezionato",
    "Genome build: GRCh37 / hg19": "Genome Build: GRCH37 / HG19",
    "Genome build: GRCh38 / hg38": "Genome Build: GRCH38 / HG38",
    "External Links": "Collegamenti esterni",
    "No variants selected. Please select a variant to display the links": "Nessuna variante selezionata. Seleziona una variante per visualizzare i collegamenti",
    "No variants selected": "Nessuna variante selezionata",
    "No variant selected": "Nessuna variante selezionata",
    "Select one to use the resources": "Seleziona uno per utilizzare le risorse",
    "New Analysis": "Nuova analisi",
    "Query": "Domanda",
    "Current work": "Lavoro attuale",
    "Participants": "Partecipanti",
    "Save": "Salva",
    "Not Analyzed": "Non analizzato",
    "Solved": "Risolto",
    "Unsolved": "Irrisolto",
    "Ongoing Analysis": "Analisi in corso",
    "Under segregation/ functional studies": "In Studi di segregazione/ Funzionale",
    "Heterozygous variant found in AR disorder": "Variante eterozigote trovata nel disturbo AR",
    "Unaffected Relative": "Parente non affetto",
    "Other": "Altro",
    "select": "selezionare",
    "Lists applied:": "Elenchi applicati:",
    "Gene Filtering": "Filtraggio genico",
    "Positions": "Posizioni",
    "Variant Effect": "Effetto variante",
    "Genotype Features": "Caratteristiche del genotipo",
    "ClinVar Classes": "CLINVAR CLASSE",
    "Mane": "Criniera",
    "Onco Filter": "Filtro onco",
    "PolyPhen-2 Pred.": "Polyphen-2 Pred.",
    "GnomAD Filter": "Filtro Gnomad",
    "Programs Filter": "Filtro dei programmi",
    "Variant Consequences": "Conseguenze varianti",
    "Used filter-set": "Filtro utilizzato",
    "Applied Filters": "Filtri applicati",
    "Tag variants": "Varianti tag",
    "There is no query selected. Or applied filters are not visible.": "Non c'è query selezionata. O i filtri applicati non sono visibili.",
    "Filters": "Filtri",
    "Variants distributions - (based on max 3000 entries)": "Distribuzioni varianti - (basato su voci MAX 3000)",
    "Export Variants (max.1000)": "Varianti di esportazione (Max.1000)",
    "Once selected, click again to unapply": "Una volta selezionato, fai di nuovo clic per non applicare",
    "Label": "Etichetta",
    "Important": "Importante",
    "Follow-Up": "Seguito",
    "Discarded": "Scartato",
    "Candidate": "Candidato",
    "List of Integrated Plugins": "Elenco di plugin integrati",
    "Please, give a name to your study to save it correctly. ": "Per favore, dai un nome al tuo studio per salvarlo correttamente.",
    "You have unsaved queries": "Hai domande non salvate",
    "Save current Study with Analyses and queries": "Salva questo studio con analisi e domande",
    "You are not the creator of the study": "Non sei il creatore dello studio",
    "Study title. Max 50 chars": "Titolo di studio. Max 50 Chars",
    "Analyses without queries will not be saved": "Le analisi senza domande non saranno salvate",
    "Update Study Information": "Aggiorna le informazioni di studio",
    "Save Study": "Salva studio",
    "Fill in some information to save your work.": "Compila alcune informazioni per salvare il tuo lavoro.",
    "Study description. Max 50 chars": "Descrizione dello studio. Max 50 Chars",
    "Please select study visibility": "Seleziona la visibilità dello studio",
    "Private": "Privato",
    "Share with your groups": "Condividi con i tuoi gruppi",
    "Everyone can see": "Tutti possono vedere",
    "Cancel": "Cancellare",
    "Save changes": "Salva le modifiche",
    "Share the study with collaborators": "Condividi lo studio con i collaboratori",
    "Pathogenic": "Patogeno",
    "Likely pathogenic": "Probabilmente patogeno",
    "Uncertain significance": "Significato incerto",
    "Likely benign": "Probabilmente benigno",
    "Benign": "Benigno",
    "Drug response": "Risposta al farmaco",
    "Association": "Associazione",
    "Risk factor": "Fattore di rischio",
    "Protective": "Protettivo",
    "Oncogenic": "Oncogenico",
    "Likely oncogenic": "Probabilmente oncogenico",
    "Autosomal recessive": "Recessivo autosomico",
    "X-linked": "Collegato a X.",
    "X-linked dominant": "Dominante legato all'X",
    "X-linked recessive": "Recessivo legato all'X",
    "Y-linked inheritance": "Ereditarietà collegata a Y.",
    "Mitochondrial inheritance": "Ereditarietà mitocondriale",
    "Oligogenic inheritance": "Ereditarietà oligogenica",
    "Digenic inheritance": "Ereditarietà digenica",
    "Autosomal dominant with paternal imprinting": "Autosomiale dominante con impronta paterna",
    "Autosomal dominant with maternal imprinting": "Autosomiale dominante con impronta materna",
    "Unknown": "Sconosciuto",
    "Heterozygous": "Eterozigote",
    "Homozygous": "Omozigote",
    "Hemizygous": "Emizygous",
    "Primary disorder": "Disturbo primario",
    "Secondary findings": "Risultati secondari",
    "Pharmacogenomics": "Farmacogenomica",
    "Polygenic risk score": "Punteggio di rischio poligenico",
    "SNV-InDel": "SNV-indel",
    "Duplication": "Duplicazione",
    "Deletion": "Cancellazione",
    "Inversion": "Inversione",
    "Short tandem repeat": "Ripeti in tandem breve",
    "Translocation": "Traslocazione",
    "germline": "germine",
    "somatic": "somatico",
    "de novo": "de novo",
    "maternal": "materno",
    "paternal": "paterno",
    "inherited": "ereditato",
    "unknown": "sconosciuto",
    "gene fusion": "Gene Fusion",
    "none": "nessuno",
    "Gene Fusion": "Gene Fusion",
    "You also deleted the variant from PhenoStore. If you did it by mistake, please tag again.": "Hai anche eliminato la variante dal fenostore. Se l'hai fatto per errore, per favore tagga di nuovo.",
    "Go to participant summary": "Vai al riepilogo dei partecipanti",
    "Click here if you want to edit/delete your CNV tag": "Fai clic qui se si desidera modificare/eliminare il tag CNV",
    "Click here if you want to see a summary of the data stored in PhenoStore for the tagged participant": "Clicca qui se si desidera visualizzare un riepilogo dei dati archiviati in Phenostore per il partecipante taggato",
    "Report": "Rapporto",
    " module. Editing this tag will modify the interpretation comment.": "modulo. La modifica di questo tag modificherà il commento di interpretazione.",
    "Interpretation": "Interpretazione",
    "Tag deleted": "Tag cancellato",
    "Tag submitted": "Tag inviato",
    "Tag updated": "Tag aggiornato",
    "CNV previously tagged": "CNV precedentemente etichettato",
    "Tag CNV Variant": "Tag variante CNV",
    "Required": "Necessario",
    "When you TAG a variant, the tag will be visible to all users of the platform regardless of the embargo period": "Quando si etichetta una variante, il tag sarà visibile a tutti gli utenti della piattaforma indipendentemente dal periodo di embargo",
    "Sample": "Campione",
    "Related to": "Correlato a",
    "Type of variant": "Tipo di variante",
    "Inheritance": "Eredità",
    "Origin": "Origine",
    "Zygosity": "Zigosità",
    "Clinical Significance": "Significato clinico",
    "Is this the Causative Variant?": "È questa la variante causale?",
    "Causative Variant (confirmed as the cause of the primary disorder)": "Variante causale (confermata come causa del disturbo primario)",
    "Fusioned genes": "Geni fusion",
    "Tag variants in the GPAP database": "Tag varianti nel database GPAP",
    "Delete": "Eliminare",
    "Variant not tagged yet": "Variante non ancora taggata",
    "Create Query": "Crea query",
    "New Query": "Nuova domanda",
    "Apply your own filters": "Applica i tuoi filtri",
    "The filters selected for SNV-InDels do not automatically apply to CNVs and SVs except gene lists. You can further filter the CNVs directly in the table": "I filtri selezionati per gli indici SNV non si applicano automaticamente a CNV e SV ad eccezione degli elenchi genici. È possibile filtrare ulteriormente i CNV direttamente nella tabella",
    "The annotation is done with periodic OMIM updates through an internal API built with the same rationale as the OMIM one,": "L'annotazione viene eseguita con aggiornamenti OMIM periodici tramite un'API interna costruita con la stessa logica di quella omim,",
    "Clinical significance available for exonic SNVs and based on InterVar,": "Significato clinico disponibile per SNV esonici e basato su intervalli,",
    "Click on a gene or experiment in the left panel, or select a participant from the table above to load variants.": "Fai clic su un gene o su un esperimento nel pannello sinistro o seleziona un partecipante dalla tabella sopra per caricare le varianti.",
    "No variants have passed the selected filters.": "Nessuna variante ha superato i filtri selezionati.",
    "The displayed number is the total sum of events in the FULL and SPLIT results.": "Il numero visualizzato è la somma totale degli eventi nei risultati completi e divisi.",
    "SNV and InDels": "SNV e indels",
    "Note that the number of rows shown in the variants table can be higher than the number of genomic variants shown in the counter since one genomic variant can affect more than one gene.": "Si noti che il numero di righe mostrate nella tabella delle varianti può essere superiore al numero di varianti genomiche mostrate nel contatore poiché una variante genomica può influenzare più di un gene.",
    "Gene Info": "Informazioni geniche",
    "Variant Information": "Informazioni varianti",
    "Chrom": "Chrom",
    "Start": "Inizio",
    "End": "FINE",
    "Length": "Lunghezza",
    "SV Type": "Tipo SV",
    "Copy Number": "Numero di copia",
    "CytoBand": "Cytoband",
    "Translocation_ID     ": "Traslocazione_id",
    "Quality": "Qualità",
    "Clinical Association": "Associazione clinica",
    "ACMG Classific.": "ACMG Classifica.",
    "AnnotSV Reported pathogenic": "AnnoTSV ha riportato patogeni",
    "P_gain_phen": "P_gain_phen",
    "P_loss_phen": "P_loss_phen",
    "P_ins_phen": "P_ins_phen",
    "Population Frequency": "Frequenza della popolazione",
    "B_gain_AFmax": "B_gain_afmax",
    "B_loss_AFmax": "B_loss_afmax",
    "B_ins_AFmax": "B_ins_afmax",
    "Albert AnnotSV populaton AFMax ": "Albert annotsv populaton afmax",
    "Internal Freq.": "Freq interno.",
    "Exact match": "Corrispondenza esatta",
    "Additional Information": "Ulteriori informazioni",
    "Tool": "Attrezzo",
    "Translocation_ID": "Traslocazione_id",
    "Internal Freq. Count": "Freq interno. Contare",
    "Location": "Posizione",
    "Clinical Association ": "Associazione clinica",
    "AnnotSV population AFMax": "Popolazione di Annotsv Afmax",
    "Internal Freq": "Freq interno",
    "Additional information": "Ulteriori informazioni",
    "Previous Tags": "Tag precedenti",
    "Type": "Tipo",
    "Status": "Stato",
    "Significance": "Significato",
    "Date": "Data",
    "Comments": "Commenti",
    "Your Tag": "Il tuo tag",
    "Samples Information not available": "Informazioni sui campioni non disponibili",
    "IGV Browser": "Browser IGV",
    "Predictors": "Predittori",
    "Genotypes": "Genotipi",
    "indel": "indel",
    "ref": "rif",
    "alt": "Al",
    "pos": "pos",
    "Population": "Popolazione",
    "freqIntGermline": "freqintgermline",
    "gnomad_an_popmax": "gnomad_an_popmax",
    "gnomad_an": "gnomad_an",
    "freqIntGermlineNum": "freqintgermlinenum",
    "freqIntGermlineDem": "freqintgermlindem",
    "gnomad_ac": "gnomad_ac",
    "gnomad_af": "gnomad_af",
    "gnomad_nhomalt": "gnomad_nhomalt",
    "gnomad_ac_popmax": "gnomad_ac_popmax",
    "gnomad_af_popmax": "gnomad_af_popmax",
    "spliceai_dp_al": "splocai_dp_al",
    "sift_score": "sift_score",
    "mm_poly_ac": "mm_poly_ac",
    "gnomad_faf95_popmax": "gnomad_faf95_popmax",
    "spliceai_ds_ag": "splocai_ds_ag",
    "spliceai_dp_dg": "splocai_dp_dg",
    "spliceai_max_ds": "splocai_max_ds",
    "spliceai_ds_al": "splocai_ds_al",
    "spliceai_ds_dl": "splocai_ds_dl",
    "spliceai_dp_ag": "splocai_dp_ag",
    "spliceai_dp_dl": "splocai_dp_dl",
    "spliceai_ds_dg": "splocai_ds_dg",
    "revel_score": "Revel_score",
    "vep_proc_id.block_idx": "vep_proc_id.block_idx",
    "mt": "Mt",
    "mm_dis_disease": "mm_dis_disease",
    "intervar_automated": "intervar_automated",
    "mm_dis_disease_status": "mm_dis_disease_status",
    "vep_proc_id.part_idx": "vep_proc_id.part_idx",
    "intervar_evidence": "Intervar_evidence",
    "mm_poly_af": "mm_poly_af",
    "Clearing filters": "Filtri di compensazione",
    "Use a predefined filter set": "Utilizzare un set di filtri predefiniti",
    "Variant Type": "Tipo variante",
    "SNV Effect Predictions": "Previsioni dell'effetto SNV",
    "Position Specific and Runs Of Homozygosity": "Posizione specifica e corse di omozigosità",
    "Create/Adjust Filters": "Creare/regolare i filtri",
    "Filter by variants": "Filtro per varianti",
    "Gene Filters": "Filtri genici",
    "Filter by genes": "Filtro per geni",
    "Predefined lists": "Elenchi predefiniti",
    "Select a Predefined Gene List prepared from ERNs or the GPAP team": "Seleziona un elenco genico predefinito preparato da ERNS o dal team GPAP",
    "Gene search and upload": "Ricerca e caricamento geni",
    "Enter multiple gene symbols using HGNC nomenclature or Upload your own": "Immettere più simboli genici utilizzando la nomenclatura HGNC o caricalo",
    "WARNING: This platform uses annotations from Ensembl ": "ATTENZIONE: questa piattaforma utilizza annotazioni da Ensembl",
    ". Any Gene Symbol used for filtering which does not match the symbol used in Ensembl v109 may be missed or wrongly assigned. This also applies to external web-services. We are currently working to mitigate this issue.": ". Qualsiasi simbolo genico utilizzato per il filtraggio che non corrisponde al simbolo utilizzato in Ensembl V109 può essere perso o assegnato in modo errato. Questo vale anche per i servizi Web esterni. Attualmente stiamo lavorando per mitigare questo problema.",
    "Diseases": "Malattie",
    "Select one or more OMIM terms or a PanelApp to query the associated genes": "Seleziona uno o più termini OMIM o un panelApp per interrogare i geni associati",
    "Symptoms": "Sintomi",
    "Collect the HPOs from the index case or search more HPOs to query the associated genes": "Raccogli gli HPO dal caso dell'indice o cerca più HPO per interrogare i geni associati",
    "Pathways are retrieved from REACTOME and associations with genes from The Comparative Toxicogenomics database": "I percorsi vengono recuperati da Reactome e associazioni con geni dal database comparativo di tossicogenomica",
    "Clear gene list": "Elenco genico chiaro",
    "Operator": "Operatore",
    "Union": "Unione",
    "Intersection": "Intersezione",
    "Upload comma separated list of HGNC identifiers": "Carica l'elenco separato della virgola degli identificatori HGNC",
    "(The file is not stored in GPAP)": "(Il file non è archiviato in GPAP)",
    "Genes not validated": "Geni non validati",
    "Current selected genes by applying ": "Geni selezionati attuali applicando",
    " of gene lists": "di elenchi genici",
    "No genes selected": "Nessun geni selezionati",
    "All gene symbols were validated using Ensembl": "Tutti i simboli genici sono stati validati usando Ensembl",
    " gene symbols could not be validated using ": "I simboli genici non potevano essere validati usando",
    "Validating gene symbols..": "Convalidamento dei simboli genici ..",
    "Gene Validation": "Convalida del gene",
    "Back": "Indietro",
    "Intersect the genes extracted from the HPOs ": "Interseca i geni estratti dall'HPO",
    "Make union of extracted genes (default)": "Fare unione di geni estratti (inadempienza)",
    "You included the genes in your query. You made an ": "Hai incluso i geni nella tua domanda. Hai fatto un",
    " between the HPO genes": "tra i geni HPO",
    "No genes shared across all HPOs": "Nessun geni condivisi in tutti gli HPO",
    "No Genes": "Nessun geni",
    "Clear current HPO selection": "Cancella selezione HPO corrente",
    "HPOs collected from analysed index case": "HPOS raccolto dall'indice analizzato",
    "Get HPOs from Participants": "Ottieni HPO dai partecipanti",
    "search for HPO terms": "Cerca termini HPO",
    "You have now": "Hai adesso",
    "HPO terms selected": "Termini HPO selezionati",
    "Select Resource:": "Seleziona risorsa:",
    "Select Method:": "Seleziona Metodo:",
    "of genes in this section is applied ": "di geni in questa sezione viene applicato",
    "In the next step, you can extract genes from HPOs and add them to your queries": "Nel passaggio successivo, puoi estrarre geni da HPO e aggiungerli alle tue domande",
    "Select a predefined gene list": "Seleziona un elenco genico predefinito",
    "search for OMIM terms": "Cerca termini OMIM",
    "Delete OMIM associated genes": "Elimina i geni associati a omim",
    "Enter multiple OMIM ids or names of disorders": "Immettere più ID OMIM o nomi di disturbi",
    "Search for OMIM terms": "Cerca termini OMIM",
    "Request Error": "Errore di richiesta",
    "Error": "Errore",
    "Loading genes...": "Caricamento dei geni ...",
    "Panel ": "Pannello",
    "includes a total of ": "include un totale di",
    " entities. There are ": "entità. Ci sono",
    " distinct genes in the ": "geni distinti nel",
    " green list": "Elenco verde",
    "Read more of this panel in PanelApp": "Leggi di più di questo pannello in PanelApp",
    "Delete PanelApp genes from query": "Elimina i geni PanelApp dalla query",
    "PanelApp genes added to the query": "Geni PanelApp aggiunti alla query",
    "Add Panel App Genes": "Aggiungi i geni dell'app del pannello",
    "Type of Panel": "Tipo di pannello",
    "Genes associated to": "Geni associati a",
    "No genes": "Nessun geni",
    "Search Pathways": "Percorsi di ricerca",
    "Delete Pathway associated genes": "Elimina i geni associati al percorso",
    "Search for Pathways": "Cerca percorsi",
    "Gene Search": "Ricerca genetica",
    "search for a gene name": "Cerca un nome genetico",
    "Upload File": "Carica file",
    "A number between 0-1": "Un numero tra 0-1",
    "INTERNAL FREQUENCY": "Frequenza interna",
    "Clear Filters": "Filtri chiari",
    "PASS + unannotated (NA)": "Pass + non annotato (NA)",
    "non-PASS": "non pass",
    "Selected Positions": "Posizioni selezionate",
    "X": "X",
    "The X chromosome has been automatically selected to match the selected inheritance": "Il cromosoma X è stato automaticamente selezionato per abbinare l'eredità selezionata",
    "Info": "Informazioni",
    "Select a chromosome. Positions are considered ONLY if a chromosome is selected": "Seleziona un cromosoma. Le posizioni sono considerate solo se viene selezionato un cromosoma",
    "Chromosome": "Cromosoma",
    "Start Position": "Inizia la posizione",
    "Chromosome required": "Cromosoma richiesto",
    "End Position": "Posizione finale",
    "Upload BED file": "Carica file letto",
    "Upload Coordinate file": "Carica file di coordinate",
    "When applied, will return only variants that fall within a run of homozygosity of at least the length selected in the first affected sample": "Quando applicato, restituiranno solo varianti che rientrano in una serie di omozigosi di almeno la lunghezza selezionata nel primo campione interessato",
    "Minimum run of homozygosity length": "Fun minima della lunghezza omozigote",
    "Not Specified": "Non specificato",
    "Annotated as disease causing": "Annotato come malattia che causa la malattia",
    "Disease causing": "Malattia che causa",
    "Polymorphism": "Polimorfismo",
    "Neutral": "Neutro",
    "Damaging": "Dannoso",
    "Tolerated": "Tollerato",
    "Probably damaging": "Probabilmente dannoso",
    "Possibly damaging": "Forse dannoso",
    " Likely Oncogenic": "Probabilmente oncogenico",
    " VUS": "Vus",
    " Benign": "Benigno",
    " Likely Benign": "Probabilmente benigno",
    "Likely Benign": "Probabilmente benigno",
    " Pathogenic": "Patogeno",
    " Likely Pathogenic": "Probabilmente patogeno",
    "0": "0",
    "20": "20",
    "40": "40",
    "60": "60",
    "80": "80",
    "100": "100",
    "Phred-scaled CADD score, indicating level of deleteriousness e.g. 20 (predicted top 1% most deleterious)": "Punteggio CADD in scala Phred, che indica il livello di deleteria, ad es. 20 (previsto dall'1% più deleterio)",
    "CADD Prediction": "Previsione CADD",
    "High": "Alto",
    "Moderate": "Moderare",
    "Low": "Basso",
    "Modifier": "Modificatore",
    "transcript_ablation": "Transcript_ablation",
    "splice_acceptor_variant": "spice_acceptor_variant",
    "splice_donor_variant": "spice_donor_variant",
    "stop_gained": "stop_gined",
    "frameshift_variant": "Frameshift_variant",
    "stop_lost": "stop_lost",
    "start_lost": "start_lost",
    "transcript_amplification": "trascrizione_amplification",
    "feature_elongation": "Feature_Elongation",
    "feature_truncation": "caratteristica_truncation",
    "inframe_insertion": "inframe_insertion",
    "inframe_deletion": "inframe_deletion",
    "missense_variant": "Missense_variant",
    "protein_altering_variant": "Protein_altering_variant",
    "splice_donor_5th_base_variant": "splice_donor_5th_base_variant",
    "splice_region_variant": "spice_region_variant",
    "splice_donor_region_variant": "spice_donor_region_variant",
    "splice_polypyrimidine_tract_variant": "splice_polypirimidine_tract_variant",
    "incomplete_terminal_codon_variant": "incomplete_terminal_codon_variant",
    "start_retained_variant": "start_reteined_variant",
    "stop_retained_variant": "stop_reteined_variant",
    "synonymous_variant": "sinonimo_variante",
    "coding_sequence_variant": "Coding_sequence_variant",
    "mature_miRNA_variant": "mature_mirna_variant",
    "5_prime_UTR_variant": "5_prime_utr_variant",
    "3_prime_UTR_variant": "3_prime_utr_variant",
    "non_coding_transcript_exon_variant": "non_coding_transcript_exon_variant",
    "intron_variant": "Intron_variant",
    "NMD_transcript_variant": "Nmd_transcript_variant",
    "non_coding_transcript_variant": "non_coding_transcript_variant",
    "coding_transcript_variant": "Coding_Transcript_variant",
    "upstream_gene_variant": "upstream_gene_variant",
    "downstream_gene_variant": "Downstream_gene_variant",
    "TFBS_ablation": "Tfbs_ablation",
    "TFBS_amplification": "Tfbs_amplification",
    "TF_binding_site_variant": "Tf_binding_site_variant",
    "regulatory_region_ablation": "Regulatory_Region_Ablation",
    "regulatory_region_amplification": "Regulatory_Region_amplification",
    "regulatory_region_variant": "Regulatory_Region_variant",
    "intergenic_variant": "intergenic_variant",
    "sequence_variant": "sequence_variant",
    "SNV": "SNV",
    "INDEL": "Indel",
    "Mane Select": "Mane Seleziona",
    "Selected samples": "Campioni selezionati",
    "Any Sample": "Qualsiasi campione",
    "Variant of uncertain significance": "Variante di significato incerto",
    "Conflicting interpretations": "Interpretazioni contrastanti",
    "Any": "Qualunque",
    "Coding": "Coding",
    "RNA": "RNA",
    "Known": "Conosciuto",
    "Predicted driver: Tier 1": "Driver previsto: livello 1",
    "Predicted driver: Tier 2": "Driver previsto: livello 2",
    "Predicted passenger": "Passeggero previsto",
    "The filters have changed": "I filtri sono cambiati",
    "GPAP Predefined Filters are curated by the GPAP team": "I filtri predefiniti GPAP sono curati dal team GPAP",
    "Select a predefined filter set": "Seleziona un set di filtri predefiniti",
    "Filter Set deleted": "Set di filtri Eliminato",
    "No leading and trailing spaces are allowed": "Non sono consentiti spazi di piombo e finali",
    "Name already exists.": "Il nome esiste già.",
    "Filter Panel": "Pannello del filtro",
    "Filter Name": "Nome del filtro",
    "Filter Description": "Descrizione del filtro",
    "filter_description": "filter_description",
    "Save Filter": "Salva filtro",
    "Filters saved in the database. You can find the filter-set in the section below.": "Filtri salvati nel database. È possibile trovare il filtro nella sezione seguente.",
    "Filter Set Saved Correctly": "Set di filtri salvato correttamente",
    "Create New Analysis": "Crea una nuova analisi",
    "Next": "Prossimo",
    "Genotype Settings": "Impostazioni del genotipo",
    "Apply": "Fare domanda a",
    "You have created ": "Hai creato",
    " analyses.": "analisi.",
    "You can now create a query that will be applied to the genotype(s). Select your filters and run the new query.": "Ora puoi creare una query che verrà applicata al genotipo. Seleziona i tuoi filtri ed esegui la nuova query.",
    "Run Query": "Esegui query",
    "Apply to current Samples": "Applicare ai campioni attuali",
    "or start from new Samples:": "o iniziare da nuovi campioni:",
    "custom": "costume",
    "Autosomal Dominant": "Autosomiale dominante",
    "Autosomal Recessive Homozygous": "Autosomiale omozigote recessivo",
    "Autosomal Recessive Compound Heteroz.": "Eteroz composto recessivo autosomico.",
    "De Novo": "De novo",
    "Deleterious": "Deleterio",
    "tolerated": "tollerato",
    "Disease_causing_automatic": "Malattia_causing_automatic",
    "Disease_causing": "Malattia_causing",
    "Probably harmless": "Probabilmente innocuo",
    "Known to be harmless": "Noto per essere innocuo",
    "Oncegenic": "Onceganic",
    "Likely Oncogenic": "Probabilmente oncogenico",
    "VUS": "Vus",
    "Likely Pathogenic": "Probabilmente patogeno",
    "Variants distributions": "Distribuzioni varianti",
    "GnomAD Population": "Popolazione di Gnomad",
    "OncoEvidences": "Oncoevidences",
    "There are 0 onco evidences": "Ci sono 0 prove di onco",
    "How this score is calculated?": "Come viene calcolato questo punteggio?",
    "Filter Sets": "Set di filtri",
    "Predefined Gene Lists": "Elenchi di geni predefiniti",
    "Analysis Settings": "Impostazioni di analisi",
    "Analysis Parameters": "Parametri di analisi",
    "Standard Pipelines": "Pipeline standard",
    "Preview External Resources": "Anteprima delle risorse esterne",
    "Filter Set Manager": "Filtro Set Manager",
    "Create and Save a new Filter Set": "Crea e salva un nuovo set di filtri",
    "Close": "Vicino",
    "Available Filter Sets": "Set di filtri disponibili",
    "The filters shown below include both built-in filters and those you have created.": "I filtri mostrati di seguito includono sia i filtri integrati che quelli che hai creato.",
    "No description": "Nessuna descrizione",
    "View filters": "Visualizza i filtri",
    "Applied filters:": "Filtri applicati:",
    "No filters found": "Nessun filtro trovato",
    "List of genes": "Elenco dei geni",
    "Search:": "Ricerca:",
    "No results": "Nessun risultato",
    "This list contains": "Questo elenco contiene",
    "genes": "geni",
    "Create a new List": "Crea un nuovo elenco",
    "List Information": "Informazioni sull'elenco",
    "List Title": "Titolo dell'elenco",
    "List Source": "Elenco Fonte",
    "Notes": "Note",
    "Gene List": "Elenco genico",
    "Variant Positions": "Posizioni varianti",
    "Visibility": "Visibilità",
    "Public": "Pubblico",
    "List Visibility": "Elenco visibilità",
    "When active, the list will be usable for analyses. If this list has the same name as an existing one, the previous one will become inactive to users.": "Quando attivo, l'elenco sarà utilizzabile per le analisi. Se questo elenco ha lo stesso nome di uno esistente, il precedente diventerà inattivo per gli utenti.",
    "Active": "Attivo",
    "Upload a List": "Carica un elenco",
    "View Lists": "Visualizza elenchi",
    "File type": "Tipo di file",
    "Please select file type": "Seleziona il tipo di file",
    "GPAP Gene File": "File genico GPAP",
    ".list File": "File. Elenco",
    "Comma Separated List": "Elenco separato da virgola",
    "ACTIVE": "ATTIVO",
    "Not Active": "Non attivo",
    "Predefined Gene List Info": "Informazioni predefinite dell'elenco genico",
    "Predefined Lists in GPAP": "Elenchi predefiniti in GPAP",
    "Visible List": "Elenco visibile",
    "Are you sure to delete the Predefined Gene List?": "Sei sicuro di eliminare l'elenco genico predefinito?",
    "Gene list was validated": "L'elenco genico è stato convalidato",
    "Some genes could not be validated. Please check them and re-upload the file:": "Alcuni geni non potevano essere convalidati. Si prega di controllarli e ricaricare il file:",
    "Please check the text": "Si prega di controllare il testo",
    "Genes are not in the correct format, please check the text": "I geni non sono nel formato corretto, controlla il testo",
    "Fields should not be empty, please check the text": "I campi non devono essere vuoti, controlla il testo",
    "Gene list is empty. Please check the file.": "La lista genica è vuota. Si prega di controllare il file.",
    "Cancel Gene List Upload": "Annulla caricamento dell'elenco genico",
    "Upload a GPAP gene file in txt format": "Carica un file genico GPAP in formato txt",
    "Be sure that the file is in the correct format and all required fields are present": "Assicurarsi che il file sia nel formato corretto e che siano presenti tutti i campi richiesti",
    "Select file": "Seleziona file",
    "Download our template file and fill it in with your data": "Scarica il nostro file modello e compilalo con i tuoi dati",
    "Download sample file": "Scarica il file di esempio",
    "Validating genes..": "Convalidamento dei geni ..",
    "Upload a .list file": "Carica un file .list",
    "The .list file is the legacy file format used to store predefined gene lists in the GPAP. This upload is meant for CNAG users. For more information, contact Steve Laurie.": "Il file .list è il formato del file legacy utilizzato per archiviare elenchi di geni predefiniti nel GPAP. Questo caricamento è pensato per gli utenti CNAG. Per ulteriori informazioni, contattare Steve Laurie.",
    "The file must contain a JSON array of Predefined Gene List Objects.": "Il file deve contenere un array JSON di oggetti elenchi di geni predefiniti.",
    "upload .list file": "Carica file .list",
    "lists parsed correctly and ready to be saved.": "Elenchi analizzati correttamente e pronti per essere salvati.",
    "Save Lists": "Salva elenchi",
    "Preview of External resources accessible in this instance:": "Anteprima delle risorse esterne accessibili in questa istanza:",
    "SNV external resources": "Risorse esterne SNV",
    "CNV external resources": "Risorse esterne CNV",
    "Edit": "Modificare",
    "Shaping Genomic Futures. GPAP + is your one-stop-shop platform for cutting-edge genomics services. The CNAG is committed to providing top-tier genomic analysis, interpretation and reporting, to meet your needs.": "Modellare i futuri genomici. GPAP + è la tua piattaforma One-Stop-Shop per servizi di genomica all'avanguardia. Il CNAG si impegna a fornire analisi genomiche di alto livello, interpretazione e reporting, per soddisfare le tue esigenze.",
    "GPAP+ in a nutshell": "GPAP+ in poche parole",
    "Genome Analysis": "Analisi del genoma",
    "State-of-the art standardised bioinformatics analysis workflows for the analysis of NGS data within a user-friendly interface.": "Flussi di lavoro di analisi bioinformatica standardizzati all'avanguardia per l'analisi dei dati NGS all'interno di un'interfaccia intuitiva.",
    "Interpretation and Reporting": "Interpretazione e reporting",
    "User friendly interpretation of integrated genomic, phenotypic and clinical information to facilitate molecular diagnosis and reporting.": "Interpretazione intuitiva di informazioni genomiche, fenotipiche e cliniche integrate per facilitare la diagnosi e la segnalazione molecolare.",
    "Pipeline and process validated with reference materials. GPAP+ includes QC checkpoints and informative reports, metrics and figures.": "Pipeline e processo validati con materiali di riferimento. GPAP+ include checkpoint QC e report informativi, metriche e cifre.",
    "Flexibility and Scalability": "Flessibilità e scalabilità",
    "GPAP+ is built upon open standards and scalable technologies. It can be deployed On Cloud, As a Service, and On Premises, allowing customisation and integration within a broader workflow.": "GPAP+ è costruito su standard aperti e tecnologie scalabili. Può essere distribuito su cloud, come servizio e sui locali, consentendo la personalizzazione e l'integrazione all'interno di un flusso di lavoro più ampio.",
    "Know us a bit more!": "Conosci un po 'di più!",
    "GPAP+ is a development from the RD-Connect GPAP and the Cancer GPAP, both developed by the CNAG. The platform facilitates advanced genome analysis for the diagnosis of rare diseases, cancer, and other conditions. With over 15 years of experience in clinical research projects, the CNAG has processed and uploaded to GPAP clinical research instances data from 35,000+ individuals submitted by 35+ hospitals and research centers across Europe.": "GPAP+ è uno sviluppo del GPAP con connessione RD e del GPAP di cancro, entrambi sviluppati dal CNAG. La piattaforma facilita l'analisi del genoma avanzata per la diagnosi di malattie rare, cancro e altre condizioni. Con oltre 15 anni di esperienza in progetti di ricerca clinica, il CNAG ha elaborato e caricato su dati di istanze di ricerca clinica GPAP da oltre 35.000 persone presentate da oltre 35 ospedali e centri di ricerca in tutta Europa.",
    "Contact us": "Contattaci",
    "Developed by": "Sviluppato da",
    "With support from": "Con supporto da",
    "The RD-Connect Genome-Phenome Analysis Platform has received funding from EU projects RD-Connect, Solve-RD and EJP-RD (grant numbers FP7 305444, H2020 779257, H2020 825575), Instituto de Salud Carlos III (Grant numbers PT13/0001/0044, PT17/0009/0019; Instituto Nacional de Bioinformática, INB), ELIXIR-EXCELERATE (Grant number EU H2020 #676559) and several ELIXIR Implementation Studies. The Cancer GPAP has received funding from contracts signed through the EU INSTAND-NGS4P project (grant number 874719).": "La piattaforma di analisi del genoma-fenoma-connessione RD ha ricevuto finanziamenti da progetti UE RD-connect, Solve-RD ed EJP-RD (Numeri di sovvenzione FP7 305444, H2020 779257, H2020 825575), Instituto De Salud Carlos III (numero di sovvenzioni PT13/0001 /0044, PT17/0009/0019; Instituto Nacional de Bioinformática, INB), Elisir-Excelerate (Numero di sovvenzione UE H2020 #676559) e numerosi studi di implementazione elisir. Il GPAP Cancer ha ricevuto finanziamenti dai contratti firmati attraverso il progetto UE Instand-NGS4P (numero di sovvenzione 874719).",
    "© CNAG 2024. All rights reserved.": "© CNAG 2024. Tutti i diritti riservati.",
    "A comprehensive state-of-the-art platform for NGS cancer data analysis, interpretation and reporting": "Una piattaforma completa all'avanguardia per l'analisi, l'interpretazione e la segnalazione dei dati del cancro NGS",
    "Our solutions": "Le nostre soluzioni",
    "Cancer Analysis": "Analisi del cancro",
    "Bioinformatic analysis of NGS data with standardized workflows for the comprehensive identification of germline variants and somatic mutations.": "Analisi bioinformatica dei dati NGS con flussi di lavoro standardizzati per l'identificazione completa di varianti germinali e mutazioni somatiche.",
    "Access Platform": "Piattaforma di accesso",
    "Cancer Reporting": "Segnalazione del cancro",
    "Integration of NGS results, e-medication data, and clinical evidence for therapy decision making.": "Integrazione dei risultati di NGS, dati di modalità elettronica e prove cliniche per il processo decisionale della terapia.",
    "We value your feedback!": "Apprezziamo il tuo feedback!",
    "Your experience matters to us. Help us improve by filling out our quick feedback form. What do you love? What can we do better? Let us know! Thank you!": "La tua esperienza conta per noi. Aiutaci a migliorare compilando il nostro modulo di feedback rapido. Cosa ami? Cosa possiamo fare di meglio? Facci sapere! Grazie!",
    "Cancer Analysis Feedback Form": "Modulo di feedback dell'analisi del cancro",
    "Cancer Reporting Feedback Form": "Modulo di feedback di segnalazione del cancro",
    "Instand-NGS4P Partners": "Partners Instand-NGS4P",
    "DIR LIST": "Elenco DIR",
    "Case ID": "Caso ID",
    "Patient ID": "ID paziente",
    "Analysis Type": "Tipo di analisi",
    "Priority": "Priorità",
    "Deadline": "Scadenza",
    "Assignee(s)": "Cessazione (i)",
    "Creation Date": "Data di creazione",
    "Tumor Experiment": "Esperimento tumorale",
    "Control Experiment": "Esperimento di controllo",
    "Clinical referrer": "Referrer clinico",
    "Clinical contact": "Contatto clinico",
    "Hospital Name": "Nome dell'ospedale",
    "Tumor characteristics": "Caratteristiche del tumore",
    "Tumor purity": "Purezza del tumore",
    "Average ploidy": "Ploidia media",
    "Biomarkers of genetic instability": "Biomarcatori dell'instabilità genetica",
    "Microsatellite status": "Stato di microsatellite",
    "Tumor mutational burden": "Onere mutazionale tumorale",
    "Homologous recombination deficiency (HRD) status": "Stato di carenza di ricombinazione omologa (HRD)",
    "Bin-level log2 coverages and segmentation calls": "Coperture Log2 a livello di bidone e chiamate di segmentazione",
    "Source: CNVkit": "Fonte: cnvkit",
    "Copy number per chromosome": "Numero di copia per cromosoma",
    "Tumor Sample ID:": "ID campione tumorale:",
    "Mutational signatures": "Firme mutazionali",
    "Source: SigProfilerAssignment": "Fonte: SigProfilerassment",
    "No control experiment id for tumor experiment id: ": "Nessun ID esperimento di controllo per ID esperimento tumorale:",
    "Create Interpretation Request": "Crea richiesta di interpretazione",
    "Analysis ": "Analisi",
    "Assignee": "Cessionario",
    "The users who will handle the interpretation of this request. Only these users can edit the request. If no users are selected, everyone in the organization can edit.": "Gli utenti che gestiranno l'interpretazione di questa richiesta. Solo questi utenti possono modificare la richiesta. Se non vengono selezionati utenti, tutti nell'organizzazione possono modificare.",
    "Clinical Referrer": "Referrer clinico",
    "Clinical Referrer Contact": "Contatto di referrer clinico",
    "Hospital name": "Nome dell'ospedale",
    "Select Priority": "Seleziona priorità",
    "Medium": "Medio",
    "Group Owner": "Proprietario del gruppo",
    "Filter Set": "Set di filtri",
    "Select Filter Set": "Seleziona il set di filtri",
    "Select Gene List": "Seleziona Elenco genico",
    "Create": "Creare",
    "Search Experiment ID": "ID esperimento di ricerca",
    "Search Participant ID": "Cerca ID partecipante",
    "Tumor Experiment ID": "ID esperimento tumorale",
    "Experiment and Sample Info": "Esperimento e informazioni di esempio",
    "Submitter ID": "ID SPIDIO",
    "Library Strategy": "Strategia della biblioteca",
    "Kit": "Kit",
    "Sample ID": "ID campione",
    "Sample tissue of origin": "Tessuto campione di origine",
    "Sample tissue of origin status": "Stato del tessuto campione di origine",
    "Sample type": "Tipo di campionamento",
    "Percentage of tumoral cells": "Percentuale di cellule tumorali",
    "Fixative": "Fissativo",
    "Experiments": "Esperimenti",
    "Search by Experiment ID": "Ricerca per ID esperimento",
    "Search by Patient ID": "Cerca per ID paziente",
    "Associate Experiment IDs to the request.": "Associate ID esperimento alla richiesta.",
    "Only experiments that have been processed and are ready for analysis are visible.": "Sono visibili solo esperimenti che sono stati elaborati e che sono pronti per l'analisi.",
    "Patient and Clinical Info": "Informazioni per i pazienti e clinici",
    "Clinical referral": "Referral clinico",
    "Observed Symptoms": "Sintomi osservati",
    "Cancer Type": "Tipo di cancro",
    "Case number pathology": "Patologia del numero del caso",
    "Treatment history": "Storia del trattamento",
    "Treatment response": "Risposta al trattamento",
    "New Instand DIR Study": "Nuovo studio di Instand Dir",
    "Request Overview": "Panoramica della richiesta",
    "Germline Genetic Findings": "Risultati genetici della linea germinale",
    "Reports": "Segnalazioni",
    "Tumor Genetic Overview": "Panoramica genetica del tumore",
    "Somatic Genetic Findings": "Risultati genetici somatici",
    "Clinical Evidence": "Prove cliniche",
    "Gene Fusions": "Fusioni geniche",
    "Request": "Richiesta",
    "To Do": "Fare",
    "In Progress": "In corso",
    "Done": "Fatto",
    "Somatic fusion analysis": "Analisi di fusione somatica",
    "Somatic tumor only": "Solo tumore somatico",
    "Somatic tumor normal": "Tumore somatico normale",
    "Germline": "Germine",
    "Male": "Maschio",
    "Female": "Femmina",
    "No information provided": "Nessuna informazione fornita",
    "General Info": "Informazioni generali",
    "Request ID": "Richiesta ID",
    "Administrative Info": "Informazioni amministrative",
    "Creator": "Creatore",
    "Creation date": "Data di creazione",
    "Clinical referrer contact info": "Informazioni di contatto con referrer clinico",
    "Update Filters": "Aggiorna i filtri",
    "Patient Info": "Informazioni del paziente",
    "Clinical Info": "Informazioni cliniche",
    "Diagnostic date": "Data diagnostica",
    "Clinical diagnosis": "Diagnosi clinica",
    "Treatment history and response": "Storia e risposta del trattamento",
    "Cancer type": "Tipo di cancro",
    "Tumor Sample and Experiment Info": "Campione tumorale e informazioni sull'esperimento",
    "Sample Info": "Informazioni di esempio",
    "Sample fixation": "Fissazione del campione",
    "Sample site of origin": "SEMPLICE SITO DI ORIGINE",
    "Sample site of origin status": "Stato del sito di esempio di origine",
    "Purity": "Purezza",
    "Experiment Info": "Informazioni per gli esperimenti",
    "Library strategy": "Strategia della biblioteca",
    "Sample and Experiment Info": "INFORMAZIONI DI ESEMPIO E DELL'ESPRITÀ",
    "Relevant Observations": "Osservazioni rilevanti",
    "Sample quality observations": "Osservazioni di qualità del campione",
    "Sequencing quality observations": "Osservazioni di qualità di sequenziamento",
    "Fusion": "Fusione",
    "Junction read count": "Conteggio delle letture delle giunzioni",
    "Left Gene": "Gene sinistro",
    "Left gene": "Gene sinistro",
    "Left breakpoint": "Breakpoint sinistro",
    "Right Gene": "Gene giusto",
    "Right gene": "Gene giusto",
    "Right breakpoint": "Breakpoint destro",
    "These are all the gene fusions our system calculated. They’re not affected by the request’s filters or gene list.": "Queste sono tutte le fusioni geniche calcolate dal nostro sistema. Non sono interessati dai filtri della richiesta o dalla lista genica.",
    "These are all the clinical evidences our system calculated. They’re not affected by the request’s filters or gene list.": "Queste sono tutte le prove cliniche calcolate dal nostro sistema. Non sono interessati dai filtri della richiesta o dalla lista genica.",
    "Germline SNV and InDels": "Germline snv e indels",
    "1 genomic variant": "1 variante genomica",
    "genomic variants": "varianti genomiche",
    "The displayed number is the total sum of events in the Complete view and Split by genes results": "Il numero visualizzato è la somma totale degli eventi nella vista completa e divisa dai risultati dei geni",
    "SNVs and InDels - Somatic": "SNV e indels - somatico",
    "SNVs and InDels - Germline": "SNV e indels - Germline",
    "CNVs and SVs": "CNV e SVS",
    "Gene Evidence": "Prova genica",
    "Genotype": "Genotipo",
    "Phenotype": "Fenotipo",
    "Haplotype1": "Haplotype1",
    "Haplotype1 Evidence": "Prove haplotype1",
    "Haplotype2": "Haplotype2",
    "Haplotype2 Evidence": "Prove haplotype2",
    "Variant Data": "Dati varianti",
    "These are all the haplotypes our system calculated. They’re not affected by the request’s filters or gene list.": "Questi sono tutti gli aplotipi calcolati dal nostro sistema. Non sono interessati dai filtri della richiesta o dalla lista genica.",
    "Clinical Reports": "Rapporti clinici",
    "Patient Reports": "Rapporti sui pazienti",
    "Clinical reports": "Rapporti clinici",
    "Report ID": "Report ID",
    "Report Name": "Nome report",
    "Genomic breakpoints detected": "Punti di rottura genomici rilevati",
    "Clinically relevant gene": "Gene clinicamente rilevante",
    "Clinical significance**": "Significato clinico **",
    "cDNA": "cDNA",
    "Protein": "Proteina",
    "Associated Clinical Report ID": "ID clinico associato",
    "ANNEXES": "Annessi",
    "The following annexes complete the report, which should be reviewed as a whole.": "I seguenti allegati completano il rapporto, che dovrebbe essere rivisto nel suo insieme.",
    "ANNEX I: CLINICAL RECORD": "Allegato I: record clinico",
    "ANNEX II: METHODOLOGY": "Allegato II: metodologia",
    "ANNEX III: QUALITY CONTROL METRICS": "Allegato III: Metriche di controllo di qualità",
    "ANNEX IV: VARIANT METRICS AND GENOTYPE-PHENOTYPE ASSOCIATIONS": "Allegato IV: metriche varianti e associazioni di genotipo-fenotipo",
    "ANNEX V: PHARMACOGENOMICS": "Allegato V: farmacogenomica",
    "Clinical symptoms and physical findings": "Sintomi clinici e risultati fisici",
    "Family history": "Storia familiare",
    "Test method": "Metodo di prova",
    "Type of variants assessed": "Tipo di varianti valutate",
    "Sample received": "Campione ricevuto",
    "Date of sample collection": "Data della raccolta di campioni",
    "Sample processing:": "Elaborazione del campione:",
    "Center": "Centro",
    "Sequencing platform": "Piattaforma di sequenziamento",
    "Fragment (size and type)": "Frammento (dimensione e tipo)",
    "Bioinformatics analysis:": "Analisi bioinformatica:",
    "Human reference genome": "Genoma di riferimento umano",
    "Aligner": "Allineatore",
    "Mark duplicates": "Segna i duplicati",
    "Base recalibration": "Ricalibrazione di base",
    "Smart variants caller": "Caller di varianti intelligenti",
    "Analysis pipeline": "Pipeline di analisi",
    "Genotype-phenotype associations": "Associazioni genotipo-fenotipo",
    "No data available": "Nessun dato disponibile",
    "Coverage of the genes of interest (RefSeq coding regions)": "Copertura dei geni di interesse (regioni di codifica RefSeq)",
    "All genes have a C10 of 90 or more, except for:": "Tutti i geni hanno un C10 di 90 o più, ad eccezione di:",
    "Genome coverage metrics": "Metriche di copertura del genoma",
    "No haplotypes have been detected": "Non sono stati rilevati aplotipi",
    "Variant metrics": "Metriche varianti",
    "No additional information provided.": "Nessuna ulteriore informazione fornita.",
    "Haplotype Interpretation": "Interpretazione dell'aplotipo",
    "Treatment": "Trattamento",
    "Tumour mutation load": "Carico di mutazione tumorale",
    "Tumour mutational burden": "Onere mutazionale tumorale",
    "Homologous Recombination Deficiency": "Deficit di ricombinazione omologa",
    "Mutational profiles": "Profili mutazionali",
    "Molecular tissue of origin prediction": "Previsione del tessuto molecolare di origine",
    "Tumor specific viral insertions": "Inserimenti virali specifici per tumore",
    "Sample condition": "Condizione del campione",
    "Site of tumour": "Sito di tumore",
    "Treatment given before sample was taken": "Trattamento dato prima che il campione sia stato effettuato",
    "Sample information": "Informazioni di esempio",
    "DNA purity": "PURITÀ DNA",
    "DNA integrity": "Integrità del DNA",
    "Sample processing": "Elaborazione del campione",
    "Bioinformatics analysis": "Analisi bioinformatica",
    "Exome coverage metrics": "Metriche di copertura dell'esoma",
    "C10/C15: percentage of bases covered by at least 10 and 15 reads, respectively.": "C10/C15: percentuale di basi coperte da almeno 10 e 15 letture, rispettivamente.",
    "MOLECULAR GENETIC REPORT": "Rapporto genetico molecolare",
    "Page ": "Pagina",
    " of ": "Di",
    "No additional information provided": "Nessuna ulteriore informazione fornita",
    "CLINICAL EVIDENCE AND PATIENT MANAGEMENT": "Prove cliniche e gestione dei pazienti",
    "Only information relevant to the specific type of cancer under investigation is reported.": "Sono riportate solo informazioni rilevanti per il tipo specifico di cancro sotto inchiesta.",
    "Conclusion": "Conclusione",
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to ClinGen, CGC and VICC recommendations (Horak et al 2022) and the Human Variation Variant Society (HGVS) ": "Il significato clinico delle varianti genetiche viene interpretato utilizzando le informazioni attualmente disponibili ed è soggetta a cambiamenti mentre la conoscenza scientifica si espande. Le varianti genomiche sono classificate e riportate secondo le raccomandazioni di Clingen, CGC e VICC (Horak et al 2022) e la Società di variazione umana (HGVS)",
    "Only FDA or EMA approved treatments are reported. The conclusion of this report is based solely on the results of the DNA sequencing of the tumour and the received tumour type. Final interpretation of the clinical consequence of this report should therefore always be performed by the treating physician.": "Vengono riportati solo trattamenti approvati dalla FDA o EMA. La conclusione di questo rapporto si basa esclusivamente sui risultati del sequenziamento del DNA del tumore e del tipo di tumore ricevuto. L'interpretazione finale della conseguenza clinica di questo rapporto dovrebbe quindi essere sempre eseguita dal medico curante.",
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to the American College of Medical Genetics (ACMG) recommendations (Richards et al. 2015, Riggs et al. 2020), ClinGen recommendations (Welcome to ClinGen ) and the Human Variation Variant Society (HGVS) ": "Il significato clinico delle varianti genetiche viene interpretato utilizzando le informazioni attualmente disponibili ed è soggetta a cambiamenti mentre la conoscenza scientifica si espande. Le varianti genomiche sono classificate e riportate secondo le raccomandazioni dell'American College of Medical Genetics (ACMG) (Richards et al. 2015, Riggs et al. 2020), raccomandazioni di Clingen (Welcome to Clingen) e Human Variation Variant Society (HGVS)",
    "Genetic counselling and recommendations are based on GeneReviews guidelines (Margaret et al 1993-2020).": "La consulenza e le raccomandazioni genetiche si basano sulle linee guida Genereviews (Margaret et al 1993-2020).",
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. The conclusion of this report is based solely on the results of the RNA sequencing of the tumour and the received tumour type. Final interpretation of the clinical consequence of this report should therefore always be performed by the treating physician.": "Il significato clinico delle varianti genetiche viene interpretato utilizzando le informazioni attualmente disponibili ed è soggetta a cambiamenti mentre la conoscenza scientifica si espande. La conclusione di questo rapporto si basa esclusivamente sui risultati del sequenziamento dell'RNA del tumore e del tipo di tumore ricevuto. L'interpretazione finale della conseguenza clinica di questo rapporto dovrebbe quindi essere sempre eseguita dal medico curante.",
    "DISCLAIMER": "DISCLAIMER",
    "Issuing centre": "Centro di emissione",
    "Requesting centre": "Centro di richiesta",
    "Reporting date": "Data di segnalazione",
    "Referrer": "Referrer",
    "Name": "Nome",
    "Surname": "Cognome",
    "Biopsy location": "Posizione della biopsia",
    "REASON FOR REFERRAL": "Motivo del referral",
    "PERFORMED STUDY": "Studio condotto",
    "TEST PERFORMED (Library strategy)": "Test eseguito (Strategia di biblioteca)",
    "GENETIC FINDINGS": "Risultati genetici",
    "*Based upon reference genome version GRCh38": "*Basato sulla versione del genoma di riferimento Grch38",
    "*Based upon reference genome version GRCh38.": "*Basato sulla versione del genoma di riferimento GRCH38.",
    "***This column includes a range of genes. Because of the size of it, the platform can’t display them all in the exported report but you can review them in the Genetic Findings view.": "*** Questa colonna include una gamma di geni. A causa delle dimensioni, la piattaforma non può mostrarli tutti nel rapporto esportato, ma puoi rivederli nella vista dei risultati genetici.",
    "Genomic Position:": "Posizione genomica:",
    "Left and Right Breakpoint:": "Breakpoint sinistro e destro:",
    "INTERPRETATION": "INTERPRETAZIONE",
    "Recommendation": "Raccomandazione",
    "REFERENCES": "Riferimenti",
    "Genetic Findings": "Risultati genetici",
    "Clinical Evidence and Patient Management": "Prove cliniche e gestione dei pazienti",
    "Disclaimer": "Disclaimer",
    "Technical Limitations": "Limitazioni tecniche",
    "References": "Riferimenti",
    "Annexes": "Annessi",
    "New Clinical Report": "Nuovo rapporto clinico",
    "Welcome to the Somatic Clinical Report creation screen. In here you’ll be able to create and export a molecular genetic report document with the information you’ve reviewed and processed for this interpretation request. Read, review and complete each section and, when you’re done, click on the Create button to export your report.": "Benvenuti nella schermata di creazione del rapporto clinico somatico. Qui sarai in grado di creare ed esportare un documento di report genetico molecolare con le informazioni che hai rivisto ed elaborato per questa richiesta di interpretazione. Leggi, rivedi e completa ogni sezione e, quando hai finito, fai clic sul pulsante Crea per esportare il rapporto.",
    "Welcome to the Germline Clinical Report creation screen. In here you’ll be able to create and export a molecular genetic report document with the information you’ve reviewed and processed for this interpretation request. Read, review and complete each section and, when you’re done, click on the Create button to export your report.": "Benvenuti nella schermata di creazione del rapporto clinico germinale. Qui sarai in grado di creare ed esportare un documento di report genetico molecolare con le informazioni che hai rivisto ed elaborato per questa richiesta di interpretazione. Leggi, rivedi e completa ogni sezione e, quando hai finito, fai clic sul pulsante Crea per esportare il rapporto.",
    "Preview": "Anteprima",
    "Annex 5: Pharmacogenomics": "Allegato 5: farmacogenomica",
    "These are the haplotypes that were tagged in the Pharmacogenomics tab of this Interpretation Request.Check all the haplotypes that you want to display in the final report.": "Questi sono gli aplotipi che sono stati taggati nella scheda Pharmacogenomics di questa richiesta di interpretazione. Controlla tutti gli aplotipi che si desidera visualizzare nel rapporto finale.",
    "These are the interpretation comments for the selected haplotypes.": "Questi sono i commenti di interpretazione per gli aplotipi selezionati.",
    "The original tag comment will not be modified here. Your modifications will be visible only in the exported report.": "Il commento del tag originale non verrà modificato qui. Le tue modifiche saranno visibili solo nel rapporto esportato.",
    "No genes were targeted": "Nessun geni è stato preso di mira",
    "Annex 1: Clinical record": "Allegato 1: record clinico",
    "Annex 2: Methodology": "Allegato 2: metodologia",
    "Annex 3: Quality control metrics": "Allegato 3: Metriche di controllo di qualità",
    "The fields in this section have to be filled in manually after downloading the document. Automatic synchronisation coming soon.": "I campi in questa sezione devono essere compilati manualmente dopo aver scaricato il documento. Sincronizzazione automatica in arrivo.",
    "List of diagnostically targeted genes": "Elenco di geni diagnosticamente mirati",
    "Coverage of the genes of interest (RefSeq coding sections)": "Copertura dei geni di interesse (sezioni di codifica RefSeq)",
    "Annex 4: Variant metrics and genotype-phenotype associations": "Allegato 4: metriche varianti e associazioni di genotipo-fenotipo",
    "Gene Symbol": "Simbolo genico",
    "Mean Coverage": "Copertura media",
    "Median Coverage": "Copertura mediana",
    "C10": "C10",
    "C15": "C15",
    "C20": "C20",
    "Gene MIM Number": "Numero MIM genetico",
    "Phenotype MIM Number": "Numero MIM del fenotipo",
    "Transcript cDNA": "CDNA di trascrizione",
    "GQ": "GQ",
    "DP": "Dp",
    "REF count": "Conteggio dei ref",
    "ALT count": "ALT COUNT",
    "AAF": "Aaf",
    "Genome Coverage Metrics": "Metriche di copertura del genoma",
    "Exome Coverage Metrics": "Metriche di copertura dell'esoma",
    "Panel Coverage Metrics": "Metriche di copertura del panel",
    "Coverage Metrics": "Metriche di copertura",
    "Unavailable coverage data": "Dati di copertura non disponibili",
    "Annex 2: Tumor genomic characteristics": "Allegato 2: caratteristiche genomiche tumorali",
    "Annex 3: Other biomarkers": "Allegato 3: altri biomarcatori",
    "Annex 4: Methodology": "Allegato 4: metodologia",
    "Annex 5: Quality control metrics": "Allegato 5: Metriche di controllo di qualità",
    "Duplication Rate ": "Tasso di duplicazione",
    "Uniformity": "Uniformità",
    "Gene symbol": "Simbolo genico",
    "Mean coverage": "Copertura media",
    "C1": "C1",
    "These are the evidences that were tagged in the": "Queste sono le prove che sono state taggate nel",
    "tab of this Interpretation Request.": "Scheda di questa richiesta di interpretazione.",
    "Check all the evidences that you want to display in the final report.": "Controlla tutte le prove che si desidera visualizzare nel rapporto finale.",
    "The": "IL",
    " Clinical Significance": "Significato clinico",
    "field shows the comments that were added when tagging an evidence.": "Il campo mostra i commenti che sono stati aggiunti durante il tag un'evidenza.",
    "The original tag comment ": "Il commento del tag originale",
    "will not": "non lo farà",
    " be modified here. Your modifications will only be visible in the exported report.": "essere modificato qui. Le tue modifiche saranno visibili solo nel rapporto esportato.",
    "These are the interpretation comments that were written in each evidence tag.": "Questi sono i commenti di interpretazione che sono stati scritti in ogni tag di prova.",
    "Variant": "Variante",
    "Therapy": "Terapia",
    "Comment": "Commento",
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to the American College of Medical Genetics (ACMG) recommendations (Richards et al. 2015, Riggs et al. 2020), ClinGen recommendations (Welcome to ClinGen ) and the Human Variation Variant Society (HGVS)": "Il significato clinico delle varianti genetiche viene interpretato utilizzando le informazioni attualmente disponibili ed è soggetta a cambiamenti mentre la conoscenza scientifica si espande. Le varianti genomiche sono classificate e riportate secondo le raccomandazioni dell'American College of Medical Genetics (ACMG) (Richards et al. 2015, Riggs et al. 2020), raccomandazioni di Clingen (Welcome to Clingen) e Human Variation Variant Society (HGVS)",
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to ClinGen, CGC and VICC recommendations (Horak et al 2022) and the Human Variation Variant Society (HGVS)": "Il significato clinico delle varianti genetiche viene interpretato utilizzando le informazioni attualmente disponibili ed è soggetta a cambiamenti mentre la conoscenza scientifica si espande. Le varianti genomiche sono classificate e riportate secondo le raccomandazioni di Clingen, CGC e VICC (Horak et al 2022) e la Società di variazione umana (HGVS)",
    "Sample Type": "Tipo di campionamento",
    "Biopsy Location": "Posizione della biopsia",
    "Reason for referral": "Motivo del referral",
    "Performed Study": "Studio condotto",
    "e.g. Molecular testing to determine management including treatment options.": "per esempio. Test molecolari per determinare la gestione, comprese le opzioni di trattamento.",
    "Test performed (Library strategy)": "Test eseguito (Strategia di biblioteca)",
    "**Based upon ClinGen, CGC and VICC recommendations (Horak et al. 2022)": "** Basato su raccomandazioni Clingen, CGC e VICC (Horak et al. 2022)",
    "**Based upon ACMG and ClinGen recommendations (Richards et al. 2015 and  ": "** Basato su raccomandazioni ACMG e Clingen (Richards et al. 2015 e",
    "These are the variants that were tagged in the": "Queste sono le varianti che sono state taggate nel",
    "tab of this Interpretation Request. Check all the variants that you want to display in the final report.": "Scheda di questa richiesta di interpretazione. Controlla tutte le varianti che si desidera visualizzare nel rapporto finale.",
    "SNVs and InDels": "SNV e indeli",
    "Genomic breakpoints detected*": "Punti di interruzione genomici rilevati*",
    "Included gene(s)": "Geni incluso",
    "Clinical significance*": "Significato clinico*",
    "Genomic position*": "Posizione genomica*",
    "These are the interpretation comments that were written in each variant tag.": "Questi sono i commenti di interpretazione che sono stati scritti in ogni tag variante.",
    "The original tag comment will not be modified here. Your modifications will only be visible in the exported report.": "Il commento del tag originale non verrà modificato qui. Le tue modifiche saranno visibili solo nel rapporto esportato.",
    "Recommendations": "Raccomandazioni",
    "Horak P, Griffith M, Danos AM, et al. Standards for the classification of pathogenicity of somatic variants in cancer (oncogenicity): Joint recommendations of Clinical Genome Resource (ClinGen), Cancer Genomics Consortium (CGC), and Variant Interpretation for Cancer Consortium (VICC) [published correction appears in Genet Med. 2022 Sep;24(9):1991]. Genet Med. 2022;24(5):986-998.": "Horak P, Griffith M, Danos AM, et al. Standard per la classificazione della patogenicità delle varianti somatiche nel cancro (oncogenicità): raccomandazioni articolari della risorsa clinica del genoma (clingen), consorzio di genomica del cancro (CGC) e interpretazione delle varianti per il consorzio del cancro (VICC) [la correzione pubblicata appare in Genet Med. 2022 set; 24 (9): 1991]. Genet Med. 2022; 24 (5): 986-998.",
    "Li MM, Datto M, Duncavage EJ, et al. Standards and Guidelines for the Interpretation and Reporting of Sequence Variants in Cancer: A Joint Consensus Recommendation of the Association for Molecular Pathology, American Society of Clinical Oncology, and College of American Pathologists. J Mol Diagn. 2017;19(1):4-23.": "Li MM, Datto M, Duncavage EJ, et al. Standard e linee guida per l'interpretazione e la segnalazione di varianti di sequenza nel cancro: una raccomandazione di consenso congiunto dell'associazione per la patologia molecolare, la società americana di oncologia clinica e il college di patologi americani. J mol Diagn. 2017; 19 (1): 4-23.",
    "Richards S, Aziz N, Bale S, et al. ACMG Laboratory Quality Assurance Committee. Standards and guidelines for the interpretation of sequence variants: a joint consensus recommendation of the American College of Medical Genetics and Genomics and the Association for Molecular Pathology. Genet Med. 2015 May;17(5):405-24.": "Richards S, Aziz N, Bale S, et al. Comitato per l'assicurazione della qualità di laboratorio ACMG. Standard e linee guida per l'interpretazione delle varianti di sequenza: una raccomandazione di consenso congiunto dell'American College of Medical Genetics and Genomics e dell'associazione per la patologia molecolare. Genet Med. 2015 maggio; 17 (5): 405-24.",
    "Riggs ER, Andersen EF, Cherry AM, et al. Technical standards for the interpretation and reporting of constitutional copy-number variants: a joint consensus recommendation of the American College of Medical Genetics and Genomics (ACMG) and the Clinical Genome Resource (ClinGen). Genet Med. 2020 Feb;22(2):245-257.": "Riggs ER, Andersen EF, Cherry AM, et al. Standard tecnici per l'interpretazione e la segnalazione delle varianti costituzionali del numero di copia: una raccomandazione di consenso congiunto dell'American College of Medical Genetics and Genomics (ACMG) e della risorsa clinica del genoma (Clingen). Genet Med. 2020 febbraio; 22 (2): 245-257.",
    "No information available about the Experiment Type": "Nessuna informazione disponibile sul tipo di esperimento",
    "Left and Right Breakpoints:": "Punti di interruzione sinistra e destra:",
    "No ": "NO",
    " calculated": "calcolato",
    "Please fill these in manually after downloading the report": "Si prega di compilarli manualmente dopo aver scaricato il rapporto",
    "Report name": "Nome report",
    "This field is required.": "Questo campo è obbligatorio.",
    "Associated Clinical Report": "Rapporto clinico associato",
    "Supported files: .doc, .docx, .pdf, .txt": "File supportati: .doc, .docx, .pdf, .txt",
    "Maximum file size: 10MB": "Dimensione massima del file: 10 MB",
    "File uploaded successfully": "File caricato correttamente",
    "OK": "OK",
    "TECHNICAL LIMITATIONS": "Limitazioni tecniche",
    "Treatments": "Trattamenti",
    "Gene name": "Nome genetico",
    "Level of evidence": "Livello di prova",
    "Match": "Incontro",
    "Therapy status": "Stato di terapia",
    "Drug status ": "Stato del farmaco",
    "Evidence": "Prova",
    "Evidence CGI": "Evidenza CGI",
    "Evidence CIViC ": "Prova civica",
    "Participants included in the study": "Partecipanti inclusi nello studio",
    "Minimum read depth at variant position": "Profondità di lettura minima in posizione variante",
    "Min-Max Alternate Allele Freq": "Min-Max Alternate Allele Freq",
    "Minimum genotype quality (Phred-scaled) for variant position": "Qualità minima del genotipo (SCALDATO PHRED) per la posizione della variante",
    "Variant Source": "Sorgente variante",
    "User": "Utente",
    "Timestamp": "Timestamp",
    "Ref": "Rif",
    "Region Name": "Nome regione",
    "Type in a name": "Digita un nome",
    "Configuration details": "Dettagli di configurazione",
    "Affected": "Ricercato",
    "set Genotype Settings": "Imposta le impostazioni del genotipo",
    "Quality Settings": "Impostazioni di qualità",
    "No genotype configured. The sample will not be included in the analysis": "Nessun genotipo configurato. Il campione non sarà incluso nell'analisi",
    "Minimum Depth": "Profondità minima",
    "Minimum Genotype Quality": "Qualità minima del genotipo",
    "Alternate Allele Frequency": "Frequenza allele alternativa",
    "Select a simulated mode of inheritance configuration:": "Selezionare una modalità simulata di configurazione ereditaria:",
    "Please, bear in mind that the predefined inheritances take into account the reported affected status of the family members which in some cases may force unrealistic configurations. In such cases, please use the custom configuration to formulate your hypothesis": "Per favore, tieni presente che le eredità predefinite tengono conto dello stato interessato dei membri della famiglia che in alcuni casi può forzare configurazioni non realistiche. In tali casi, utilizzare la configurazione personalizzata per formulare l'ipotesi",
    "or configure a custom mode of inheritance": "o configurare una modalità di eredità personalizzata",
    "10": "10",
    "30": "30",
    "50": "50",
    "70": "70",
    "90": "90",
    "0.1": "0.1",
    "0.2": "0.2",
    "0.3": "0.3",
    "0.4": "0.4",
    "0.5": "0,5",
    "0.6": "0.6",
    "0.7": "0.7",
    "0.8": "0.8",
    "0.9": "0.9",
    "1": "1",
    "You have selected the De Novo Inheritance. To avoid misleading scenarios, we prefer to prevent editing the analyzed experiments/participants. If you want to do so, please create a new study from scratch by clicking on Restart on the left of the screen": "Hai selezionato l'eredità de novo. Per evitare scenari fuorvianti, preferiamo impedire la modifica degli esperimenti/partecipanti analizzati. Se lo desideri, crea un nuovo studio da zero facendo clic sul riavvio a sinistra dello schermo",
    "Go back to see the clinical and experiment information.": "Torna a vedere le informazioni cliniche e dell'esperimento.",
    "Only Visible to you": "Visibile solo a te",
    "Shared with your groups": "Condiviso con i tuoi gruppi",
    "Etikett": "Etikett",
    "etichetta": "eChetta",
    "Amino Acid length": "Lunghezza degli aminoacidi",
    "CDS Position": "Posizione CDS",
    "Unaffective Relative": "Parente non affettivo",
    "Gene Coding": "Codice genetica",
    "Functional Class": "Classe funzionale",
    "Aminoacid Length": "Lunghezza aminoacida",
    "Aminoacid Change": "Cambiamento di aminoacidi",
    "Codon Change": "Cambiamento del codone",
    "Transcript Biotype": "Biotipo di trascrizione",
    "GnomAD AF": "Gnomad AF",
    "GnomAD AF PopMAx": "Gnomad af popmax",
    "CADD Pred.": "CADD PRED.",
    "SIFT Pred.": "Setacciare il pred.",
    "Polyphen2 Hvar Pred.": "Polyphen2 Hvar Pred.",
    "Mutation Taster Pred.": "Taster di mutazione Pred.",
    "Indel": "Indel",
    "Reported HPOs": "HPOS segnalato",
    "HPOs": "Hpos",
    "Reported Diagnosis": "Diagnosi riportata",
    "Obs. HPSs": "Obs. HPSS",
    "Position - Start": "Posizione - Avvia",
    "Positions - End": "Posizioni - fine",
    "Polyphen2 hvar": "Polyphen2 Hvar",
    "Tagged Variants": "Varianti contrassegnate",
    "Variant Class": "Classe variante",
    "ClinVar Classification": "Classificazione Clinvar",
    " + labelFromStack + ": "+ Labelfromstack +",
    "docs": "Documenti",
    "Clear": "Chiaro",
    "Loading...": "Caricamento...",
    "breadcrumb": "pane",
    "Toggle navigation": "Attiva la navigazione",
    "label": "etichetta",
    "dummyInput": "DummyInput",
    "menu": "menu",
    "container": "contenitore",
    "indicatorContainer": "indicatore. Container",
    "indicatorSeparator": "indicatori",
    "loadingIndicator": "CAROADINGINDICATOR",
    "control": "controllare",
    "group": "gruppo",
    "input": "ingresso",
    "multiValue": "multivale",
    "option": "opzione",
    "placeholder": "segnaposto",
    "singleValue": "valotto singolo"
}