


import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {getHeaders, getMainHeaders} from "./getHeaders";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {Alert, Snackbar} from "@mui/material";
import {useStylesHeader} from "./useStylesHeader";


function EnhancedTableHead(props) {
    const {  order, orderBy, onRequestSort, rowCount } = props;

    const [snackOpen, setSnackOpen] = useState(false);
    const [snackText, setSnackText] = useState( '' );
    const [snackColor, setSnackColor] = useState( '' );

    const headerClasses = useStylesHeader();

    let vertical ="top";
    let horizontal = "center";


    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false)
    };


    const handleSnackMsg = (msg) => {
        setSnackOpen(true);
        setSnackText(msg);            
        setSnackColor("warning");
    }


    const createSortHandler = (property,numeric) => (event) => {
      
        const max_sortable_svn_rows = (window.config.max_sortable_svn_rows) ? window.config.max_sortable_svn_rows : 1000

        // Sort only if the number of variants is less than a specified threshold
        if (rowCount < max_sortable_svn_rows)
            
            if (property !== "omim")
                onRequestSort(event, property, numeric);
            else{
                handleSnackMsg("Sorting is not available for the OMIM column.")          
            }
        
        // Otherwise show a message
        else{
            handleSnackMsg("Sorting is not available when the number of variants exceeds " + max_sortable_svn_rows + ".\nPlease add more filters to narrow down the results and enable sorting.")
        }
    };

    return (
        <React.Fragment>
            <TableHead  key={"TBH_0"}>
            <TableRow
                key={"headTBH_0"}>
                {getMainHeaders(props.type, props.annotations_cols)}
            </TableRow>
            </TableHead>
            <TableHead  key={"TBH_2"}>
            <TableRow key={"headTBH_1"}>
                {getHeaders(orderBy, onRequestSort, createSortHandler, order, props.type, props.annotations_cols, headerClasses)}
            </TableRow>
            </TableHead>

            <Snackbar
                anchorOrigin={ {vertical, horizontal }}
                open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={snackColor}>
                    {snackText}
                </Alert>
            </Snackbar>

        </React.Fragment>

    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default EnhancedTableHead;