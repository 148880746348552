




import React from "react";
import GQForm from "./GQ_Form";


import {connect} from "react-redux";
import {Grid} from "@mui/material";
import * as RANGE_CONFIG from "../../../samples-section/config/range_slider_config";
import {getRenderingSettings} from "./getRenderingSettings";
import {Box} from "@mui/system";


const SettingsBox = (props) => {

    let { sample_id, gt_parameters, samples} = props;

    let {ad_low, ad_high }  = gt_parameters;

    let AlternateSettings = {...RANGE_CONFIG.Alternate};
    if(ad_low !== 0 && ad_high!== 0){
        AlternateSettings.defaultValue = [ad_low*100, ad_high*100];
    }
    else{
        AlternateSettings.defaultValue = [ad_low, ad_high];
    }


    function getAlternateSettings(sample_id, samples, inheritance_key, inheritance_label){

        let alternate_defVal;

        if(samples){
    
            let sample_redux = samples.find(s => s.sample_id === sample_id);
    
             if(sample_redux.gt_settings !== undefined && sample_redux.gt_settings.length> 0) 
                {
            
                    let inheritance_settings = sample_redux.gt_settings.find(s => s.inheritance_key === inheritance_key && s.label === inheritance_label)  ;
                    alternate_defVal = [inheritance_settings.ad_low *100, inheritance_settings.ad_high*100];

            } 

        }
        return alternate_defVal;
    }

  


    const rendering_settings = getRenderingSettings(props.sample_case, AlternateSettings);

    

    return (
        <Grid container
              direction={"row"}
              spacing={2}>
                {rendering_settings.map(field => {

                    const alternate_defVal = getAlternateSettings(sample_id, samples, gt_parameters.inheritance_key, gt_parameters.label);        
                    const defaultValueFinal = (field.name === "Alternate") ? alternate_defVal : gt_parameters?.[field.queryKey] ;

                    return (
                        <Grid lg={4}>
                            <Box p={2}>
                             <GQForm
                                 label={field.label}
                                 subtitle={field.subtitle}
                                 defaultValue={defaultValueFinal}
                                 sample_id={sample_id}
                                 queryKey = {field.queryKey}
                                 name={field.name}
                                 config={field.config}
                                 tooltip = {field.tooltip}
                                 inheritance_key={gt_parameters.inheritance_key}
                                 inheritance_type = {gt_parameters.label}
                             />
                        </Box>
                        </Grid>
                    )
                })}

        </Grid>
    )


};

//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.patients.length!==0)
    {
        return {
            patients: state.patients.patients,
            samples: state.sample_list.samples,
            token: state.authorization.token
        }
    }
    else{
        return {patients: [], samples:[], token: state.authorization.token}
    }
}




export default connect(mapStateToProps,null)(SettingsBox);



