
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { delete_list, get_all_lists } from "../../../services/api/lists_api";
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, TextField } from "@mui/material";
import List from "@mui/material/List";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green, red } from "@mui/material/colors";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import GPAP_Tooltip from "../../../gpap-lib/components/GPAP_Tooltip";
import Chip from "@mui/material/Chip";
import ListIcon from '@mui/icons-material/List';
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import { parse_date } from "../../study-page/helpers";
import InfoIcon from "@mui/icons-material/Info";
import GeneDialog from "./GeneDialog";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import {makeStyles} from "@mui/styles";
import CaButton from "../../../gpap-lib/components/shared-components/CaButton";





const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 752,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
        overflow: "auto"
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    chip: {
        margin: theme.spacing(1, 1),

    }
}));




function Predefined_List_View(props){

    const classes = useStyles();
    const [predefined_lists, setPredefinedLists] = useState([]);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(false);
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [viewList, setViewList] = useState(false);
    const [listaGenes, setListaGenes]=useState(false)
    const [openGenesDialog, setOpenGenesDialog] = useState(false);

    

    const getData = () => {

        get_all_lists(props.token, window.config.apis_configuration.api_nextgpap_server)
            .then(r => r.json())
            .then(json => {
                setPredefinedLists(json.data);
            })
    }



    const handleClickOpen = () => {
        setOpen(true);
    };


    useEffect(function(){

        if(predefined_lists.length === 0){
            getData();

        }
    })


    const deleteList = () => {


        delete_list(props.token, window.config.apis_configuration.api_nextgpap_server , {list_id: selected})
            .then(r => r.json())
            .then(json => {
                getData();
            })

        setOpen(false);
        setSelected(false);

    }


    const handleSelect = (id) => {

        setSelected(id);
        setOpen(!open);


    }

    const viewListDialog = (list) => {
        setOpenInfoDialog(true); 
        setViewList(list);
    }
    
    const viewGenesDialog = (list_id) => {
        setOpenGenesDialog(true);  
        setListaGenes(list_id);
    }
    
    const handleClose = () => {
        setOpenInfoDialog(false); 
        setOpenGenesDialog(false);
        setOpen(false);
        setViewList(false);
    }

    const getField = (value) => {

        if(value!== ""){
            return value;
        }
        else{
            return "NA";
        }

    }

    const getActive = (activeBool) => {


        return (activeBool)
            ? <div style={{color: "darkgreen", fontWeight: "700", display: "inline-block"}}><MappedText text="ACTIVE"/></div>
            : <div style={{color: "darkgray", fontWeight: "700",  display: "inline-block"}}><MappedText text="Not Active"/></div>


    }


    const renderInfoDialog = () => {

        return     <Dialog
        open={openInfoDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"><MappedText text={"Predefined Gene List Info"}/></DialogTitle>
            <DialogContent>
                {
                    (viewList)
                        ? <React.Fragment>
                            <Typography><strong>Title:</strong> {getField(viewList.title)} </Typography>
                            <GPAP_Tooltip title={viewList.description}>
                                <Typography><strong>Description:</strong> {getField(viewList.description)} </Typography>
                            </GPAP_Tooltip>
                            <Typography><strong>Source:</strong> {getField(viewList.source)} </Typography>
                            <Typography><strong>Permission:</strong> {getField(viewList.permission)} </Typography>
                            <Typography><strong>Creator:</strong> {getField(viewList.created_by)} </Typography>
                            <Typography><strong>Version:</strong> {getField(viewList.version)} </Typography>
                            <Typography><strong>Size:</strong> {getField(viewList.size)} </Typography>
                            <Typography><strong>Creation Date:</strong> {getField(viewList.creationDate)} </Typography>
                            <Typography><strong>Status:</strong> {getActive(viewList.active)} </Typography>
                    </React.Fragment>
                    : null
                }

            </DialogContent>
            <DialogActions>
                <CaButton
                    te
                    onClick={handleClose} color="primary"
                    text={"Close"}
                    autoFocus/>
            </DialogActions>
        </Dialog>


    }



    return <Box p={1}>
        {renderInfoDialog()}
        {(openGenesDialog && listaGenes)
            ? <GeneDialog list={listaGenes} closeDialog = { () => setOpenGenesDialog(false)}/>
            : null}
        <Grid container direction={"column"} spacing={2}>
        <Grid item lg={12}>
            <Typography variant="h6" className={classes.title}>
                <ListIcon/><MappedText text="Predefined Lists in GPAP"/>  - ( {predefined_lists.length} )
            </Typography>
            <div className={classes.demo}>
                <List dense={false} style={{height: "700px", width: "100%"}}>
                {
                    (predefined_lists.map(function(list){

                        let title = (list.title!== "") ? list.title : "Untitled";
                        let text = (list.active) ? 
                        <span><MappedText text="Visible List" /></span> : 
                        <span><MappedText text="Disabled List. Users won't see this predefined gene list" /></span>
                        ;

                        let data = new Date(list.creationDate);
                        let list_date = parse_date(data);
                        
                        return <ListItem>
                            <ListItemIcon>
                                
                                <GPAP_Tooltip title={text}>
                                    {(list.active) ? <CheckCircleIcon style={{ color: green[500] }}/> : <HighlightOffIcon style={{ color: red[500] }}/>}
                                </GPAP_Tooltip>
                            </ListItemIcon>
                            <ListItemText
                                
                                primary={title}
                                secondary={
                                <React.Fragment>
                                    <Chip
                                    className={classes.chip}
                                    size="small"
                                    color="primary"
                                    label={list.type}
                                    />
                                    {list_date}
                                </React.Fragment>
                                }
                            />
                            <ListItemSecondaryAction>
                                <CaButton
                                    text={list.size + " genes"}
                                    variant="contained" color="#EAEAEA" disableElevation onClick={() => viewGenesDialog(list)}/>
                                <IconButton onClick={() => viewListDialog(list)}>
                                    <InfoIcon/>
                                </IconButton>
                                <IconButton onClick={() => handleSelect(list.id)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    }))
                }
                </List>
            </div>
        </Grid>
        </Grid>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"><MappedText text={"Are you sure to delete the Predefined Gene List?"}/></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <MappedText text="You are deleting this predefined list from the GPAP instance database. If you delete it, GPAP users won't be able to use this list anymore."/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CaButton text={"Cancel"} onClick={handleClose}/>
                <CaButton text={"Yes, delete it" }
                          onClick={() => deleteList()}
                          autoFocus/>
            </DialogActions>
        </Dialog>
    </Box>


}

//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
            username: state.authorization.preferredName

        }
    }
}



export default connect(mapStateToProps,null)(Predefined_List_View);

