

import React from "react";
import Box from "@mui/material/Box"; // Updated import for MUI 5
import { makeStyles } from "@mui/styles"; // `makeStyles` from @mui/styles (still usable in MUI 5, though it's deprecated in favor of `styled` or `sx`)



import PatientCard from "./PatientCard/PatientCard";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PersonIcon from '@mui/icons-material/Person';
import TabPanel from "../../../../../../gpap-lib/components/GPAP_TabPanel";
import Tabs from "@mui/material/Tabs";
import {Paper} from "@mui/material";
import Tab from "@mui/material/Tab";


const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});



function PatientTabs(props){

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let { patients, phenostore_participants } = props;


    const createTabs = (patients) => {

        let uniqueObjArray = [
            ...new Map(patients.map((item) => [item["pheno_id"], item])).values(),
        ];

        return uniqueObjArray
            .filter(s => Object.keys(s).length>0)
            .map(function (p, index) {

                let icon = (p.sample_type === "index") ? <PersonIcon /> : <PersonOutlineIcon/>
                return <Tab key={index} label={p.pheno_id} icon={icon}/>;
        })
    };

    const createTabPanels = (patients, phenostore_participants) => {

        //TODO: not optimal solution but works

        let uniqueObjArray = [
            ...new Map(patients.map((item) => [item["pheno_id"], item])).values(),
        ];

        return uniqueObjArray
            .filter(s => Object.keys(s).length>0)
            .map(function (p, index) {
                return  <TabPanel value={value} index={index} key={index}>
                        <PatientCard phenostore_participants = { phenostore_participants }
                                     sampleID={ p.pheno_id }
                                     experiment_id = {p.sample_id}
                                     sample_type = {p.sample_type}
                        />
                    </TabPanel>

            });
    };


    return(
        <Box>
            <Paper className={classes.root}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto">
                    {createTabs(patients)}
                </Tabs>
                {createTabPanels(patients, phenostore_participants)}
            </Paper>

        </Box>
    )



}



export default PatientTabs;