import React from "react";
import { Toolbar, Typography, Box } from "@mui/material"; // Updated import from @mui/material
import clsx from "clsx";
import { lighten, styled } from "@mui/material/styles"; // Replacing makeStyles with styled
import FindReplaceIcon from '@mui/icons-material/FindReplace'; // Updated icon import
import ContactMailIcon from '@mui/icons-material/ContactMail'; // Updated icon import
import PropTypes from "prop-types";

import GPAP_Tooltip from "../../../../gpap-lib/components/GPAP_Tooltip";
import ContactDialog from "../ContactDialog";
import { GPAP_Button } from "../../../../gpap-lib/components/Button_Collection";
import {makeStyles} from "@mui/styles";
import CaButton from "../../../../gpap-lib/components/shared-components/CaButton";


const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        {
            color: "#3182bd",
            backgroundColor: "#deebf7"
        },
        title: {
        flex: '1 1 100%',
    },
}));

const CohortTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const [ContactFlag, setContactFlag] = React.useState(false);

    const { selected, numSelected } = props;

    
    React.useEffect(() => {

        setContactFlag(false)


    }, [selected]);

    const handleClickOpen = () => {
        // Ensure the dialog is unmounted before remounting
        setContactFlag(false);
        setTimeout(() => {
          setContactFlag(true);
        }, 0);
      };
    
   

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}>
            {numSelected > 0 ? (
                    <React.Fragment>
                            <Box p={1}>
                                <GPAP_Tooltip title="Contact Owner">
                                <GPAP_Button
                                    variant="contained"
                                    onClick={handleClickOpen} aria-label="contact" startIcon={ <ContactMailIcon />}>
                                    Contact
                                </GPAP_Button>
                            </GPAP_Tooltip>
                        </Box>
                            <Box p={1}>
                                <GPAP_Tooltip title="Show the variants of the selected participant">
                                    <CaButton
                                        text={"Show Participant Variants"}
                                        variant="contained"
                                        onClick={props.loadVariants}
                                        aria-label="contact"
                                        startIcon={ <FindReplaceIcon />}/>
                                </GPAP_Tooltip>
                            </Box>
                    </React.Fragment>
            ) : (
                <Typography className={classes.title} variant="body1"
                            color="text.secondary"
                            id="tableTitle"
                            component="div">
                    Select a participant to query for associated variants. The query includes the selected gene list
                </Typography>
            )}

            {numSelected > 0 ? (
                <React.Fragment>

                 {ContactFlag &&
                 <ContactDialog  contactFlag = {ContactFlag} selected={selected}/> 
                 }

                </React.Fragment>
                )
                : null}

        </Toolbar>
    );
};

CohortTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};



export default CohortTableToolbar;

