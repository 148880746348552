import React, { useEffect, useState } from "react";
import { axiosApi, preFilter, newColumns } from "../../utils";
import TableComponent from "./TableComponent";
import { useDispatch, useSelector } from "react-redux";
import useViewFeatures from "../../hooks/useViewFeatures";
import { setTable } from "../../../../../reducers/slices/tables/table.slice";
import FileUpload from "../../../../../scenes/instand/reports/create-report/shared-components/general-table/components/dialogs/FileUpload";
import { apiData } from "../../utils/apiData";
import { linkJoin } from "../../utils/linkJoin";
import DNA from "../../../../../../public/asset/img/DNA.png"
import treatmentDna from "../../../../../../public/asset/img/treatment_dna.png"
import {Box, CircularProgress, Typography} from "@mui/material";
import NoDataBox from "./NoDataBox";
import MappedText from "../../../../../scenes/instand/reports/create-report/shared-components/mapText";
import { renderToStringAlt } from "../../../../../scenes/instand/reports/create-report/shared-components/renderToString";

function CustomTable({
  classRow,
  dataApiExtended,
  url,
  urlUpload = null,
  urlBase,
  urlDownload,
  method,
  reportType,
  json,
  dataSend,
  configLink = [],
  dataFilterAdd = [],
  idRelation,
  locationData = false,
  addApiData = [],
  dataHeader = [],
  token,
  configFilter,
  nestedInfo = [],
  nestedInfoB = [],
  split = [],
  compactColumns,
  compactColumnsSplit,
  tooltipCompact,
  tooltipCompactSplit,
  customFeatures,
  chipFull = [],
  chipSmall = [],
  buttons,
  tableName,
  type = "toggle",
  participant, dataType,
  enableTag,
  experiment,getTotal, setSelected,getAllTaggedVariants,all_tagged_variants, analysisType
}) 

{
  const [data, setData] = useState([]);
  const [uniqueData, setUniqueData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [dataButton, setDataButton] = useState([]);
  const [isCheck, setIsCheck] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowIdSelected, setRowIdSelected] = useState(null);
  const [render, setRender] = useState(<h1>Test</h1>);
  const [resApi, setResApi] = useState([]);
  const [isToggle, setIsToggle] = useState(false);
  const [apiRefresh, setApiRefresh] = useState(false);
  const [idExpanded, setIdExpanded] = useState({
    id: null,
    isExpanded: false,
  });
  const refresh = useSelector((state) => state.generaltable.refresh);
  const getUniqueRows = (data) => {
    const unique = new Map();
    data.forEach((item) => {
      const rowString = JSON.stringify(item);
      if (!unique.has(rowString)) {
        unique.set(rowString, item);
      }
    });
    return Array.from(unique.values());
  };
  const filterData = (data, dataFilter) => {
    const dataFiltered = data.filter((item) => {
      const matches = dataFiltered.every((filter) => {
        return item[filter.name] === filter.type;
      });
      return matches;
    });
    return dataFiltered;
  };
  

  const [testExpand, setTestExpand] = useState({});
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const states = {
    classRow,
    dataApiExtended,
    urlUpload,
    reportType,
    urlDownload,
    configLink,
    urlBase,
    mainData,
    dataFilterAdd,
    customFeatures,
    setMainData,
    data,
    resApi,
    apiRefresh,
    setApiRefresh,
    addApiData,
    isCheck,
    setIsCheck,
    open,
    setOpen,
    render,
    setRender,
    idExpanded,
    setIdExpanded,
    isOpenPopover,
    setIsOpenPopover,
    dataButton,
    setDataButton,
    testExpand,
    setTestExpand,
    rowIdSelected,
    setRowIdSelected,
    dataFilter,
    setDataFilter,
    setSelected,
    getAllTaggedVariants,
    all_tagged_variants,  
    idRelation,
    isToggle,
    setIsToggle,
    participant,
    tableName,
  };


 useEffect(() => {

  dispatch(
    setTable({
      option: "allState",
      value: { mainData, data, customFeatures, addApiData },
    })
  );

  dispatch(
    setTable({
      option: "dataAdicional",
      value: mainData?.[0]?.infoAdicional,
    })
  );
}, [dispatch, data]);


const renderFeatures = useViewFeatures(states, customFeatures);

useEffect(() => {

  const body = {
    "page": 1,
    "pageSize": 10000,
    "fields": [],
    "sorted": [],
    "filtered": [{"id":"Experiment_ID", "value": experiment}]
  };

  if (json) {
    setData(json);
    setMainData(json);
  } else {


    setLoading(true);
    const options = {
      url,
      method,
      dataSend,
      dataFilterAdd,
      reportType,
      token,
      isCheck,
      locationData,
      split,
      dataHeader,
      nestedInfo,
      nestedInfoB,
      setData: (apiData) => {

        const uniqueRows = getUniqueRows(apiData);

        let sortedArray = uniqueRows;
        if(states.classRow === "Treatments"){
           sortedArray = uniqueRows.sort((a, b) => {
             return a.gene_name.toLowerCase().localeCompare(b.gene_name.toLowerCase());
           });
        }

        setData(sortedArray);
        setMainData(sortedArray);
        setLoading(false);
      },
      setMainData,
      setResApi,
      body,
      getTotal
    };
    axiosApi(options);
  }
}, [json, isCheck, experiment, apiRefresh, refresh]);

  const nestedColumns = isCheck
    ? nestedInfoB.map((item) => {
        return {
          Header: item.nameColumn,
          accessor: item.dataSearch,
          tooltip: item.tooltip
        };
      })
    : nestedInfo.map((item) => {
        return {
          Header: item.nameColumn,
          accessor: item.dataSearch,
          tooltip: item.tooltip
        };
      });

  const selectArray = isCheck ? split : dataHeader;

  const [preFilteredData, setPreFilteredData] = useState([]);

useEffect(() => {

  let addData;
  const fetchData = async () => {
    if (dataApiExtended) {
      const response = await apiData(data, dataApiExtended);
      addData = response;
    } else {
      addData = data;
    }
  

  if (configFilter) {
    return preFilter(
      dataApiExtended ? addData : data,
      isCheck,
      configFilter,
      compactColumns,
      compactColumnsSplit,
      setPreFilteredData,
      setDataFilter
    );
  }
  if (dataFilterAdd.length > 0) {
    const addDataFilter = filterData(data, dataFilterAdd);
    addData = addDataFilter;  
  } 

  addData = linkJoin(addData, configLink);
    if (Array.isArray(addData) && addData.length === 1 && typeof addData[0] === 'object' && addData[0] !== null && Object.keys(addData[0]).length === 0) {
      console.log("Empty Object");
      setPreFilteredData([]);
  }
    else{
      setPreFilteredData(addData);
      //setPreFilteredData([]);
      setLoading(false)

    }


};

fetchData();

}, [data, isCheck, configFilter, apiRefresh]);

  useEffect(() => {
    let initialExpandState = {};

    preFilteredData.forEach((item, index) => {
      initialExpandState[index] = false;
    });
    
    setTestExpand(initialExpandState);
  }, [preFilteredData]);


  const columns = React.useMemo(
    () =>
      newColumns(
        selectArray,
        configFilter,
        isCheck,
        preFilteredData,
        compactColumns,
        compactColumnsSplit,
        tooltipCompact,
        tooltipCompactSplit
      ),
      
    [isCheck]
  );

  const renderNoDataMessage = (dataType) => {

    const messages = {
      Pharmacogenomics_Germline: {
        text: renderToStringAlt("Haplotypes not available"),
        marginBottom: "6px"
      },
      Treatment_Somatic: {
        text: renderToStringAlt("Clinical evidences not available"),
        marginBottom: "24px"
      },
      Fusions: {
        text: renderToStringAlt("Gene Fusions data not available"),
        marginBottom: "24px"
      }
    };
    

    let message = messages[dataType]
        ? <Typography variant="body1" sx={{ fontWeight: "bold", color:"#757575", marginBottom: messages[dataType].marginBottom }}>
          <MappedText text={messages[dataType].text}/>
        </Typography>
        : null;

    const dnaSources = {
      Pharmacogenomics_Germline: DNA,
      Fusions: DNA,
      Treatment_Somatic: treatmentDna
    };

    const dnaImage = dnaSources[dataType]
        ? (
            <img
                src={dnaSources[dataType]}
                alt="No data available"
                style={{ width: "30%", height: "auto", margin: "40px" }}
            />
        )
        : null;
        

    return (
      <>
      <Box sx={{backgroundColor:"#FAFAFA", display: "flex", justifyContent: "center", alignItems: "center", flexDirection:"column"}}>
      {dnaImage}
      {message}
      {dataType === "Pharmacogenomics_Germline" || dataType === "fusions" && (
          <Typography variant="body2" sx={{color:"#757575", marginBottom: "24px" }}><MappedText text={"Experiment "}/>{experiment}<MappedText text={" does not have any data available yet"}/></Typography>
        )}
      </Box>
      </>
    );
  };

  const Loading = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
    );
  };


  const renderTable = () => {

    if(loading){
      // render loading
      return <Loading/>
    }
    else{
      return (
          <TableComponent
              enableTag={enableTag}
              key={refresh}
              columns={columns}
              data={ preFilteredData}
              nestedData={preFilteredData}
              type={type}
              nestedColumns={nestedColumns}
              buttons={buttons}
              states={states}
              renderFeatures={renderFeatures}
              tooltipCompact={tooltipCompact}
              chipFull={chipFull}
              chipSmall={chipSmall}
              dataType ={dataType}
              setData= {setData}
              analysisType={analysisType}
          />
      )
    }





  }
  const allDataApis = useSelector(state => state.generaltable.allDataApis);

  const renderView = (dataType) => {

    let views = ["dirs", "clinical_reports", "patient"];
    const dirId = allDataApis?.dirs?.dir_id;

    let configNoData = [
      {
        key: "dirs",
        redirect: "/create_dir",
        linkText: "Create",
        title: renderToStringAlt("No interpretation requests yet"),
        subtitle: renderToStringAlt("a request and it will show up here.")
      },
      {
        key: "clinical_reports",
        redirect: `/dir_overview/${dirId}/new_clinical_report`,
        linkText: "Create",
        title: renderToStringAlt("No clinical reports yet"),
        subtitle: renderToStringAlt("or upload a report and it will show up here")
      },
      {
        key: "patient",
        redirect: "",
        linkText: "",
        title: renderToStringAlt("No patient reports yet"),
        subtitle: renderToStringAlt("Upload a report and it will show up here")
      }
    ];

    let configToShow
        = configNoData.find(s => s.key === tableName);
        
    const handleUploadClick = (reportType) => {
      dispatch(setTable({ option: "isOpenUpload", value: true }));
      dispatch(setTable({ option: "reportType", value: reportType }));
    };


    if (loading) {
      return <Loading />;
    }

    if (preFilteredData.length === 0) {
      if (views.includes(tableName)) {
        return (
            <>
              {renderTable()}
              <NoDataBox
                  title=<MappedText text={configToShow.title}/>
                  subtitle={configToShow.subtitle}
                  redirect={configToShow.redirect}
                  linkText=<MappedText text={configToShow.linkText}/>
                  onUploadClick={() => handleUploadClick(configToShow.key)}
              />
            </>
        );
      } else {
        return renderNoDataMessage(dataType);
      }
    } else {
      return renderTable();
    }


  }



  return (
<>
      <FileUpload reportType={reportType} apiRefresh={apiRefresh} setApiRefresh={setApiRefresh}  />
      {renderView(dataType)}
  </>
  );
}

export default CustomTable;
