// React
import React, {Component, Fragment} from "react";

// Redux
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as StudyActions from "../../../dashboard/reducers/study_actions";
import * as GPAP_General_Actions from "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/actions";

// MUI Components
import Box from "@mui/material/Box";
import {Accordion, AccordionDetails, CircularProgress, Snackbar, Grid, Typography, Alert} from "@mui/material";

import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Custom Components
import External_Links from "../../../dashboard/components/genomics-dashboard/components/variant-tabs/ExternalResources";
import SNV_Table from "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/gpap-table/SNV_Table";
import CNV_Table from "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/gpap-table/cnv_table/CNV_Table";
import QueryTabs from "../../../dashboard/components/genomics-dashboard/components/results/QueryTabs";
import AccordionInfo from "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/component/AccordionInfo";
import ToolBar_New from "../../../dashboard/components/genomics-dashboard/components/results/components/toolbar/toolbar-new/ToolBar_New";
import SaveDialog from "../../../dashboard/components/genomics-dashboard/components/results/components/toolbar/save_dialog/SaveDialog";
import AddAnalysisButton from "../../../dashboard/components/genomics-dashboard/components/results/components/general-query-view/AddAnalysisButton";
import AnalysesTabs from "../../../dashboard/components/genomics-dashboard/components/results/analyses-tabs/AnalysesTabs";
import CustomAccordion from "./CustomAccordion";
import CaButton from "../../../../gpap-lib/components/shared-components/CaButton";
import {GPAP_Button} from "../../../../gpap-lib/components/Button_Collection";
import MappedText from "../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import { renderToStringAlt } from "../../../../scenes/instand/reports/create-report/shared-components/renderToString.js";

// Services and Utilities
import {getAnalysisAnnotations, getStudy} from "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/getAnnotations";
import {prepareVariantsTable} from "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/gpap-table/variant_finder";
import {STATIC_VARIABLES} from "../../../../config-files/const_variables";

// Styles
import "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/css/variant_table.css";
import {getDisplayText} from "./utils/getDisplayText";




class MainView_ManualFiltering extends Component{

    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            selectedRows: [],
            viewMode: true, // this is because in the first version there were two ways of showing the data. gene view (true) or transcript view (false)
            tableType: true,
            selectedParticipants: [],
            selectedExperiments: [],
            from:0,
            cnv_number: 0,
            cnvRegions:0,
            somaticNumber:0,
            edit: true,
            snackMessage: false
    };

        // config

        //this.cols_items = cols_items;
        this.getTableDataContent = this.getTableDataContent.bind(this);

        this.getSNVTable = this.getSNVTable.bind(this);
        this.renderTablesLayout = this.renderTablesLayout.bind(this);
        this.annotate_variants = this.annotate_variants.bind(this);
        this.collectVariants = this.collectVariants.bind(this);
        this.getCNVTable = this.getCNVTable.bind(this);
        this.getCNVNumber = this.getCNVNumber.bind(this);
        this.setSelectedCNV = this.setSelectedCNV.bind(this);
        this.getTreatmentTable = this.getTreatmentTable.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.getSomaticNumber = this.getSomaticNumber.bind(this);
        this.handleSnackClose = this.handleSnackClose.bind(this);
    }


   collectVariants(){
       let {studySettings, studyBucket} = this.props;
       let study = getStudy(studySettings, studyBucket);

       if(study!== undefined && study.study!== undefined){
           this.setState({
               tagged_variants: study.study.tagged_variants
           })
       }

   }

   componentDidMount() {

       this.collectVariants()

   }



    getTableDataContent(){

        let { variants } =this.props;
        const data = prepareVariantsTable(variants, this.state.viewMode);
        return {
            data: data,
            variants: data
        }

    }

    annotate_variants = (type, extra, operation_type) => {

        let { study_actions, studySettings, selected_table_rows} = this.props;

        // get data type
        const dataType = "SNV";
        let item = this.props.selected_table_rows.find(s => s.data_type.includes(dataType) && s.data_row.length > 0);

        let selected_variant = []
        if(item && item.data_row.length > 0 ){
            // we are storing only one variant for each type
            selected_variant.push(item.data_row[0]);
        }


        if(operation_type !== "delete"){
            if(selected_variant.length>0 ){
                study_actions.annotate_variants(
                    {
                        query_id: studySettings.current_query,
                        analysis_id: studySettings.current_analysis,
                        study_id: studySettings.id,
                        variants: selected_variant, // array
                        label: extra,
                        type: type,
                        operation_type: operation_type
                    }
                );
            }
        }



        this.collectVariants()

    };





    getCNVNumber = (summary) =>{

        const {full_tot, split_tot, tot} = summary


        this.setState({
            cnvNumber: tot,
            cnvRegions: full_tot
        });

        this.props.getCNVTotal(tot);

        this.setState({snackMessage: (tot === -1)})
    }

    getSomaticNumber = (tot) =>{

        this.setState({somaticNumber: tot});
        //this.props.getCNVTotal(tot);
    }


    setSelectedCNV = (cnv_row) => {


        let {gpap_general_actions} = this.props;

        console.log("Set selected CNV");

        if(cnv_row){

            gpap_general_actions.setSelectedTableRows({
                data_row : [cnv_row],
                data_type: "CNV",
            })
        }
        else{

            // reset CNV row
            gpap_general_actions.resetRow({
                data_type: "CNV",
            })
        }

    }

    getTreatmentTable() {


        return <Box p={1} style={{float: "left", width: "100%"}}>
            <Accordion defaultExpanded={false}>
                <AccordionInfo title={"Treatments"}
                               total={this.state.cnvNumber}
                               appendixTitle = {""}
                />
                <AccordionDetails>
                    <Box style={{ overflow: "auto", overflowY: "hidden", maxHeight: "100%" }}>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>



    }


    handleSnackClose = () => {

        this.setState({snackMessage:false})
    }

    getCNVTable(){


        const self = this;

        let url = window.config.apis_configuration.api_nextgpap_server_elastic + "/api/cnvs";

        const isSNVSelected = self.props.selected_table_rows.some(s =>
            (s.data_type === "SNV_Somatic" || s.data_type === "SNV_Germline") && s.data_row.length > 0
        );

        return <Fragment>
            <Snackbar  open={this.state.snackMessage}
                       //autoHideDuration={5000}
                       onClose={this.handleSnackClose}>
                <Alert onClose={this.handleSnackClose} severity="danger">
                    CNV could not be loaded
                </Alert>
            </Snackbar>
            <CNV_Table
            url={url}
            method ={"POST"}
            token={this.props.token}
            locationData="items"
            experiment={this.props.experiment}
            genes={this.props.genes_state}
            getCNVNumber = {this.getCNVNumber}
            setSelectedCNV = {this.setSelectedCNV}
            cnv_number={this.state.cnvNumber}
            queryID = {this.props.studySettings.current_query}
            setLoading = {()=> console.log("Loading")}
            // how can we make this more generic?
            getAllTaggedVariants = {self.props.getAllTaggedVariants}
            all_tagged_variants = {self.props.all_tagged_variants}
            isSNVSelected = {isSNVSelected}

        />

        </Fragment>
    }



    getSNVTable(){

        let obj = this.getTableDataContent();

        let self = this;
        let { total, summary } = this.props;

        let query_type = (self.props.instandGeneticFindingsTab === "somatic") ? "somatic" : "default";

        let type = (summary) ? "search_across" : query_type;

        return    <SNV_Table
            annotate_variants = {self.annotate_variants}
            getAllTaggedVariants = {self.props.getAllTaggedVariants}
            all_tagged_variants = {self.props.all_tagged_variants}
            passAnnotations={ self.props.passAnnotations}
            loading={self.props.loading}
            queryRunner={self.props.queryRunner}
            fetchData={ self.props.fetchData}
            total={(self.state.selectedParticipants.length=== 0) ? total : obj.data.length}
            type={type}
            tagged_variants = {self.state.tagged_variants}
            data = {obj.data}
            participants = {this.state.selectedParticipants}
            selectedExperiments = {self.state.selectedExperiments}
            pageCount={Math.ceil(total/100)}
            pid_array = {(self.props.participants.participants!== undefined) ? self.props.participants.participants.map(s => s.report_id) : []}
        />


    }
    
    renderTablesLayout(){

        const self = this;
        const SNV_Table = this.getSNVTable()

        //Use the SNV table also for the somatic

        const SomaticTable = this.getSNVTable();

        let accordionEl = <Box p={0} style={{float: "left", width: "100%"}}>
            <CustomAccordion defaultExpanded={true}>

            <AccordionInfo
            customStyle={"instand"}
            title={renderToStringAlt("Germline SNV and InDels")}
            total={
                self.props.total === 1
                ? renderToStringAlt("1 genomic variant")
                : `${self.props.total} ${renderToStringAlt("genomic variants")}`
            }
            tooltipTitle={renderToStringAlt("Note that the number of rows shown in the variants table can be higher than the number of genomic variants shown in the counter since one genomic variant can affect more than one gene.")}
            />
                <AccordionDetails>

                    <Grid container direction={"row"}>

                        <Grid item lg={12}>
                         {/*   <AnalysesTabs
                                switchAnalysis = {self.props.switchAnalysis}
                                resetAll = {self.props.resetAll}
                                deleteButton={false}
                            />*/}
                            <QueryTabs
                                switchQuery = {self.props.switchQuery}
                            />
                        </Grid>
                        <Grid item lg={12}>
                            <Box style={{ overflow: "auto", overflowY: "hidden", maxHeight: "100%" }}>
                                {SNV_Table}
                            </Box>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </CustomAccordion>
        </Box>


        // if we are in family trio, we show the SNV Table as an accordion. In cohort analysis we don't.
        let SNV_Table_Element = (self.props.summary || self.props.cnv_data)
            ? accordionEl
            : SNV_Table
                    
        let appendixTitleCNV = "- no filters applied"
                             if (this.props.genes_state && this.props.genes_state.length > 0)
                                { appendixTitleCNV="- Genes filter applied";}

        return <Fragment>

               {self.props.instandGeneticFindingsTab === "germline" && (
               <>
                {SNV_Table_Element}
               </>
             )}

             <Box p={0} style={{float: "left", width: "100%"}}>
                {self.props.instandGeneticFindingsTab === "somatic" && (
                    <>
                    <CustomAccordion defaultExpanded={true}>
                        <AccordionInfo
                                       customStyle={"instand"}
                                       title={"Somatic SNV and InDels "}
                                       total={self.props.total === 1 ? "1 genomic variant" : self.props.total + " genomic variants"} 
                                       tooltipTitle={<MappedText text="Note that the number of rows shown in the variants table can be higher than the number of genomic variants shown in the counter since one genomic variant can affect more than one gene." />}
                         />
                        <AccordionDetails>
                            <Grid container direction={"row"}>

                                <Grid item lg={12}>
                                    {(!window.config.instand)
                                        ? <AnalysesTabs
                                        switchAnalysis = {self.props.switchAnalysis}
                                        resetAll = {self.props.resetAll}
                                        deleteButton={false}
                                    />
                                        : null
                                    }

                                    <QueryTabs
                                        switchQuery = {self.props.switchQuery}
                                    />
                                </Grid>
                                <Grid item lg={12}>
                                    <Box style={{ overflow: "auto", overflowY: "hidden", maxHeight: "100%" }}>
                                        {SomaticTable}
                                    </Box>
                                </Grid>
                                {/* This doesn't seem necessary for the Manual Filtering Option*/}

                            </Grid>

                        </AccordionDetails>
                    </CustomAccordion>
                    </>
                )}
            </Box>
            <Box p={0} style={{float: "left", width: "100%"}}>
                <CustomAccordion defaultExpanded={true}>
                    <AccordionInfo
                                   customStyle={"instand"}
                                   title={"CNVs and SVs"}
                                   total={this.state.cnvNumber}
                                   appendixTitle = {appendixTitleCNV}
                                   tooltipTitle=""/>
                    <AccordionDetails>
                        <Grid container direction={"row"}>
                            <Grid item lg={12}>
                                <Box style={{ width: "100%" }} pt={1} pb={2}>
                                    <Typography color={"textSecondary"}>
                                     <MappedText text={"The displayed number is the total sum of events in the Complete view and Split by genes results"}/> 
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item lg={12}>
                                <Box style={{ overflow: "auto", overflowY: "hidden", maxHeight: "100%" }}>
                                    {this.getCNVTable()}
                                </Box>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </CustomAccordion>
            </Box>
        </Fragment>


    }

    handleEdit(editBool) {

        this.setState({edit: editBool})

    }

    render(){

        let self  = this;
   
        let obj = this.getTableDataContent();
        let annotations = getAnalysisAnnotations(self.props.studySettings, self.props.studyBucket);


        // Utility function to get the display text for genomic variants


// Usage in the component
        const cnvTot = this.state.cnvNumber;
        const snvTot = this.props.total;

        const cnvDisplayText = getDisplayText(cnvTot);
        const snvDisplayText = getDisplayText(snvTot);


        if(self.state.edit){
            if(self.props.variants!== undefined)
            {
                return ( <Grid container direction={"row"}>

                        {(window.config.instand)
                            ? null
                            : <Fragment>
                                <Grid item lg={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <GPAP_Button onClick={() => self.handleEdit(!self.state.edit)} startIcon={<ArrowBackIcon />}><MappedText text={"Back"}/></GPAP_Button>

                            </Grid>
                                <Grid item lg={12}>
                                    <SaveDialog list_of_studies = {[ ]}/>
                                    {/*
                            This is hidden if the configuration is for Instand
*/}
{/*
                                    <AddAnalysisButton queryRunner={self.props.queryRunner} instandGeneticFindingsTab={self.props.instandGeneticFindingsTab}/>
*/}
                                </Grid>
                             </Fragment>
                        }

                        
                       <Grid item lg={10}>
                            <ToolBar_New
                                data = {obj.data}
                                annotations = {annotations}
                                annotate_variants = {self.annotate_variants}
                                tagged_variants = {self.props.all_tagged_variants}
                                getAllTaggedVariants = {self.props.getAllTaggedVariants}
                                passAnnotations={ self.props.passAnnotations}
                            />
                        </Grid>
                       <Grid item lg={10}>
                           <div className="variant_table_instand">
                           {self.renderTablesLayout()}
                           </div>
                       </Grid>
                        <Grid item lg={2}>
                            <External_Links
                                classStyle={""}
                                summary = {self.props.summary}
                                sample_agg = {self.props.sample_agg}
                                queryRunner={self.props.queryRunner}
                                runSearchAcrossQuery = {() => {console.log("not needed here")}}
                                participants = {this.props.participants}
                                passExperiments = {self.selectedExperiments}
                                passGenes = {() => {console.log("not needed here")} }
                                totalVariants={self.props.total}
                            />
                        </Grid>
                    </Grid>
                   )
            }
            else if(self.props.requestStatus === STATIC_VARIABLES.UNAVAILABLE_SERVER){
                return <div className={'central-panel'}>
                    <div className={"error_msg"}>
                        <CircularProgress />
                        The GPAP Genomics server is currently unavailable. We apologize for the the inconvenience. Please contact us at <a href="mailto:help@rd-connect.eu">help@rd-connect.eu</a> for more information.
                    </div>
                </div>
            }
            else if(self.props.variants  === undefined ){
                return <div className={'central-panel'}>
                    <div
                        className="error_msg"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh", // full height to center vertically
                            textAlign: "center",
                        }}
                    >
                        <CircularProgress />
                        <p>Loading genomic variants</p>
                    </div>
                </div>
            }
            else{
                return <div className={'central-panel'}>
                    <div className={"error_msg"}>Ooops, the query returned an error;</div>
                </div>
            }
        }
        else{
            return <Fragment>
                <Grid item lg={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CaButton
                        variant={"contained"}
                        text={"Review full list of variants"}
                        onClick={() => self.handleEdit(!self.state.edit)}
                        startIcon={<FilterListIcon/>}>
                    </CaButton>
                </Grid>
                 


                <Grid item lg={12}>
                   <Box p={1}>
                   {self.props.instandGeneticFindingsTab === "somatic" && (
                    <>
                     <Typography variant={"h6"}>
                     <MappedText text={"SNVs and InDels - Somatic"} /> ({snvDisplayText})
                     </Typography>
                     {this.getSNVTable()}
                     

                    </>
                   )}
                 
                  {self.props.instandGeneticFindingsTab === "germline" && (
                   <>
                    <Typography variant={"h6"}>
                    <MappedText text={"SNVs and InDels - Germline"} /> ({snvDisplayText})
                    </Typography>
                   {this.getSNVTable()}
                   
                   </>
                  )}
                 </Box>
                </Grid>

                <Grid item lg={12}>
                    <Box p={1}>
                    <Typography variant={"h6"}>
                    <MappedText text={"CNVs and SVs"} /> ({cnvDisplayText})
                    </Typography>
                    </Box>
                </Grid>
                <Grid item lg={12}>
                    <Box p={1}>
                    {this.getCNVTable()}
                   
                    </Box>
                </Grid>

            </Fragment>
        }

    }
}


function mapStateToProps (state) {
    if(state.variants!==undefined ){

        return {
            token: state.authorization.token,
            selected_table_rows: state.selected_table_rows,
            studySettings: state.studySettings,
            studyBucket: state.studyBucket,
            patients: state.patients,
            genes_state :( state.currentFilterSet.filters!==undefined ) ? state.currentFilterSet.filters.gene : (state.query.gene) ? state.query.gene: null,
            // TODO: we need to get all the experiments in the analysis
            experiment: state.sample_list.samples.map(s => s.sample_id)
        }
    }
    else{
        return {snv: []}
    }
}

const mapDispatchToProps = (dispatch) => ({
    study_actions: bindActionCreators(StudyActions, dispatch),
    gpap_general_actions: bindActionCreators(GPAP_General_Actions, dispatch)
});




export default connect(mapStateToProps,mapDispatchToProps)(MainView_ManualFiltering);
