
import React, { useState, useEffect } from 'react';

//Redux
import { connect } from 'react-redux';

// CSS
import "../../../dashboard/components/genomics-dashboard/components/results/results.css";

// Local Imports
import { getTotalFromJson } from "../../../dashboard/components/genomics-dashboard/components/results/data-utils/getTotalFromJson";
import { getTableData } from "../../../dashboard/components/genomics-dashboard/components/results/data-utils/getTableData";
import MainView_ManualFiltering from "./MainView_ManualFiltering";

// Material UI
import Box from "@mui/material/Box";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function ManualFiltering(props) {
    const [index, setIndex] = useState(0);
    const [annotations, setAnnotations] = useState([]);
    const [snvTotal, setSnvTotal] = useState(0);
    const [variant_data, setVariantData] = useState([]);


    useEffect(() => {
        // Your code that runs when props or state change goes here
        let snv = undefined;
        let variant_data = undefined;

        if (props.variants !== undefined && props.variants.snv !== undefined) {
            snv = props.variants.snv;

            // Create Variant Table Data;
            variant_data = getTableData(snv, props.summary,  annotations, props.analysisType,props.samples);
            setVariantData(variant_data);
        }

        const { snv_total } = getTotalFromJson(props.variants);

        setSnvTotal(snv_total);


        // Update state or perform side effects based on the changes in props or state
        // For example, you can set the state using setAnnotations, setIndex, setCnvTotal, etc.
    }, [props.variants, props.analysisType, props.samples, annotations]);


    const renderMainFindings = () => {


        return  <MainView_ManualFiltering
            // mandatory props
            loading={props.loading}
            variants={variant_data}
            setIndex={setIndex}
            total={snvTotal}
            type={props.type}
            summary={props.summary}
            queryRunner={props.queryRunner}
            fetchData={props.fetchData}
            all_tagged_variants={props.all_tagged_variants}
            getAllTaggedVariants={props.getAllTaggedVariants}
            passAnnotations={setAnnotations}
            resetAll={props.resetAll}
            requestStatus={props.requestStatus}
            switchAnalysis={props.switchAnalysis}
            switchQuery={props.switchQuery}
            // optional props;
            // search across all
            loadingParticipants={props.loadingParticipants}
            cnv_data={true}
            sampleList={props.sampleList}
            participants={props.participants}
            sample_agg={props.sample_agg}
            instandGeneticFindingsTab = {props.instandGeneticFindingsTab}
            getCNVTotal={() => {return 0}}
        />



    }


    return (
        <Box>
            <Backdrop
                sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }} // Using sx prop for styling
                open={props.loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {renderMainFindings()}
        </Box>
    );
}

function mapStateToProps(state) {
    if (state.authorization !== undefined) {
        return {
            patients: state.patients.patients,
            analysisType: state.studySettings.analysisType,
            samples: state.sample_list.samples,
        };
    }
}

export default connect(mapStateToProps)(ManualFiltering);
