import React from "react";
import { Typography } from "@mui/material";
import MappedText from "../reports/create-report/shared-components/mapText";
import axios from "axios";
import DataField from "../dir_overview/Datafield";


export const PhenostoreObservedSymptoms = ({symptoms}) => (
    <>
      {
      (Array.isArray(symptoms))

      ? symptoms.map(symptom => (
        symptom.observed ? (
            <Typography sx={{ color: '#202020', fontWeight:'400', fontSize:'16px' }}>
              {symptom.name + ", " + symptom.id}
            </Typography>
        ) : null
      ))
        : <DataField title="" value={""} />
  }
    </>
);

export const PhenostoreDiagnosis = ({ diagnosis }) => {
  if (!diagnosis || !Array.isArray(diagnosis.value)) {
      return <DataField title="Diagnosis" value={""} />;
  }

  return (
      <>
          <Typography sx={{ color: '#626262', fontWeight: '400', fontSize: '14px' }}>
              <MappedText text={"Diagnosis"} />
          </Typography>

          {diagnosis.value.map((diagnose, index) => (
              diagnose.ordo ? (
                  <Typography key={index} sx={{ color: '#202020', fontWeight: '400', fontSize: '16px' }}>
                      {diagnose.diagnosis_date
                          ? `${diagnose.diagnosis_date} , ${diagnose.ordo.id} , ${diagnose.ordo.name}`
                          : `${diagnose.ordo.id} , ${diagnose.ordo.name}`}
                  </Typography>
              ) : null
          ))}
      </>
  );
};


export const dirAnalysisTypes = async (token) => {

    console.log("dir analysis type");

    const analysisServiceUrl = window.config.apis_configuration.api_analysis_service;

    try {
      const response = await axios.get(analysisServiceUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  

      return response.data.data.map((item) => ({
        id: item.name,
        value: item.name,
        label: item.name
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
      }));
    } catch (error) {
      console.error("Error fetching dirAnalysisTypes:", error);
      return []; 
    }
  };