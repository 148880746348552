import axios from "axios";
import {getValueByPath, setValueByPath} from "./utilsValues";
import createVariantRow from "../../../../scenes/dashboard/components/genomics-dashboard/components/results/data-utils/createVariantRow";
import {getCNVSummary} from "./getCNVSummary";
import {dataMapper} from "./dataMapper";

export const axiosApi = (options) => {
  const {
    url, method,
    token,
    isCheck,
    split,
    dataHeader,
    nestedInfo,
    nestedInfoB,
    setMainData,
    locationData,
    setData,
    setResApi, 
    body,
    dataType,
    getTotal,
      setLoading
  } = options;




  const handleMapping = (res) => {


      const dataToMap = locationData ? res.data[locationData] : res.data;
      setMainData(dataToMap);

      const response = dataMapper(dataToMap, isCheck, split, dataHeader, nestedInfoB, nestedInfo);

      setData(response);

  }


  const handleGetRequest = () => {

      axios
          .get(url)
          .then((res) => {
              setResApi(res.data);

              // check if results are over 10k.
              // if so...inform the user

              if("items" in res.data){


                  const summary =  getCNVSummary(res.data.items);

                  getTotal(summary);
                  //getTotal(res.data.items.length);
                  if(res.data.items.length === 10000){
                      alert("More than 10000 CNVs have been found. Please, apply a gene list to your query to limit the number of results.")
                  }
              }
              else{

                  getTotal({
                      full_tot: "NA",
                      split_tot: "NA",
                      tot: "NA"
                  });
              }


              handleMapping(res);


          })
          .catch((error) => {
              setLoading(false);
              if (error.response && error.response.status === 400) {
                  // Handle HTTP 400 error here
                  console.error("HTTP 400 Bad Request:", error.response.data);
              } else {
                  // Handle other errors here
                  console.error("An error occurred:", error);
              }
          });


  }

  // I think it's never used...
  const handleSomaticResponse = (res) => {

      let data = []
      if("snv" in res.data){
          let variants =  res.data.snv.hits.hits.map(s => s);

          variants.forEach(function(hit){

              let obj = hit._source;

              // how many genes in effs;

              let gene_names = [...new Set(hit.fields.effs.map(s => s.gene_name))];

              // filter out undefined;

              gene_names = gene_names.filter(s => s !== undefined);

              gene_names.forEach(function(geneName){

                  let row=  createVariantRow(obj, hit, [], "family_trio", [], geneName);
                  data.push(row.source);

              })


          });

          console.log(res.data.snv.hits.total.value)
          getTotal(res.data.snv.hits.total.value);
          setMainData(data);
          setData(data);
      }

  }

  const handlePostRequest = () =>  {

      let headers =  {
          "Authorization": token
      };

      if(dataType === "somatic"){

          headers = {"X-TOKEN-AUTH": token}

      }
      setLoading(true);
      axios.post(url,body, {headers: headers} )
          .then((res) => {
              setLoading(false);

              if( dataType=== "somatic"){
                  // process data
                  handleSomaticResponse(res);

              }
              else{


                  if("items" in res.data){

                      const summary =  getCNVSummary(res.data.items);

                      getTotal(summary);
                      if(res.data.items.length >= 10000){
                          alert("More than 10,000 CNVs have been identified. Note that only the first 10,000 CNVs (in chromosomal order) will be sorted unless you apply a gene list to your query to limit the results")
                      }

                      setResApi(res.data);
                  }
                  else{
                      getTotal({
                          full_tot: "NA",
                          split_tot: "NA",
                          tot: "NA"
                      });
                  }

                  handleMapping(res);

              }



          })
          .catch((error) => {

              handleError(error);});

  }

  const handleError = (error) => {
      setLoading(false);
      if (error.response && error.response.status === 400) {
          // Handle HTTP 400 error here
          console.error("HTTP 400 Bad Request:", error.response.data);
      } else {
          // Handle other errors here
          console.error("An error occurred:", error);
      }
      if (error.response?.data?.message) {
          // Both 'data' and 'message' properties exist
          getTotal(-1);


      } else {
          // Either 'data' or 'message' or both do not exist
          alert("error when retrieving data from the server");
      }

  }

  if(method === "GET"){
      handleGetRequest();
  }
  else if (method === "POST"){
      handlePostRequest()
  }
};