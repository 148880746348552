import {origin_opts, related_to_opts, zygosity_opts} from "../tag_form_options";


import {getVariantType} from "./getSvType";

export const getInitialData = (dataType) => ({
    sample: "None",
    rs: "",
    gene: "None",
    chromosome: "None",
    transcript: "None",
    zygosity: zygosity_opts[0].label,
    inheritance: "",
    cDNA: "",
    protein: "",
    origin: origin_opts[0].label,
    significance: "",
    variant_source: "GPAP",
    comments: "",
    variant_type: related_to_opts[0].label,
    sv_type: getVariantType(dataType)[0].label ,
    project: ""
});
