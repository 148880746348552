
// Mapping of data from Genomics (Next-GPAP server) API to Object used in the Client and sent to PhenoStore



const mappingVariantType = {
    DEL: "Deletion",
    DUP: "Duplication",
    INV: "Inversion",
    STR: "Short tandem repeat",
    TRA: "Translocation",
    INS: "Insertion"
};

export const handleCNVVariant = (variant, transcripts) => {
    return {
        sample: variant["Experiment_ID"],
        chromosome: variant["SV_chrom"],
        gene: variant["Gene_name"],
        ref: variant["ref"],
        rs: variant["rs"],
        alt: variant["alt"],
        start_pos: variant["SV_start"],
        end_pos: variant["SV_end"],
        transcript: transcripts.length > 0 ? transcripts[0].value : "None",
        comments: variant["comments"] || "",
        sv_type: mappingVariantType[variant.SV_type] || "", // Default to original value if not mapped
    };
};
