import { ExpandMore, KeyboardArrowRight, Upload, ReadMore} from "@mui/icons-material";
import DownloadIcon from '@mui/icons-material/Download';
import { Checkbox, IconButton, Stack, Tooltip } from "@mui/material";
import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTable } from "../../../../reducers/slices/tables/table.slice";
import useNavigate from "./useNavigate";
import iconsImage from '../../../../../resources/icons.png';
import MappedText from "../../../../scenes/study-page/create-study/analysis-page/components/clinical-info/mapText";
import { fileDownload } from "../utils/fileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteRequest , deleteClinicalReport, deletePatientReport } from "./requests/deleteRequest";



const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const deleteConfig = {
  clinical: {
    deleteFunction: deleteClinicalReport,
    idField: "clinical_report_id",
  },
  patient: {
    deleteFunction: deletePatientReport,
    idField: "patient_report_id",
  },
  default: {
    deleteFunction: deleteRequest,
    idField: "dir_id",
  },
};

const test = (e) => {
  e.stopPropagation();
};
const useOptionFilter = (columns, type, states, setExpandedRows, tableData, handleOpenDeleteDialog) => {

  const dataRef = React.useRef(states?.mainData);
  const dispatch = useDispatch();
  const token = useSelector(state => state.authorization.token);
  const navigate = useNavigate();

  let isToggle = states.customFeatures.some(
    (item) => item.hasOwnProperty("toggle") && item["toggle"] === true
  );

  let expanding = states.customFeatures.some(
    (item) => item.hasOwnProperty("expanding") && item["expanding"] === true
  );
  
  const handleDeleteClick = (row) => {
    const { deleteFunction, idField } =
      deleteConfig[states.reportType] || deleteConfig.default;
    handleOpenDeleteDialog(row.original[idField], deleteFunction, idField);
  }; 

  const actionsObject = states.customFeatures.find((item) => "actions" in item);
  let isEdit = actionsObject?.actions?.edit;
  let isDelete = actionsObject?.actions?.delete;
  let isUpload = actionsObject?.actions?.upload;
  let isLink = actionsObject?.actions?.link;
  let isDownload= actionsObject?.actions?.download;
  let isDownloadDoc = actionsObject?.actions?.downloadDoc;

  let showActions = false; 

  if (actionsObject && actionsObject.actions) {

    showActions = Object.values(actionsObject.actions).some(
      (value) => value === true
    );
  }

  dataRef.current = states?.mainData;

  return (hooks) => {

    hooks.visibleColumns.push((columns) => {

      let newColumns = [...columns];

      if (expanding) {
        isToggle = false;

        newColumns = [
          {
            id: "expansion",
            disableFilters: true,
            Header: () => null, 
            Cell: ({ row }) => (
              <div>
                <span
                  {...row.getToggleRowExpandedProps()}
                  onClick={(e) => {
                    e.stopPropagation();

                    {
                      row.isExpanded
                        ? dispatch(
                            setTable({
                              option: "isLabel",
                              value: { status: false, rowId: row.id },
                            })
                          )
                        : dispatch(
                            setTable({
                              option: "isLabel",
                              value: { status: true, rowId: row.id },
                            })
                          );
                    }

                    const newData = dataRef.current[row.original.originalIndex]; 
                    setExpandedRows((prevState) => ({
                      ...prevState,
                      [row.id]: {
                        expanded: true,
                        data: [newData], 
                      },
                    }));
                    states.setRowIdSelected(row.id);
                    states.setIdExpanded({
                      id: row.id,
                      expanded: true,
                    });
                    row.toggleRowExpanded();
                  }}
                >

              { row.isExpanded ? 
              <IconButton aria-label="expandMore" color="black" >
                  <ExpandMore />
                </IconButton>
                
              :
              <IconButton aria-label="keyboardArrowRight" color="black" >
              <KeyboardArrowRight />
            </IconButton>
            }
                </span>
              </div>
            ),
          },
          ...newColumns,
        ];
      }

      if (showActions) {
        newColumns = [
          ...newColumns,
          {
            id: "actions",
            disableFilters: true,
            Header: () => isLink ? 'Evidence' : <MappedText text='Actions' />,

            Cell: ({ row }) => (
              <Stack direction="row">

                {isEdit && (
                  <Tooltip title={<MappedText text="Access Request" />}>
                    <div
                      onClick={() =>
                        navigate(`dir_overview/${row.original[states.idRelation]}`)
                      }
                    >
                      <IconButton aria-label="expandMore" color="black" >
                        <ReadMore />
                      </IconButton></div>
                  </Tooltip>
                )}
                {isDelete && (
                  <>
                    <Tooltip title={<MappedText text="Delete Request" />}>
                      <div onClick={() => handleDeleteClick(row)}>
                        <IconButton aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </>
                )}
                {isDownload && (
                  <Tooltip title={<MappedText text="Download"/>}>
                  <div>
                    <IconButton aria-label="expandMore" color="black" >
                    <DownloadIcon />
                  </IconButton></div>
                  </Tooltip>
                )}
                
                 {isUpload && (
                  <Tooltip title={<MappedText text="Upload"/>}>
                    <div
                      onClick={() => {
                                                dispatch(
                          setTable({
                            option: "isOpenUpload",
                            value: true,
                          })
                        );
                      }}
                    >
                     <IconButton aria-label="expandMore" color="black" >
                  <Upload />
                </IconButton></div>
               
                  </Tooltip>
                )}
                 {isLink && (
                  
              <Tooltip title={<MappedText text="Link"/>}>
              <IconButton onClick={() => window.open(window.origin, "_blank")} color="black">
                <img src={iconsImage} alt="Link Icon" style={{ width: '100%', height: '24px' }} />
              </IconButton>
               
              </Tooltip>
            )}
           {isDownloadDoc && (
          <Tooltip title={<MappedText text={"Download Report"}/>}>
          <IconButton
            aria-label="download"
            onClick={() => {
              let url = "";
              if (states.reportType === "clinical") {
                url = `${states.urlDownload}/download_clinical_report/${row.original.clinical_report_id}`;
              } else if (states.reportType === "patient") {
                url = `${states.urlDownload}/download_patient_report/${row.original.patient_report_id}`;
              }
              fileDownload(url, token);
            }}
            color="black"
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
          )}
              </Stack>
            ),
          },
        ];
      }


      if (isToggle) {
        newColumns = [
          {
            id: "selection",
            disableFilters: true,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div style={{ borderRight:"0px!important" }}>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {isToggle && (
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                )}
              </div>
            ),
          },
          ...newColumns,
        ];
      }
      return newColumns;
    });
  };
};

export default useOptionFilter;
