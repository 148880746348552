
import React, { useEffect, useState } from "react";
import { inheritances } from "../../../../../../config-files/config_inheritances";

// redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useSelector } from "react-redux";

// actions
import * as Inheritance_Actions from "../../reducers/inheritance_actions";
import * as Sample_Actions from "../../reducers/sample_actions";
import * as Study_Actions from "../../../../../dashboard/reducers/study_actions";
import { filterConditions } from "../GenotypeBuilder/helpers/filterConditions";


// MUI5
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Checkbox, FormControlLabel } from "@mui/material";
import Chip from "@mui/material/Chip";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

// local imports
import MappedText from "../clinical-info/mapText";
import GPAP_Dialog from "../../../../../../gpap-lib/components/GPAP_Dialog";






const Inheritance_Mode = (props) => {

    const [values, setValues] = useState([]);
    const [initialized, setInitialized] = useState(false);

    // dialog stuff

    const [openDialog, setOpenDialog] = useState(false);
    const [confirmationCallback, setConfirmationCallback] = useState(null);
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogValues, setDialogValues] = useState(null); // Store values for confirmation
    const [dialogAnalysisIds, setDialogAnalysisIds] = useState(null); // Store analysisIds for confirmation


    const inheritance_modes = inheritances;

    const allParticipants = useSelector(state => state.generaltable.allDataApis.participants);
    const participants = props.patients.patients.length !== 0
        ? props.patients.patients
        : allParticipants;


    const samples = props.samples;


    useEffect(function(){

        if(!initialized){
            if(props.inheritance_key!== null){

                if(Array.isArray(props.inheritance_key)){
                    setValues(props.inheritance_key)
                }
                else{
                    setValues([props.inheritance_key])
                }

                setInitialized(true)
            }
        }
        else{
            //
            setInitialized(true);
        }


    })




    const handleChange = (evt) => {
        const { checked: isChecked, value: selectedValue } = evt.target;

        // Toggle value in the array
        const updatedValues = values.includes(selectedValue)
            ? values.filter(v => v !== selectedValue) // Remove value
            : [...values, selectedValue]; // Add value

        // Get selected inheritance configurations
        const selectedInheritances = inheritance_modes.filter(inh =>
            updatedValues.includes(inh.key)
        );

        let analysisIds = getAnalysisIds(selectedInheritances);

        // Handle "x-linked-dominant" inheritance
        if (selectedValue === "x-linked-dominant") {

            let formattedParticipants = JSON.parse(JSON.stringify(participants))
            let options = ["Yes", true];

            formattedParticipants.forEach(function(p){
                p.index = (options.includes(p.index));
            })

            analysisIds = analysisIds.filter(config =>
                filterConditions(formattedParticipants, config.condition).length !== 0
            );
        }

        // Handle "de_novo" inheritance
        if (isChecked && selectedValue === "de_novo") {
            const samplesToRemove = samples
                .filter(s => !["index", "father", "mother"].includes(s.sample_type))
                .map(s => s.sample_id);

            if (samplesToRemove.length > 0) {
                const confirmMessage = `De Novo inheritance can only be applied to a family trio. The following experiments will be removed from the analysis (and all selected analyses): ${samplesToRemove.join(", ")}. Do you want to proceed? Otherwise, please select a custom inheritance instead.`;

                // Set the message, updated values, and analysisIds
                setDialogMessage(confirmMessage);
                setDialogValues(updatedValues); // Store the updated values
                setDialogAnalysisIds(analysisIds); // Store the analysisIds
                setConfirmationCallback(() => () => {
                    // Proceed with the logic after confirmation
                    samplesToRemove.forEach(sampleId =>
                        props.samples_actions.delete_sample(sampleId)
                    );
                    console.log("Proceeding with De Novo inheritance.");
                });

                // Open the dialog
                setOpenDialog(true);
                return; // Prevent proceeding further until the user confirms
            }
        }

        // Update state and configuration
        setValues(updatedValues);
        configureInheritances(analysisIds, updatedValues);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleDialogConfirm = () => {
        if (confirmationCallback) {
            confirmationCallback(); // Execute the callback passed to the dialog
        }

        // Now that we have the correct values and analysisIds, update them
        setValues(dialogValues); // Use dialogValues from state
        configureInheritances(dialogAnalysisIds, dialogValues); // Use dialogAnalysisIds from state
    };

    const getAnalysisIds = (selectedInheritances) => {
        return selectedInheritances.flatMap(inheritance =>
            inheritance.config.map(({ id, label, condition }) => ({ id, label, condition }))
        );
    };


    const configureInheritances = (analysis_ids, val_arr) => {

        //Gender Tab handling for X-linked Dominant
        let sex_index;

        //Get sex of index case
        const patients = props.patients.patients;

        if (patients.length > 1) {
            const filteredPatients = patients.find(sample => sample.index === "Yes");
            sex_index = filteredPatients ? filteredPatients.sex : "U";
        } else {
            sex_index = patients.length === 1 ? patients[0].sex : "U";
        }

        //If Male, remove the "X-Linked Dominant - Index Case Female" by id
        if (sex_index === "M"){
            analysis_ids = analysis_ids.filter(obj => obj.id !== 6);
        }

        //If Female remove the "X-Linked Dominant - Index Case Male" by id
        if (sex_index === "F"){
            analysis_ids = analysis_ids.filter(obj => obj.id !== 5);
        }


        let settings = {
            analysis_ids: analysis_ids
        };

        // configure inheritance;
        props.inheritance_actions.setInheritance(val_arr);
        props.study_actions.setStudySettings(settings);

    }

    const createOptions = (inheritances) => {
        
        //Remove somatic option if we are not coming from instand somatic tab
        if (props.instandGeneticFindingsTab !== "somatic")
          inheritances = inheritances.filter(obj => obj.key !== "somatic");

        return inheritances.map(function(mode){


            let key= mode.key;
            let label = mode.label;
            let alert = mode.alert;
            let disabled = false;
            if(participants!== undefined){

                let formattedParticipants = JSON.parse(JSON.stringify(participants))

                let options = ["Yes", true];

                formattedParticipants.forEach(function(p){
                    p.index = (options.includes(p.index));
                })

                let any = filterConditions(formattedParticipants, mode.condition);
                if(any.length === 0){
                    disabled = true;
                }

                if(mode.key === "de_novo"){
                    // check for all sample types ["index", "father", "mother"]

                    if (samples) {
                        disabled = samples < 3 || ["index", "father", "mother"].some(type =>
                            !samples.map(s => s.sample_type).includes(type)
                        );
                    }
                }
            }



            let gridItem =  <Grid item lg={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id={key}
                            value={key}
                            key={key}
                            onChange={handleChange}
                            checked={values.includes(key)}
                            name={key}
                            color="primary"
                            disabled={disabled}
                        />
                    }
                    label={<MappedText text={label} />}
                />
                {alert && (
                    <Chip
                        label={alert}
                        color="warning"
                        icon={<WarningAmberIcon />}
                        sx={{
                            backgroundColor: 'rgb(255, 244, 229)', // Custom background color
                            color: '#000', // Optional: set text color if needed (e.g., black text)
                            '& .MuiChip-icon': {
                                color: '#f57c00', // Optional: color for the icon (if you want to adjust it)
                            },
                        }}
                    />
                )}
                {/*<FormCheck
                    key={key}
                    onChange={handleChange}
                    type={"checkbox"}
                    id={key}
                    disabled={disabled}
                    value={key}
                    label={label}
                    checked={values.includes(key)}
                />*/}
                {(disabled) ? <div><Typography variant={"subtitle2"}>{mode.explanation} </Typography></div> :null}
            </Grid>

            return <React.Fragment>
                {gridItem}
            </React.Fragment>

    })
    }

    const options_automatic = createOptions(inheritances.filter(mode => !mode.label.includes("Custom")))
    const option_custom = createOptions(inheritances.filter(mode => mode.label.includes("Custom")));

    return <div id="inheritance_mode_selection">

        <GPAP_Dialog
            open={openDialog}
            handleClose = {handleDialogClose}
            title={"Confirmation Required"}
            text={dialogMessage}
            onExitText={"Cancel"}
            onConfirmText={"Proceed"}
            onConfirmAction={() => {
                handleDialogConfirm();
                handleDialogClose();
            }}
            onExitAction={handleDialogClose}
            type={"warning"}/>

        {/*<ConfirmationDialog
            open={openDialog}
            onClose={handleDialogClose}
            onConfirm={handleDialogConfirm}
            message={dialogMessage}
        />*/}
        <Grid container direction={"row"}>
            <Grid item lg={12}>
                    <Typography variant={"h5"}>
                    <MappedText text={"Select a simulated mode of inheritance configuration:"}/>
                    </Typography>
                    <Box py={1}>
                        <Typography color={"textSecondary"}>
                            <MappedText text={"Please, bear in mind that the predefined inheritances take into account the reported affected status of the family members which in some cases may force unrealistic configurations. In such cases, please use the custom configuration to formulate your hypothesis"}/>
                        </Typography>
                    </Box>

                <Box p={1}>
                    {options_automatic}
                </Box>
            </Grid>
            <Grid item lg={12}>
            <Typography variant={"h5"}>
            <MappedText text={"or configure a custom mode of inheritance"}/>
            </Typography>
            <Box p={1}>
            {option_custom}
            </Box>
            </Grid>
        </Grid>
    </div>;
};


function getPatients (state) {
    if(state.sample_list?.samples.length!==0)
    {
        return {
            patients:state.patients.length !== 0 ? state.patients : state.generaltable.allDataApis.participants,
            samples: state.sample_list.samples,
            inheritance_key: state.inheritance_key
        }
    }
    else{ return {render:false}}

}

const mapDispatchToProps = (dispatch) => ({
    samples_actions: bindActionCreators(Sample_Actions, dispatch),
    study_actions: bindActionCreators(Study_Actions, dispatch),
    inheritance_actions: bindActionCreators(Inheritance_Actions, dispatch),
});



export default connect(getPatients,mapDispatchToProps)(Inheritance_Mode);