import {
    getColumnKeys,
    POPULATION_COLUMNS,
    PREDICTIVE_COLUMNS, getTranscriptColumns
} from "../../../../../../../../../config-files/export_columns";


function getFormattedDate(date){
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    let minute = date.getMinutes();
    let hour = date.getHours();

    return day + "-" + (month+1) + "-" + year + "-" + hour + "." + minute;

}

function create_string(item, keys, separator){

    let col_array = [];

    keys.forEach(key => {
        let val = item[key];
        if(typeof val === "string"){
            val = val.replaceAll(",", " ");
        }
        else if(val === undefined){
            val = "NA"
        }

        col_array.push(val)

    });
    return col_array.toString() + separator
}


const formatField  = (value) => {


return (value !== undefined) ? value : "NA" 

}

 // pass the variants;
const save_CSV_file = function(json, maxSortableRows, genomeRef){


    const columns_keys = getColumnKeys(genomeRef);
    //separators
    let token_separator_field = ",";
    let token_separator_line = '\n';

    //info of tab lines
    let main_line_string = "";
    let transcript_line_string = "";
    let predictive_line_string = "";
    let population_line_string = "";
    let sample_line_string = "";
    let full_line_string = "";
    let version_line_string = "";

    let data = json.snv.hits;

    //init column titles
    //samples array must be sorted before

    if(data && data.hits.length!== 0 ){

    
        // extract and add sample columns for quality metrics

        // Find the variant with the most samples_germline
        let variantWithMoreSamples = data.hits.reduce((maxVariant, currentVariant) => 
            currentVariant.fields.samples_germline.length > maxVariant.fields.samples_germline.length 
                ? currentVariant 
                : maxVariant, 
            data.hits[0]
        );

        // Extract and add sample columns for quality metrics
        let sample_line_string = "";

        let orderedSampleCols = JSON.parse(JSON.stringify(variantWithMoreSamples));
        let samples = orderedSampleCols.fields.samples_germline;
        
        samples.sort(function(a,b){ return a.sample.localeCompare(b.sample);});

        for (let t = 0; t < samples.length; t++) {
            let sample_obj = samples[t];
            let sample = sample_obj ? sample_obj.sample : "_sample";

            sample_line_string += [
                `GT_${sample}`,
                `GQ_${sample}`,
                `DP_${sample}`,
                `AD_${sample}`
            ].join(token_separator_field) + token_separator_field;
        }

        let all_keys =  columns_keys.concat(sample_line_string.split(','));
        let columns_header =all_keys.toString() + token_separator_line;

      
        //fit column titles
        const MAX =  (maxSortableRows) ? maxSortableRows : 1000;


        for (let i = 0; i < Math.min(MAX, data.hits.length); i++) {
            //main table
        
            let item = data.hits[i];

            if(item !== undefined){

                main_line_string = [
                    int_To_Chrom(item._source.chrom),
                    item._source.pos,
                    item._source.ref,
                    item._source.alt,
                    formatField(item._source.rs)
                  ].join(token_separator_field);
              

            //predictive tab

           
            predictive_line_string = create_string(item._source, PREDICTIVE_COLUMNS, token_separator_field);

            //population tab

            population_line_string = create_string(item._source, POPULATION_COLUMNS, token_separator_field);

            let sampleList = item.fields.samples_germline.map(s => s.sample);

            let sampleIds_line_string = token_separator_field + `"${sampleList.join(" ")}"` + token_separator_field;

            
           
            //samples array must be sorted before // it should follow the same order as above.
            item.fields.samples_germline.sort(function(a,b){ return a.sample.localeCompare(b.sample);});

            sample_line_string = "";
            for (let k = 0; k < item.fields.samples_germline.length; k++) {
                
                let sample_id  = item.fields.samples_germline[k];
                
                sample_line_string += [
                    formatField(sample_id.gt),
                    formatField(sample_id.gq),
                    formatField(sample_id.dp),
                    formatField(sample_id.ad)
                  ].join(token_separator_field) + token_separator_field;
            }

            //functional tab
            for (let j = 0; j < item.fields.effs.length; j++) {

                let transcript = item.fields.effs[j];
                const transcriptColumns = getTranscriptColumns(genomeRef);
                transcript_line_string = create_string(transcript, transcriptColumns, token_separator_field);

                full_line_string += [
                    main_line_string,
                    sampleIds_line_string,
                    transcript_line_string,
                    predictive_line_string,
                    population_line_string,
                    sample_line_string
                  ].join("") + token_separator_line;
            }
            }
            else{
                console.log("Error. Can't prepare data for export.")
            }
           
        }

        //timestamp and versions of platform & dataset
        let date = new Date();
        let now = getFormattedDate(date);

        console.log("full_line_string", full_line_string);
        //generating downloading .csv file
        let blob = new Blob([columns_header + full_line_string], {type: "octet/stream"});
        let fileName = "gpap_" + now + "_" +version_line_string + ".csv";
        let downloadElement = document.createElement("a");
        downloadElement.href = window.URL.createObjectURL(blob);
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
    }else{
        alert("Error. Can't prepare data for export.")
    }

}; 






const int_To_Chrom= function(chrom){
    let output=chrom;
    if (chrom==="23") output="MT";
    if (chrom==="24") output="X";
    if (chrom==="25") output="Y";
    return output
};



export default save_CSV_file;