import React from 'react';
import { Grid, Typography, Button, Box, Divider, IconButton} from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {NavLink} from "react-router-dom";
import MappedText from '../../instand/reports/create-report/shared-components/mapText';
import HostingInfo from './HostingInfo';

function App() {


    const url_analysis = window.config?.instand_config?.url_analysis || null;
    const url_reporting = window.config?.instand_config?.url_reporting || null;
    const url_feedbackForm = window.config?.instand_config?.url_feedback_form || null;
    const url_img = `${window.config?.static_files}/img/nextgpap/cafolder/home`;
    
    const CNAG_LOGO = `${url_img}/logo_CNAG.svg`;
    const HomeBG_Clean = `${url_img}/HomeBG-Clean.png`;
    const LogoCancer = `${url_img}/logo_cancer.svg`;
    const AnalysisSVG = `${url_img}/analysis.svg`;
    const ReportingSVG = `${url_img}/reporting.svg`;
    const FeedbackSVG = `${url_img}/feedback.svg`;
    const InstandPNG = `${url_img}/Instand.png`;
    const MedUniGrazPNG = `${url_img}/med-uni-graz-gruen_2-200x200.png`;
    const P3UNIFIPNG = `${url_img}/P3-UNIFI-300x150.png`;
    const ErasmusMCSVG = `${url_img}/erasmus-mc.svg`;
    const P5CCRIPNG = `${url_img}/P5-CCRI-250x75.png`;
    const P10UKSHPNG = `${url_img}/P10-uksh.png`;
    const LeonBerardPNG = `${url_img}/leonberard.png`;
    const P6UNIMIBPNG = `${url_img}/P6-unimib-160x160.png`;
    const FAVOPNG = `${url_img}/P11-Favo-340x120.jpg`;
    const EuropeanCancerPatientCoalition = `${url_img}/EuropeanCancerPatientCoalition.jpg`;

    const isUkshInstance = window.config?.instand_config.url_analysis.includes("uksh");


  const solutionsRef = React.useRef(null);
  const handleScrollToSolutions = () => {
    if (solutionsRef.current) {
      const yOffset = -10; 
      const y = solutionsRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };
  
  return (
    <div>
    {/* Hero */}    
      <Box sx={{
          textAlign: 'center',
          backgroundImage: `url(${HomeBG_Clean})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 0,
          padding: '80px 0', 
        }}
        >
        <img
          src={LogoCancer} 
          alt="GPAP Logo"
          style={{ height: '77px', marginTop:'80px' }}
        />
        <Typography
          variant={"h4"}
          sx={{
            mt: 5,
            color: '#fff',
            fontWeight: '300',
            fontSize: '34px',
            px: 10
          }}
        >
        <MappedText text={"A comprehensive state-of-the-art platform for NGS cancer data analysis, interpretation and reporting"}/>
        </Typography>
        <IconButton
            sx={{
              backgroundColor: '#fff',
              color: '#3E69A9',
              height: '64px',
              width: '64px',
              marginTop: '76px',
              '&:hover': {
                backgroundColor: '#f0f0f0',
              }
            }}
            onClick={handleScrollToSolutions}
          >
            <KeyboardArrowDown sx={{ fontSize: '32px' }} />
          </IconButton>
      </Box>

      {/* Solutions Section */}
      <Box
        ref={solutionsRef}
        sx={{
            padding: '0 100px', 
        }}
        >
      <Typography
        variant={"h3"}
        sx={{
          marginTop:'55px',
          marginBottom:'55px',
          textAlign: 'center',
          fontWeight: '400',
          fontSize: '48px',
          color: '#3A3A3A',
        }}
      >
        <MappedText text={"Our solutions"}/>
        </Typography>
        <Grid container spacing={4} alignItems="stretch">
                {/* Cancer Analysis */}
                <Grid item xs={12} md={6}>
                    <Box
                    sx={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        height: '100%',
                      
                    }}
                    >
                    <img
                        src={AnalysisSVG}
                        alt="Cancer Analysis"
                        style={{ height: '164px' }}  
                    />
                    <Typography
                        variant={"h4"}
                        sx={{ mt: 5, color: '#3E69A9', fontSize: '34px' }}
                    >
                        <MappedText text={"Cancer Analysis"}/>
                    </Typography>
                    <Typography
                        variant={"h5"}
                        sx={{
                        mt: 5,
                        mb: 5,
                        fontSize: '24px',
                        fontWeight: '300',
                        color: '#000000',
                        }}
                    >
                        <MappedText text={"Bioinformatic analysis of NGS data with standardized workflows for the comprehensive identification of germline variants and somatic mutations."}/>
                    </Typography>
                    <Box sx={{ mt: 'auto' }}> 
                    <Button 
                        variant="contained"
                        sx={{ backgroundColor: '#3E69A9', fontWeight: '400', fontSize: '18px', padding: '10px 25px', textTransform: 'none',
                            '&:hover': {
                                backgroundColor: '#314C89'
                            } }}

                        >  <a href={url_analysis}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: "none", color: "white" }}>
                       <MappedText text={"Access Platform"}/>

                    </a></Button>

                    </Box>
                    </Box>
                </Grid>

                {/* Cancer Reporting */}
                <Grid item xs={12} md={6}>
                    <Box
                    sx={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        height: '100%',
                    }}
                    >
                    <img
                        src={ReportingSVG}
                        alt="Cancer Reporting"
                        style={{ height: '164px' }}  
                    />
                    <Typography
                        variant={"h4"}
                        sx={{ mt: 5, color: '#3E69A9', fontSize: '34px' }}
                    >
                        <MappedText text={"Cancer Reporting"}/>
                    </Typography>
                    <Typography
                        variant={"h5"}
                        sx={{
                        mt: 5,
                        mb: 5,
                        fontSize: '24px',
                        fontWeight: '300',
                        color: '#000000',
                        }}
                    >
                       <MappedText text={"Integration of NGS results, e-medication data, and clinical evidence for therapy decision making."}/>
                    </Typography>
                    <Box sx={{ mt: 'auto' }}> 
                        <Button 
                        variant="contained"
                        sx={{ backgroundColor: '#3E69A9', fontWeight: '400', fontSize: '18px', padding: '10px 25px', textTransform: 'none',
                            '&:hover': {
                                backgroundColor: '#314C89'
                            } }}
                        ><NavLink exact to={url_reporting}
                                  style={{textDecoration:"none", color:"white"}}><MappedText text="Access Platform"/></NavLink></Button>
                    </Box>
                    </Box>
                    
                </Grid>
                </Grid>
                </Box>
       {/* Feedback*/}
        <Box
        sx={{
            backgroundColor: '#f5f5f5',
            mt: 12,
            padding: 10,
            textAlign: 'left',
        }}
        >
        <Grid container spacing={2} alignItems="center">
            {/* Texto y botones */}
            <Grid item xs={12} md={6}>
            <Typography
                variant={"h4"}
                sx={{
                mb: 3,
                color: '#000000',
                fontWeight: '400',
                fontSize: '34px',
                }}
            >
                <MappedText text={"We value your feedback!"}/>
            </Typography>
            <Typography
                variant={"h5"}
                sx={{
                mb: 2,
                fontWeight: '400',
                fontSize: '20px',
                }}
            >
                <MappedText text={"Your experience matters to us. Help us improve by filling out our quick feedback form. What do you love? What can we do better? Let us know! Thank you!"}/>
            </Typography>
        {/*    <Button
                variant="contained"
                sx={{
                padding: '8px 22px',
                marginRight: '8px',
                color: '#fff',
                backgroundColor: '#3E69A9',
                fontSize: '13px',
                textTransform: 'none',
                '&:hover': {
                                backgroundColor: '#314C89'
                            } }}

            >

                <MappedText text={"Cancer Analysis Feedback Form"}/>
            </Button>
                */}

            <Button
                href={url_feedbackForm}
                target={"_blank"}
                variant="contained"
                sx={{
                padding: '8px 22px',
                color: '#fff',
                backgroundColor: '#66A7E0',
                fontSize: '13px',
                textTransform: 'none',
                '&:hover': {
                                backgroundColor: '#4F8BCE'
                            } }}
            >

                <MappedText text={"Cancer Reporting Feedback Form"}/>

            </Button>
            </Grid>

            <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <img
                src={FeedbackSVG}
                alt="Feedback Icon"
                style={{ maxWidth: '100%', height: 'auto', maxHeight: '150px' }}  
            />
            </Grid>
        </Grid>
        </Box>


  
      <Box sx={{ padding: '0 80px', textAlign: 'left', mb: { xs: 4, md: 5 } }}>
        
        {(isUkshInstance) 
        ? <HostingInfo /> 
        : null }
        <Box sx={{ m: 2, bgcolor: "background.paper" }}>
          <Typography
          variant={"h4"}
          sx={{
            mt: 5,
            mb: 3,
            color: '#3A3A3A',
            size: '34px'
          }}
        >
          <MappedText text={"Developed by"}/>
        </Typography>
        <img
          src={CNAG_LOGO} 
          alt="CNAG Logo"
          style={{ maxWidth: { xs: '120px', md: '150px' }, marginBottom: '20px' }}
        />
        </Box>
      </Box>


        {/* Partners*/}
            <Box sx={{ textAlign: 'left', mb: 5, padding: '0 80px',  }}>
            <Typography
            variant={"h4"}
            sx={{
                mt: 5,
                mb: 3,
                color: '#3A3A3A',
                size: '34px'
              }}
            >

            <MappedText text={"Instand-NGS4P Partners"}/>

            </Typography>
            <Box>
         <Grid container spacing={4} justifyContent="left">
         <Grid item xs={4} md={2}>
        <a href="https://www.instandngs4p.eu/" target="_blank" rel="noopener noreferrer">
            <img
            src={InstandPNG}
            alt="Instand Project"
            style={{ maxWidth: '100%', height: '130px', objectFit: 'contain',}}
            />
        </a>
        </Grid>
        <Grid item xs={4} md={2}>
        <a href="https://www.medunigraz.at/" target="_blank" rel="noopener noreferrer">
            <img
            src={MedUniGrazPNG}
            alt="Med Uni Graz"
            style={{ maxWidth: '100%', height: '130px', objectFit: 'contain',}}
            />
        </a>
        </Grid>
        <Grid item xs={4} md={2}>
        <a href="https://www.unifi.it/it" target="_blank" rel="noopener noreferrer">
            <img
            src={P3UNIFIPNG}
            alt="Università degli Studi di Firenze"
            style={{ maxWidth: '100%', height: '130px', objectFit: 'contain',}}
            />
        </a>
        </Grid>
        <Grid item xs={4} md={2}>
        <a href="https://www.erasmusmc.nl/nl-nl/" target="_blank" rel="noopener noreferrer">
            <img
            src={ErasmusMCSVG}
            alt="Erasmus MC"
            style={{ maxWidth: '100%', height: '130px', objectFit: 'contain',}}
            />
        </a>
        </Grid>
        <Grid item xs={4} md={2} />
        <Grid item xs={4} md={2} />

        <Grid item xs={4} md={2}>
        <a href="https://kinderkrebsforschung.at/" target="_blank" rel="noopener noreferrer">
            <img
            src={P5CCRIPNG}
            alt="St. Anna Kinderkrebsforschung"
            style={{ maxWidth: '100%', height: '130px', objectFit: 'contain',}}
            />
        </a>
        </Grid>
        <Grid item xs={4} md={2}>
        <a href="https://www.uni-kiel.de/de/" target="_blank" rel="noopener noreferrer">
            <img
            src={P10UKSHPNG}
            alt="Universitätsklinikum Schleswig-Holstein"
            style={{ maxWidth: '100%', height: '130px', objectFit: 'contain',}}
            />
        </a>
        </Grid>
        <Grid item xs={4} md={2}>
        <a href="https://www.centreleonberard.fr/" target="_blank" rel="noopener noreferrer">
            <img
            src={LeonBerardPNG}
            alt="Centre Léon Bérard"
            style={{ maxWidth: '100%', height: '130px', objectFit: 'contain',}}
            />
        </a>
        </Grid>
        <Grid item xs={4} md={2}>
        <a href="https://www.unimib.it/" target="_blank" rel="noopener noreferrer">
            <img
            src={P6UNIMIBPNG}
            alt="Università degli Studi di Milano-Bicocca"
            style={{ maxWidth: '100%', height: '130px', objectFit: 'contain',}}
            />
        </a>
        </Grid>
        <Grid item xs={4} md={2} />
        <Grid item xs={4} md={2} />
        <Grid item xs={4} md={2}>
        <a href="https://www.favo.it/" target="_blank" rel="noopener noreferrer">
            <img
            src={FAVOPNG}
            alt="F.A.V.O."
            style={{ maxWidth: '100%', height: '130px', objectFit: 'contain',}}
            />
        </a>
        </Grid>
   
        <Grid item xs={4} md={2}>
        <a href="https://www.ecpc.org/" target="_blank" rel="noopener noreferrer">
            <img
            src={EuropeanCancerPatientCoalition}
            alt="European Cancer Patient Coalition"
            style={{ maxWidth: '100%', height: '130px', objectFit: 'contain',}}
            />
        </a>
        </Grid>
       
    </Grid>
    </Box>
        </Box>

      {/* Footer */}
      <Box sx={{ textAlign: 'left', mt: 3, color: '#3A3A3A', padding: '0 80px',mb:2  }}>
        <Typography variant="body2"><MappedText text={"© CNAG 2024. All rights reserved."}/></Typography>
      </Box>
      </div>
  );
}


export default App;
