import React from "react";
import {Grid, Typography} from "@mui/material";

import MappedText from "../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import Treatments from "../../treatments/Treatments";
import AlertInfoViews from "../../reports/create-report/shared-components/AlertInfo";
import Box from "@mui/material/Box";
import { renderToStringAlt } from "../../reports/create-report/shared-components/renderToString";


function ClinicalEvidence({analysisType}){


    return  <>
        <Grid item lg={12}>
            <Box p={1}>
            <Typography variant={"h5"} style={{  fontWeight: "bold" }}><MappedText text={"Clinical Evidence"} /></Typography>
            </Box>
        </Grid>
        <Grid item lg={12}>
        <AlertInfoViews text={renderToStringAlt("These are all the clinical evidences our system calculated. They’re not affected by the request’s filters or gene list.")} />
        </Grid>
        <Grid item lg={12}>
            <Treatments analysisType={analysisType}/>
        </Grid>
    </>



}

export default ClinicalEvidence;