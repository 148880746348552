

import React, {Fragment, useEffect, useState} from "react";
import {GPAP_Button} from "../../../../../../../../../gpap-lib/components/Button_Collection";

import Divider from '@mui/material/Divider'; // Updated to @mui/material
import Typography from '@mui/material/Typography'; // Updated to @mui/material
import Grid from '@mui/material/Grid'; // Updated to @mui/material

import StudyCreator from "../../../../../../../../study-page/create-study/StudyCreator";
import Inheritance_Mode_renderer
    from "../../../../../../../../study-page/create-study/analysis-page/components/InheritanceMode/Inheritance_Mode_renderer";
import GB_renderer
    from "../../../../../../../../study-page/create-study/analysis-page/components/GenotypeBuilder/GB_renderer";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import * as StudyActions from "../../../../../../../reducers/study_actions.js"
import WarningBox from "../../../../../../../../study-page/create-study/WarningBox";
import * as SidebarActions from "../../../../../../../../../components/SideNavigation/sidebar_actions";
import StartFilters from "../../../filter-panel/components/filter-sections/start-filters/StartFilters";
import formatSamples from "../../../../../../../../study-page/create-study/analysis-page/formatSamples";

import {X_CHROM_OBJ} from "../../../../../../../../study-page/helpers";
import {date_generator} from "../../../../../../../../func/helpers";
import {useSelector} from "react-redux";
import { setInheritance } from "../../../../../../../../study-page/create-study/analysis-page/reducers/inheritance_actions.js";
import * as Inheritance_Actions from "../../../../../../../../study-page/create-study/analysis-page/reducers/inheritance_actions.js";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText.js";
import { checkCustomGenotype } from "../../../../../../../../study-page/create-study/analysis-page/components/GenotypeBuilder/helpers/checkCustomGenotype.js";
import CustomNoGenotypeMessage from "../../../../../../../../study-page/create-study/analysis-page/components/GenotypeBuilder/components/GenotypeForm/CustomInheritance_NoGenotype.js";

function DialogAnalysisContent(props){

    const [step, setStep] = useState(0);
    const [analyses, setAnalyses] = useState([]);
    const [customNoGQ, setCustomNoGQ] = useState(false);

    const allParticipants = useSelector(state => state.generaltable.allDataApis.participants);

    //For Instand case, get them with useSelector
    const participants = props.patients.length !== 0 ? props.patients : allParticipants

    
    useEffect(() => {

        if (props.instandGeneticFindingsTab === "germline"){
            setStep(1)
        }

        if (props.instandGeneticFindingsTab === "somatic"){

        // configure inheritance;
        let ananysis_obj =  {
                "analysis_ids": [
                    {
                        "id": 9,
                        "label": "Somatic",
                        "condition": {}
                    }
                ]
            }

        props.inheritance_actions.setInheritance(["somatic"]);
        props.studyActions.setStudySettings(ananysis_obj);

        setStep(2)

      }

      }, []); 



    useEffect(function(){


    }, [step]);
    

    const showInheritances = () => {

        // create new analysis with same samples ....
        ////
        setStep(1);
    };


    useEffect(() => {
        checkCustomGenotype(props.samples, props.inheritance, setCustomNoGQ);
      }, [props.samples, props.inheritance]);


    const createQuery = () => {

        let studyActions = props.studyActions;
        let { samples, studySettings, query } = props;

        // assign a query to each analysis. It would include the genotype for now.... then we will edit accordingly.
        let first_query_id = 0;
        let first_query_to_run = 0;
        let date = date_generator();

        let which_analyses = [];
        studySettings.analysis_ids.forEach(function(s){

            which_analyses= which_analyses.concat(s)

        })

        which_analyses.forEach(function(analysis, index){

            // create new query taking the query from the REDUX Store

            // get inheritance id

            const new_query = JSON.parse(JSON.stringify(query));

            // save variant type and delete;

            let variant_type= new_query["variant_type"];

            delete new_query["variant_type"];

            // Germline default samples
            let samples_copy = JSON.parse(JSON.stringify(samples));

            let INDEX = index;
            if(samples_copy.length>0 && samples_copy[0].gt_settings.length>0){
                INDEX= samples_copy[0].gt_settings.findIndex(s=> s.label === analysis.label);
            }

            new_query.samples_germline = formatSamples(samples_copy, INDEX);

            const localID= 1;

            // first analysis to save

            if(index === 0) first_query_id = localID;

            let v_type= (variant_type!== undefined)
                ? {variant_type: variant_type}
                : {variant_type: "GPAP"};
            let extra = [];
            extra.push(v_type);

            // TODO: order the code;

            extra = extra.concat(props.geneList.applied_lists);

            if(analysis.label.includes("compound heterozygosity")){
                new_query.compound_in = true
            }

            // take into account X-Linked Inheritances;
            if(analysis.label.includes("X")){

                if(new_query["chrom"].findIndex(s => s.chrom === "24") === -1){
                    new_query["chrom"].push(X_CHROM_OBJ);
                }

            }

            // first query to save
            const data = {
                study_id: studySettings.id ,
                analysis_id:  analyses[index],
                query: {
                    "localId": localID ,
                    "name": "query_" + localID,
                    "analysis_id": analyses[index], // assign analyses ID
                    "filter_set_id": -1,
                    "query_name": "query_" + localID,
                    "filters": JSON.stringify(new_query),
                    "creationDate": date,
                    "outputs": [{"n_variants": -1, "run_date": date }],
                    "extra": extra

                }
            };

            if(index === 0){
                first_query_to_run = {
                    query_to_run: new_query,
                    store: true,
                    analysis_type:"family_trio",
                    analysis_id: analyses[index],
                    query_id: localID
                }
            }

            // add analysis to the study; we should specify to which study;

            studyActions.create_query(data);
        });

        // run the first query for the first query created;
        if(first_query_to_run){

            let {query_to_run, store, analysis_type, analysis_id, query_id} = first_query_to_run
            studyActions.setStudySettings({current_analysis: analysis_id, current_query: first_query_id})
            // don't story query as it's already saved above..
            props.queryRunner(query_to_run, false, analysis_type, analysis_id, query_id)
        }

        props.handleClose();

    }


    //Function that maps the GQ values from the germline to the somatic samples
    function mapGQValues() {
        const { samples, somatic_samples } = props;
      
        if (
          samples &&
          samples.length > 0 &&
          samples[0].gt_settings &&
          samples[0].gt_settings.length > 0 &&
          somatic_samples &&
          somatic_samples.length > 0 &&
          somatic_samples[0].gt_settings &&
          somatic_samples[0].gt_settings.length > 0
        ) {
          const {
            dp: dpTumorValue,
            dp_control: dpControlValue,
            ad_high: adHighValue,
            ad_low: adLowValue,
          } = samples[0].gt_settings[0];
      
          const mappedValues = {
            dp_tumor: dpTumorValue,
            dp_control: dpControlValue,
            ad_tumor_high: adHighValue,
            ad_tumor_low: adLowValue,
          };

          // Check if dp_control is undefined (when it is not set from the slider)
          if (typeof mappedValues.dp_control === 'undefined') {
             // And set to a default value
             mappedValues.dp_control = 10;
          }

      
          somatic_samples[0].gt_settings[0] = {
            ...somatic_samples[0].gt_settings[0],
            ...mappedValues,
          };

          return somatic_samples
      
        }
      }


    const createAnalysis = () => {

        let {samples, somatic_samples, studySettings, studyActions } = props;

        let date = date_generator();

        // get inheritance key;

        // for each inheritance I create a different analysis

        let analyses = [];

        // concatenate all analysis

        studySettings.analysis_ids.forEach(function(item){
            analyses = analyses.concat(item)
        })


        let analyses_ids = [];
        analyses.forEach(function(type_of_inheritance){
            const analysis_id = Math.floor(Math.random() * 200);
            let samples_copy = JSON.parse(JSON.stringify(samples));

            // remove unneccesary inheritances;
            samples_copy.forEach(function(s){
                s.gt_settings = s.gt_settings.filter(opt => opt.label === type_of_inheritance.label)
            });

            let new_analysis = {
                "studyLocalId": studySettings.id,
                "localId": analysis_id,
                "analysis_type": studySettings.analysisType,
                "inheritance_mode_id": type_of_inheritance.id,
                "creationDate": date,
                "samples": samples_copy,   // break reference.
                "description": "",
                "queries": [],
                "labelled": {},
                "tagged": []
            };


            // Append Somatic samples to analysis
            if (props.instandGeneticFindingsTab === "somatic"){
                //let somatic_samples_copy = JSON.parse(JSON.stringify(somatic_samples));
                let somatic_gq_applied = mapGQValues()
                let somatic_samples_copy = JSON.parse(JSON.stringify(somatic_gq_applied));
                new_analysis.samples_somatic = somatic_samples_copy
            }


            studyActions.create_analysis(new_analysis);
            studyActions.setStudySettings({current_analysis: analysis_id})
            analyses_ids.push(analysis_id);

        });

        setAnalyses(analyses_ids)
        setStep(3);

    };



    const showOptions = () => {


        switch (step) {
            case 0:
                return NewAnalysisDialog();
            case 1:
                return <Fragment>
                    <Inheritance_Mode_renderer
                        full_size={true} instandGeneticFindingsTab={props.instandGeneticFindingsTab}
                    />

                    {props.instandGeneticFindingsTab !== "somatic" && props.instandGeneticFindingsTab !== "germline" && (
                    <>
                    <GPAP_Button onClick={() => setStep(0)}> <MappedText text="Back"/> </GPAP_Button>
                    </>
                    )}
                    <GPAP_Button onClick={() => setStep(2)}> <MappedText text="Next"/> </GPAP_Button>

                </Fragment>;
            case 2:
                return <Fragment>
                    <Typography
                        variant="h6"
                        align={"left"}
                        style={{
                            color: "#4575B4",
                            padding: "2px",
                            textTransform: "uppercase"
                        }}>
                        <MappedText text="Genotype Settings" />
                    </Typography>
                    <GB_renderer instandGeneticFindingsTab={props.instandGeneticFindingsTab}/>

                    {props.instandGeneticFindingsTab !== "somatic" && (
                    <>
                    <GPAP_Button onClick={() => setStep(1)}> <MappedText text="Back"/> </GPAP_Button>
                    </>
                    )}

                    <GPAP_Button onClick={() => createAnalysis() }  disabled={(customNoGQ)}> <MappedText text="Apply"/> </GPAP_Button>

                    <CustomNoGenotypeMessage show={customNoGQ} />

                </Fragment>;
            case 3:
                return <Fragment>
                    <Grid container direction={"row"} alignItems={"center"} style={{textAlign: "center"}}>
                        <Grid item lg={12}>
                            <Typography variant={"h6"}>
                                <MappedText text="You have created " />
                                {props.studySettings.analysis_ids.length}
                                <MappedText text=" analyses." />
                            </Typography>
                            <WarningBox text={<MappedText text="You can now create a query that will be applied to the genotype(s). Select your filters and run the new query." />}/>
                        </Grid>
                        <Grid item lg={12}>
                            <GPAP_Button onClick={() => setStep(2)}><MappedText text="Back"/></GPAP_Button>
                            <GPAP_Button autoFocus onClick={() => createQuery()} color="primary">
                                <MappedText text="Run Query"/>
                            </GPAP_Button>
                        </Grid>
                    </Grid>
                    <StartFilters/>

                </Fragment>


        }



    };

    const currentSamplesBox = () => {

        let current_analysis_type = props.studySettings.analysisType;

        if((current_analysis_type === "family_trio") || (current_analysis_type === "instand")){
            return <Fragment>
                <div>
                    <GPAP_Button style={{width: "100%"}} onClick={() => showInheritances()}><MappedText text="Apply to current Samples"/></GPAP_Button>
                </div>
                <Divider/>
            </Fragment>
        }
        else{
            return null;
        }

    };

    const NewAnalysisDialog = () => {


        return <Fragment>
            {
                (participants.length!== 0)
                    ?  <Fragment>
                        {currentSamplesBox()}
                        
                        {/* Comment for now
                        <Typography variant={"subtitle1"}>
                            <MappedText text="or start from new Samples:"/>
                        </Typography>
                        */}
                      </Fragment>
                    : null
            }

             {/* Comment for now 'Edit family trio'
             <StudyCreator
                default={false}
                disableStudyCreation={true}
            />*/}

        </Fragment>
    };

    return showOptions();




}

function mapInheritance (state) {
    if(state.patients!== undefined)
    {
        return {
            patients: state.patients.patients,
            studySettings: state.studySettings,
            studyBucket: state.studyBucket,
            samples: state.sample_list.samples,
            somatic_samples: state.sample_list.somatic_samples,
            query: state.query,
            geneList: state.geneList,
            inheritance: state.inheritance_key
        }
    }
    else{
        return {patients: []}
    }

}




// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    studyActions: bindActionCreators(StudyActions, dispatch),
    sidebarActions: bindActionCreators(SidebarActions, dispatch),
    inheritance_actions: bindActionCreators(Inheritance_Actions, dispatch)
});



export default connect(mapInheritance, mapDispatchToProps)(DialogAnalysisContent);

