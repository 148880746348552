import React from "react";
import {DATA_HEADER} from "./data_header";
import CustomTable from "../../../components/tables/general-table/components/tables/CustomTable";
import {useSelector, useDispatch} from "react-redux";
import { setSelectedTableRows } from "../../dashboard/components/genomics-dashboard/components/results/components/variant-table/actions";

function Fusions_Table(props){

  const dispatch = useDispatch();

  const {allDataApis } = useSelector((state) => state.generaltable);
  const selectedDir = allDataApis?.dir?.find(d => d.dir_id === allDataApis?.dirs?.dir_id);

  const handleSelectedFusions= (selectedRows) => {
  
          let data = {
              data_row : [selectedRows],
              data_type: "Fusions",
  
          };
  
          dispatch(setSelectedTableRows(data))
  
      }

    return <CustomTable
        dataType={"Fusions"}
        tableName={"fusions"}
        url={props.url}
        method= "POST"
        dataSend = {{
          "fields": [],
          "filtered": [
            {
              "id": "Experiment_ID",
              "value": [selectedDir?.tumor_experiment_id]
            }
          ],
          "page": 1,
          "pageSize": 1000,
          "sorted": []
        }}  
        enableTag={props.enableTag}
        token={props.token}
        participant={props.participant}
        locationData="items"
        classRow= "Fusions"
        setSelected = {(fusions) => handleSelectedFusions(fusions)}
        customFeatures= {[
            { toggle: false },
            { expanding: false },
            { showHeader: true },
            { columnsButton: true },
        ]}

        dataHeader={DATA_HEADER}
       
    />


}

export default Fusions_Table
