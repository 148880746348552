
import React from "react";

export function generateLink(row) {
    const rs = row?.rs; // Safeguard against undefined or null row

    if (!rs) {
        return "NA"; // Return "NA" if rs is undefined or null
    }

    // Determine the appropriate link prefix
    const linkPrefix = rs.includes("COS")
        ? "https://cancer.sanger.ac.uk/cosmic/search?q="
        : "https://www.ncbi.nlm.nih.gov/snp/";

    // Construct and return the link
    const dbSNPLink = `${linkPrefix}${rs}`;
    return <a href={dbSNPLink} target="_blank" rel="noopener noreferrer">{rs}</a>;
}

