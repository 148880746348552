export const prepareObject = (source, data, mapping) => {
    const output = {};

    // Map properties based on the provided mapping
    Object.entries(mapping).forEach(([sourceKey, outputKey]) => {
        if (source[sourceKey] && source[sourceKey].length > 0) {
            output[outputKey] = source[sourceKey];
        } else {
            output[outputKey] = "NA";
        }
    });

    // Set the interpretation property from data.comments
    output.interpretation = data?.comments || "";

    return output;
};