import {origin_opts, related_to_opts} from "../tag_form_options";

import {getVariantType} from "./getSvType";




export const getInitialFormOptions = (dataType) => {
    
    if(dataType=="Fusions"){
        return ({
            samples: [{ value: "none", label: "none" }],
            genes: [{ value: "none", label: "none" }],
            significance_opts: [{ value: "none", label: "none" }],
            related_to: related_to_opts,
            variant_type: getVariantType(dataType),
        })
    }
    else {

        return ({
            participant_id: "xxxx",
            samples: [{ value: "none", label: "none" }],
            genes: [{ value: "none", label: "none" }],
            transcripts: [{ value: "none", label: "none" }],
            zygosity_opts: [{ value: "none", label: "none" }],
            inheritance_opts: [{ value: "none", label: "none" }],
            significance_opts: [{ value: "none", label: "none" }],
            origin: origin_opts,
            variant_type: related_to_opts,
            sv_type: getVariantType(dataType),
            project: []
            })
}
};
