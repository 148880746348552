import React, { Fragment } from 'react';
import { Box, Typography } from '@mui/material';
import CaButton from "../../../../../../../../../../gpap-lib/components/shared-components/CaButton";
import WarningBox from "../../../../../../../../../study-page/create-study/WarningBox";
import GPAP_Tooltip from "../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import {GPAP_Button} from "../../../../../../../../../../gpap-lib/components/Button_Collection";
import MappedText from "../../../../../../../../../instand/reports/create-report/shared-components/mapText";
import UnderConstructionMsg from "./UnderConstructionMsg";


// Review ALL BUTTONS


const AfterSubmitContent = ({ dataType, index_case, setUpdated, setSubmitted, operationType, analysisType }) => {
    const { url_phenostore } = window.config.apis_configuration;

    const renderSNVButtons = () => {
        return (
            <Fragment>
                {(analysisType !== "instand")
                ? <CaButton
                    variant="outlined"
                    text="Go to participant summary"
                    href={`${url_phenostore}/#/summary/${index_case}`}
                    target="_blank" />
                : null
                }
            </Fragment>
        );
    };

    const renderTreatmentMessage = () => {
        return (
            <Box p={1}>
                <Typography variant="subtitle1">
                    Clinical evidence {operationType} correctly
                </Typography>
            </Box>
        );
    };

    const renderFusionMessage = () => {
        return (
            <Box p={1}>
                <Typography variant="subtitle1">
                    Gene Fusion {operationType} correctly
                </Typography>
            </Box>
        );
    };


    const renderPharmacogenomicsMessage = () => {
        return (
            <Box p={1}>
                <Typography variant="subtitle1">
                    Haplotype tag {operationType} correctly
                </Typography>
            </Box>
        );
    };

    const renderDefaultMessage = () => {


        if(analysisType === "instand"){
            return <UnderConstructionMsg/>
        }
        else{
            return (
                <Fragment>
                    <Box p={1}>
                        <WarningBox
                            text="If you want to edit this tag or if you tagged it by mistake, please go to the PhenoStore record." />
                    </Box>
                    <Box p={1}>
                        <GPAP_Tooltip title="Click here if you want to edit/delete your CNV tag">
                            <CaButton
                                variant={"contained"}
                                href={`${url_phenostore}/#/patient/${index_case}`}
                                target="_blank"
                                text={"See tag in Participant Form"}/>
                        </GPAP_Tooltip>
                        <GPAP_Tooltip title={<MappedText text="Click here if you want to see a summary of the data stored in PhenoStore for the tagged participant" />}>
                            <CaButton
                                variant={"contained"}
                                text={"Go to Participant Summary"}
                                href={`${url_phenostore}/#/summary/${index_case}`}
                                target="_blank"/>
                        </GPAP_Tooltip>
                    </Box>
                </Fragment>
            );
        }
        // this is the case for CNVs

    };

    const messagePhenoStore = () => {

        if(analysisType!== "instand"){
            return <Box p={1}>
            <Typography variant="body2" color="textSecondary">
             By clicking below, another tab will open with the PhenoStore submission form for the participant to whom the variant was assigned.
                  </Typography>
                </Box>
        }
        else{
            return null;
    
    }
}

    // Main rendering logic
    if (dataType === "SNV_Germline" || dataType === "SNV_Somatic") {
        return operationType === "deleted"
            ? <CaButton href={`${url_phenostore}/#/summary/${index_case}`} variant="contained" target="_blank" text={"Go to Patient Summary"}/>
            : <Fragment>
                <div style={{width: "400px"}}>
                    {messagePhenoStore()}
                    {renderSNVButtons()}
                </div>
            </Fragment>
    } else if (dataType === "Treatment_Somatic") {
        return renderTreatmentMessage();
    } else if (dataType === "Pharmacogenomics_Germline") {
        return renderPharmacogenomicsMessage();
    } 
    else if(dataType === "Fusions"){
        return renderFusionMessage();
    }
    else {
        return renderDefaultMessage();
    }
};

export default AfterSubmitContent;
