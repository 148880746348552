
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import GeneticFindingsReport from "./GeneticFindingsReport.js";
import { splitSNVandCNV } from "../../../create-report/sections/genetic_findings/utils.js";
import MappedText from "../../shared-components/mapText.js";
import { useSelector } from "react-redux";
import { getParticipant } from "../../../../../../services/api/get.js";


export default function GeneticFindingsSection({ report_id, data, handleSectionUpdate, analysisType }) {

    const token = useSelector(state => state.authorization.token);
    const urlBase = window.config.apis_configuration.api_phenostore;

    const [tableData, setTableData] = useState({ snv_data: [], cnv_data: [] });
    const [dataSNV, setGeneticFindingsDataSNV] = useState([]);
    const [dataCNV, setGeneticFindingsDataCNV] = useState([]);
    const [fusionDataAll, setFusionDataAll] = useState([]);
    const [fusionDataSelected, setFusionDataSelected] = useState([]);






    useEffect(() => {
        const fetchData = async () => {
            if (!report_id) return;

            try {
                const response = await getParticipant(urlBase, token, report_id);
                const json = await response.json();

                if (analysisType === "somatic_fusion_analysis") {
                    if (Array.isArray(json.tagged_gene_fusions)) {
                        const fusionFiltered = json.tagged_gene_fusions.filter(f => f.origin === "somatic");
                        setFusionDataAll(fusionFiltered); 
                    }
                } else {

                    const allVariants = [];

                    const filterMatchOrigin = analysisType.toLowerCase().includes("somatic") ? "somatic" : "germline";
                    // SNV
                    // Filter variants by analysis type


                    if (json.genes && Array.isArray(json.genes)) {
                        json.genes.forEach(gene => {

                            if (gene.variants && Array.isArray(gene.variants)) {

                                // filtered variants by origin

                                let filtered = gene.variants.filter(variant => { return variant.origin === filterMatchOrigin });
                                filtered.forEach(variant => {
                                    variant.geneName = gene.gene.name;
                                    allVariants.push(variant);
                                });
                            }
                        });
                    }

                    // CNV
                    if (json.regions && Array.isArray(json.regions)) {
                        json.regions.forEach(region => {
                            if (region.variants && Array.isArray(region.variants)) {

                                let filtered = region.variants.filter(variant => { return variant.origin === filterMatchOrigin });
                                filtered.forEach(variant => {
                                    variant.region = region.region;
                                    allVariants.push(variant);
                                });
                            }
                        });
                    }

                    const initData = splitSNVandCNV(allVariants);
                    setTableData(initData);
                    setGeneticFindingsDataSNV(initData.snv_data);
                    setGeneticFindingsDataCNV(initData.cnv_data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [urlBase, token, report_id,]);


    const handleUpdate = (dataSelected, variantType) => {
        if (analysisType === "somatic_fusion_analysis") {
            if (variantType === "Gene Fusion") {
                setFusionDataSelected(dataSelected.rows);
                let sectionData = {
                    rows: dataSelected.rows,
                    conclusions: "",
                    recommendations: ""
                };
                handleSectionUpdate(sectionData, "Genetic_Findings");
            }
        } else {
            let updateData = [];
            if (variantType === "SNV") {
                setGeneticFindingsDataSNV(dataSelected.rows);
                updateData = [...dataSelected.rows, ...dataCNV];
            } else if (variantType === "CNV") {
                setGeneticFindingsDataCNV(dataSelected.rows);
                updateData = [...dataSNV, ...dataSelected.rows];
            }

            let sectionData = {
                rows: updateData,
                conclusions: "",
                recommendations: ""
            };
            handleSectionUpdate(sectionData, "Genetic_Findings");
        }
    };

    if (analysisType === "somatic_fusion_analysis") {
        return (
            <>
                <Typography variant="body1">
                    <MappedText text="These are the variants that were tagged in the" />{" "}
                    <strong><MappedText text="Genetic Findings" /></strong>
                    &nbsp;<MappedText text="tab of this Interpretation Request. Check all the variants that you want to display in the final report." />
                </Typography>

                <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                    <MappedText text="Gene Fusions" />
                </Typography>
                <GeneticFindingsReport
                    handleUpdate={handleUpdate}
                    data={fusionDataAll}           
                    selectedData={fusionDataSelected}
                    variant_type="Gene Fusion"
                    analysisType={analysisType}
                />
            </>
        );
    } else {
        return <>
            <Typography variant="body1">
                <MappedText text="These are the variants that were tagged in the" />{" "}
                <strong><MappedText text="Genetic Findings" /></strong>
                &nbsp;<MappedText text="tab of this Interpretation Request. Check all the variants that you want to display in the final report." />
            </Typography>


            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                <MappedText text="SNVs and InDels" />
            </Typography>
            <GeneticFindingsReport
                handleUpdate={handleUpdate}
                data={tableData.snv_data}
                variant_type="SNV"
                analysisType={analysisType}
            />
            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                <MappedText text="CNVs and SVs" />
            </Typography>
            <GeneticFindingsReport
                handleUpdate={handleUpdate}
                data={tableData.cnv_data}
                variant_type="CNV"
                analysisType={analysisType}
            />
        </>
    }

}