import Typography from "@mui/material/Typography";
import React from "react";
import { renderToStringAlt } from "./renderToString";

function ReportTypography({ selected, text }) {
    return (
        <Typography
            variant="body1"
            color={selected ? "inherit" : "textSecondary"}
        >
            {renderToStringAlt(text)}
        </Typography>
    );
}

export default ReportTypography;