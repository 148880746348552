import React from "react";
import FlagIcon from "@mui/icons-material/Flag";

// Utility function to check for matching fusions
export function matchFusion(fusion, selected) {
    const { FusionName, LeftGene, RightGene, RightBreakpoint, LeftBreakpoint } = selected;
    return (
        fusion.fusioned_genes === FusionName &&
        fusion.left_gene === LeftGene &&
        fusion.right_gene === RightGene &&
        fusion.left_breakpoint === LeftBreakpoint &&
        fusion.right_breakpoint === RightBreakpoint
    );
}

// Function to get the fusion annotation icon
export function getFusionAnnotationIcon(row, participant) {
    const { FusionName, JunctionReadCount, LeftGene, LeftBreakpoint, RightBreakpoint, RightGene  } = row.values;
  
    console.log("Check for icon");
    if (!Object.isExtensible(participant)) {
        participant = { ...participant };
      }
  
  
    // Check if tagged gene fusions array exists in participant
    if (participant.tagged_gene_fusions) {
      // Iterate over each tagged treatment
      for (const fusion of participant.tagged_gene_fusions) {
        // Check if gene_name and hgvs_p match
        if (matchFusion(fusion, { FusionName, LeftGene, RightGene, JunctionReadCount, LeftBreakpoint, RightBreakpoint })) {
          let color = fusion.causative ? "red" : "#3182bd";
          return <FlagIcon style={{ color: color }} />;
      }
      }
    }
  
    // Return null or an empty string if no match is found
    return null;
  }

// Function to find the fusion and return additional information
export function fusionsFinder(selected, participant) {

    let found = false;
    let interpretation = "";
    let variant = []

    if (!Object.isExtensible(participant)) {
        participant = { ...participant };
    }

    if (participant.tagged_gene_fusions) {
        for (const fusion of participant.tagged_gene_fusions) {
            if (matchFusion(fusion, selected)) {
                found = true;
                interpretation = fusion.interpretation;
                variant= [fusion];
                break;
            }
        }
    }

    return { found, interpretation, variant };
}