import * as RANGE_CONFIG from "../../../samples-section/config/range_slider_config";


export const standardSettings = [
    { label: 'Minimum Depth', 
         subtitle: "The minumum value configurable is 8",
        name: 'minDepth', 
        queryKey: "dp", 
        config: RANGE_CONFIG.minDepth_props, 
       
        tooltip: "Minimum read depth at variant position (greater depth increases reliability)" },
    { label: 'Minimum Genotype Quality', 
        subtitle: "The minumum value configurable is 20",
        //subtitle: "Confidence in genotype calls",
        name: 'gQ_Range', 
        queryKey: "gq", 
        config: RANGE_CONFIG.gQ_Range_props, 
        tooltip: "Minimum genotype quality (Phred-scaled) for variant position (higher values indicate higher confidence)" },
    { label: 'Alternate Allele Frequency', 
        name: 'Alternate', 
        queryKey: "ad", 
        config: { ...RANGE_CONFIG.Alternate, defaultValue: [0, 100] },
        tooltip: "Minimum and Maximum proportion of reads supporting the alternative Allele" }
];

export const controlSettings = [
    { 
        label: 'Minimum Depth', 
        name: 'minDepth', 
        queryKey: "dp_control", 
        config: RANGE_CONFIG.minDepth_props, 
        tooltip: "Minimum read depth at variant position (greater depth increases reliability)" }
];

export const tumorSettings = [
                { label: 'Minimum Depth', name: 'minDepth', 
                    queryKey: "dp", 
                    config: RANGE_CONFIG.minDepth_props, 
                    tooltip: "Minimum read depth at variant position (greater depth increases reliability)" },
                { label: 'Alternate Allele Frequency', 
                    name: 'Alternate', queryKey: "ad", 
                    config: { ...RANGE_CONFIG.Alternate, defaultValue: [0, 100] },
                     tooltip: "Minimum and Maximum proportion of reads supporting the alternative Allele" }
            ];