


const CNV_DATA_HEADER_SPLIT= [
    {
        header: "Gene Info",
        columns: [
            { nameTable: "Gene", dataSearch: "Gene_name" ,  filterType:  "none"},
        ]
    },

    {
        header: "Variant Information",
        columns: [
            { filterType: { type: "select", option: "multiple" }, filter: "multiSelect", nameTable: "Chrom", dataSearch: "SV_chrom", tooltip: "" },
            { filter: "includesNum", nameTable: "Start", dataSearch: "SV_start", tooltip: "" },
            { filter: "includesNum", nameTable: "End", dataSearch: "SV_end", tooltip: "" },
            { filter: "includesNum", nameTable: "Length", dataSearch: "SV_length", type: "number", tooltip: "Negative values displayed for the deletions" },
            { filterType: { type: "select", option: "default" }, nameTable: "SV Type", dataSearch: "SV_type", tooltip: "" },
            { filterType: { type: "select", option: "multiple" }, filter: "multiSelect", nameTable: "Copy Number", dataSearch: "Copy_number", tooltip: "" },
            { filterType: { type: "select", option: "default" }, nameTable: "CytoBand", dataSearch: "CytoBand", tooltip: "" },
            { filterType: { type: "select", option: "multiple" }, filter: "multiSelect", nameTable: "Location", dataSearch: "Location", tooltip: "" },
            // { filter: "includesNum", filterType: { type: "select", option: "multiple" }, nameTable: "Quality", dataSearch: "QC_tagg", tooltip: "" }
        ],
    },
    {
        header: "Clinical Association ",
        columns: [
            { filterType: { type: "select", option: "multiple" },
                filter: "multiSelect", nameTable: "ACMG Classific.", dataSearch: "ACMG_class",
                tooltip: "According to https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7313390/" },
            { filterType: { type: "select", option: "multiple" }, filter: "multiSelect", nameTable: "OMIM", dataSearch: "OMIM_phenotype", tooltip: "" },
            /*{
                nameTable: "AnnotSV Reported pathogenic",
                dataSearch: "P_gain_phen",
                tooltip: "Phenotype of the pathogenic genomic regions completely overlapped with the SV. Source is displayed in the tooltip: dbVarNR (dbVar), ClinVar (CLN), ClinGen (TS3).",
                accessor: (row) => {
                    if (row.P_gain_phen) {
                        return row.P_gain_phen + ", source: " + row.P_gain_source;
                    } else if (row.P_loss_phen) {
                        return row.P_loss_phen + ", source: " + row.P_loss_source;
                    } else if (row.P_ins_phen) {
                        return row.P_ins_phen + ", source: " + row.P_ins_source;
                    }
                   // return <div className={"score_NA"}>NA</div>; // Return an empty string if none of the fields exist
                }
            },*/
        ],
    },
    {
        header: "Population Frequency",
        columns: [
            {
                nameTable: "AnnotSV population AFMax",
                dataSearch: "B_gain_AFmax",
                tooltip: "Maximum allele frequency of the reported benign genomic regions completely overlapped with the SV. Source is displayed in the tooltip: gnomAD, ClinVar (CLN), ClinGen (TS40), DGV (dgv, nsv or esv), DDD, 1000 genomes (1000g), Ira M. Hall’s lab (IMH), Children’s Mercy Research Institute (CMRI).",
                accessor: (row) => {
                    if (row.B_gain_AFmax) {
                        return row.B_gain_AFmax + ", (source: " + row.B_gain_source + ")";
                    } else if (row.B_loss_AFmax) {
                        return row.B_loss_AFmax + ", (source: " + row.B_loss_source +")";
                    } else if (row.B_ins_AFmax) {
                        return row.B_ins_AFmax + ", (source: " + row.B_ins_source + ")";
                    }
                  //  return <div className={"score_NA"}>NA</div>; // Return an empty string if none of the fields exist
                }
            },
            { nameTable: "Internal Freq", dataSearch: "Internal_Freq" },
        ],
    },
    {
        header: "Additional information",
        columns: [
            { filterType: { type: "select", option: "multiple" }, filter: "multiSelect", nameTable: "Tool", dataSearch: "Tool" },
            { filterType: { type: "select", option: "multiple" }, filter: "multiSelect", nameTable: "Experiment ID", dataSearch: "Experiment_ID" },
        ],
    },
]

export default CNV_DATA_HEADER_SPLIT;