import {parse_date} from "../helpers";
import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


const permissions =  [
    {value: "user", label: "Only Visible to you"},
    {value: "group", label: "Shared with your groups"},
    {value: "public", label: "Everyone can see"}];


export const  getPermission = (study) => {


    let el = permissions.find(s => s.value === study.permission);

    let d = study.creationDate;
    let data = new Date(d);
    let date_formatted = parse_date(data);

    return  <Box>
        <Typography variant={"body1"} color={"textSecondary"}>
            {(study) ? el.label : "NA"}
        </Typography>
        {(study.owner)
            ?  <Typography variant={"subtitle1"} color={"textSecondary"}>
                You created the study on {date_formatted}
            </Typography>
            : <Typography variant={"subtitle1"} color={"textSecondary"}>
                The study was created on {date_formatted}
            </Typography>
        }
    </Box>


}