
import React from "react";
import { Box, Typography } from "@mui/material";

const HostingInfo = () => {
  return (
    <Box sx={{ m: 2, bgcolor: "background.paper" }}>
      <Typography variant="body1">
        This website is hosted at the Universitätsklinikum Schleswig-Holstein (UKSH) and maintained by the 
        Centro Nacional de Análisis Genómico (CNAG).
      </Typography>

      <Box mt={2}>
        <Typography variant="body1" fontWeight="bold">
          Centro Nacional de Análisis Genómico (CNAG)
        </Typography>
        <Typography variant="body2">c/ Baldiri Reixac, 4</Typography>
        <Typography variant="body2">Barcelona Science Park - Tower I</Typography>
        <Typography variant="body2">08028 Barcelona, Spain</Typography>
        <Typography variant="body2">Phone: +34 93 4020542</Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body1">
          Platform contact: <a href="mailto:gpap@cnag.eu">gpap@cnag.eu</a>
        </Typography>
      </Box>
    </Box>
  );
};

export default HostingInfo;
