import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Dialog, Typography, TextField, LinearProgress, IconButton, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setTable, toggleRefresh } from "../../../../../../../../reducers/slices/tables/table.slice";
import axios from "axios";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from "react-router-dom";
import MappedText from "../../../mapText";
import CaButton from "../../../../../../../../gpap-lib/components/shared-components/CaButton";

  const FileUpload = ({ setApiRefresh}) => {
  const dispatch = useDispatch();
  const { isOpenUpload, reportType } = useSelector((state) => state.generaltable); 
  const token = useSelector((state) => state.authorization.token);
  const fileInputRef = useRef();
  const { id: dir_id } = useParams();
  const [file, setFile] = useState(null);
  const [reportName, setReportName] = useState("");
  const [associatedClinicalReport, setAssociatedClinicalReport] = useState("none");
  const [availableClinicalReports, setAvailableClinicalReports] = useState([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {

    if (reportType === "patient") {
      fetchAvailableClinicalReports();
    }
  }, [reportType]);

  const fetchAvailableClinicalReports = async () => {
    try {
      const response = await axios.get(`${window.config.apis_configuration.api_nextgpap_server}/reports_by_dir/${dir_id}`, {
        headers: { 'Authorization': token }
      });
      setAvailableClinicalReports(response.data.clinical_reports);
    } catch (error) {
      console.error("Error fetching clinical reports:", error);
    }
  };

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'];
      if (!validTypes.includes(newFile.type)) {
        setErrorMessage('Unsupported file type. Supported types are .doc, .docx, .pdf, and .txt');
        return;
      }
      const maxSize = 10 * 1024 * 1024;
      if (newFile.size > maxSize) {
        setErrorMessage('File must be less than 10MB.');
        return;
      }
      setFile(newFile);
      setErrorMessage('');
    }
  };
  const resetStates = () => {
    setFile(null);
    setReportName("");
    setAssociatedClinicalReport("");
    setErrorMessage('');
    setUploadProgress(0);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("dir_id", dir_id);
      formData.append("group_owner", "admin");
      formData.append("origin", "uploaded_by_user");

      if (reportType === "clinical") {
        formData.append("clinical_report_name", reportName);
      } else {
        formData.append("patient_report_name", reportName);

        // convert "none" to ""
        let assCliniReport = (associatedClinicalReport === "none") ? "" : associatedClinicalReport;
        formData.append("associated_clinical_report", assCliniReport);

      }

      const config = {
        headers: {
          'Authorization': token,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      };

      try {
        const endpoint = reportType === "clinical" ? "/clinical_report" : "/patient_report";
        const response = await axios.post(
          `${window.config.apis_configuration.api_nextgpap_server}${endpoint}`,
          formData,
          config
        );

        setFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        resetStates();
        dispatch(setTable({ option: "isUploadFileTable", value: true }));
        setUploadSuccess(true);
        setUploadProgress(0);
        setApiRefresh(prevState => !prevState);
      } catch (error) {
        console.error("Upload error:", error);
        setErrorMessage("An error occurred during upload. Please try again.");
      }
    }
  };

  const handleCloseModal = () => {
    setFile(null);
    setReportName("");
    setAssociatedClinicalReport("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setErrorMessage('');
    dispatch(setTable({ option: "isOpenUpload", value: false }));
  };

  const handleCloseSuccessDialog = () => {
    setUploadSuccess(false);
    dispatch(toggleRefresh());
    dispatch(setTable({ option: "isOpenUpload", value: false }));
  };

  return (
    <>
      <Dialog open={isOpenUpload} onClose={handleCloseModal}>
        <Box p="15px" display="flex" flexDirection="column" gap="15px">
          <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" gutterBottom>
          <MappedText text={"Upload"} />{" "}
          <MappedText text={reportType === "patient" ? "Patient" : "Clinical"} />{" "}
          <MappedText text={"Report"} />
        </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            label={<MappedText text="Report name" />}
            variant="outlined"
            value={reportName}
            onChange={(e) => setReportName(e.target.value)}
            required 
              InputLabelProps={{ shrink: true }} 
              helperText={<MappedText text="This field is required." />} 
          />
          {reportType === "patient" && (
            <TextField
            select
            fullWidth
            label={<MappedText text="Associated Clinical Report" />}
            value={associatedClinicalReport}
            onChange={(e) => setAssociatedClinicalReport(e.target.value)}
            InputLabelProps={{ shrink: true }} // Ensure the label behaves correctly
            >
              {/* First option as None with an empty string value */}
              <MenuItem value="none">
                None
              </MenuItem>
              {/* Other options */}
            {availableClinicalReports.map((report) => (
              <MenuItem key={report.clinical_report_id} value={report.clinical_report_id}>
                {report.clinical_report_id} 
              </MenuItem>
            ))}
          </TextField>
          
          )}
          <Box display="flex" alignItems="center">
          <CaButton
                variant="contained"
                startIcon={<AttachFileIcon />}
                text="Select file"
                onClick={() => fileInputRef.current.click()}  
                onChange={handleFileChange}
            />
              <input
                type="file"
                hidden
                onChange={handleFileChange}
                ref={fileInputRef}
              />
            {file && (
              <Typography style={{ marginLeft: 8 }}>
                {file.name}
              </Typography>
            )}
          </Box>
          <Typography variant="caption">
          <div>
                <MappedText text="Supported files: .doc, .docx, .pdf, .txt" />
              </div>
              <div>
                <MappedText text="Maximum file size: 10MB" />
              </div>
          </Typography>
          {errorMessage && (
            <Typography color="error" variant="body2">
              {errorMessage}
            </Typography>
          )}
          {uploadProgress > 0 && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
              <Typography variant="body2" color="textSecondary">{`${uploadProgress}%`}</Typography>
            </Box>
          )}
          <Box display="flex" justifyContent="flex-end" gap="10px">
            <CaButton variant="outlined" onClick={handleCloseModal} text="Cancel" />
            <CaButton
              variant="contained"
              onClick={handleUpload}
              text="Upload"
              startIcon={<CloudUploadIcon />}
              //disabled={!file || !reportName || (reportType === "patient" && !associatedClinicalReport)}
              disabled={!file || !reportName  }
            />
          </Box>
        </Box>
      </Dialog>
      <Dialog open={uploadSuccess} onClose={handleCloseSuccessDialog}>
        <Box p="15px" textAlign="center">
          <Typography variant="h6" gutterBottom>
            <MappedText text="File uploaded successfully" />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseSuccessDialog}
          >
            <MappedText text="OK" />
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default FileUpload;