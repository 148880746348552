import { makeStyles } from "@mui/styles"; // Updated to @mui/styles
import React, { useState, useRef } from "react";
import { Box, Typography, IconButton } from "@mui/material"; // Updated to @mui/material
import BackupIcon from "@mui/icons-material/Backup"; // Updated to @mui/icons-material
import DoneIcon from "@mui/icons-material/Done"; // Updated to @mui/icons-material
import ClearIcon from "@mui/icons-material/Clear"; // Updated to @mui/icons-material
import CaButton from "../../../../../../../../../../../gpap-lib/components/shared-components/CaButton";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

function List_Upload(props) {
  const classes = useStyles();
  const [genes, setGenes] = useState([]);
  const fileInputRef = useRef(null);

  const handleFile = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = function () {
      let text = reader.result;
      text = text.replace(/(\r\n|\n|\r|\s*,\s*)/g, ","); // Remove line breaks and spaces
      let geneList = text.split(",").filter((s) => s !== ""); // Split by comma
      let final = geneList.filter((s) => s.trim() !== ""); // Filter empty values
      setGenes(final);
      props.getGenes(final);
    };
    reader.readAsText(file);
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const clear = () => {
    setGenes([]);
  };

  return (
    <Box>
      {genes.length === 0 ? (
        <>
          <Typography variant="body1">Here, you can upload a comma-separated list.</Typography>
          <div className={classes.root}>
            <input
              type="file"
              accept=".txt,.csv"
              ref={fileInputRef}
              className={classes.input}
              onChange={handleFile}
            />
            <CaButton
              onClick={handleUploadClick}
              variant="contained"
              color="#EAEAEA"
              text="Upload File"
              disableElevation
              component="span"
              startIcon={<BackupIcon />}
            />
          </div>
        </>
      ) : (
        <Typography variant="subtitle1">
          <DoneIcon color="primary" /> Uploaded {genes.length} genes{" "}
          <IconButton onClick={clear}>
            <ClearIcon />
          </IconButton>
        </Typography>
      )}
    </Box>
  );
}

export default List_Upload;
