
export const deutsch = {
    "Get in touch": "Sich in Verbindung setzen",
    "Thank you for using our product.": "Vielen Dank, dass Sie unser Produkt verwendet haben.",
    "Feel free to reach out if you have any questions or comments.": "Fühlen Sie sich frei zu erreichen, wenn Sie Fragen oder Kommentare haben.",
    "Data Platforms & Tools Development Team": "Datenplattformen und Tools Entwicklungsteam",
    "Barcelona, Spain": "Barcelona, ​​Spanien",
    "Phenotypic Data": "Phänotypische Daten",
    "Experiments & Files": "Experimente und Dateien",
    "Genomic Analysis": "Genomanalyse",
    "Cohort Creation": "Kohortenschöpfung",
    "Browse Phenotypic Participants": "Phänotypische Teilnehmer durchsuchen",
    "Browse Experiments & Files": "Experimente und Dateien durchsuchen",
    "Settings": "Einstellungen",
    "Interpretation Requests": "Interpretationsanfragen",
    "Docs": "Dokumente",
    "Contact": "Kontakt",
    "Bug Report Form": "Fehlerberichtsformular",
    "No variants have passed the selected filters": "Keine Varianten haben die ausgewählten Filter bestanden",
    "To display variants, try using less restrictive filters or expanding the gene list": "Um Varianten anzuzeigen, verwenden Sie weniger restriktive Filter oder erweitern Sie die Genliste",
    "Rows per page:": "Zeilen pro Seite:",
    "Column Hiding": "Säulenversteck",
    "Toggle All": "Alles umschalten",
    "Equal or less": "Gleich oder weniger",
    "Search...": "Suchen...",
    "Select": "Wählen",
    "Complete view": "Vollständige Ansicht",
    "Split by genes": "Geteilt von Genen",
    "Columns": "Spalten",
    "Create request": "Anfrage erstellen",
    "Upload report": "Bericht hochladen",
    "Create report": "Bericht erstellen",
    "Haplotypes not available": "Haplotypen nicht verfügbar",
    "Clinical evidences not available": "Klinische Beweise nicht verfügbar",
    "Gene Fusions data not available": "Genfusionsdaten nicht verfügbar",
    "Experiment ": "Experiment",
    " does not have any data available yet": "hat noch keine Daten verfügbar",
    "No interpretation requests yet": "Noch keine Auslegungsanfragen",
    "a request and it will show up here.": "Eine Anfrage und es wird hier angezeigt.",
    "No clinical reports yet": "Noch keine klinischen Berichte",
    "or upload a report and it will show up here": "Oder laden Sie einen Bericht hoch und er wird hier angezeigt",
    "No patient reports yet": "Noch keine Patientenberichte",
    "Upload a report and it will show up here": "Laden Sie einen Bericht hoch und er wird hier angezeigt",
    "Tag": "Etikett",
    "Actions": "Aktionen",
    "Access Request": "Zugangsanforderung",
    "Delete Request": "Anfrage löschen",
    "Download": "Herunterladen",
    "Upload": "Hochladen",
    "Link": "Link",
    "Download Report": "Bericht herunterladen",
    "For technical limitations of our servers, we only display the first 1000 participants for a query. Please narrow your search if needed.": "Für technische Einschränkungen unserer Server zeigen wir nur die ersten 1000 Teilnehmer für eine Frage. Bitte beschränken Sie Ihre Suche bei Bedarf.",
    "Fam.ID": "Fam.id",
    "Experiment ID": "Experiment ID",
    "Local Exp. ID": "Lokale Exp. AUSWEIS",
    "Num. of Genes": "Nummer. von Genen",
    "Project": "Projekt",
    "Affected Status": "Betroffener Status",
    "Consanguinity": "Verbraucher",
    "Sex": "Sex",
    "Solved Status": "Gelöster Status",
    "Obs. HPOs": "Obs. HPOs",
    "Diagnosis": "Diagnose",
    "Contact Owner": "Kontaktbesitzer",
    "Show the variants of the selected participant": "Zeigen Sie die Varianten des ausgewählten Teilnehmers",
    "Participant ID": "Teilnehmer -ID",
    "Owner": "Eigentümer",
    "ERNs": "Erns",
    "Life Status": "Lebensstatus",
    "# HPOs": "# HPOS",
    "Date of Birth": "Geburtsdatum",
    "Date of Death": "Todesdatum",
    "Autosomal recessive (both homozygous and compound heterozygous)": "Autosomal rezessiv (sowohl homozygote als auch zusammengesetzte heterozygote)",
    "homozygous": "homozygot",
    "compound heterozygosity": "zusammengesetzte Heterozygotie",
    "Autosomal dominant": "Autosomal dominant",
    "Autosomal dominant - de novo": "Autosomal dominant - de novo",
    "De novo": "De novo",
    "AR Consanguineous": "AR Consanguineous",
    "X-Linked Recessive ": "X-Brenner rezessiv",
    "X-Linked Recessive - Index Case Male": "X -Brenner rezessiv - Indexfall Männlich",
    "X-Linked Dominant ": "X-verknüpft dominant",
    "X-Linked Dominant - Index Case Male": "X -verknüpfter dominant - Indexfall Männlich",
    "X-Linked Dominant - Index Case Female": "X -Brenne dominant - Indexfall weiblich",
    "Custom Inheritance ": "Benutzerdefinierte Vererbung",
    "Custom Inheritance": "Benutzerdefinierte Vererbung",
    "Somatic": "Somatisch",
    "Ensembl": "Ensembl",
    "gnomAD": "Gnomad",
    "UCSC": "UCSC",
    "NCBI": "NCBI",
    "DGVa": "DGVA",
    "GWAS Central": "GWAS Central",
    "GA4GH": "Ga4gh",
    "VarSome": "Uni",
    "dbSNP": "DBSNP",
    "OMIM": "Omim",
    "Genetics Home Reference": "Genetik Home Referenz",
    "NCBI Gene Reviews": "NCBI -Gen -Bewertungen",
    "PubCaseFinder": "Pubcasefinder",
    "PubMed": "PubMed",
    "HGMD": "Hgmd",
    "Entrez": "Entrez",
    "GeneCards": "Genekards",
    "COSMIC": "Kosmisch",
    "ClinVar": "Clinvar",
    "ExAC": "Nutzungstreiber",
    "GTEx": "Gtex",
    "ATLAS": "ATLAS",
    "WikiPathways": "Wikipathways",
    "Open PHACTS": "Phakten öffnen",
    "DisGeNET": "Disgenet",
    "Gene Name": "Genname",
    "Transcript ID": "Transkript -ID",
    "Effect Impact": "Auswirkungen auf den Einfluss",
    "Consequence": "Folge",
    "Feature Type": "Feature -Typ",
    "HGVS coding": "HGVS -Codierung",
    "Human Splicing Finder": "Menschlicher Spleißfinder",
    "Amino Acid change": "Aminosäureveränderung",
    "Exon Rank": "Exon Rang",
    "Transcript BioType": "Transkriptbiotyp",
    "CADD Pred": "Cadd Pred",
    "Revel Pred": "Revel Pred",
    "SplicaAI Pred": "Splicaai Pred",
    "SIFT PRED": "Sift Pred",
    "SIFT SCORE": "SIFT -Score",
    "POLYPHEN2 Pred": "POLYPHEN 2 Vorhersage",
    "POLYPHEN Score": "Polyphen Score",
    "Mutation Taster": "Mutation Taster",
    "InterVar": "Intervar",
    "MT Score": "MT -Score",
    "phyloP": "Phylop",
    "GERP++": "GERP ++",
    "SiPhy": "Siphy",
    "OCScore": "OCSCORE",
    "onco_classifier": "Onco_Classifier",
    "Study Name": "Studienname",
    "Description": "Beschreibung",
    "Created on": "Erstellt auf",
    "Created by": "Erstellt von",
    "Permission": "Erlaubnis",
    "Gene": "Gen",
    "Gene ID": "Gene id",
    "Gene coding": "Gencodierung",
    "Functional class": "Funktionsklasse",
    "Aminoacid length": "Aminoacid Länge",
    "Aminoacid": "Aminoacid",
    "Impact": "Auswirkungen",
    "Change": "Ändern",
    "Nucleotide": "Nukleotid",
    "Transcript": "Transkript",
    "GT": "Gt",
    "MANE": "MÄHNE",
    "Clin.Sign.": "Clin.Sign.",
    "Criteria": "Kriterien",
    "ACMG evidences": "ACMG zeigt",
    "ACMG classification": "ACMG -Klassifizierung",
    "Internal": "Intern",
    "GnomAD": "Gnomad",
    "CADD": "Cadd",
    "Revel": "Revel",
    "SpliceAI": "Spliceai",
    "SIFT": "SIEBEN",
    "Polyphen2.": "Polyphen 2.",
    "MT": "Mt",
    "Intervar Evidence": "Intervar -Beweise",
    "Intervar Autom.": "Intervar automat.",
    "# Exp.": "# Exp.",
    "Polyphen2": "Polyphen 2",
    "Onco evidences": "Onco -Beweise",
    "Classification": "Einstufung",
    "Source": "Quelle",
    "Evidence Level??": "Evidenzniveau ??",
    "The initial count of SNVs identified during the first execution of this query.": "Die anfängliche Anzahl von SNVs, die während der ersten Ausführung dieser Abfrage identifiziert wurden.",
    "This query did not return any variants": "Diese Abfrage gab keine Varianten zurück",
    "No Associations": "Keine Assoziationen",
    "We are working to integrate more resources in this section": "Wir arbeiten daran, mehr Ressourcen in diesen Abschnitt zu integrieren",
    "Pathways": "Wege",
    "HmtDB - Human Mitochondrial Database": "HMTDB - menschliche mitochondriale Datenbank",
    "Human Pluripotent Stem Cell Registry": "Menschliches pluripotentes Stammzellregister",
    "Pathways Reactome": "Wege reagieren",
    "Reactome is a free, open-source, curated and peer-reviewed pathway database": "Reactome ist eine freie, offene, kuratierte und von Experten begutachtete Pathway-Datenbank",
    "URL": "URL",
    "Disease": "Krankheit",
    "Cell Line Name": "Zelllinienname",
    "Cell Line": "Zelllinie",
    "Provider": "Anbieter",
    "# variants grouped by chromosome": "# Varianten nach Chromosom gruppiert",
    "External Databases": "Externe Datenbanken",
    "No gene selected": "Kein Gen ausgewählt",
    "Genome build: GRCh37 / hg19": "Genombau: GRCH37 / HG19",
    "Genome build: GRCh38 / hg38": "Genombau: GRCH38 / HG38",
    "External Links": "Externe Links",
    "No variants selected. Please select a variant to display the links": "Keine Varianten ausgewählt. Bitte wählen Sie eine Variante aus, um die Links anzuzeigen",
    "No variants selected": "Keine Varianten ausgewählt",
    "No variant selected": "Keine Variante ausgewählt",
    "Select one to use the resources": "Wählen Sie eine aus, um die Ressourcen zu verwenden",
    "New Analysis": "Neue Analyse",
    "Query": "Abfrage",
    "Current work": "Aktuelle Arbeit",
    "Participants": "Teilnehmer",
    "Save": "Speichern",
    "Not Analyzed": "Nicht analysiert",
    "Solved": "Gelöst",
    "Unsolved": "Ungelöst",
    "Ongoing Analysis": "Laufende Analyse",
    "Under segregation/ functional studies": "Unter Segregation/ Funktionsstudien",
    "Heterozygous variant found in AR disorder": "Heterozygote Variante bei AR -Störung",
    "Unaffected Relative": "Nicht betroffener Verwandter",
    "Other": "Andere",
    "select": "wählen",
    "Lists applied:": "Listen angewendet:",
    "Gene Filtering": "Genfilterung",
    "Positions": "Positionen",
    "Variant Effect": "Varianteneffekt",
    "Genotype Features": "Genotypmerkmale",
    "ClinVar Classes": "Clinvar -Klassen",
    "Mane": "Mähne",
    "Onco Filter": "Onco -Filter",
    "PolyPhen-2 Pred.": "Polyphen-2 Pred.",
    "GnomAD Filter": "Gnomad -Filter",
    "Programs Filter": "Programme Filter",
    "Variant Consequences": "Variante Konsequenzen",
    "Used filter-set": "Gebrauchter Filterset",
    "Applied Filters": "Angewandte Filter",
    "Tag variants": "Tag -Varianten",
    "There is no query selected. Or applied filters are not visible.": "Es wird keine Abfrage ausgewählt. Oder angewandte Filter sind nicht sichtbar.",
    "Filters": "Filter",
    "Variants distributions - (based on max 3000 entries)": "Variantenverteilungen - (basierend auf max. 3000 Einträge)",
    "Export Variants (max.1000)": "Exportvarianten (max.1000)",
    "Once selected, click again to unapply": "Klicken Sie nach der Auswahl erneut zu Unapply",
    "Label": "Etikett",
    "Important": "Wichtig",
    "Follow-Up": "Nachverfolgen",
    "Discarded": "Weggeworfen",
    "Candidate": "Kandidat",
    "List of Integrated Plugins": "Liste der integrierten Plugins",
    "Please, give a name to your study to save it correctly. ": "Bitte geben Sie Ihrem Studium einen Namen, um es richtig zu sparen.",
    "You have unsaved queries": "Sie haben nicht gerettete Fragen",
    "Save current Study with Analyses and queries": "Sparen Sie die aktuelle Studie mit Analysen und Abfragen",
    "You are not the creator of the study": "Du bist nicht der Schöpfer der Studie",
    "Study title. Max 50 chars": "Studientitel. Max 50 Chars",
    "Analyses without queries will not be saved": "Analysen ohne Anfragen werden nicht gespeichert",
    "Update Study Information": "Studieninformationen aktualisieren",
    "Save Study": "Studium sparen",
    "Fill in some information to save your work.": "Füllen Sie einige Informationen ein, um Ihre Arbeit zu speichern.",
    "Study description. Max 50 chars": "Studienbeschreibung. Max 50 Chars",
    "Please select study visibility": "Bitte wählen Sie Sichtbarkeit der Studie",
    "Private": "Privat",
    "Share with your groups": "Teilen Sie mit Ihren Gruppen",
    "Everyone can see": "Jeder kann sehen",
    "Cancel": "Stornieren",
    "Save changes": "Änderungen speichern",
    "Share the study with collaborators": "Teilen Sie die Studie mit Mitarbeitern",
    "Pathogenic": "Pathogen",
    "Likely pathogenic": "Wahrscheinlich pathogen",
    "Uncertain significance": "Ungewisse Bedeutung",
    "Likely benign": "Wahrscheinlich gutartig",
    "Benign": "Gutartig",
    "Drug response": "Drogenreaktion",
    "Association": "Verein",
    "Risk factor": "Risikofaktor",
    "Protective": "Schutz",
    "Oncogenic": "Onkogen",
    "Likely oncogenic": "Wahrscheinlich onkogen",
    "Autosomal recessive": "Autosomal rezessiv",
    "X-linked": "X-Brunnen",
    "X-linked dominant": "X-verknüpft dominant",
    "X-linked recessive": "X-Brenner rezessiv",
    "Y-linked inheritance": "Y-verknüpfte Erbschaft",
    "Mitochondrial inheritance": "Mitochondrienerbschaft",
    "Oligogenic inheritance": "Oligogener Erbschaft",
    "Digenic inheritance": "Digenische Erbschaft",
    "Autosomal dominant with paternal imprinting": "Autosomal dominant mit väterlichem Prägen",
    "Autosomal dominant with maternal imprinting": "Autosomal dominant mit mütterlichen Prägen",
    "Unknown": "Unbekannt",
    "Heterozygous": "Heterozygot",
    "Homozygous": "Homozygot",
    "Hemizygous": "Hemizygous",
    "Primary disorder": "Primärstörung",
    "Secondary findings": "Sekundäre Erkenntnisse",
    "Pharmacogenomics": "Pharmakogenomik",
    "Polygenic risk score": "Polygener Risikobewertung",
    "SNV-InDel": "Snv-Indel",
    "Duplication": "Vervielfältigung",
    "Deletion": "Streichung",
    "Inversion": "Inversion",
    "Short tandem repeat": "Kurze Tandem -Wiederholung",
    "Translocation": "Translokation",
    "Insertion": "Einfügen",
    "germline": "Keimbahn",
    "somatic": "somatisch",
    "de novo": "de novo",
    "maternal": "mütterlich",
    "paternal": "väterlich",
    "inherited": "geerbt",
    "unknown": "unbekannt",
    "gene fusion": "Genfusion",
    "none": "keiner",
    "Gene Fusion": "Genfusion",
    "You also deleted the variant from PhenoStore. If you did it by mistake, please tag again.": "Sie haben auch die Variante von Phenostore gelöscht. Wenn Sie es versehentlich getan haben, markieren Sie bitte noch einmal.",
    "Go to participant summary": "Gehen Sie zur Zusammenfassung der Teilnehmer",
    "Click here if you want to edit/delete your CNV tag": "Klicken Sie hier, wenn Sie Ihr CNV -Tag bearbeiten/löschen möchten",
    "Click here if you want to see a summary of the data stored in PhenoStore for the tagged participant": "Klicken Sie hier, wenn Sie eine Zusammenfassung der in Phenostore gespeicherten Daten für den markierten Teilnehmer sehen möchten",
    "Report": "Bericht",
    " module. Editing this tag will modify the interpretation comment.": "Modul. Das Bearbeiten dieses Tags ändert den Interpretationskommentar.",
    "Interpretation": "Interpretation",
    "Tag deleted": "Tag gelöscht",
    "Tag submitted": "Tag eingereicht",
    "Tag updated": "Tag aktualisiert",
    "CNV previously tagged": "CNV zuvor getaggt",
    "Tag CNV Variant": "Tag CNV -Variante",
    "Required": "Erforderlich",
    "When you TAG a variant, the tag will be visible to all users of the platform regardless of the embargo period": "Wenn Sie eine Variante markieren, ist das Tag für alle Benutzer der Plattform unabhängig von der Embargo -Zeit sichtbar",
    "Sample": "Probe",
    "Related to": "Im Zusammenhang mit",
    "Type of variant": "Art der Variante",
    "Inheritance": "Nachlass",
    "Origin": "Herkunft",
    "Zygosity": "Zygotie",
    "Clinical Significance": "Klinische Bedeutung",
    "Is this the Causative Variant?": "Ist das die ursächliche Variante?",
    "Causative Variant (confirmed as the cause of the primary disorder)": "Ursächliche Variante (als Ursache der primären Störung bestätigt)",
    "Fusioned genes": "Fusionierte Gene",
    "Tag variants in the GPAP database": "Tag -Varianten in der GPAP -Datenbank",
    "Delete": "Löschen",
    "Variant not tagged yet": "Variante noch nicht markiert",
    "Create Query": "Abfrage erstellen",
    "New Query": "Neue Abfrage",
    "Apply your own filters": "Wenden Sie Ihre eigenen Filter an",
    "The filters selected for SNV-InDels do not automatically apply to CNVs and SVs except gene lists. You can further filter the CNVs directly in the table": "Die für SNV-Indels ausgewählten Filter gelten nicht automatisch für CNVs und SVs außer Genlisten. Sie können die CNVs direkt in der Tabelle filtern",
    "The annotation is done with periodic OMIM updates through an internal API built with the same rationale as the OMIM one,": "Die Annotation erfolgt mit periodischen OMIM -Updates über eine interne API, die mit derselben Begründung wie der Omim -One erstellt wurde.",
    "Clinical significance available for exonic SNVs and based on InterVar,": "Klinische Bedeutung für exonische SNVs verfügbar und basierend auf Intervar,",
    "Click on a gene or experiment in the left panel, or select a participant from the table above to load variants.": "Klicken Sie auf ein Gen oder Experiment im linken Feld oder wählen Sie einen Teilnehmer aus der obigen Tabelle aus, um Varianten zu laden.",
    "No variants have passed the selected filters.": "Keine Varianten haben die ausgewählten Filter übergeben.",
    "The displayed number is the total sum of events in the FULL and SPLIT results.": "Die angezeigte Zahl ist die Gesamtsumme der Ereignisse in den vollständigen und geteilten Ergebnissen.",
    "SNV and InDels": "SNV und Indels",
    "Note that the number of rows shown in the variants table can be higher than the number of genomic variants shown in the counter since one genomic variant can affect more than one gene.": "Beachten Sie, dass die Anzahl der in der Varianten -Tabelle gezeigten Zeilen höher sein kann als die Anzahl der im Zähler gezeigten genomischen Varianten, da eine genomische Variante mehr als ein Gen beeinflussen kann.",
    "Gene Info": "Geninfo",
    "Variant Information": "Varianteninformationen",
    "Chrom": "Chrom",
    "Start": "Start",
    "End": "Ende",
    "Length": "Länge",
    "SV Type": "SV -Typ",
    "Copy Number": "Kopiernummer",
    "CytoBand": "Cytoband",
    "Translocation_ID     ": "Translocation_id",
    "Quality": "Qualität",
    "Clinical Association": "Klinische Vereinigung",
    "ACMG Classific.": "ACMG Classific.",
    "AnnotSV Reported pathogenic": "Annotesv berichtete überpathogen",
    "P_gain_phen": "P_gain_phen",
    "P_loss_phen": "P_loss_phen",
    "P_ins_phen": "P_ins_phen",
    "Population Frequency": "Bevölkerungsfrequenz",
    "B_gain_AFmax": "B_gain_afmax",
    "B_loss_AFmax": "B_loss_afmax",
    "B_ins_AFmax": "B_INS_AFMAX",
    "Albert AnnotSV populaton AFMax ": "Albert Annotesv Populaton Afmax",
    "Internal Freq.": "Interner Freq.",
    "Exact match": "Genaue Übereinstimmung",
    "Additional Information": "Weitere Informationen",
    "Tool": "Werkzeug",
    "Translocation_ID": "Translocation_id",
    "Internal Freq. Count": "Interner Freq. Zählen",
    "Location": "Standort",
    "Clinical Association ": "Klinische Vereinigung",
    "AnnotSV population AFMax": "AnnotesV -Bevölkerung AFMAX",
    "Internal Freq": "Interner Freq",
    "Additional information": "Weitere Informationen",
    "Previous Tags": "Vorherige Tags",
    "Type": "Typ",
    "Status": "Status",
    "Significance": "Bedeutung",
    "Date": "Datum",
    "Comments": "Kommentare",
    "Your Tag": "Dein Tag",
    "Samples Information not available": "Beispielinformationen nicht verfügbar",
    "IGV Browser": "IGV -Browser",
    "Predictors": "Prädiktoren",
    "Genotypes": "Genotypen",
    "indel": "Indel",
    "ref": "Ref",
    "alt": "Alt",
    "pos": "pos",
    "Population": "Bevölkerung",
    "freqIntGermline": "freqintgermline",
    "gnomad_an_popmax": "gnomad_an_popmax",
    "gnomad_an": "gnomad_an",
    "freqIntGermlineNum": "freqintgerminenum",
    "freqIntGermlineDem": "freqintgermlinedem",
    "gnomad_ac": "gnomad_ac",
    "gnomad_af": "gnomad_af",
    "gnomad_nhomalt": "gnomad_nhomalt",
    "gnomad_ac_popmax": "gnomad_ac_popmax",
    "gnomad_af_popmax": "gnomad_af_popmax",
    "spliceai_dp_al": "spliceai_dp_al",
    "sift_score": "SIFT_SCORE",
    "mm_poly_ac": "mm_poly_ac",
    "gnomad_faf95_popmax": "gnomad_faf95_popmax",
    "spliceai_ds_ag": "spliceai_ds_ag",
    "spliceai_dp_dg": "spliceai_dp_dg",
    "spliceai_max_ds": "spliceai_max_ds",
    "spliceai_ds_al": "spliceai_ds_al",
    "spliceai_ds_dl": "spliceai_ds_dl",
    "spliceai_dp_ag": "spliceai_dp_ag",
    "spliceai_dp_dl": "spliceai_dp_dl",
    "spliceai_ds_dg": "spliceai_ds_dg",
    "revel_score": "avevel_score",
    "vep_proc_id.block_idx": "vep_proc_id.block_idx",
    "mt": "mt",
    "mm_dis_disease": "MM_DIS_DISEASE",
    "intervar_automated": "Intervar_automated",
    "mm_dis_disease_status": "MM_DIS_DISEASE_STATUS",
    "vep_proc_id.part_idx": "VEP_PROC_ID.PART_IDX",
    "intervar_evidence": "Intervar_evidence",
    "mm_poly_af": "mm_poly_af",
    "Clearing filters": "Filter löschen",
    "Use a predefined filter set": "Verwenden Sie einen vordefinierten Filtersatz",
    "Variant Type": "Variantentyp",
    "SNV Effect Predictions": "SNV -Effekt -Vorhersagen",
    "Position Specific and Runs Of Homozygosity": "Positionsspezifisch und läuft von Homozygotie",
    "Create/Adjust Filters": "Filter erstellen/einstellen",
    "Filter by variants": "Filter durch Varianten",
    "Gene Filters": "Genfilter",
    "Filter by genes": "Filter durch Gene",
    "Predefined lists": "Vordefinierte Listen",
    "Select a Predefined Gene List prepared from ERNs or the GPAP team": "Wählen Sie eine vordefinierte Genliste aus, die von ERNS oder dem GPAP -Team erstellt wurde",
    "Gene search and upload": "Gensuche und Upload",
    "Enter multiple gene symbols using HGNC nomenclature or Upload your own": "Geben Sie mehrere Gensymbole mithilfe der HGNC -Nomenklatur ein oder laden Sie Ihre eigenen hoch",
    "WARNING: This platform uses annotations from Ensembl ": "WARNUNG: Diese Plattform verwendet Anmerkungen aus Ensembl",
    ". Any Gene Symbol used for filtering which does not match the symbol used in Ensembl v109 may be missed or wrongly assigned. This also applies to external web-services. We are currently working to mitigate this issue.": ". Jedes zum Filterung verwendete Gensymbol, das nicht mit dem in Ensembl V109 verwendeten Symbol übereinstimmt, kann übersehen oder falsch zugeordnet werden. Dies gilt auch für externe Webservices. Wir arbeiten derzeit daran, dieses Problem zu mildern.",
    "Diseases": "Krankheiten",
    "Select one or more OMIM terms or a PanelApp to query the associated genes": "Wählen Sie einen oder mehrere OMIM -Begriffe oder ein PanelApp aus, um die zugehörigen Gene abzufragen",
    "Symptoms": "Symptome",
    "Collect the HPOs from the index case or search more HPOs to query the associated genes": "Sammeln Sie die HPOs aus dem Indexfall oder suchen Sie mehr HPOs, um die zugehörigen Gene abzufragen",
    "Pathways are retrieved from REACTOME and associations with genes from The Comparative Toxicogenomics database": "Wege werden aus Reaktom und Assoziationen mit Genen aus der vergleichenden toxikogenomischen Datenbank abgerufen",
    "Clear gene list": "Klare Genliste",
    "Operator": "Operator",
    "Union": "Union",
    "Intersection": "Überschneidung",
    "Upload comma separated list of HGNC identifiers": "Überladen von Kommas getrennter Liste der HGNC -Identifikatoren",
    "(The file is not stored in GPAP)": "(Die Datei wird nicht in GPAP gespeichert)",
    "Genes not validated": "Gene nicht validiert",
    "Current selected genes by applying ": "Strom ausgewählte Gene durch Anwendung",
    " of gene lists": "von Genlisten",
    "No genes selected": "Keine Gene ausgewählt",
    "All gene symbols were validated using Ensembl": "Alle Gensymbole wurden mit Ensembl validiert",
    " gene symbols could not be validated using ": "Gensymbole konnten nicht validiert werden",
    "Validating gene symbols..": "Validierung von Gensymbolen ..",
    "Gene Validation": "Genvalidierung",
    "Back": "Zurück",
    "Intersect the genes extracted from the HPOs ": "Schneiden Sie die aus den HPOs extrahierten Gene",
    "Make union of extracted genes (default)": "Machen Sie die Vereinigung extrahierter Gene (Standard)",
    "You included the genes in your query. You made an ": "Sie haben die Gene in Ihre Anfrage aufgenommen. Du hast an gemacht",
    " between the HPO genes": "zwischen den HPO -Genen",
    "No genes shared across all HPOs": "Keine Gene, die über alle HPOs teilnehmen",
    "No Genes": "Keine Gene",
    "Clear current HPO selection": "Clear Clear Clear HPO -Auswahl",
    "HPOs collected from analysed index case": "HPOs, die aus dem analysierten Indexfall gesammelt wurden",
    "Get HPOs from Participants": "Holen Sie sich HPOs von den Teilnehmern",
    "search for HPO terms": "Suche nach HPO -Begriffen",
    "You have now": "Du hast jetzt",
    "HPO terms selected": "HPO -Begriffe ausgewählt",
    "Select Resource:": "Wählen Sie Ressource:",
    "Select Method:": "Methode auswählen:",
    "of genes in this section is applied ": "von Genen in diesem Abschnitt wird angewendet",
    "In the next step, you can extract genes from HPOs and add them to your queries": "Im nächsten Schritt können Sie Gene aus HPOs extrahieren und zu Ihren Anfragen hinzufügen",
    "Select a predefined gene list": "Wählen Sie eine vordefinierte Genliste aus",
    "search for OMIM terms": "Suche nach Omim -Begriffen",
    "Delete OMIM associated genes": "Omim -assoziierte Gene löschen",
    "Enter multiple OMIM ids or names of disorders": "Geben Sie mehrere OMIM -IDs oder Namen von Störungen ein",
    "Search for OMIM terms": "Suche nach Omim -Begriffen",
    "Request Error": "Anforderungsfehler",
    "Error": "Fehler",
    "Loading genes...": "Ladegene ...",
    "Panel ": "Panel",
    "includes a total of ": "beinhaltet insgesamt insgesamt",
    " entities. There are ": "Entitäten. Es gibt",
    " distinct genes in the ": "unterschiedliche Gene in der",
    " green list": "Grüne Liste",
    "Read more of this panel in PanelApp": "Lesen Sie mehr von diesem Panel in PanelApp",
    "Delete PanelApp genes from query": "PanelApp -Gene aus der Abfrage löschen",
    "PanelApp genes added to the query": "PanelApp -Gene zur Abfrage hinzugefügt",
    "Add Panel App Genes": "Panel -App -Gene hinzufügen",
    "Type of Panel": "Art des Panels",
    "Genes associated to": "Gene mit",
    "No genes": "Keine Gene",
    "Search Pathways": "Suchwege",
    "Delete Pathway associated genes": "Löschen von den assoziierten Genen des Weges",
    "Search for Pathways": "Suche nach Wegen",
    "Gene Search": "Gensuche",
    "search for a gene name": "Suche nach einem Gennamen",
    "Upload File": "Datei hochladen",
    "A number between 0-1": "Eine Zahl zwischen 0-1 und",
    "INTERNAL FREQUENCY": "Interne Frequenz",
    "Clear Filters": "Klare Filter",
    "PASS + unannotated (NA)": "Pass + nicht annotiert (NA)",
    "non-PASS": "Nicht-Pass",
    "Selected Positions": "Ausgewählte Positionen",
    "X": "X",
    "The X chromosome has been automatically selected to match the selected inheritance": "Das X -Chromosom wurde automatisch ausgewählt, um dem ausgewählten Vererbung übereinzustimmen",
    "Info": "Info",
    "Select a chromosome. Positions are considered ONLY if a chromosome is selected": "Wählen Sie ein Chromosom aus. Positionen werden nur berücksichtigt, wenn ein Chromosom ausgewählt wird",
    "Chromosome": "Chromosom",
    "Start Position": "Startposition",
    "Chromosome required": "Chromosom erforderlich",
    "End Position": "Endposition",
    "Upload BED file": "Bettdatei hochladen",
    "Upload Coordinate file": "Die Koordinatendatei hochladen",
    "When applied, will return only variants that fall within a run of homozygosity of at least the length selected in the first affected sample": "Bei der Anwendung kehrt nur Varianten zurück, die mindestens der in der ersten betroffenen Probe ausgewählten Länge in eine Homozygotie fallen",
    "Minimum run of homozygosity length": "Mindestlänge der Homozygotie",
    "Not Specified": "Nicht angegeben",
    "Annotated as disease causing": "Annotiert als Krankheit verursacht",
    "Disease causing": "Krankheit verursacht",
    "Polymorphism": "Polymorphismus",
    "Neutral": "Neutral",
    "Damaging": "Schädlich",
    "Tolerated": "Toleriert",
    "Probably damaging": "Wahrscheinlich schädlich",
    "Possibly damaging": "Möglicherweise schädlich",
    " Likely Oncogenic": "Wahrscheinlich onkogen",
    " VUS": "Vus",
    " Benign": "Gutartig",
    " Likely Benign": "Wahrscheinlich gutartig",
    "Likely Benign": "Wahrscheinlich gutartig",
    " Pathogenic": "Pathogen",
    " Likely Pathogenic": "Wahrscheinlich pathogen",
    "0": "0",
    "20": "20",
    "40": "40",
    "60": "60",
    "80": "80",
    "100": "100",
    "Phred-scaled CADD score, indicating level of deleteriousness e.g. 20 (predicted top 1% most deleterious)": "PHRED-scaled CADD-Score, was auf Grad des Distanzs hinweist, z. 20 (vorhergesagte Top 1% der schädlichsten)",
    "CADD Prediction": "CADD -Vorhersage",
    "High": "Hoch",
    "Moderate": "Mäßig",
    "Low": "Niedrig",
    "Modifier": "Modifikator",
    "transcript_ablation": "Transcript_ablation",
    "splice_acceptor_variant": "splice_acceptor_variant",
    "splice_donor_variant": "splice_donor_variant",
    "stop_gained": "stop_gained",
    "frameshift_variant": "Frameshift_variant",
    "stop_lost": "STOP_LOST",
    "start_lost": "start_lost",
    "transcript_amplification": "Transcript_Amplification",
    "feature_elongation": "feature_elongation",
    "feature_truncation": "feature_truncation",
    "inframe_insertion": "inframe_insertion",
    "inframe_deletion": "inframe_deletion",
    "missense_variant": "MISSENSENS_VARIANT",
    "protein_altering_variant": "protein_altering_variant",
    "splice_donor_5th_base_variant": "splice_donor_5th_base_variant",
    "splice_region_variant": "splice_region_variant",
    "splice_donor_region_variant": "splice_donor_region_variant",
    "splice_polypyrimidine_tract_variant": "splice_polypyrimidine_tract_variant",
    "incomplete_terminal_codon_variant": "Incomplete_terminal_codon_variant",
    "start_retained_variant": "start_retained_variant",
    "stop_retained_variant": "STOP_RETAINED_VARIANT",
    "synonymous_variant": "synonymous_variant",
    "coding_sequence_variant": "coding_sequence_variant",
    "mature_miRNA_variant": "mature_mirna_variant",
    "5_prime_UTR_variant": "5_prime_UTR_VARIANT",
    "3_prime_UTR_variant": "3_prime_UTR_VARIANT",
    "non_coding_transcript_exon_variant": "Non_Coding_Transcript_exon_variant",
    "intron_variant": "Intron_variant",
    "NMD_transcript_variant": "Nmd_transcript_variant",
    "non_coding_transcript_variant": "Non_Coding_Transcript_variant",
    "coding_transcript_variant": "coding_transcript_variant",
    "upstream_gene_variant": "upstream_gene_variant",
    "downstream_gene_variant": "Downstream_gene_variant",
    "TFBS_ablation": "Tfbs_ablation",
    "TFBS_amplification": "Tfbs_amplification",
    "TF_binding_site_variant": "Tf_binding_sit_variant",
    "regulatory_region_ablation": "Regulatory_region_ablation",
    "regulatory_region_amplification": "Regulatory_region_amplification",
    "regulatory_region_variant": "regulatorisch_region_variant",
    "intergenic_variant": "intergenic_variant",
    "sequence_variant": "sequence_variant",
    "SNV": "Snv",
    "INDEL": "Indel",
    "Mane Select": "Mähne ausgewählt",
    "Selected samples": "Ausgewählte Proben",
    "Any Sample": "Jede Probe",
    "Variant of uncertain significance": "Variante von unsicherer Bedeutung",
    "Conflicting interpretations": "Widersprüchliche Interpretationen",
    "Any": "Beliebig",
    "Coding": "Codierung",
    "RNA": "RNA",
    "Known": "Bekannt",
    "Predicted driver: Tier 1": "Vorhergesagter Treiber: Tier 1",
    "Predicted driver: Tier 2": "Vorhergesagter Treiber: Tier 2",
    "Predicted passenger": "Vorhergesagter Passagier",
    "The filters have changed": "Die Filter haben sich geändert",
    "GPAP Predefined Filters are curated by the GPAP team": "Vordefinierte GPAP -Filter werden vom GPAP -Team kuratiert",
    "Select a predefined filter set": "Wählen Sie einen vordefinierten Filtersatz aus",
    "Filter Set deleted": "Filterset gelöscht",
    "No leading and trailing spaces are allowed": "Es sind keine führenden und nachfolgenden Räume erlaubt",
    "Name already exists.": "Name existiert bereits.",
    "Filter Panel": "Filterpanel",
    "Filter Name": "Filtername",
    "Filter Description": "Filterbeschreibung",
    "filter_description": "filter_description",
    "Save Filter": "Filter speichern",
    "Filters saved in the database. You can find the filter-set in the section below.": "Filter in der Datenbank gespeichert. Sie finden die Filtereinstellung im Abschnitt unten.",
    "Filter Set Saved Correctly": "Filtersatz korrekt gespeichert",
    "Create New Analysis": "Neue Analyse erstellen",
    "Next": "Nächste",
    "Genotype Settings": "Genotypeinstellungen",
    "Apply": "Anwenden",
    "You have created ": "Sie haben erstellt",
    " analyses.": "Analysen.",
    "You can now create a query that will be applied to the genotype(s). Select your filters and run the new query.": "Sie können jetzt eine Abfrage erstellen, die auf den Genotyp (en) angewendet wird. Wählen Sie Ihre Filter aus und führen Sie die neue Abfrage aus.",
    "Run Query": "Abfrage führen",
    "Apply to current Samples": "Bewerben Sie sich für aktuelle Proben",
    "or start from new Samples:": "Oder beginnen Sie mit neuen Proben:",
    "custom": "Brauch",
    "Autosomal Dominant": "Autosomal dominant",
    "Autosomal Recessive Homozygous": "Autosomal rezessiv homozygot",
    "Autosomal Recessive Compound Heteroz.": "Autosomal rezessive Verbindung Heteroz.",
    "De Novo": "De novo",
    "Deleterious": "Schädlich",
    "tolerated": "toleriert",
    "Disease_causing_automatic": "Disease_causing_automatic",
    "Disease_causing": "Disease_cauthusing",
    "Probably harmless": "Wahrscheinlich harmlos",
    "Known to be harmless": "Bekannt als harmlos",
    "Oncegenic": "Einmalgen",
    "Likely Oncogenic": "Wahrscheinlich onkogen",
    "VUS": "Vus",
    "Likely Pathogenic": "Wahrscheinlich pathogen",
    "Variants distributions": "Variantenverteilungen",
    "GnomAD Population": "Gnomad -Bevölkerung",
    "OncoEvidences": "Oncoevidences",
    "There are 0 onco evidences": "Es gibt 0 Onco -Beweise",
    "How this score is calculated?": "Wie wird diese Punktzahl berechnet?",
    "Filter Sets": "Filtersätze",
    "Predefined Gene Lists": "Vordefinierte Genlisten",
    "Analysis Settings": "Analyseeinstellungen",
    "Analysis Parameters": "Analyseparameter",
    "Standard Pipelines": "Standardpipelines",
    "Preview External Resources": "Vorschau externe Ressourcen",
    "Filter Set Manager": "Filterset Manager",
    "Create and Save a new Filter Set": "Erstellen und speichern Sie einen neuen Filtersatz",
    "Close": "Schließen",
    "Available Filter Sets": "Verfügbare Filtersätze",
    "The filters shown below include both built-in filters and those you have created.": "Die unten gezeigten Filter enthalten sowohl eingebaute Filter als auch die von Ihnen erstellten.",
    "No description": "Keine Beschreibung",
    "View filters": "Filter anzeigen",
    "Applied filters:": "Angewandte Filter:",
    "No filters found": "Keine Filter gefunden",
    "List of genes": "Liste der Gene",
    "Search:": "Suchen:",
    "No results": "Keine Ergebnisse",
    "This list contains": "Diese Liste enthält",
    "genes": "Gene",
    "Create a new List": "Erstellen Sie eine neue Liste",
    "List Information": "Informationen auflisten",
    "List Title": "Title auflisten",
    "List Source": "Quelle auflisten",
    "Notes": "Notizen",
    "Gene List": "Genliste",
    "Variant Positions": "Variantenpositionen",
    "Visibility": "Sichtweite",
    "Public": "Öffentlich",
    "List Visibility": "Sichtbarkeit auflisten",
    "When active, the list will be usable for analyses. If this list has the same name as an existing one, the previous one will become inactive to users.": "Wenn die Liste aktiv ist, kann die Liste für Analysen verwendet werden. Wenn diese Liste den gleichen Namen wie eine vorhanden ist, wird die vorherige für Benutzer inaktiv.",
    "Active": "Aktiv",
    "Upload a List": "Laden Sie eine Liste hoch",
    "View Lists": "Listen anzeigen",
    "File type": "Dateityp",
    "Please select file type": "Bitte wählen Sie Dateityp",
    "GPAP Gene File": "GPAP -Gendatei",
    ".list File": ".List -Datei",
    "Comma Separated List": "Komma getrennte Liste",
    "ACTIVE": "AKTIV",
    "Not Active": "Nicht aktiv",
    "Predefined Gene List Info": "Vordefinierte Genlisteninformationen",
    "Predefined Lists in GPAP": "Vordefinierte Listen in GPAP",
    "Visible List": "Sichtbare Liste",
    "Are you sure to delete the Predefined Gene List?": "Sind Sie sicher, die vordefinierte Genliste zu löschen?",
    "Gene list was validated": "Die Genliste wurde validiert",
    "Some genes could not be validated. Please check them and re-upload the file:": "Einige Gene konnten nicht validiert werden. Bitte überprüfen Sie sie und laden Sie die Datei erneut an:",
    "Please check the text": "Bitte überprüfen Sie den Text",
    "Genes are not in the correct format, please check the text": "Gene sind nicht im richtigen Format, bitte überprüfen Sie den Text",
    "Fields should not be empty, please check the text": "Felder sollten nicht leer sein, bitte überprüfen Sie den Text",
    "Gene list is empty. Please check the file.": "Die Genliste ist leer. Bitte überprüfen Sie die Datei.",
    "Cancel Gene List Upload": "Abbrechen der Genliste Upload",
    "Upload a GPAP gene file in txt format": "Laden Sie eine GPAP -Gendatei im TXT -Format hoch",
    "Be sure that the file is in the correct format and all required fields are present": "Stellen Sie sicher, dass sich die Datei im richtigen Format befindet und alle erforderlichen Felder vorhanden sind",
    "Select file": "Datei auswählen",
    "Download our template file and fill it in with your data": "Laden Sie unsere Vorlagendatei herunter und füllen Sie sie mit Ihren Daten aus",
    "Download sample file": "Beispieldatei herunterladen",
    "Validating genes..": "Gene validieren ..",
    "Upload a .list file": "Laden Sie eine .list -Datei hoch hoch",
    "The .list file is the legacy file format used to store predefined gene lists in the GPAP. This upload is meant for CNAG users. For more information, contact Steve Laurie.": "Die .List -Datei ist das Legacy -Dateiformat, mit dem vordefinierte Genlisten im GPAP gespeichert werden. Dieser Upload ist für CNAG -Benutzer gedacht. Weitere Informationen erhalten Sie von Steve Laurie.",
    "The file must contain a JSON array of Predefined Gene List Objects.": "Die Datei muss ein JSON -Array von vordefinierten Genlistenobjekten enthalten.",
    "upload .list file": "Datei hochladen",
    "lists parsed correctly and ready to be saved.": "Listet ordnungsgemäß analysiert und bereit, gerettet zu werden.",
    "Save Lists": "Listen speichern",
    "Preview of External resources accessible in this instance:": "Vorschau von externen Ressourcen in dieser Instanz zugänglich:",
    "SNV external resources": "SNV externe Ressourcen",
    "CNV external resources": "CNV externe Ressourcen",
    "Edit": "Bearbeiten",
    "Shaping Genomic Futures. GPAP + is your one-stop-shop platform for cutting-edge genomics services. The CNAG is committed to providing top-tier genomic analysis, interpretation and reporting, to meet your needs.": "Genomische Futures formen. GPAP + ist Ihre One-Stop-Shop-Plattform für modernste Genomikdienste. Der CNAG ist bestrebt, eine erstklassige genomische Analyse, Interpretation und Berichterstattung bereitzustellen, um Ihre Bedürfnisse zu erfüllen.",
    "GPAP+ in a nutshell": "GPAP+ Kinne",
    "Genome Analysis": "Genomanalyse",
    "State-of-the art standardised bioinformatics analysis workflows for the analysis of NGS data within a user-friendly interface.": "Standode-the Art-standardisierte Bioinformatikanalyse-Workflows für die Analyse von NGS-Daten innerhalb einer benutzerfreundlichen Schnittstelle.",
    "Interpretation and Reporting": "Interpretation und Berichterstattung",
    "User friendly interpretation of integrated genomic, phenotypic and clinical information to facilitate molecular diagnosis and reporting.": "Benutzerfreundliche Interpretation integrierter genomischer, phänotypischer und klinischer Informationen zur Erleichterung der molekularen Diagnose und Berichterstattung.",
    "Pipeline and process validated with reference materials. GPAP+ includes QC checkpoints and informative reports, metrics and figures.": "Pipeline und Prozess mit Referenzmaterialien validiert. GPAP+ enthält QC -Kontrollpunkte und informative Berichte, Metriken und Abbildungen.",
    "Flexibility and Scalability": "Flexibilität und Skalierbarkeit",
    "GPAP+ is built upon open standards and scalable technologies. It can be deployed On Cloud, As a Service, and On Premises, allowing customisation and integration within a broader workflow.": "GPAP+ basiert auf offenen Standards und skalierbaren Technologien. Es kann in Cloud, als Dienst und vor Ort eingesetzt werden, um die Anpassung und Integration in einen breiteren Workflow zu ermöglichen.",
    "Know us a bit more!": "Kenne uns ein bisschen mehr!",
    "GPAP+ is a development from the RD-Connect GPAP and the Cancer GPAP, both developed by the CNAG. The platform facilitates advanced genome analysis for the diagnosis of rare diseases, cancer, and other conditions. With over 15 years of experience in clinical research projects, the CNAG has processed and uploaded to GPAP clinical research instances data from 35,000+ individuals submitted by 35+ hospitals and research centers across Europe.": "GPAP+ ist eine Entwicklung aus dem RD-Connect-GPAP und dem Cancer GPAP, die beide vom CNAG entwickelt wurden. Die Plattform erleichtert die fortgeschrittene Genomanalyse für die Diagnose seltener Krankheiten, Krebs und anderer Erkrankungen. Mit über 15 Jahren Erfahrung in klinischen Forschungsprojekten hat der CNAG Daten von über 35.000 Personen, die von mehr als 35 Krankenhäusern und Forschungszentren in ganz Europa eingereicht wurden, verarbeitet und in GPAP -Daten von über 35.000 Personen hochgeladen.",
    "Contact us": "Kontaktieren Sie uns",
    "Developed by": "Entwickelt von",
    "With support from": "Mit Unterstützung von",
    "The RD-Connect Genome-Phenome Analysis Platform has received funding from EU projects RD-Connect, Solve-RD and EJP-RD (grant numbers FP7 305444, H2020 779257, H2020 825575), Instituto de Salud Carlos III (Grant numbers PT13/0001/0044, PT17/0009/0019; Instituto Nacional de Bioinformática, INB), ELIXIR-EXCELERATE (Grant number EU H2020 #676559) and several ELIXIR Implementation Studies. The Cancer GPAP has received funding from contracts signed through the EU INSTAND-NGS4P project (grant number 874719).": "Die RD-Connect-Genom-Phenom-Analyse-Plattform hat von EU Projects RD-Connect, Solve-RD und EJP-RD (Zuschussnummern FP7 305444, H2020 779257, H2020 82575), Instituto de Salud Carlos III (Grant Nummern PT13/0001/0001/0001/0001/0001/0001) Mittel erhalten. /0044, PT17/0009/0019; Instituto Nacional de Bioinformática, INB), Elixir-Excelerate (Zuschussnummer EU H2020 #676559) und mehrere Elixier-Implementierungsstudien. Die Krebs-GPAP hat Finanzmittel aus Verträgen erhalten, die über das EU INTAST-NGS4P-Projekt (Zuschussnummer 874719) unterzeichnet wurden.",
    "© CNAG 2024. All rights reserved.": "© CNAG 2024. Alle Rechte vorbehalten.",
    "A comprehensive state-of-the-art platform for NGS cancer data analysis, interpretation and reporting": "Eine umfassende hochmoderne Plattform für die Datenanalyse, Interpretation und Berichterstattung von NGS-Krebskrebs",
    "Our solutions": "Unsere Lösungen",
    "Cancer Analysis": "Krebsanalyse",
    "Bioinformatic analysis of NGS data with standardized workflows for the comprehensive identification of germline variants and somatic mutations.": "Bioinformatische Analyse von NGS -Daten mit standardisierten Workflows zur umfassenden Identifizierung von Keimbahnvarianten und somatischen Mutationen.",
    "Access Platform": "Zugangsplattform",
    "Cancer Reporting": "Krebsberichterstattung",
    "Integration of NGS results, e-medication data, and clinical evidence for therapy decision making.": "Integration von NGS-Ergebnissen, E-Medikationsdaten und klinischen Erkenntnissen für die Entscheidungsfindung von Therapie.",
    "We value your feedback!": "Wir schätzen Ihr Feedback!",
    "Your experience matters to us. Help us improve by filling out our quick feedback form. What do you love? What can we do better? Let us know! Thank you!": "Ihre Erfahrung ist für uns wichtig. Helfen Sie uns, sich zu verbessern, indem Sie unser schnelles Feedback -Formular ausfüllen. Was liebst du? Was können wir besser machen? Lass es uns wissen! Danke schön!",
    "Cancer Analysis Feedback Form": "Feedback -Formular für Krebsanalyse",
    "Cancer Reporting Feedback Form": "Feedback -Formular zur Krebsberichterstattung",
    "Instand-NGS4P Partners": "INTAND-NGS4P-Partner",
    "DIR LIST": "Dir List",
    "Case ID": "Fall ID",
    "Patient ID": "Patient ID",
    "Analysis Type": "Analyseart",
    "Priority": "Priorität",
    "Deadline": "Frist",
    "Assignee(s)": "Abtretung (en)",
    "Creation Date": "Schöpfungsdatum",
    "Tumor Experiment": "Tumorexperiment",
    "Control Experiment": "Kontrollversuch",
    "Clinical referrer": "Klinischer Überweiser",
    "Clinical contact": "Klinischer Kontakt",
    "Hospital Name": "Krankenhausname",
    "Tumor characteristics": "Tumoreigenschaften",
    "Tumor purity": "Tumorreinheit",
    "Average ploidy": "Durchschnittliche Ploidie",
    "Biomarkers of genetic instability": "Biomarker der genetischen Instabilität",
    "Microsatellite status": "Mikrosatellitenstatus",
    "Tumor mutational burden": "Tumor -Mutationsbelastung",
    "Homologous recombination deficiency (HRD) status": "Homologer Rekombinationsmangel (HRD) Status",
    "Bin-level log2 coverages and segmentation calls": "Bin-Level-Log2-Abdeckungen und Segmentierungsaufrufe",
    "Source: CNVkit": "Quelle: cnvkit",
    "Copy number per chromosome": "Kopienzahl pro Chromosom",
    "Tumor Sample ID:": "Tumorproben -ID:",
    "Mutational signatures": "Mutationssignaturen",
    "Source: SigProfilerAssignment": "Quelle: SigProfilerAssignment",
    "No control experiment id for tumor experiment id: ": "Keine Kontrollexperiment -ID für Tumorexperiment ID:",
    "Create Interpretation Request": "Interpretationsanforderung erstellen",
    "Analysis ": "Analyse",
    "Assignee": "Beauftragter",
    "The users who will handle the interpretation of this request. Only these users can edit the request. If no users are selected, everyone in the organization can edit.": "Die Benutzer, die die Interpretation dieser Anfrage bewältigen. Nur diese Benutzer können die Anfrage bearbeiten. Wenn keine Benutzer ausgewählt sind, kann jeder in der Organisation bearbeiten.",
    "Clinical Referrer": "Klinischer Überweiser",
    "Clinical Referrer Contact": "Klinischer Überweisungskontakt",
    "Hospital name": "Krankenhausname",
    "Select Priority": "Wählen Sie Priorität",
    "Medium": "Medium",
    "Group Owner": "Gruppenbesitzer",
    "Filter Set": "Filterset",
    "Select Filter Set": "Wählen Sie den Filtersatz",
    "Select Gene List": "Wählen Sie die Genliste",
    "Create": "Erstellen",
    "Search Experiment ID": "Such -Experiment -ID",
    "Search Participant ID": "Suchteilnehmer -ID",
    "Tumor Experiment ID": "Tumorexperiment ID",
    "Experiment and Sample Info": "Experimentier- und Beispielinformationen",
    "Submitter ID": "Einsender -ID",
    "Library Strategy": "Bibliotheksstrategie",
    "Kit": "Kit",
    "Sample ID": "Beispiel -ID",
    "Sample tissue of origin": "Probengewebe des Ursprungs",
    "Sample tissue of origin status": "Probengewebe des Ursprungsstatus",
    "Sample type": "Probentyp",
    "Percentage of tumoral cells": "Prozentsatz der Tumorzellen",
    "Fixative": "Fixativ",
    "Experiments": "Experimente",
    "Search by Experiment ID": "Suche nach Experiment -ID",
    "Search by Patient ID": "Suche nach Patienten -ID",
    "Associate Experiment IDs to the request.": "Associate Experiment IDs mit der Anfrage assoziieren.",
    "Only experiments that have been processed and are ready for analysis are visible.": "Es sind nur Experimente, die verarbeitet und zur Analyse bereit sind, sichtbar.",
    "Patient and Clinical Info": "Patienten- und klinische Informationen",
    "Clinical referral": "Klinische Überweisung",
    "Observed Symptoms": "Beobachtete Symptome",
    "Cancer Type": "Krebsart",
    "Case number pathology": "Fallnummer Pathologie",
    "Treatment history": "Behandlungsgeschichte",
    "Treatment response": "Behandlungsreaktion",
    "New Instand DIR Study": "Neue INSAL DIR -Studie",
    "Request Overview": "Übersicht über Anfrage",
    "Germline Genetic Findings": "Genetische Erkenntnisse der Keimbahn",
    "Reports": "Berichte",
    "Tumor Genetic Overview": "Genetische Tumorübersicht",
    "Somatic Genetic Findings": "Somatische genetische Befunde",
    "Clinical Evidence": "Klinische Beweise",
    "Gene Fusions": "Genfusionen",
    "Request": "Anfrage",
    "To Do": "Zu tun",
    "In Progress": "Im Gange",
    "Done": "Erledigt",
    "Somatic fusion analysis": "Somatische Fusionsanalyse",
    "Somatic tumor only": "Nur somatischer Tumor",
    "Somatic tumor normal": "Somatischer Tumor normal",
    "Germline": "Keimbahn",
    "Male": "Männlich",
    "Female": "Weiblich",
    "No information provided": "Keine Informationen zur Verfügung gestellt",
    "General Info": "Allgemeine Info",
    "Request ID": "Anfrage ID",
    "Administrative Info": "Verwaltungsinformationen",
    "Creator": "Schöpfer",
    "Creation date": "Schöpfungsdatum",
    "Clinical referrer contact info": "Kontaktinformationen klinischer Überweiser",
    "Update Filters": "Filter aktualisieren",
    "Patient Info": "Patienteninformationen",
    "Clinical Info": "Klinische Informationen",
    "Diagnostic date": "Diagnosedatum",
    "Clinical diagnosis": "Klinische Diagnose",
    "Treatment history and response": "Behandlungsanamnese und Reaktion",
    "Cancer type": "Krebsart",
    "Tumor Sample and Experiment Info": "Tumorprobe und Experimentinformationen",
    "Sample Info": "Beispielinformationen",
    "Sample fixation": "Probenfixierung",
    "Sample site of origin": "Probenortstelle der Herkunftsstelle",
    "Sample site of origin status": "Beispielstelle des Ursprungsstatus",
    "Purity": "Reinheit",
    "Experiment Info": "Experimentinfo",
    "Library strategy": "Bibliotheksstrategie",
    "Sample and Experiment Info": "Beispiel- und Experimentinformationen",
    "Relevant Observations": "Relevante Beobachtungen",
    "Sample quality observations": "Probenqualitätsbeobachtungen",
    "Sequencing quality observations": "Sequenzierungsqualitätsbeobachtungen",
    "Fusion": "Fusion",
    "Junction read count": "Junction Read Count",
    "Left Gene": "Links Gene",
    "Left gene": "Links Gene",
    "Left breakpoint": "Linker Bruchpunkt",
    "Right Gene": "Richtiges Gen",
    "Right gene": "Richtiges Gen",
    "Right breakpoint": "Rechts Breakpoint",
    "These are all the gene fusions our system calculated. They’re not affected by the request’s filters or gene list.": "Dies sind alle Genfusionen, die unser System berechnet hat. Sie sind nicht von den Filtern oder Genliste der Anfrage betroffen.",
    "These are all the clinical evidences our system calculated. They’re not affected by the request’s filters or gene list.": "Dies sind alle klinischen Beweise für unser System berechnet. Sie sind nicht von den Filtern oder Genliste der Anfrage betroffen.",
    "Germline SNV and InDels": "Keimbahn -SNV und Indels",
    "1 genomic variant": "1 Genomvariante",
    "genomic variants": "Genomische Varianten",
    "The displayed number is the total sum of events in the Complete view and Split by genes results": "Die angezeigte Zahl ist die Gesamtsumme der Ereignisse in der vollständigen Ansicht und ist durch Geneergebnisse aufgeteilt",
    "SNVs and InDels - Somatic": "SNVs und Indels - somatisch",
    "SNVs and InDels - Germline": "SNVs und Indels - Keimbahn",
    "CNVs and SVs": "CNVs und SVs",
    "Gene Evidence": "Gene Beweise",
    "Genotype": "Genotyp",
    "Phenotype": "Phänotyp",
    "Haplotype1": "Haplotyp1",
    "Haplotype1 Evidence": "Haplotyp1 Evidenz",
    "Haplotype2": "Haplotyp2",
    "Haplotype2 Evidence": "Haplotyp2 Evidenz",
    "Variant Data": "Variantendaten",
    "These are all the haplotypes our system calculated. They’re not affected by the request’s filters or gene list.": "Dies sind alle Haplotypen, die unser System berechnet hat. Sie sind nicht von den Filtern oder Genliste der Anfrage betroffen.",
    "Clinical Reports": "Klinische Berichte",
    "Patient Reports": "Patientenberichte",
    "Clinical reports": "Klinische Berichte",
    "Report ID": "Berichtsausweis",
    "Report Name": "Berichtsname",
    "Genomic breakpoints detected": "Genomische Haltepunkte nachgewiesen",
    "Clinically relevant gene": "Klinisch relevantes Gen",
    "Clinical significance**": "Klinische Bedeutung **",
    "cDNA": "cDNA",
    "Protein": "Protein",
    "Associated Clinical Report ID": "Assoziierte klinische Berichts -ID",
    "ANNEXES": "Anhänge",
    "The following annexes complete the report, which should be reviewed as a whole.": "Die folgenden Anhänge vervollständigen den Bericht, der insgesamt überprüft werden sollte.",
    "ANNEX I: CLINICAL RECORD": "Anhang I: Klinische Aufzeichnung",
    "ANNEX II: METHODOLOGY": "Anhang II: Methodik",
    "ANNEX III: QUALITY CONTROL METRICS": "Anhang III: Qualitätskontrollmetriken",
    "ANNEX IV: VARIANT METRICS AND GENOTYPE-PHENOTYPE ASSOCIATIONS": "Anhang IV: Variantenmetriken und Genotyp-Phänotyp-Assoziationen",
    "ANNEX V: PHARMACOGENOMICS": "Anhang V: Pharmakogenomik",
    "Clinical symptoms and physical findings": "Klinische Symptome und körperliche Befunde",
    "Family history": "Familiengeschichte",
    "Test method": "Testmethode",
    "Type of variants assessed": "Art der Varianten bewertet",
    "Sample received": "Probe empfangen",
    "Date of sample collection": "Datum der Stichprobensammlung",
    "Sample processing:": "Probenverarbeitung:",
    "Center": "Center",
    "Sequencing platform": "Sequenzierungsplattform",
    "Fragment (size and type)": "Fragment (Größe und Typ)",
    "Bioinformatics analysis:": "Bioinformatikanalyse:",
    "Human reference genome": "Menschliches Referenzgenom",
    "Aligner": "Aligner",
    "Mark duplicates": "Mark Duplikate",
    "Base recalibration": "Basis -Neukalibrierung",
    "Smart variants caller": "Smart Varianten Anrufer",
    "Analysis pipeline": "Analysepipeline",
    "Genotype-phenotype associations": "Genotyp-Phänotyp-Assoziationen",
    "No data available": "Keine Daten verfügbar",
    "Coverage of the genes of interest (RefSeq coding regions)": "Berichterstattung über die Gene von interessierten Genen (RefSeq -Codierungsregionen)",
    "All genes have a C10 of 90 or more, except for:": "Alle Gene haben einen C10 von 90 oder mehr, mit Ausnahme von:",
    "Genome coverage metrics": "Genomabdeckungsmetriken",
    "No haplotypes have been detected": "Es wurden keine Haplotypen festgestellt",
    "Variant metrics": "Variantenmetriken",
    "No additional information provided.": "Keine zusätzlichen Informationen zur Verfügung gestellt.",
    "Haplotype Interpretation": "Haplotyp -Interpretation",
    "Treatment": "Behandlung",
    "Tumour mutation load": "Tumormutationsbelastung",
    "Tumour mutational burden": "Tumor -Mutationsbelastung",
    "Homologous Recombination Deficiency": "Homologer Rekombinationsmangel",
    "Mutational profiles": "Mutationsprofile",
    "Molecular tissue of origin prediction": "Molekulargewebe der Herkunftsvorhersage",
    "Tumor specific viral insertions": "Tumorspezifische virale Insertionen",
    "Sample condition": "Probenbedingung",
    "Site of tumour": "Ort des Tumors",
    "Treatment given before sample was taken": "Behandlung, die vor der Probe genommen wurde",
    "Sample information": "Beispielinformationen",
    "DNA purity": "DNA -Reinheit",
    "DNA integrity": "DNA -Integrität",
    "Sample processing": "Probenverarbeitung",
    "Bioinformatics analysis": "Bioinformatikanalyse",
    "Exome coverage metrics": "Exome Deckungsmetriken",
    "C10/C15: percentage of bases covered by at least 10 and 15 reads, respectively.": "C10/C15: Prozentsatz der von mindestens 10 bzw. 15 Lesevorgängen abgedeckten Basen.",
    "MOLECULAR GENETIC REPORT": "Molekularer genetischer Bericht",
    "Page ": "Seite",
    " of ": "von",
    "No additional information provided": "Keine zusätzlichen Informationen zur Verfügung gestellt",
    "CLINICAL EVIDENCE AND PATIENT MANAGEMENT": "Klinische Beweise und Patientenmanagement",
    "Only information relevant to the specific type of cancer under investigation is reported.": "Es werden nur Informationen gemeldet, die für die spezifische Krebsart relevant sind.",
    "Conclusion": "Abschluss",
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to ClinGen, CGC and VICC recommendations (Horak et al 2022) and the Human Variation Variant Society (HGVS) ": "Die klinische Bedeutung genetischer Varianten wird unter Verwendung der derzeit verfügbaren Informationen interpretiert und Änderungen unterzogen, wenn wissenschaftliches Wissen erweitert wird. Genomische Varianten werden gemäß den Empfehlungen von Clingen, CGC und VICC (Horak et al. 2022) und der menschlichen Variationsvarianten (HGVs) klassifiziert und berichtet.",
    "Only FDA or EMA approved treatments are reported. The conclusion of this report is based solely on the results of the DNA sequencing of the tumour and the received tumour type. Final interpretation of the clinical consequence of this report should therefore always be performed by the treating physician.": "Es werden nur FDA- oder EMA -zugelassene Behandlungen gemeldet. Die Schlussfolgerung dieses Berichts basiert ausschließlich auf den Ergebnissen der DNA -Sequenzierung des Tumors und des empfangenen Tumortyps. Die endgültige Interpretation der klinischen Folge dieses Berichts sollte daher immer vom behandelnden Arzt durchgeführt werden.",
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to the American College of Medical Genetics (ACMG) recommendations (Richards et al. 2015, Riggs et al. 2020), ClinGen recommendations (Welcome to ClinGen ) and the Human Variation Variant Society (HGVS) ": "Die klinische Bedeutung genetischer Varianten wird unter Verwendung der derzeit verfügbaren Informationen interpretiert und Änderungen unterzogen, wenn wissenschaftliches Wissen erweitert wird. Genomische Varianten werden gemäß den Empfehlungen des American College of Medical Genetics (ACMG) (Richards et al. 2015, Riggs et al. 2020), Cleingenempfehlungen (Willkommen bei Clingen) und der Human Variationsvariante (HGVs) klassifiziert und berichtet.",
    "Genetic counselling and recommendations are based on GeneReviews guidelines (Margaret et al 1993-2020).": "Genetische Beratung und Empfehlungen basieren auf Genereviews-Richtlinien (Margaret et al. 1993-2020).",
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. The conclusion of this report is based solely on the results of the RNA sequencing of the tumour and the received tumour type. Final interpretation of the clinical consequence of this report should therefore always be performed by the treating physician.": "Die klinische Bedeutung genetischer Varianten wird unter Verwendung der derzeit verfügbaren Informationen interpretiert und Änderungen unterzogen, wenn wissenschaftliches Wissen erweitert wird. Die Schlussfolgerung dieses Berichts basiert ausschließlich auf den Ergebnissen der RNA -Sequenzierung des Tumors und des empfangenen Tumortyps. Die endgültige Interpretation der klinischen Folge dieses Berichts sollte daher immer vom behandelnden Arzt durchgeführt werden.",
    "DISCLAIMER": "HAFTUNGSAUSSCHLUSS",
    "Issuing centre": "Ausstellungszentrum",
    "Requesting centre": "Anfrage in Zentrum",
    "Reporting date": "Berichtsdatum",
    "Referrer": "Überweisung",
    "Name": "Name",
    "Surname": "Nachname",
    "Biopsy location": "Biopsie",
    "REASON FOR REFERRAL": "Grund zur Überweisung",
    "PERFORMED STUDY": "Durchgeführte Studie",
    "TEST PERFORMED (Library strategy)": "Test durchgeführt (Bibliotheksstrategie)",
    "GENETIC FINDINGS": "Genetische Befunde",
    "*Based upon reference genome version GRCh38": "*Basierend auf Referenzgenomversion GRCH38",
    "*Based upon reference genome version GRCh38.": "*Basierend auf Referenzgenomversion GRCH38.",
    "***This column includes a range of genes. Because of the size of it, the platform can’t display them all in the exported report but you can review them in the Genetic Findings view.": "*** Diese Spalte enthält eine Reihe von Genen. Aufgrund der Größe kann die Plattform nicht alle im exportierten Bericht anzeigen, aber Sie können sie in der Sichtweise genetischer Befunde überprüfen.",
    "Genomic Position:": "Genomische Position:",
    "Left and Right Breakpoint:": "Linker und rechter Breakpoint:",
    "INTERPRETATION": "INTERPRETATION",
    "Recommendation": "Empfehlung",
    "REFERENCES": "Referenzen",
    "Genetic Findings": "Genetische Befunde",
    "Clinical Evidence and Patient Management": "Klinische Beweise und Patientenmanagement",
    "Disclaimer": "Haftungsausschluss",
    "Technical Limitations": "Technische Einschränkungen",
    "References": "Referenzen",
    "Annexes": "Anhänge",
    "New Clinical Report": "Neuer klinischer Bericht",
    "Welcome to the Somatic Clinical Report creation screen. In here you’ll be able to create and export a molecular genetic report document with the information you’ve reviewed and processed for this interpretation request. Read, review and complete each section and, when you’re done, click on the Create button to export your report.": "Willkommen auf dem somatischen Bildschirm für klinische Bericht. Hier können Sie ein molekulares genetisches Berichtsdokument mit den Informationen erstellen und exportieren, die Sie für diese Interpretationsanforderung überprüft und verarbeitet haben. Lesen, überprüfen und vervollständigen Sie jeden Abschnitt und klicken Sie, wenn Sie fertig sind, auf die Schaltfläche Erstellen, um Ihren Bericht zu exportieren.",
    "Welcome to the Germline Clinical Report creation screen. In here you’ll be able to create and export a molecular genetic report document with the information you’ve reviewed and processed for this interpretation request. Read, review and complete each section and, when you’re done, click on the Create button to export your report.": "Willkommen auf dem Bildschirm zur Erstellung von Keimbahnberichten. Hier können Sie ein molekulares genetisches Berichtsdokument mit den Informationen erstellen und exportieren, die Sie für diese Interpretationsanforderung überprüft und verarbeitet haben. Lesen, überprüfen und vervollständigen Sie jeden Abschnitt und klicken Sie, wenn Sie fertig sind, auf die Schaltfläche Erstellen, um Ihren Bericht zu exportieren.",
    "Preview": "Vorschau",
    "Annex 5: Pharmacogenomics": "Anhang 5: Pharmakogenomik",
    "These are the haplotypes that were tagged in the Pharmacogenomics tab of this Interpretation Request.Check all the haplotypes that you want to display in the final report.": "Dies sind die Haplotypen, die auf der Registerkarte Pharmakogenomik dieser Interpretationsanforderung markiert wurden. Überprüfen Sie alle Haplotypen, die Sie im Abschlussbericht anzeigen möchten.",
    "These are the interpretation comments for the selected haplotypes.": "Dies sind die Interpretationskommentare für die ausgewählten Haplotypen.",
    "The original tag comment will not be modified here. Your modifications will be visible only in the exported report.": "Der Original -Tag -Kommentar wird hier nicht geändert. Ihre Änderungen sind nur im exportierten Bericht sichtbar.",
    "No genes were targeted": "Es wurden keine Gene gezielt",
    "Annex 1: Clinical record": "Anhang 1: Klinische Aufzeichnung",
    "Annex 2: Methodology": "Anhang 2: Methodik",
    "Annex 3: Quality control metrics": "Anhang 3: Qualitätskontrollmetriken",
    "The fields in this section have to be filled in manually after downloading the document. Automatic synchronisation coming soon.": "Die Felder in diesem Abschnitt müssen nach dem Herunterladen des Dokuments manuell ausgefüllt werden. Automatische Synchronisation kommt bald.",
    "List of diagnostically targeted genes": "Liste der diagnostisch gezielten Gene",
    "Coverage of the genes of interest (RefSeq coding sections)": "Abdeckung der interessierenden Gene (RefSeq -Codierungsabschnitte)",
    "Annex 4: Variant metrics and genotype-phenotype associations": "Anhang 4: Variantenmetriken und Genotyp-Phänotyp-Assoziationen",
    "Gene Symbol": "Gensymbol",
    "Mean Coverage": "Mittlere Berichterstattung",
    "Median Coverage": "Mittlere Berichterstattung",
    "C10": "C10",
    "C15": "C15",
    "C20": "C20",
    "Gene MIM Number": "Gene MIM -Nummer",
    "Phenotype MIM Number": "Phenotyp -MIM -Nummer",
    "Transcript cDNA": "Transkript -cDNA",
    "GQ": "GQ",
    "DP": "Dp",
    "REF count": "Ref Count",
    "ALT count": "Alt",
    "AAF": "AAF",
    "Genome Coverage Metrics": "Genomabdeckungsmetriken",
    "Exome Coverage Metrics": "Exome Deckungsmetriken",
    "Panel Coverage Metrics": "Panel -Abdeckungsmetriken",
    "Coverage Metrics": "Abdeckungsmetriken",
    "Unavailable coverage data": "Nicht verfügbare Abdeckungsdaten",
    "Annex 2: Tumor genomic characteristics": "Anhang 2: Genomische Tumoreigenschaften",
    "Annex 3: Other biomarkers": "Anhang 3: Andere Biomarker",
    "Annex 4: Methodology": "Anhang 4: Methodik",
    "Annex 5: Quality control metrics": "Anhang 5: Qualitätskontrollmetriken",
    "Duplication Rate ": "Duplizierungsrate",
    "Uniformity": "Gleichmäßigkeit",
    "Gene symbol": "Gensymbol",
    "Mean coverage": "Mittlere Berichterstattung",
    "C1": "C1",
    "These are the evidences that were tagged in the": "Dies sind die Beweise, die in der markiert waren",
    "tab of this Interpretation Request.": "Registerkarte dieser Interpretationsanforderung.",
    "Check all the evidences that you want to display in the final report.": "Überprüfen Sie alle Beweise, die Sie im Abschlussbericht anzeigen möchten.",
    "The": "Der",
    " Clinical Significance": "Klinische Bedeutung",
    "field shows the comments that were added when tagging an evidence.": "Das Feld zeigt die Kommentare, die beim Markieren eines Beweises hinzugefügt wurden.",
    "The original tag comment ": "Der Original -Tag -Kommentar",
    "will not": "wird nicht",
    " be modified here. Your modifications will only be visible in the exported report.": "hier geändert werden. Ihre Änderungen sind im exportierten Bericht nur sichtbar.",
    "These are the interpretation comments that were written in each evidence tag.": "Dies sind die Interpretationskommentare, die in jedem Evidenz -Tag geschrieben wurden.",
    "Variant": "Variante",
    "Therapy": "Therapie",
    "Comment": "Kommentar",
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to the American College of Medical Genetics (ACMG) recommendations (Richards et al. 2015, Riggs et al. 2020), ClinGen recommendations (Welcome to ClinGen ) and the Human Variation Variant Society (HGVS)": "Die klinische Bedeutung genetischer Varianten wird unter Verwendung der derzeit verfügbaren Informationen interpretiert und Änderungen unterzogen, wenn wissenschaftliches Wissen erweitert wird. Genomische Varianten werden gemäß den Empfehlungen des American College of Medical Genetics (ACMG) (Richards et al. 2015, Riggs et al. 2020), Cleingenempfehlungen (Willkommen bei Clingen) und der Human Variationsvariante (HGVs) klassifiziert und berichtet.",
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to ClinGen, CGC and VICC recommendations (Horak et al 2022) and the Human Variation Variant Society (HGVS)": "Die klinische Bedeutung genetischer Varianten wird unter Verwendung der derzeit verfügbaren Informationen interpretiert und Änderungen unterzogen, wenn wissenschaftliches Wissen erweitert wird. Genomische Varianten werden gemäß den Empfehlungen von Clingen, CGC und VICC (Horak et al. 2022) und der menschlichen Variationsvarianten (HGVs) klassifiziert und berichtet.",
    "Sample Type": "Probentyp",
    "Biopsy Location": "Biopsie",
    "Reason for referral": "Grund zur Überweisung",
    "Performed Study": "Durchgeführte Studie",
    "e.g. Molecular testing to determine management including treatment options.": "z.B. Molekulare Tests zur Bestimmung des Managements einschließlich der Behandlungsoptionen.",
    "Test performed (Library strategy)": "Test durchgeführt (Bibliotheksstrategie)",
    "**Based upon ClinGen, CGC and VICC recommendations (Horak et al. 2022)": "** Basierend auf den Empfehlungen von Clingen, CGC und VICC (Horak et al. 2022)",
    "**Based upon ACMG and ClinGen recommendations (Richards et al. 2015 and  ": "** Basierend auf den Empfehlungen von ACMG und Clingen (Richards et al. 2015 und",
    "These are the variants that were tagged in the": "Dies sind die Varianten, die in der markiert waren",
    "tab of this Interpretation Request. Check all the variants that you want to display in the final report.": "Registerkarte dieser Interpretationsanforderung. Überprüfen Sie alle Varianten, die Sie im Abschlussbericht anzeigen möchten.",
    "SNVs and InDels": "SNVs und Indels",
    "Genomic breakpoints detected*": "Genomische Haltepunkte erkannt*",
    "Included gene(s)": "Eingeschlossene Gen (en)",
    "Clinical significance*": "Klinische Bedeutung*",
    "Genomic position*": "Genomische Position*",
    "These are the interpretation comments that were written in each variant tag.": "Dies sind die Interpretationskommentare, die in jedem Varianten -Tag geschrieben wurden.",
    "The original tag comment will not be modified here. Your modifications will only be visible in the exported report.": "Der Original -Tag -Kommentar wird hier nicht geändert. Ihre Änderungen sind im exportierten Bericht nur sichtbar.",
    "Recommendations": "Empfehlungen",
    "Horak P, Griffith M, Danos AM, et al. Standards for the classification of pathogenicity of somatic variants in cancer (oncogenicity): Joint recommendations of Clinical Genome Resource (ClinGen), Cancer Genomics Consortium (CGC), and Variant Interpretation for Cancer Consortium (VICC) [published correction appears in Genet Med. 2022 Sep;24(9):1991]. Genet Med. 2022;24(5):986-998.": "Horak P., Griffith M., Danos AM, et al. Standards für die Klassifizierung der Pathogenität somatischer Varianten in Krebs (Onkogenität): gemeinsame Empfehlungen der klinischen Genomressource (Clingen), des Krebsgenomikkonsortiums (CGC) und der Varianteninterpretation für das Krebskonsortium (VICC) [veröffentlicht in Genet Med. 2022 Sep; 24 (9): 1991]. Genet med. 2022; 24 (5): 986-998.",
    "Li MM, Datto M, Duncavage EJ, et al. Standards and Guidelines for the Interpretation and Reporting of Sequence Variants in Cancer: A Joint Consensus Recommendation of the Association for Molecular Pathology, American Society of Clinical Oncology, and College of American Pathologists. J Mol Diagn. 2017;19(1):4-23.": "Li MM, Datto M, Duncavage EJ, et al. Standards und Richtlinien für die Interpretation und Berichterstattung von Sequenzvarianten bei Krebs: Eine gemeinsame Konsensempfehlung der Vereinigung für molekulare Pathologie, American Society of Clinical Oncology und College of American Pathologen. J Mol Diagn. 2017; 19 (1): 4-23.",
    "Richards S, Aziz N, Bale S, et al. ACMG Laboratory Quality Assurance Committee. Standards and guidelines for the interpretation of sequence variants: a joint consensus recommendation of the American College of Medical Genetics and Genomics and the Association for Molecular Pathology. Genet Med. 2015 May;17(5):405-24.": "Richards S., Aziz N., Bale S., et al. ACMG Laboratory Quality Assurance Committee. Standards und Richtlinien für die Interpretation von Sequenzvarianten: Eine gemeinsame Konsensempfehlung der American College of Medical Genetics and Genomics und der Assoziation für molekulare Pathologie. Genet med. 2015 Mai; 17 (5): 405-24.",
    "Riggs ER, Andersen EF, Cherry AM, et al. Technical standards for the interpretation and reporting of constitutional copy-number variants: a joint consensus recommendation of the American College of Medical Genetics and Genomics (ACMG) and the Clinical Genome Resource (ClinGen). Genet Med. 2020 Feb;22(2):245-257.": "Riggs ER, Andersen EF, Cherry AM, et al. Technische Standards für die Interpretation und Berichterstattung von Varianten für Verfassungskopienzahl: Eine gemeinsame Konsensempfehlung des American College of Medical Genetics and Genomics (ACMG) und der klinischen Genomressource (Cleingen). Genet med. 2020 Februar; 22 (2): 245-257.",
    "No information available about the Experiment Type": "Keine Informationen zum Experimententyp verfügbar",
    "Left and Right Breakpoints:": "Linke und rechte Haltepunkte:",
    "No ": "NEIN",
    " calculated": "berechnet",
    "Please fill these in manually after downloading the report": "Bitte füllen Sie diese nach dem Herunterladen des Berichts manuell aus",
    "Report name": "Berichtsname",
    "This field is required.": "Dieses Feld ist erforderlich.",
    "Associated Clinical Report": "Assoziierte klinische Bericht",
    "Supported files: .doc, .docx, .pdf, .txt": "Unterstützte Dateien: .doc, .docx, .pdf, .txt",
    "Maximum file size: 10MB": "Maximale Dateigröße: 10 MB",
    "File uploaded successfully": "Datei erfolgreich hochgeladen",
    "OK": "OK",
    "TECHNICAL LIMITATIONS": "Technische Einschränkungen",
    "Treatments": "Behandlungen",
    "Gene name": "Genname",
    "Level of evidence": "Beweisniveau",
    "Match": "Übereinstimmen",
    "Therapy status": "Therapiestatus",
    "Drug status ": "Drogenstatus",
    "Evidence": "Beweis",
    "Evidence CGI": "Beweis CGI",
    "Evidence CIViC ": "Beweis bürgerlich",
    "Participants included in the study": "Teilnehmer in die Studie einbezogen",
    "Minimum read depth at variant position": "Minimale Lesetiefe an der Variantenposition",
    "Min-Max Alternate Allele Freq": "Min-Max Alternative Allel Freq",
    "Minimum genotype quality (Phred-scaled) for variant position": "Minimale Genotypqualität (PHRED-scaliert) für die variante Position",
    "Variant Source": "Variantequelle",
    "User": "Benutzer",
    "Timestamp": "Zeitstempel",
    "Ref": "Ref",
    "Region Name": "Regionenname",
    "Type in a name": "Geben Sie einen Namen ein",
    "Configuration details": "Konfigurationsdetails",
    "Affected": "Betroffen",
    "set Genotype Settings": "Setzen Sie die Genotypeinstellungen",
    "Quality Settings": "Qualitätseinstellungen",
    "No genotype configured. The sample will not be included in the analysis": "Kein Genotyp konfiguriert. Die Probe wird nicht in die Analyse einbezogen",
    "Minimum Depth": "Mindesttiefe",
    "Minimum Genotype Quality": "Minimale Genotypqualität",
    "Alternate Allele Frequency": "Alternative Allelfrequenz",
    "Select a simulated mode of inheritance configuration:": "Wählen Sie einen simulierten Modus der Vererbungskonfiguration aus:",
    "Please, bear in mind that the predefined inheritances take into account the reported affected status of the family members which in some cases may force unrealistic configurations. In such cases, please use the custom configuration to formulate your hypothesis": "Bitte beachten Sie, dass die vordefinierten Erbschaften den gemeldeten betroffenen Status der Familienmitglieder berücksichtigen, die in einigen Fällen unrealistische Konfigurationen erzwingen können. In solchen Fällen verwenden Sie bitte die benutzerdefinierte Konfiguration, um Ihre Hypothese zu formulieren",
    "or configure a custom mode of inheritance": "oder konfigurieren Sie einen benutzerdefinierten Vererbungsmodus",
    "10": "10",
    "30": "30",
    "50": "50",
    "70": "70",
    "90": "90",
    "0.1": "0,1",
    "0.2": "0,2",
    "0.3": "0,3",
    "0.4": "0,4",
    "0.5": "0,5",
    "0.6": "0,6",
    "0.7": "0,7",
    "0.8": "0,8",
    "0.9": "0,9",
    "1": "1",
    "You have selected the De Novo Inheritance. To avoid misleading scenarios, we prefer to prevent editing the analyzed experiments/participants. If you want to do so, please create a new study from scratch by clicking on Restart on the left of the screen": "Sie haben die De -Novo -Erbschaft ausgewählt. Um irreführende Szenarien zu vermeiden, bevorzugen wir es vor, die analysierten Experimente/Teilnehmer zu bearbeiten. Wenn Sie dies tun möchten, erstellen Sie bitte eine neue Studie von Grund auf neu, indem Sie links auf dem Bildschirm auf Neustart klicken",
    "Go back to see the clinical and experiment information.": "Gehen Sie zurück, um die klinischen und experimentellen Informationen zu sehen.",
    "Only Visible to you": "Nur für Sie sichtbar",
    "Shared with your groups": "Mit Ihren Gruppen geteilt",
    "Etikett": "Etikett",
    "etichetta": "Etichetta",
    "Amino Acid length": "Aminosäurelänge",
    "CDS Position": "CDS -Position",
    "Unaffective Relative": "Unangünstiger Verwandter",
    "Gene Coding": "Gencodierung",
    "Functional Class": "Funktionsklasse",
    "Aminoacid Length": "Aminoacid Länge",
    "Aminoacid Change": "Aminosäureveränderung",
    "Codon Change": "Codonänderung",
    "Transcript Biotype": "Transkriptbiotyp",
    "GnomAD AF": "Gnomad af",
    "GnomAD AF PopMAx": "Gnomad af Popmax",
    "CADD Pred.": "Cadd Pred.",
    "SIFT Pred.": "Sift Pred.",
    "Polyphen2 Hvar Pred.": "Polyphen2 Hvar Pred.",
    "Mutation Taster Pred.": "Mutation Taster Pred.",
    "Indel": "Indel",
    "Reported HPOs": "Berichtete HPOs",
    "HPOs": "HPOs",
    "Reported Diagnosis": "Gemeldete Diagnose",
    "Obs. HPSs": "Obs. HPS",
    "Position - Start": "Position - Start",
    "Positions - End": "Positionen - Ende",
    "Polyphen2 hvar": "Polyphen2 Hvar",
    "Tagged Variants": "Tagged Varianten",
    "Variant Class": "Variantenklasse",
    "ClinVar Classification": "Clinvar -Klassifizierung",
    " + labelFromStack + ": "+ LabelFromStack +",
    "docs": "Dokumente",
    "Clear": "Klar",
    "Loading...": "Laden...",
    "breadcrumb": "Breadcrumb",
    "Toggle navigation": "Navigation umschalten",
    "label": "Etikett",
    "dummyInput": "DummyInput",
    "menu": "Speisekarte",
    "container": "Container",
    "indicatorContainer": "Anzeigecontainer",
    "indicatorSeparator": "Indikatorseparator",
    "loadingIndicator": "Ladeindikator",
    "control": "Kontrolle",
    "group": "Gruppe",
    "input": "Eingang",
    "multiValue": "Multivalue",
    "option": "Option",
    "placeholder": "Platzhalter",
    "singleValue": "SingleValue"
}


