import React from "react";
import { Typography, Box } from "@mui/material";
import { Fragment } from "react";
import {Divider} from "@mui/material";
import CaButton from "../../../../../../../../../../gpap-lib/components/shared-components/CaButton";


export const CompleteDiagnosisForm = (props) => {

    let { url_phenostore } = window.config.apis_configuration;
    let {index_case, setUpdated={setUpdated}, setSubmitted, analysisType} = props;

   return <Fragment>
       <Box>
           <Typography variant="body1">
               The variant is now tagged as <b>Causative</b>.
           </Typography>
           { (analysisType !== "instand") ? 
                <>
               <Typography variant="body1">
                    You can now complete the information for this case by adding the corresponding diagnosis. 
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Clicking below will open a new tab with the PhenoStore submission form for the participant linked to the tagged variant.
                    </Typography>
                 <CaButton
                    text={"Complete Case"}
                    variant="contained"
                    href={`${url_phenostore}/#/complete_diagnosis/${index_case}`}
                    target="_blank"/>
           </>
               : null
           }
           <Divider/>
       </Box>
    </Fragment>


}