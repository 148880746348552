
// React and PropTypes
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from "prop-types";

// Material-UI Components

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';

import GPAP_Dialog_Title from "../../../../../../../../../gpap-lib/components/GPAP_Dialog_Title";
import GPAP_Dialog from "../../../../../../../../../gpap-lib/components/GPAP_Dialog";

// Services API
import { delete_variants, post_variant, update_variants } from "../../../../../../../../../services/api/variants_api";
import { delete_treatment, post_treatment, put_treatment } from '../../../../../../../../../services/api/treatments_api';
import { delete_pharmacogenomics, post_pharmacogenomics, put_pharmacogenomics } from '../../../../../../../../../services/api/pharmacogenomics_api';
import { post_fusions, put_fusions, delete_fusions } from '../../../../../../../../../services/api/fusions_api';
import { experiments_view_new, getGenes } from "../../../../../../../../../services/api/get";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import * as StudyActions from "../../../../../../../reducers/study_actions";
import { setTable } from '../../../../../../../../../reducers/slices/tables/table.slice';

// Utility Functions
import { variantFinder } from "../../variant-table/gpap-table/variant_finder";
import { treatmentFinder } from '../../../../../../../../instand/treatments/utils';
import { pharmacogenomicsFinder } from '../../../../../../../../instand/pharmacogenomics_table/utils';
import { fusionsFinder } from '../../../../../../../../instand/fusions_table/utils';


// Custom Components
import getTitle from './ui-renderers/getDialogTitle';

// Styles
import "./tag-dialog.css";
import CaButton from "../../../../../../../../../gpap-lib/components/shared-components/CaButton";
import {getInitialData} from "./init/initialData";
import {getInitialFormOptions} from "./init/getInitialFormOptions";
import {getFallbackIndex, getIdFromIndex} from "./helpers/index-handlers";
import {handleSNVVariant} from "./helpers/handleSNVVariant";
import {handleCNVVariant} from "./helpers/handleCNVVariant";
import {prepareRegionObject} from "./data-preparation/prepareRegionObject";
import {prepareGeneObject} from "./data-preparation/prepareGeneObject";
import AfterSubmitContent from './ui-renderers/AfterSubmitContent';
import {findTaggedVariant} from "./helpers/findTaggedVariant";
import {createDataOptions} from "./helpers/createDataOptions";
import {prepareCNVData} from "./data-preparation/prepareCNVData";
import {preparePharmacogenomicsObject, prepareTreatmentObject} from "./data-preparation/preparePharmacogenomics";
import {prepareSNVData} from "./data-preparation/prepareSNVData";
import GenericForm from "./ui-renderers/GenericFormRender";
import SubmissionForm from "./ui-renderers/SubmissionForm";
import TagButton from "./ui-renderers/TagButton";
import SubmissionFormFusions from './ui-renderers/SubmissionFormFusion';
import {handleFusionVariant} from "./helpers/handleFusionVariant";
import AfterSubmit from "./ui-renderers/AfterSubmit";
import { treatmentMatchingRule, fusionMatchingRule, pharmaMatchingRule } from './matchingRules/rules';
import {cleanFusionObject} from "./data-preparation/cleanFusionObject";


function TagDialog(props) {

    //TODO: list all props here.

    const [open, setOpen] = useState(false);
    const [state, setState] = useState({ causative: false });
    const [initialized, setInitialized] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [index_case, setIndexCaseID] = useState(null);
    // 
    const [variant_flagged, setVariantFlagged] = useState(false);
    const [fusions_flagged, setFusionsFlagged] = useState(false);
    const [geneId, setGeneIdentifier] = useState("");
    const [tagVisibility, setTagVisibility] = useState(false);
    const [projects, setProjects] = useState([]);
    const [openDialogError, setOpenDialogError]= useState(false);
    const [treatment_flagged, setTreatmentFlagged] = useState(false);
    const [pharmacogenomics_flagged, setPharmacogenomicsFlagged] = useState(false);
    
    const [shouldRunEffect, setShouldRunEffect] = useState(true);

    const allDataApis = useSelector(state => state.generaltable.allDataApis);
    const dispatch = useDispatch();



    const [errorInfo, setErrorInfo] = useState({
        title: "",
        description: "",
        type: "warning"
    })

    const [data, setData] = useState(getInitialData(props.dataType));
    // initial formOptions
    const [formOptions, setFormOptions] = useState(getInitialFormOptions(props.dataType));

    const analysisType = props.studySettings?.analysisType;
    const dirType = allDataApis?.dirs?.analysis;

    const setIndexCase = (index_case) => {

        const {patients} = props;

        if (index_case.length !== 0) {
            const INDEX = index_case[0];
            setIndexCaseID(getIdFromIndex(INDEX));
        } else {
            const fallbackIndexId = getFallbackIndex(patients.patients);
            if (fallbackIndexId) {
                setIndexCaseID(fallbackIndexId);
            }
        }
    };

    // Function to create and initialize data based on variant type
// Parameters:
// - variant: object representing the variant information
// - samples: array of samples related to the variant
// - transcripts: array of transcripts related to the variant
// - genes: array of genes related to the variant
// - type: type of variant, either "SNV" or "CNV"

    const create_data_init = (variant, samples, transcripts, genes, dataType) => {
        // Create a shallow copy of the data object
        let data_copy = {...data};

        // Extract cDNA and protein information from the variant
        const cDNA = variant.codon_change;
        const protein = variant.amino_acid_change;

        let variantData;

        // If the variant type is SNV
        if (dataType === "SNV_Germline" || dataType === "SNV_Somatic") {
            variantData = handleSNVVariant(variant, samples, genes, transcripts, cDNA, protein);
            return { ...data_copy, ...variantData };
        }
        // If the variant type is CNV
        else if(dataType === "CNV"){ 
            variantData = handleCNVVariant(variant, transcripts, dataType);
            return { ...data_copy, ...variantData };
        }
        else if(dataType === "Fusions"){
            let experimentID = samples ? samples[0].value : "None";
            variantData = handleFusionVariant(variant, experimentID);

            // we only return these fields as most of the other SNV/CNV fields are not needed
            return variantData
        }
        
    };


    // initialize data;
    /**
     * Initializes data based on the variant, samples, genes, transcripts, and type.
     * If the 'patients' property is defined and contains a 'patients' key, sets the index case based on the 'Yes' value.
     * Calls the 'create_data_init' function to create and initialize data based on the variant type.
     * Calls the 'getProjects' function with the 'sample' from the data copy.
     * @param {Object} variant - Object representing the variant information
     * @param {Array} samples - Array of samples related to the variant
     * @param {Array} genes - Array of genes related to the variant
     * @param {Array} transcripts - Array of transcripts related to the variant
     * @param {string} type - Type of variant, either "SNV" or "CNV"
     * @returns {Object} - Initialized data copy
     */
    const initData = (variant, samples, genes, transcripts, dataType) => {


        // extract data;
        if (props.patients !== undefined && "patients" in props.patients) {
            let index_case = props.patients.patients.filter(p => p.index === "Yes");

            // Set the index case based on the 'Yes' value
            setIndexCase(index_case);

            // Call the create_data_init function to create and initialize data based on the variant type
            let data_copy = create_data_init(variant, samples, transcripts, genes, dataType);

            // Call the getProjects function with the 'sample' from the data copy
            if (data_copy["sample"] !== undefined){
                getProjects(data_copy["sample"]);
            }

            // Return the initialized data copy
            return data_copy;
        }
    };

    const resetData = () => {
        setData(getInitialData(props.dataType));
        setFormOptions(getInitialFormOptions(props.dataType));
    }

    useEffect(function(){

        if(analysisType !== "instand"){
            if(data["sample"] !== "None" && data["sample"] !== undefined){
                getProjects(data["sample"]);
            }
    }


    },[data["sample"]])




    useEffect(() => {

        let dataType = getRowDataType();

        // Find the relevant item based on the data type
        let item = props.selected_table_rows?.find(s => s.data_type === dataType);

        // If item exists and contains data, proceed to process it
        if (item?.data_row?.length > 0 && (dataType === "Treatment_Somatic" || dataType === "Pharmacogenomics_Germline")) {
            // Take the first item in data_row and clear its comments
            let current_data = { ...item.data_row[0], comments: "" };
            setData(current_data);
        }

    }, [props.selected_table_rows, props.samples]);




    /**
     * Prepares form data based on the tagged variant, samples, genes, and transcripts.
     * Sets the 'gene' property of the copy to either the existing 'gene' value or the 'gene_name' value.
     * Calls the 'setDataToPost' function to set data based on the copy and the current variant type.
     * Sets the state based on whether the copy's status is "Causative".
     * Searches for the 'TAGGED_VARIANT.sample' in the samples and sets the index case ID and tag visibility accordingly.
     * Sets form options using data options including samples, genes, transcripts, zygosity options, inheritance options, significance options, origin options, variant types, and structural variant types.
     * @param {Object} TAGGED_VARIANT - Object representing the tagged variant information
     * @param {Array} samples - Array of samples
     * @param {Array} genes - Array of genes
     * @param {Array} transcripts - Array of transcripts
     */
    const prepareFormFromTag = (tagged_variant, samples, genes, transcripts) => {
        const copy = { ...tagged_variant };
        copy.gene = copy.gene !== undefined ? copy.gene : copy.gene_name;
        setDataToPost(copy);
        setState({ causative: copy.status === "Causative" });

        const exp = props.samples.find((d) => d.sample_id === tagged_variant.sample);

        let formInitValues = createDataOptions(samples, genes, transcripts, dataType, analysisType)

        setFormOptions(formInitValues);

        if (exp !== undefined && exp.hasOwnProperty("pheno_id")) {
            setIndexCaseID(exp.pheno_id);
            setTagVisibility("sample_tag");
        } else {
            setTagVisibility("my_tag");
        }
    };


    // TODO: use await async in the future..
    const getGeneIdentifier = (gene_name) => {
        // Call `getGenes` and return the gene identifier through a promise
        getGenes(gene_name)
            .then(r => r.json())
            .then(gene_id => {
                // Initialize ID as undefined
                let id = undefined;

                if (gene_id !== undefined && gene_id.length !== 0) {
                    id = gene_id[3].map(function (val) {
                        if (val.length !== 0) {
                            return { id: val[0], label: val[1] };
                        }
                    });
                }

                // Get the first gene identifier or an empty string
                let gene_identifier = (id !== undefined && Array.isArray(id) && id.length > 0) ? id[0].id : "";

                // Set the gene identifier in the state
                setGeneIdentifier(gene_identifier);

                return gene_identifier;
            })
            .catch(error => {
                console.error("Error fetching gene identifier:", error);
                return ""; // Return an empty string or handle error
            });
    };



    const processVariant = (variant, {annotations, tagged_variants}) => {
        const genes = [variant.gene_name]; // Single gene array

        // Map transcripts with default value and canonical check
        const transcripts = (variant.transcripts_all || []).map(x => ({
            value: x.transcript_id,
            label: x.transcript_id,
            canonical: !!(x.mane_select || x.mane_plus_clinical),
        }));

        getGeneIdentifier(variant.gene_name);

        // Find variant based on annotations and tagged variants
        const found = variantFinder(variant, annotations, tagged_variants, "SNV_Germline");

        // Set variant flagged state if it's tagged
        if (variant.isTagged !== undefined) {
            setVariantFlagged(variant.isTagged);
        }

        return {genes, transcripts, found};
    };


    const getSelectedTableRow = () => {

        let dataType = getRowDataType();

        let item = props.selected_table_rows.find(s => s.data_type === dataType);

        if(item!== undefined && item.data_row.length>0){
            return item.data_row[0];
        }
        else{
            return false;
        }
    }


    const getRowDataType = () => {

        return props.dataType;

    }



    useEffect(() => {
        if (!shouldRunEffect) return;
    
        const selectedDataRow = getSelectedTableRow();
        if (!selectedDataRow) return; // Early return if no row is selected
    
        // Initialize state
        setTagVisibility("no_tag");
        setSubmitted(false);
    
        const samples = props.samples.map(s => ({ "value": s.sample_id, "label": s.sample_id }));
        const dataType = getRowDataType();
    
        if (dataType === "SNV_Germline" || dataType === "SNV_Somatic") {
            handleSNV(selectedDataRow, samples);
        } else if (dataType === "Treatment_Somatic") {
            handleTreatment(selectedDataRow, dirType);
        } else if (dataType === "Pharmacogenomics_Germline") {
            handlePharmacogenomics(selectedDataRow);
        } else if (dataType === "Fusions") {
            handleFusions(selectedDataRow, samples);
        } else {
            handleCNV(selectedDataRow, samples);
        }
    }, [open, initialized, shouldRunEffect]);

    // handle germline and somatic variants
    const handleSNV = (selectedDataRow, samples) => {
        const {genes, transcripts, found} = processVariant(selectedDataRow, props);

        if (found) {
            // if a variant has been tagged
            handleTaggedVariants(found, samples, genes, transcripts, selectedDataRow);
        } else {
            // if it's an untagged variant
            handleUntaggedVariants(selectedDataRow, samples, genes, transcripts, props.dataType);
        }
    };

    // handle tagged variants
    const handleTaggedVariants = (found, samples, genes, transcripts, selectedDataRow) => {


        const USER_TAGS = found.filter(s => s.user === props.user);
        const isVisible = USER_TAGS.length > 0;

        setTagVisibility(isVisible ? "my_tag" : "no_tag");

        if (isVisible) {
            const sampleIds = props.samples.map(s => s.sample_id);
            const taggedVariant = findTaggedVariant(sampleIds, USER_TAGS, props.user);

            if (taggedVariant && (taggedVariant.gene || taggedVariant.gene_name)) {
                prepareFormFromTag(taggedVariant, samples, genes, transcripts);
            }
        } else {
            const init_data = initData(selectedDataRow, samples, genes, transcripts, dataType);
            setDataToPost(init_data);

            let formInitValues = createDataOptions(samples, genes, transcripts, dataType, analysisType)
            setFormOptions(formInitValues);
            setInitialized(true);
        }
    };

    const handleTreatment = (selectedDataRow, dirType) => {
        try {

            const treatmentTagged = treatmentFinder(selectedDataRow, allDataApis.participants[0], dirType);

            setTreatmentFlagged(false);
            if (treatmentTagged.found) {
                setData(prev => ({ ...prev, comments: treatmentTagged.interpretation }));
                setTreatmentFlagged(true);
            }
        } catch (error) {
            console.error("Error occurred while processing treatment data:", error);
        }
    };

    const handlePharmacogenomics = (selectedDataRow) => {
        const pharmacogenomicsTagged = pharmacogenomicsFinder(selectedDataRow, allDataApis.participants[0]);

        setPharmacogenomicsFlagged(false);
        if (pharmacogenomicsTagged.found) {
            setData(prev => ({ ...prev, comments: pharmacogenomicsTagged.interpretation }));
            setPharmacogenomicsFlagged(true);
        }
    };

    // called in the useEffect to handle fusions when selected.
    // it checks if a variant has been already tagged or not. Sets the data and the form options;
    const handleFusions = (selectedDataRow, samples) => {


        const dataType = getRowDataType();
        const init_data = initData(selectedDataRow, samples, selectedDataRow.FusionName, [],dataType );
        setDataToPost(init_data);

        const fusionsTagged = fusionsFinder(selectedDataRow, allDataApis.participants[0]);
        const flagged = fusionsTagged.found
        
        if (flagged) {
            // map data;
            let flaggedVariant = JSON.parse(JSON.stringify(fusionsTagged.variant[0]));
            //flaggedVariant["comments"] = fusionsTagged.interpretation;

            // map data

            let mappedObject = {
                "sample": flaggedVariant["sample"],
                "fusioned_genes": flaggedVariant["fusioned_genes"],
                "junction_read_count": flaggedVariant["junction_read_count"],
                "left_gene": flaggedVariant["left_gene"],
                "left_breakpoint": flaggedVariant["left_breakpoint"],
                "right_gene": flaggedVariant["right_gene"],
                "right_breakpoint": flaggedVariant["right_breakpoint"],
                //"interpretation": flaggedVariant["interpretation"] || "",
                //"sv_type": flaggedVariant["related_to"],
                "variant_type": flaggedVariant["variant_type"],
                "origin": flaggedVariant["origin"],
                "significance": flaggedVariant["significance"],
                "comments": flaggedVariant["interpretation"] || "",
                "related_to": flaggedVariant["related_to"]
            }

            
            if(flaggedVariant.causative){
                setState({causative: true});
            }
        
            setData(mappedObject);
        }
        else{
            setData(init_data);
        }
        
        // create data options to visualize the form;
        const formInitValues = createDataOptions(samples, [selectedDataRow.FusionName], [], dataType, analysisType);
        setFormOptions(formInitValues);

        // TODO: Review 
        if (flagged && fusionsTagged.variant.length > 0) {
            setTagVisibility("my_tag");
            setIndexCaseID(fusionsTagged.variant[0]?.participant_id);
            setVariantFlagged(flagged);
        } else {
            setTagVisibility("no_tag");
        }
    };

    const handleCNV = (selectedDataRow, samples) => {

        const cnv_init_data = initData(selectedDataRow, samples, selectedDataRow.Gene_name, [], getRowDataType());
        setDataToPost(cnv_init_data);

        const transcripts = selectedDataRow["Tx"] ? [{ value: selectedDataRow["Tx"], label: selectedDataRow["Tx"] }] : [];
        setFormOptions(createDataOptions(samples, cnv_init_data["gene"], transcripts, "CNV", analysisType));

        const found = variantFinder(selectedDataRow, [], props.tagged_variants, "CNV");

        if (found && found.length > 0) {
            setTagVisibility("my_tag");
            setIndexCaseID(found[0]?.participant_id);
        } else {
            setTagVisibility("no_tag");
        }
    };


    // handle untagged variants and prepare for first tag
    const handleUntaggedVariants = (variant,samples, genes, transcripts, dataType) => {

        let init_data = initData(variant, samples, genes, transcripts, dataType);
        
        setDataToPost(init_data);
        // data to visualize the form;

        let dataOptions = createDataOptions(samples, genes, transcripts, dataType, analysisType)

        setFormOptions(dataOptions);
        setInitialized(true);

    }

    const setDataToPost = (data_copy) => {
        const dataType = getRowDataType();
        let selectedDataRow = getSelectedTableRow();

        if (selectedDataRow) {
            if (dataType === "SNV_Germline" || dataType === "SNV_Somatic") {
                const snv_data_tag = prepareSNVData(data_copy, selectedDataRow, dataType);
                setData(snv_data_tag);
            } else if (dataType === "CNV") {
                const cnv_data_tag = prepareCNVData(data_copy);
                setData(cnv_data_tag);
            }
            else if(dataType === "Fusions"){
                setData(data_copy);
            }
        }
    };


    const handleCausativeChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });

    };


    const handleClickOpen = () => {

        let selected_table_rows =  getSelectedTableRow();

        // currently, multiple select is not allowed.

        if(selected_table_rows){
            setOpen(true);
            setShouldRunEffect(true);
        }
        else{
            alert("Please, select at least 1 variant to tag")
        }

    };


  

    // TODO: refractor
    const handleSubmit = async () => {

        let phenostore_api = config.apis_configuration.api_phenostore;

        // get gene code if exists;


        let selectedDataRow = getSelectedTableRow();
        let dataType = getRowDataType();

        if(selectedDataRow){
            if( dataType === "SNV_Germline" || dataType === "SNV_Somatic"){
                let geneObj = prepareGeneObject(geneId, data, state);

                let variant_data = {
                    participant_id: index_case,
                    genes: [geneObj],
                    field: "genes"
                };

                if(data["inheritance"]!== "" && data["significance"]!== ""){

                    // no_tag it's the first tag we are doing...
                    if(variant_flagged && tagVisibility!== "no_tag"){ // update only if the variant belongs to the user
                        // update
                        delete variant_data.participant_id;
                        update_variants(phenostore_api, props.token, variant_data,  index_case)
                            .then(r =>
                            {
                                return  r.json()

                            })
                            .then(json => {
                                if(json["message"]!== "Error" && json["message"] !== "Variant(s) to update not found or nothing to update" ){


                                    setUpdated(true);
                                    setInitialized(false);
                                    props.annotate_variants("TAGGED", []);
                                    props.getAllTaggedVariants();

                                }
                                else{
                                    setOpenDialogError(true);
                                    setErrorInfo({title: "Server Error", description: "Error in updating the variant," + json["message"] , type: "alert"})
                                }
                            })
                    }
                    else{
                        post_variant(phenostore_api, props.token, variant_data)
                            .then(r =>
                            {
                                return  r.json();

                            })
                            .then(json => {
                                if (json["message"] && json["message"].includes("Added")){
                                    props.annotate_variants("TAGGED", [], "add");
                                    setSubmitted(true);
                                    setVariantFlagged(true);
                                    setTagVisibility("sample_tag");
                                    props.getAllTaggedVariants();
                                    
                                }
                                else{
                                    setOpenDialogError(true);
                                    setErrorInfo({title: "Server Error", description: "Error in tagging the variant, " + json["message"] , type: "alert"})
                                }
                            })
                    }
                }
                else{
                    setOpenDialogError(true);
                    setErrorInfo({title: "Required Fields", description: "Please, fill in all required fields", type: "warning"})
                }
            }

            //Tag Treatment Case
            else if (dataType === "Treatment_Somatic"){

                let treatment_obj = prepareTreatmentObject(selectedDataRow, data)

                //If it is already flagged update
                if (treatment_flagged){
                    updateTreatmentData()
                }

                //Otherwise, post
                else{
                    tagTreatmentData(treatment_obj)
                }
            }


            //Tag Pharmacogenomics Case
            else if (dataType === "Pharmacogenomics_Germline"){

                let pharmacogenomics_obj = preparePharmacogenomicsObject(selectedDataRow, data)

                //If it is already flagged update
                if (pharmacogenomics_flagged){
                    updatePharmacogenomicsData()
                }

                //Otherwise, post
                else{
                    tagPharmacogenomicsData(pharmacogenomics_obj)
                }
            }

            //Tag Fusions Case
            else if (dataType === "Fusions"){

               // add causative status
                
               let {fusion_obj_renaming, report_id} = cleanFusionObject(data, state, props.patients);
                

                //If it is already flagged update
                if (variant_flagged){

                    // these keys are not required in the PUT payload;
                    delete fusion_obj_renaming.left_breakpoint;
                    delete fusion_obj_renaming.right_breakpoint;
                    updateFusionsData(fusion_obj_renaming, report_id)
                }

                //Otherwise, post
                else{
                    // data is already the dataToPost format
                    tagFusionsData(fusion_obj_renaming, report_id)
                }
            }

            else{
                // variant type CNV;

                let regionObj = prepareRegionObject(data, state);


                let cnv_variant_data = {
                    participant_id: index_case,
                    regions: [regionObj],
                    field: "regions"
                };


                if(data["inheritance"]!== "" && data["significance"]!== ""){

                    post_variant(phenostore_api, props.token, cnv_variant_data)
                        .then(r => {
                            if (r.status === 200) {
                                return r.json();
                            } else {
                                throw new Error("Server Error");
                            }
                        })
                        .then(json => {
                            // The API call was successful
                            // Do something with the 'json' response
                            // props.annotate_variants("TAGGED", [], "add");


                            setSubmitted(true);

                            setVariantFlagged(true);
                            props.getAllTaggedVariants();
                         
                        })
                        .catch(error => {
                            // An error occurred during the API call

                            setOpenDialogError(true);
                            setErrorInfo({title: "Error", description: "Error in posting variants", type: "alert"})

                        });

                }

                else{
                    setOpenDialogError(true);
                    setErrorInfo({title: "Required Fields", description: "Please, fill in all required fields", type: "warning"})
                }
            }
        }


    }



    const deleteVariantData = () => {

        // delete variant;

        let phenostore_api = config.apis_configuration.api_phenostore;

        // get the data in the right format;

        let geneObj = prepareGeneObject(geneId, data, state);

        let variant_data = {
            participant_id: index_case,
            genes: [geneObj]
        };

        if(variant_flagged){
            // update
            delete variant_data.participant_id;

            // TODO: change to async/await if possible
            delete_variants(phenostore_api, props.token, variant_data,  index_case)
                .then(r =>
                {
                    if (!r.ok) {
                        // If the response status is not OK, throw an error to be caught in the catch block
                        throw new Error(`HTTP error! Status: ${r.status}`);
                    }
                    return  r.json()
                })
                .then((json) => {

                    if (json && json["message"] !== "Error") {
                        setDeleted(true);
                        setVariantFlagged(false);
                        setTagVisibility("no_tag"); 
                        // delete variant from our tags
                        // load again Tagged Variants..
                        props.annotate_variants("TAGGED", [], "delete");
                        props.getAllTaggedVariants();
                    }
                })
                .catch((error) => {
                    // Handle errors in the fetch request or response status
                    setOpenDialogError(true);
                    setErrorInfo({
                        title: "Variant can't be deleted",
                        description: "Error in deleting the variant",
                        type: "alert"})
            })

        }


    };


    // TODO: change to generic function;
    const tagTreatmentData = (treatment_obj) => {

        try {
            let report_id = props.patients.patients[0]?.report_id;
            if (!report_id) {
                throw new Error("report_id is missing");
            }

            let phenostore_api = config.apis_configuration.api_phenostore;

            post_treatment(phenostore_api, props.token, treatment_obj, report_id)
                .then(r => {
                    if (r.status === 200) {
                        return r.json();
                    } else {
                        throw new Error("Server Error");
                    }
                })
                .then(json => {
                    setSubmitted(true);
                    let updated_participants = { ...allDataApis.participants[0]};
                    if (!updated_participants.tagged_treatments) {
                        updated_participants = { ...updated_participants, tagged_treatments: [treatment_obj] };
                    } else {
                        updated_participants = {
                            ...updated_participants,
                            tagged_treatments: [...updated_participants.tagged_treatments, treatment_obj],
                        };
                    }
                    let newDataObject = { ...allDataApis };
                    newDataObject.participants = [updated_participants];
                    dispatch(setTable({ option: "allDataApis", value: newDataObject }))
                })
                .catch(error => {
                    // An error occurred during the API call
                    console.error("Error occurred during tagging treatment:", error);
                    setOpenDialogError(true);
                    setErrorInfo({title: "Error", description: "Error in tagging treatment", type: "alert"})
                });
        } catch (error) {
            console.error("Error occurred:", error);
            setOpenDialogError(true);
            setErrorInfo({title: "Error", description: error.message, type: "alert"});
        }
    }

 // TODO: change to generic function
    const updateTreatmentData = () => {

        let phenostore_api = config.apis_configuration.api_phenostore;

        // Set the interpretation property from data.comments
        let interpretation = ""
        if (data && data.comments) {
            interpretation = data.comments;
        }

        let selectedRowData = getSelectedTableRow();
        let rowDataType = getRowDataType();



        let treatment_data = {
            gene: selectedRowData.gene_name,
            variant:selectedRowData.hgvs_p,
            interpretation: interpretation,
            therapy: selectedRowData.Therapies,
            cancer_type: selectedRowData.Cancer_Type,
            evidence_level:selectedRowData.Evidence_level
        };

        if(treatment_flagged && rowDataType === "Treatment_Somatic"){
            put_treatment(phenostore_api, props.token, treatment_data, props.patients.patients[0].report_id)
                .then(r =>r.json())
                .then((data) => {

                    const message = data.message;

                    if(message && message.includes("Updated")){
                        setDeleted(false);
                        setUpdated(true);

                        // Create a new copy of json for the participants property
                        let updated_participants = { ...allDataApis.participants[0]};

                        
                
                        // Find the matching object in tagged_treatments and update its "interpretation" property
                        const matchingTreatmentIndex = updated_participants.tagged_treatments.findIndex(treatment => {
                        return  treatmentMatchingRule(treatment, treatment_data)});


                        if (matchingTreatmentIndex !== -1) {
                            // Matching object found, create a new array with the updated object
                            const updatedTreatments = [...updated_participants.tagged_treatments];
                            updatedTreatments[matchingTreatmentIndex] = {
                                ...updatedTreatments[matchingTreatmentIndex],
                                interpretation: interpretation
                            };

                            updated_participants = {
                                ...updated_participants,
                                tagged_treatments: updatedTreatments,
                            };
                        }


                        // Create a new copy of allDataApis
                        let newDataObject = { ...allDataApis };
                        
                        // Update the participants property in the new object
                        newDataObject.participants = [updated_participants];
                        
                        // Dispatch the action to update the state
                        dispatch(setTable({ option: "allDataApis", value: newDataObject }))
                    }
                    else{
                        setOpenDialogError(true);
                        setErrorInfo({title: "Treatment can't be updated", description: "Error in updating the treatment", type: "alert"})
                    }
                })
        }
    };


    const tagPharmacogenomicsData = (pharmacogenomics_obj) => {

        let phenostore_api = config.apis_configuration.api_phenostore;

        post_pharmacogenomics(phenostore_api, props.token, pharmacogenomics_obj, props.patients.patients[0].report_id)
        .then(r => {
            if (r.status === 200) {
                return r.json();
            } else {
                throw new Error("Server Error");
            }
        })
        .then(json => {

            setSubmitted(true);

            // Create a new copy of json for the participants property
            let updated_participants = { ...allDataApis.participants[0]};

            // Check if the tagged_pharmacogenomics property exists in the first participant
            if (!updated_participants.tagged_pharmacogenomics) {
                // If it doesn't exist, create the property with an array containing pharmacogenomics_obj
                updated_participants = { ...updated_participants, tagged_pharmacogenomics: [pharmacogenomics_obj] };
            } else {
                // If it exists, create a new object with the added pharmacogenomics_obj
                updated_participants = {
                    ...updated_participants,
                    tagged_pharmacogenomics: [...updated_participants.tagged_pharmacogenomics, pharmacogenomics_obj],
                };
            }

            // Create a new copy of allDataApis
            let newDataObject = { ...allDataApis };

            // Update the participants property in the new object
            newDataObject.participants = [updated_participants];

            // Dispatch the action to update the state
            dispatch(setTable({ option: "allDataApis", value: newDataObject }))

        })
        .catch(error => {
            // An error occurred during the API call
            setOpenDialogError(true);
            setErrorInfo({title: "Error", description: "Error in tagging pharmacogenomics", type: "alert"})

        });
     }



    /**
     * Deletes tagged data of a specific type associated with a patient's report.
     * @param {function} dataToDelete - The function responsible for deleting the data.
     * @param {string} dataType - The type of data to delete (e.g., "Treatment_Somatic", "Pharmacogenomics_Germline").
     * @throws {Error} Throws an error if report_id is missing or if an error occurs during the deletion process.
     * @returns {void}
     *
     * // Example usage:
     * // deleteData(delete_treatment, "Treatment_Somatic");
     * // deleteData(delete_pharmacogenomics, "Pharmacogenomics_Germline");
     */ const deleteData = (dataToDelete, dataType) => {
        let phenostore_api = config.apis_configuration.api_phenostore;
        let selectedRowData = getSelectedTableRow();
        let rowDataType = getRowDataType();

        if (dataToDelete && dataType && rowDataType === dataType) {
            try {
                const report_id = props.patients.patients[0]?.report_id;
                if (!report_id) {
                    throw new Error("report_id is missing");
                }

                let targetObject;
                if(dataType === "Pharmacogenomics_Germline"){
                    targetObject = {
                        gene: selectedRowData["Gene"],
                        genotype: selectedRowData["Genotype"],
                        haplotype: selectedRowData["Haplotype1"],
                        haplotype2: selectedRowData["Haplotype2"],
                        phenotype:selectedRowData["Phenotype"]
                    }
                }
                else{
                    // Treatments
                    targetObject = {
                            gene: selectedRowData.gene_name,
                            variant: selectedRowData?.hgvs_p ?? "NA",
                        // add other fields
                            therapy: selectedRowData.Therapies,
                            cancer_type: selectedRowData.Cancer_Type,
                            evidence_level:selectedRowData.Evidence_level
                    }
                }

                
                let tagged_name = (dataType === "Pharmacogenomics_Germline") ? "pharmacogenomics" : "treatments"

                dataToDelete(phenostore_api, props.token, targetObject, report_id)
                    .then(r => r.json())
                    .then((data) => {
                        const message = data.message;
                        if (message && message.includes("Deleted")) {
                            setDeleted(true);
                            let updated_participants = { ...allDataApis.participants[0] };

                            


                            if (updated_participants[`tagged_${tagged_name.toLowerCase()}`] && Array.isArray(updated_participants[`tagged_${tagged_name.toLowerCase()}`])) {
                                if(dataType === "Pharmacogenomics_Germline"){


                                
                                    updated_participants[`tagged_${tagged_name.toLowerCase()}`] = updated_participants[`tagged_${tagged_name.toLowerCase()}`]
                                        .filter(item => {
                                        return !pharmaMatchingRule(item, targetObject)
                                    });
                                }
                                else{
                                    // treatment

                            
                                    updated_participants[`tagged_${tagged_name.toLowerCase()}`] = updated_participants[`tagged_${tagged_name.toLowerCase()}`]
                                        .filter(item => {
                                            return !treatmentMatchingRule(item, targetObject);
                                        });
                                }
                            }
                            let newDataObject = { ...allDataApis };
                            newDataObject.participants = [updated_participants];
                            dispatch(setTable({ option: "allDataApis", value: newDataObject }))
                        } else {
                            setOpenDialogError(true);
                            setErrorInfo({ title: `${dataType} can't be deleted`, description: `Error in deleting the ${dataType.toLowerCase()}`, type: "alert" })
                        }
                    })
                    .catch(error => {
                        console.error(`Error occurred during ${dataType.toLowerCase()} deletion:`, error);
                        setOpenDialogError(true);
                        setErrorInfo({ title: `${dataType} deletion failed`, description: `An error occurred while attempting to delete ${dataType.toLowerCase()}`, type: "alert" });
                    });
            } catch (error) {
                console.error("Error occurred:", error);
                setOpenDialogError(true);
                setErrorInfo({ title: "Error", description: error.message, type: "alert" });
            }
        }
    };




    const updatePharmacogenomicsData = () => {

        let phenostore_api = config.apis_configuration.api_phenostore;


        let selectedRowData = getSelectedTableRow();
        let rowDataType = getRowDataType();

        // Set the interpretation property from data.comments
        let interpretation = ""
        if (data && data.comments) {
            interpretation = data.comments;
        }

        let pharmacogenomics_data = {
            gene: selectedRowData["Gene"],
            genotype: selectedRowData["Genotype"],
            /*haplotype: selectedRowData["Haplotype1"],
            haplotype2: selectedRowData["Haplotype2"],
            phenotype:selectedRowData["Phenotype"],*/
            interpretation: interpretation
        };

        if(pharmacogenomics_flagged && rowDataType === "Pharmacogenomics_Germline"){
            put_pharmacogenomics(phenostore_api, props.token, pharmacogenomics_data, props.patients.patients[0].report_id)
                .then(r =>r.json())
                .then((data) => {

                    const message = data.message;

                    if(message && message.includes("Updated")){
                        setDeleted(false);
                        setUpdated(true);

                        // Create a new copy of json for the participants property
                        let updated_participants = { ...allDataApis.participants[0]};

                        // Find the matching object in tagged_pharmacogenomics and update its "interpretation" property
                        const matchingPharmacogenomicsIndex = updated_participants.tagged_pharmacogenomics.findIndex(pharmacogenomics => {
                        return pharmacogenomics.gene === pharmacogenomics_data.gene && pharmacogenomics.variant === pharmacogenomics_data.variant;
                        });


                        if (matchingPharmacogenomicsIndex !== -1) {
                            // Matching object found, create a new array with the updated object
                            const updatedPharmacogenomics = [...updated_participants.tagged_pharmacogenomics];
                            updatedPharmacogenomics[matchingPharmacogenomicsIndex] = {
                                ...updatedPharmacogenomics[matchingPharmacogenomicsIndex],
                                interpretation: interpretation
                            };

                            updated_participants = {
                                ...updated_participants,
                                tagged_pharmacogenomics: updatedPharmacogenomics,
                            };
                        }


                        // Create a new copy of allDataApis
                        let newDataObject = { ...allDataApis };

                        // Update the participants property in the new object
                        newDataObject.participants = [updated_participants];

                        // Dispatch the action to update the state
                        dispatch(setTable({ option: "allDataApis", value: newDataObject }))
                    }
                    else{
                        setOpenDialogError(true);
                        setErrorInfo({title: "Pharmacogenomics can't be updated", description: "Error in updating the pharmacogenomics", type: "alert"})
                    }
                })
        }
    };

    const tagFusionsData = (fusions_obj, report_id) => {
        let phenostore_api = config.apis_configuration.api_phenostore;


        post_fusions(phenostore_api, props.token, fusions_obj,report_id)
            .then(r => {
                if (r.status === 200) {
                    return r.json();
                } else {
                    throw new Error("Server Error");
                }
            })
            .then(json => {
                setSubmitted(true);

                // Create a new copy of json for the participants property
                let updated_participants = { ...allDataApis.participants[0] };

                // Check if the tagged_fusions property exists in the first participant
                if (!updated_participants.tagged_gene_fusions) {
                    // If it doesn't exist, create the property with an array containing fusions_obj
                    updated_participants = { ...updated_participants, tagged_gene_fusions: [fusions_obj] };
                } else {
                    // If it exists, create a new object with the added fusions_obj
                    updated_participants = {
                        ...updated_participants,
                       tagged_gene_fusions: [...updated_participants.tagged_gene_fusions, fusions_obj],
                    };
                }
                // Create a new copy of allDataApis
                let newDataObject = { ...allDataApis };

                // Update the participants property in the new object
                newDataObject.participants = [updated_participants];

                // Dispatch the action to update the state
                dispatch(setTable({ option: "allDataApis", value: newDataObject }));
            })
            .catch(error => {
                // An error occurred during the API call
                setOpenDialogError(true);
                setErrorInfo({ title: "Error", description: "Error in tagging fusions", type: "alert" });
            });
    };

    const updateFusionsData = (fusions_obj, report_id) => {
        let phenostore_api = config.apis_configuration.api_phenostore;
    
     
        put_fusions(phenostore_api, props.token, fusions_obj, report_id)
            .then(r => r.json())
            .then((data) => {
                const message = data.message;
    
                if (message && message.includes("Updated")) {
                    setDeleted(false);
                    setUpdated(true);
    
                    // Create a new copy of json for the participants property
                    let updated_participants = { ...allDataApis.participants[0] };
    
                    // Find the matching object in tagged_gene_fusions and update its "interpretation" property
                    const matchingFusionsIndex = updated_participants.tagged_gene_fusions.findIndex(fusion => {
                        return fusion.fusioned_genes === fusions_obj.fusioned_genes &&
                            fusion.left_gene === fusions_obj.left_gene &&
                            fusion.right_gene === fusions_obj.right_gene;
                    });
    
                    if (matchingFusionsIndex !== -1) {
                        // Matching object found, create a new array with the updated object
                        const updatedFusions = [...updated_participants.tagged_gene_fusions];
                        updatedFusions[matchingFusionsIndex] = {
                            ...updatedFusions[matchingFusionsIndex],
                            interpretation: updatedFusions[matchingFusionsIndex].interpretation
                        };
    
                        updated_participants = {
                            ...updated_participants,
                            tagged_gene_fusions: updatedFusions,
                        };
                    }
    
                    // Create a new copy of allDataApis
                    let newDataObject = { ...allDataApis };
    
                    // Update the participants property in the new object
                    newDataObject.participants = [updated_participants];
    
                    // Dispatch the action to update the state
                    dispatch(setTable({ option: "allDataApis", value: newDataObject }));
                } else {
                    setOpenDialogError(true);
                }
            })
            .catch(error => {
                // An error occurred during the API call
                setOpenDialogError(true);
                setErrorInfo({ title: "Error", description: "Error in updating fusions", type: "alert" });
            });
    };


    const deleteFusions = () => {

        let {fusion_obj_renaming, report_id} = cleanFusionObject(data, state, props.patients);

        const requiredKeys = ['fusioned_genes', 'junction_read_count', 'left_gene', 'right_gene', 'sample'];

        // keep only required keys of the fusion_obj_renaming
        const newObj = {}
        requiredKeys.forEach(key => {
            newObj[key] = fusion_obj_renaming[key];
        });

        deleteFusionsData(newObj, report_id);

    }

    const deleteFusionsData = (fusions_obj, report_id) => {
        let phenostore_api = config.apis_configuration.api_phenostore;
    
        
        delete_fusions(phenostore_api, props.token, fusions_obj, report_id)
            .then(r => r.json())
            .then((data) => {
                const message = data.message;
    
                if (message && message.includes("Deleted")) {
                    setDeleted(true);
                    setUpdated(false);
    
                    const tagged_name = "gene_fusions";
                    let updated_participants = { ...allDataApis.participants[0] };
                    
                    if (updated_participants[`tagged_${tagged_name.toLowerCase()}`] && Array.isArray(updated_participants[`tagged_${tagged_name.toLowerCase()}`])) {
                       if(dataType === "Fusions")
                        {

                            

                            updated_participants[`tagged_${tagged_name.toLowerCase()}`] = updated_participants[`tagged_${tagged_name.toLowerCase()}`]
                                    .filter(item => {return !fusionMatchingRule(item, fusions_obj)});
                                }
                       }
                        let newDataObject = { ...allDataApis };
                        newDataObject.participants = [updated_participants];
                        dispatch(setTable({ option: "allDataApis", value: newDataObject }))
    
                  
                } else {
                    setOpenDialogError(true);
                }
            })
            .catch(error => {
                // An error occurred during the API call
                setOpenDialogError(true);
                setErrorInfo({ title: "Error", description: "Error in deleting fusions", type: "alert" });
            });
    };

    const reset = () => {
        setOpen(false);
        setUpdated(false);
        setDeleted(false);
        setSubmitted(false);
        setState({causative:false});
        setTagVisibility(false);
        setVariantFlagged(false);
        setInitialized(false);
        resetData();
    };


    const handleClose = () => {
        setShouldRunEffect(false);
        reset()
    };

    const onSelectedOption = (name, key) => {

        //works

        let current_data = {...data};

        if(key=== "project" && name.value === "No Project"){
            current_data[key] = "";
        }
        else if(key === "sample"){

            let sample_to_tag = props.samples.find(s => s.sample_id === name.value);

            let participant_to_tag = sample_to_tag?.pheno_id !== undefined
                ? sample_to_tag.pheno_id
                : index_case;

            // change the "index case" to the one that is being tagged
            setIndexCaseID(participant_to_tag);
            current_data[key] = name.value;
            setData(current_data);
        }
        else{
            current_data[key] = name.value;
            setData(current_data);
            if(key === "sample"){
                getProjects(current_data["sample"])
            }
        }

        setDataToPost(current_data);

    };

    // add comments;
    const handleTextChange = (e) => {

        let current_data = {...data};
        current_data["comments"] =  e.target.value;
        setData(current_data);
        setDataToPost(current_data);
    };


    const submissionOutput = () => {

        const dataType = getRowDataType();

        let operationType = updated ? "updated" : deleted ? "deleted" : "tagged";

        return (
                <Grid container direction="column">
                    <Grid item lg={12}>
                        <AfterSubmit 
                            operationType={operationType}
                            dataType={dataType}
                            index_case={index_case}
                            state={state}
                            setUpdated={setUpdated}
                            setSubmitted={setSubmitted}
                            analysisType={analysisType}
                        />
                    </Grid>
                </Grid>
            );
    };



    // TODO: Move
    const getProjects = async (sample) => {

        // extract projects;
        // get sample project

        // now collect experiments
        const data_object = {
            "page": 1,
            "fields": ["Participant_ID", "ExperimentID", "LocalExperimentID", "Owner", "project", "erns"],
            "pageSize": 100,
            "sorted": [],
            "filtered": [{"id": "ExperimentID", "value": [sample]}],
            "aggregates": ["project", "tissue", "erns"]
        }

        let response = await experiments_view_new(window.config.apis_configuration.api_data_management, props.token, data_object)
        let json = await response.json()

        let project = (json["items"].length > 0) ? json["items"].map(s => {
            return {sample: s.ExperimentID, project: s.project}
        }) : [];


        let array = (project.length> 0 && project[0].hasOwnProperty("project"))
            ? project[0].project.split(" ").map(s => {

                let val = (s === "") ? "No Project" : s;

                return {label: val, value: val}})
            : []

        setProjects(array);

    }


    const submissionFormRender = () => {

        return <SubmissionForm
            formOptions={formOptions}
            tagVisibility={tagVisibility}
            deleteVariantData={deleteVariantData}
            variant_flagged={variant_flagged}
            data={data}
            onSelectedOption={onSelectedOption}
            projects={projects}
            getRowDataType={getRowDataType}
            handleTextChange={handleTextChange}
            handleCausativeChange={handleCausativeChange}
            state={state}
        />

    };


    const renderGenericForm = (isFlagged, deleteSpec, dataType, mappedTextIntro) => {
        return (
            <GenericForm
                isFlagged={isFlagged}
                deleteFunction={deleteData}
                deleteSpec={deleteSpec}
                dataType={dataType}
                handleTextChange={handleTextChange}
                dataComments={data.comments}
                mappedTextIntro={mappedTextIntro}
                deleteMappedText={"Delete tag"}
            />
        );
    };

    
    const treatmentFormRender = () => {
        return renderGenericForm(treatment_flagged, delete_treatment, "Treatment_Somatic", "clinical evidence");
    };

    const pharmacogenomicsFormRender = () => {
        return renderGenericForm(pharmacogenomics_flagged, delete_pharmacogenomics, "Pharmacogenomics_Germline", "Haplotype");
    };

    const fusionsFormRender = () => {
        return (
            <SubmissionFormFusions
                formOptions={formOptions}
                isFlagged={variant_flagged}
                deleteFunction={deleteFusions}
                //deleteSpec={delete_fusions}
                dataType={"Fusions"}
                handleTextChange={handleTextChange}
                dataComments={data.comments}
                mappedTextIntro={"Fusion"}
                deleteMappedText={"Delete tag"}
                data={data}
                onSelectedOption={onSelectedOption}
                state={state}
                handleCausativeChange={handleCausativeChange}
            />
        );
    };

    const showContent = () => {
        const tag = getSelectedTableRow();

        if (!tag) return null;

        const dataType = getRowDataType();

        // Handle different data types or variant properties
        switch (dataType) {
            case "SNV_Germline":
                return submissionFormRender();
            case "Fusions":
                return fusionsFormRender();
            
            default:
                if (tagIsTreatment(tag)) {
                    return treatmentFormRender();
                }

                if (tagIsPharmacogenomics(tag)) {
                    return pharmacogenomicsFormRender();
                }

                const CNV_FOUND = variantFinder(tag, [], props.tagged_variants, "CNV");
                return CNV_FOUND && CNV_FOUND.length !== 0
                    ? <Fragment>{submissionOutput()}</Fragment>
                    : submissionFormRender();
        }
    };

// Helper functions
    const tagIsTreatment = (tag) => {
        return "Therapies" in tag || "Evidence_Source_link" in tag;
    };

    const tagIsPharmacogenomics = (tag) => {
        return "Haplotype1" in tag || "pgx_uri" in tag;
    };


    const handleCloseDialogError = () => {


        setOpenDialogError(false);
        setErrorInfo({
            title: "",
            description: "",
            type: "warning"
        })

    }

    const getButtons = () => {

        let dataRow = getSelectedTableRow();
        let dataType = getRowDataType();

        if(dataType === "SNV_Germline" || dataType === "SNV_Somatic"){
            return <React.Fragment>
                <CaButton variant="outlined" onClick={handleClose} color="primary" text={"Cancel"}/>

                <CaButton variant="contained" onClick={handleSubmit} color="primary" autoFocus text={"Submit"}/>


            </React.Fragment>
        }
        else{
            if(dataRow){
                let found = variantFinder(dataRow, [], props.tagged_variants, dataType);
                // if the variant is found, there is no other action than closing the dialog
                if(found!== undefined && found.length !== 0 ){
                    return  <React.Fragment>
                        <CaButton text={"Close"} variant={"contained"} onClick={handleClose}/>
                    </React.Fragment>
                }
                else{
                    return <React.Fragment>
                        <CaButton text={"Cancel"} variant={"contained"} onClick={handleClose} />

                        <CaButton onClick={handleSubmit} text={"Submit"} autoFocus />
                    </React.Fragment>
                }
            }
            else{
                return null;
            }

        }

    }




    let dataRow = getSelectedTableRow();
    let dataType = getRowDataType();
    let found = false;
    if(dataRow){

        found = variantFinder(getSelectedTableRow(), [], props.tagged_variants, dataType);

    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <GPAP_Dialog_Title id="alert-dialog-title" onClose={handleClose}>
                    {
                        getTitle(tagVisibility, dataType, found, {submitted, updated, deleted} )
                    }
                </GPAP_Dialog_Title>
                <DialogContent>
                    {
                        (!submitted && !updated && !deleted)
                            ? showContent()
                            : submissionOutput()
                    }
                </DialogContent>
                <DialogActions>
                    {
                        (!submitted && !updated && !deleted)
                            ? getButtons()
                            : <CaButton text={"Close"} variant="contained" onClick={handleClose}/>


                    }
                </DialogActions>
            </Dialog>
            <GPAP_Dialog
                open={openDialogError}
                handleClose={handleCloseDialogError}
                title={errorInfo.title}
                text={errorInfo.description}
                onConfirmText={"ok"}
                onExitText={false}
                onConfirmAction={handleCloseDialogError}
                type={errorInfo.type}
            />
            <TagButton handleClickOpen={handleClickOpen}/>
        </Fragment>
    );
}


function mapStateToProps (state) {


    if(state.authorization!==undefined)
    {
        return { studySettings: state.studySettings,
            studyBucket: state.studyBucket,
            selected_table_rows: state.selected_table_rows,
            samples: state.sample_list.samples,
            token: state.authorization.token,
            user: state.authorization.preferredName,
            patients: state.patients
        }
    }

}

// to send data from MainPanel component to the Store;



// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    studyActions: bindActionCreators(StudyActions, dispatch)
});



TagDialog.propTypes = {
    tagged_variants: PropTypes.arrayOf(PropTypes.string).isRequired,
    annotations: PropTypes.object.isRequired,
    annotate_variants: PropTypes.func.isRequired,
    getAllTaggedVariants: PropTypes.func.isRequired,
    dataType: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TagDialog);
