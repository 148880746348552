import React from "react";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import { renderToStringAlt } from "./renderToString";

function AlertInfoViews({ text }) {
    return (
        <Box py={2}>
            <Alert severity="info">{renderToStringAlt(text)}</Alert>
        </Box>
    );
}

export default AlertInfoViews;
