import {
    inheritance_opts,
    origin_opts, origin_fusion_opts,
    significance_opts_germline,significance_opts_somatic,
    related_to_opts, 
    zygosity_opts
} from "../tag_form_options";

import { getVariantType } from "../init/getSvType";

export const createDataOptions = (samples, genes, transcripts, dataType, analysisType) => {
    
    
    if(dataType === "Fusions") {
        return ({
            samples,
            genes:genes.map(g => {return { label: g, value: g }}),
            significance_opts:  significance_opts_somatic,
            related_to: related_to_opts,
            variant_type: getVariantType(dataType),
        })
    }
    else {
        return ({
            samples,
            genes:genes.map(g => {return { label: g, value: g }}),
            transcripts,
            zygosity_opts,
            inheritance_opts,
            significance_opts: ((typeof dataType === "string" && dataType.toLowerCase().includes("somatic"))
                ? significance_opts_somatic
                : significance_opts_germline ),
            origin: (dataType=== "Fusions") ? origin_fusion_opts : origin_opts, // add also the somatic specific opts
            variant_type: related_to_opts,
            sv_type: getVariantType(dataType),
        })
    }
    
  };