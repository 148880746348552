

/* CONFIGURATION FILE FOR FIELDS INCLUDED IN THE EXPORT FILE*/


const PREDICTIVE_COLUMNS = ["cadd_phred", "sift_pred", "sift_score", "polyphen2_hvar_pred", "polyphen2_hvar_score", "mutationtaster_pred", "mt", "phylop46way_placental", "gerp_rs", "siphy_29way_pi","spliceai_max_ds","spliceai_ds_ag","spliceai_ds_al","spliceai_ds_dg","spliceai_ds_dl","intervar_automated","intervar_evidence","revel_score","clinvar_clnsig"];

const POPULATION_COLUMNS= ["exac", "gp1_af", "gp1_afr_af", "gp1_asn_af", "gp1_eur_af",  "gnomad_af", "gnomad_ac", "gnomad_an", "gnomad_af_popmax", "gnomad_ac_popmax", "gnomad_an_popmax", "gnomad_filter","freqIntGermline"];



const getColumnKeys = (genomeRef) => {

    return [
        "Chrom",
        "Pos",
        "Ref",
        "Alt",
        "dbSNP",
        "Experiment IDs",
/*         transcript columns
 */        "Gene Name",
        "Gene BioType",
        "Transcript ID",
        "Transcript BioType",
        "Effect",
        "Effect Impact",
        "Functional Class",
        "Codon Change",
        "Amino Acid Change",
        "Amino Acid length",
        "Genotype Number",
        "Exon Rank",
        (genomeRef === "hg38") ? "Mane Select" : null,
        /*         predictor annotation columns
 */ 
        "CADD",
        "SIFT",
        "SIFT score",
        "Polyphen2",
        "Polyphen2 score",
        "MT",
        "MT score",
        "phyloP",
        "GERP++",
        "SiPhy",
        "SpliceAI max ds",
        "SpliceAI ds ag",
        "SpliceAI ds al",
        "SpliceAI ds dg",
        "SpliceAI ds dl",
        "Intervar",
        "Intervar evidence",
        "Revel",
        "Clinvar",
        /*         population columns
 */ 
        "ExAC",
        "1000GP AF",
        "1000GP AFR AF",
        "1000GP ASN AF",
        "1000GP EUR AF",
        "gnomAD AF",
        "gnomAD AC",
        "gnomAD AN",
        "gnomAD AF_POPMAC",
        "gnomAD AC_POPMAX",
        "gnomAD AN_POPMAX",
        "gnomAD filter status",
        "Internal Frequency",
    ]

}



const getTranscriptColumns = (genomeRef)    => {

    return ["gene_name", "gene_coding", "transcript_id", "transcript_biotype", "effect", "effect_impact","functional_class", "codon_change", "amino_acid_change", "amino_acid_length", "geno_type_number", "exon_rank",(genomeRef === "hg38") ? "mane_select" : null,];


}

export {getColumnKeys, PREDICTIVE_COLUMNS, POPULATION_COLUMNS, getTranscriptColumns}
