import { Paragraph, TextRun } from "docx";
import { createSpacer } from "../../write-sections/write_utils/documentHelpers";
import { renderToStringAlt } from "../../../create-report/shared-components/renderToString";

function WritePharmaInterpretation(sectionData, analysisType) {
  let rows = [];

  if (sectionData !== null && sectionData.length > 0) {
    rows = sectionData;
  }

  const renderRows = (rows) => {
    const sections = rows.map((row) => {
      let gene = row.gene !== undefined ? row.gene : "NA";
      const text = `${gene}:${row.genotype}`;
      const titlePar = new Paragraph({
        children: [
          new TextRun({
            text: text,
            bold: true,
          }),
        ],
        spacing: { after: 40, before: 40 },
      });

      let commentInterpretation = row?.comments ?? row.interpretation;

      const commentText =commentInterpretation || renderToStringAlt("No additional information provided.");
      const CommentPar = new Paragraph({
        children: [
          new TextRun({
            text: commentText,
            italics: !commentInterpretation,
            color: !commentInterpretation ? "888888" : "000000",
          }),
        ],
      });

      const spacerPar = createSpacer(50, 50);

      return [spacerPar, titlePar, CommentPar];
    });
    return sections.flat();
  };

  const pharmaSections = renderRows(rows);

  if (rows.length === 0) {
    return [];
  }

  const title = new Paragraph({
    children: [
      new TextRun({
        text: renderToStringAlt("Haplotype Interpretation"),
        bold: true,
        size: 22,
      }),
    ],
  });

  return [title, ...pharmaSections];
}

export default WritePharmaInterpretation;
