
import React, { useState } from 'react';
import {Grid, Typography} from "@mui/material";
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { AnalysisTypeShowExperiments } from './AnalysisTypeShowExperiments';
import MappedText from '../../reports/create-report/shared-components/mapText';


export const ExperimentsAssociated = (props) => {

    const [searchByParticipant, setSearchByParticipant] = useState(false);

    const { 
        analysis_type, 
        error_tumor_experiment_id, 
        error_control_experiment_id,
        handleSearchExperiment, 
        handleSearchParticipant,
        control_experiment_id, 
        tumor_experiment_id,
        control_participant_id, 
        tumor_participant_id 
    } = props;

    const handleChangeSearchType = (event) => {        
        setSearchByParticipant(event.target.checked);
    };


    // Typography weigth
    let weigthExperiment = (searchByParticipant === true ? '300' : '600' );
    let weigthParticipant = (searchByParticipant === true ? '600' : '300' );

    return(
        <>
            <Grid item lg={12}>
                <Typography variant={"h6"}> <MappedText text={"Experiments"}/></Typography>
                
                
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography sx={{ fontWeight:weigthExperiment }}> <MappedText text={"Search by Experiment ID"}/> </Typography>
                    <Switch checked={searchByParticipant} onChange={handleChangeSearchType}/>
                    <Typography sx={{ fontWeight:weigthParticipant }}><MappedText text={"Search by Patient ID"}/></Typography>
                </Stack>


                <Typography variant="body1" gutterBottom
                            style={{marginTop: "20px"}}
                            color={"textSecondary"}>
                   <MappedText text={"Associate Experiment IDs to the request."} />
                    <br/><MappedText text={"Only experiments that have been processed and are ready for analysis are visible."} />
                </Typography>
            </Grid>

            {/*Conditional Experiment Viewer*/}
            <AnalysisTypeShowExperiments
                analysis_type={analysis_type}
                searchByParticipant={searchByParticipant}
                error_tumor_experiment_id={error_tumor_experiment_id}
                error_control_experiment_id={error_control_experiment_id}
                handleSearchExperiment={handleSearchExperiment}
                handleSearchParticipant={handleSearchParticipant}
                control_experiment_id={control_experiment_id}
                tumor_experiment_id={tumor_experiment_id}
                control_participant_id={control_participant_id}
                tumor_participant_id={tumor_participant_id}
            />
        </>
    );
}
