import React from "react";
import {Box, Typography, Chip} from "@mui/material";
import MappedText from './mapText';
import { Title } from "./general-table/Title";



export default function InterpretationTitle({fields, dataType, selected}){

    return  <Box>
    <Typography variant="body1" color={selected ? "inherit" : "textSecondary"}>
        <Title {...fields} dataType={dataType} />
        {!selected && <Chip label={"Hidden " + dataType} />}
    </Typography>
    {dataType === "variant" && <Typography variant="body2"><b><MappedText text="Genomic Position:"/> {fields.field4}</b></Typography>}
    {dataType === "fusion" && <Typography variant="body2"><b><MappedText text="Left and Right Breakpoints:"/> {fields.field4}</b></Typography>}
</Box>
};


