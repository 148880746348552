
import React from "react";
import {
  ExpandMore,
  KeyboardArrowRight,
  ArrowDownward,
  ArrowUpward,
} from "@mui/icons-material"; 
import { Checkbox, IconButton } from "@mui/material";

import FlaggedBadge from "../../../../scenes/dashboard/components/genomics-dashboard/components/results/components/variant-table/gpap-table/FlaggedBadge";
import FlagIcon from "@mui/icons-material/Flag";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const FlaggedIcon = ({ isFlagged, isCausative }) => {
  if (!isFlagged) return null;
  
  return (
    <FlaggedBadge badgeContent={1}>
      <FlagIcon style={{ color: isCausative ? "red" : "#3182bd" }} />
    </FlaggedBadge>
  );
};


const useOptionFilter = (columns, type, states, setExpandedRows, data) => {
  const dataRef = React.useRef(states?.mainData);
  dataRef.current = states?.mainData;
  return (hooks) => {
    hooks.visibleColumns.push((columns) => {
      let newColumns = [...columns];

      if (type === "toggle") {
        newColumns = [
          {
            id: "selection",
            disableFilters: true,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...newColumns,
        ];
      }

      if (type === "expanding") {
        newColumns = [
          {
            id: "expansion",
            disableFilters: true,
            Header: ({ column }) => (
              <div {...column.getSortByToggleProps()}>
                Tag
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <IconButton>
                      <ArrowDownward fontSize="inherit" />
                    </IconButton>
                  ) : (
                    <IconButton>
                      <ArrowUpward fontSize="inherit" />
                    </IconButton>
                  )
                ) : (
                  ""
                )}
              </div>
            ),
            canSort: true,
            sortType: (rowA, rowB) => {
              const isFlaggedA = rowA.original.isFlagged ? 1 : 0;
              const isFlaggedB = rowB.original.isFlagged ? 1 : 0;
              return isFlaggedB - isFlaggedA;
            },
            Cell: ({ row }) => (
              <div>
                <span
                  {...row.getToggleRowExpandedProps()}
                  onClick={(e) => {
                    e.stopPropagation();
                    const newData = dataRef.current[row.index];
                    setExpandedRows((prevState) => ({
                      ...prevState,
                      [row.id]: {
                        expanded: true,
                        data: [newData],
                      },
                    }));
                    states.setRowIdSelected(row.id);
                    states.setIdExpanded({
                      id: row.id,
                      expanded: true,
                    });
                    row.toggleRowExpanded();
                  }}
                >
                  {row.isExpanded ? (
                    <div>
                      <IconButton aria-label="expandMore" color="black">
                        <ExpandMore />
                      </IconButton>
                      <FlaggedIcon isFlagged={row.original.isFlagged} isCausative={row.original.isCausative} />
                    </div>
                  ) : (
                    <div>
                      <IconButton aria-label="keyboardArrowRight" color="black">
                        <KeyboardArrowRight />
                      </IconButton>
                      <FlaggedIcon isFlagged={row.original.isFlagged} isCausative={row.original.isCausative} />
                    </div>
                  )}
                </span>
              </div>
            ),
          },
          ...newColumns,
        ];
      }

      return newColumns;
    });
  };
};

export default useOptionFilter;