import React, { Component } from "react";
// import children
import "../css/variant_table.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as StudyActions from "../../../../../../../reducers/study_actions";
import ChromosomeChart from "../../chromosome-chart/ChromosomeChart";

import LeftPanel from "../../../../variant-tabs/ExternalResources";
import { processVariantData } from "../variant-helpers";
import SNV_Table from "./SNV_Table";
import { getAnalysisAnnotations, getStudy } from "../getAnnotations";
import ParticipantTable from "../../../../../../../../../components/tables/ParticipantTable/ParticipantTable";
import { prepareVariantsTable } from "./variant_finder";
import AnalysesTabs from "../../../analyses-tabs/AnalysesTabs";
import QueryTabs from "../../../QueryTabs";
import MappedText from "../../../../../../../../instand/reports/create-report/shared-components/mapText";


// MUI 5 Imports
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";

// Static Variables
import { STATIC_VARIABLES } from "../../../../../../../../../config-files/const_variables";
import { getCurrentQuery } from "../../query-applied-filters/query_difference";

// Custom Components
import AccordionInfo from "../component/AccordionInfo";
import CustomCNVTable from "../../../../../../../../../components/tables/customVariantTable/CustomTableForCNVs";

// CNV Configuration
import { additional_info_1, additional_info_2 } from "./cnv_config/additional_info";
import CNV_DATA_HEADER_FULL_v2 from "./cnv_config/headers/data_header_full_v2";
import CNV_DATA_HEADER_SPLIT_v2 from "./cnv_config/headers/data_header_split_v2";

// GPAP General Actions
import * as GPAP_General_Actions from "../actions";

// New Toolbar Component
import ToolBar_New from "../../toolbar/toolbar-new/ToolBar_New";
import {Alert} from "@mui/material";

class Variant_Table extends Component{

    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            selectedRows: [],
            viewMode: true,
            tableType: true,
            selectedVariant: [],
            selectedParticipants: [],
            selectedExperiments: [],
            from:0,
            cnv_number: 0,
            snackMessage: false,
            loadingCNV: false
    };


        // config

        this.getTableDataContent = this.getTableDataContent.bind(this);
        this.setViewMode = this.setViewMode.bind(this);
        this.getTable = this.getTable.bind(this);
        this.annotate_variants = this.annotate_variants.bind(this);
        this.collectVariants = this.collectVariants.bind(this);
        this.getParticipantTable = this.getParticipantTable.bind(this);
        this.getCNVTable = this.getCNVTable.bind(this);
        this.setTableType = this.setTableType.bind(this);
        this.selectParticipant = this.selectParticipant.bind(this);
        this.selectedExperiments = this.selectedExperiments.bind(this);
        this.passGenes = this.passGenes.bind(this);
        this.getCNVNumber = this.getCNVNumber.bind(this);
        this.setSelectedCNV = this.setSelectedCNV.bind(this);
        this.handleSnackClose = this.handleSnackClose.bind(this);
        this.setLoadingCNVData = this.setLoadingCNVData.bind(this);
    }


    collectVariants(){
        let {studySettings, studyBucket} = this.props;
        let study = getStudy(studySettings, studyBucket);

        if(study!== undefined && study.study!== undefined){
            this.setState({
                tagged_variants: study.study.tagged_variants
            })
        }

    }

    componentDidMount() {

        this.collectVariants()

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.selected_table_rows !== prevProps.selected_table_rows){

            console.log("Variant Table selected", this.props.selected_table_rows)
            let selectedVariants = this.props.selected_table_rows?.[0]?.data_row;

            let selectedExperiments = selectedVariants?.[0]?.samples_germline?.map(s => s.sample) || [];

            this.setState({
                selectedVariants: selectedVariants,
                selectedExperiments: selectedExperiments
            });

        }




    }

    setLoadingCNVData(bool){
        this.setState({
            loadingCNV:bool
        })
    }

    getTableDataContent(){

        let { variants } =this.props;
        const data = prepareVariantsTable(variants, this.state.viewMode);
        return {
            data: data,
            variants: data
        }

    }

    annotate_variants = (type, extra, operation_type) => {

        let { study_actions, studySettings, selected_table_rows} = this.props;

        let selected_germline = selected_table_rows.find(s => s.data_type === "SNV_Germline");
        let selected_somatic = selected_table_rows.find(s => s.data_type === "SNV_Somatic");

        let selected_rows = [];
        if(selected_germline && selected_germline.data_row.length>0) {
            selected_rows= selected_germline.data_row;
        }
        else {
            selected_rows= selected_somatic.data_row;
        }

        if(selected_rows)
        {
            study_actions.annotate_variants(
                {
                    query_id: studySettings.current_query,
                    analysis_id: studySettings.current_analysis,
                    study_id: studySettings.id,
                    variants: selected_rows,
                    label: extra,
                    type: type,
                    operation_type: operation_type
                }
            );
        }

        this.collectVariants()

    };




    setViewMode(bool){

        this.setState({viewMode:bool})  //true => variant table, false => Functional Mode


    }

    selectParticipant = (participants, fromParticipantTable) => {

        let selected = participants;
        let experiment_ids = [];
        if(participants!== "ALL_SAMPLES"){
            experiment_ids  = this.props.participants.map_by_exp.filter(s => s.Participant_ID === selected[0]).map(c => c.ExperimentID);
        }
        else{
            experiment_ids  = participants;
        }


        // pass the genes of the experiment IDS

        this.props.runSearchAcrossQuery([],experiment_ids, fromParticipantTable);


        this.setState({
            selectedParticipants: participants
        });


    };

    selectedExperiments = (experiments, genes) => {


        this.setState({
            selectedExperiments:  experiments
        });

        // shoot query with genes;

        this.props.runSearchAcrossQuery(genes, experiments)



    };

    passGenes = (genes) => {


        this.setState({
            selectedGenes:  genes
        });

        this.props.runSearchAcrossQuery(genes, this.state.selectedExperiments)

    };

    getCNVNumber = (summary) =>{


        const {full_tot, split_tot, tot} = summary


        this.setState({
            cnvNumber: tot,
            cnvRegions: full_tot
        });


        this.setState({
            cnvNumber: tot
        });
        this.props.getCNVTotal(summary);
        // open the snack bar if error is found;

        this.setState({snackMessage: (tot === -1)})
    }

    handleSnackClose = () => {

        this.setState({snackMessage:false})
    }


    setSelectedCNV = (cnv_row) => {


        let {gpap_general_actions} = this.props;


        if(cnv_row){

            gpap_general_actions.setSelectedTableRows({
                data_row : [cnv_row],
                data_type: "CNV",
            })

        }



    }

    getCNVTable(){


        const self = this;
        let url = window.config.apis_configuration.api_nextgpap_server_elastic + "/api/cnvs";
        let query = getCurrentQuery(this.props.studyBucket.studies, this.props.studySettings);
        let appendixTitle = "- no filters applied"
        if (this.props.genes_state && this.props.genes_state.length > 0)
        { appendixTitle="Genes filter applied";}

     
        // headers to show
        let cnv_full_data_header = CNV_DATA_HEADER_FULL_v2;
        let cnv_split_data_header = CNV_DATA_HEADER_SPLIT_v2;

        const isSNVSelected = self.props.selected_table_rows.some(s =>
            (s.data_type === "SNV_Somatic" || s.data_type === "SNV_Germline") && s.data_row.length > 0
        );
    
        return <Box p={1} style={{float: "left", width: "100%"}}>
            <Accordion defaultExpanded={false}>
                <AccordionInfo
                               loading={(this.state.loadingCNV)}
                               title={ "CNVs and SVs"}
                               total={this.state.cnvNumber}
                               appendixTitle = {appendixTitle}
                               tooltipTitle= {<MappedText text="The displayed number is the total sum of events in the FULL and SPLIT results."/>}
                />
                <AccordionDetails>
                <Box style={{ overflow: "auto", overflowY: "hidden", maxHeight: "100%" }}>
                    <CustomCNVTable
                      url={url}
                      method={"POST"}
                      token={this.props.token}
                      locationData="items"
                      dataType={"CNV"}
                      experiment={this.props.experiment}
                      genes={this.props.genes_state}
                      getTotal = {this.getCNVNumber}
                      setSelected = {this.setSelectedCNV}
                      configFilter={[
                        {
                            full: {
                            dataSearch: "Annotation_mode",
                            keyFilter: "full",
                          },
                        },
                        {
                          split: {
                            dataSearch: "Annotation_mode",
                            keyFilter: "split",
                          },
                        },
                      ]}
                      dataHeader={cnv_full_data_header}
                      compactColumns = {[
                    {
                        referenceTable: "ACMG Classific.",
                        nameColumn: "AnnotSV reported pathogenic",
                        tooltip: "Phenotype of the pathogenic genomic regions completely overlapped with the SV. Source is displayed in the dbVarNR (dbVar), ClinVar (CLN), ClinGen (TS3).",
                        dataSearch: "SV_type",
                        position: "right",
                        rowSelect: [
                    {
                        replaceWith: "P_gain_phen",
                        dataSearch: "P_gain_phen",
                        value: "DUP",
                    },
                    {
                        replaceWith: "P_loss_phen",
                        dataSearch: "P_loss_phen",
                        value: "DEL",
                    },
                    {
                        replaceWith: "P_ins_phen",
                        dataSearch: "P_ins_phen",
                        value: "INS",
                    },
                        ],
                    },
                    {
                        referenceTable: "Internal Freq.",
                        nameColumn: "AnnotSV population AFMax",
                        tooltip: "Maximum allele frequency of the reported benign genomic regions completely overlapped with the SV. Source is displayed in the tooltip: gnomAD, ClinVar (CLN), ClinGen (TS40), DGV (dgv, nsv or esv), DDD, 1000 genomes (1000g), Ira M. Hall’s lab (IMH), Children’s Mercy Research Institute (CMRI). This filter applies values equal to or less than the specified threshold.",
                        dataSearch: "SV_type",
                        position: "left",
                        rowSelect: [
                    {
                        replaceWith: "B_gain_AFmax",
                        dataSearch: "B_gain_AFmax",
                        value: "DUP",
                    },
                    {
                        replaceWith: "B_loss_AFmax",
                        dataSearch: "B_loss_AFmax",
                        value: "DEL",
                    },
                    {
                        replaceWith: "B_ins_AFmax",
                        dataSearch: "B_ins_AFmax",
                        value: "INS",
                    },
                        ],
                    },
                    ]}
                      compactColumnsSplit= {[
                    {
                        referenceTable: "OMIM",
                        nameColumn: "AnnotSV reported pathogenic",
                        tooltip: "Phenotype of the pathogenic genomic regions completely overlapped with the SV. Source is displayed in the dbVarNR (dbVar), ClinVar (CLN), ClinGen (TS3).",
                        dataSearch: "SV_type",
                        position: "right",
                        rowSelect: [
                    {
                        replaceWith: "P_gain_phen",
                        dataSearch: "P_gain_phen",
                        value: "DUP",
                    },
                    {
                        replaceWith: "P_loss_phen",
                        dataSearch: "P_loss_phen",
                        value: "DEL",
                    },
                    {
                        replaceWith: "P_ins_phen",
                        dataSearch: "P_ins_phen",
                        value: "INS",
                    },
                        ],
                    },
                    {
                        referenceTable: "Internal Freq.",
                        nameColumn: "AnnotSV population AFMax",
                        dataSearch: "SV_type",
                        position: "left",
                        rowSelect: [
                    {
                        replaceWith: "B_gain_AFmax",
                        dataSearch: "B_gain_AFmax",
                        value: "DUP",
                    },
                    {
                        replaceWith: "B_loss_AFmax",
                        dataSearch: "B_loss_AFmax",
                        value: "DEL",
                    },
                    {
                        replaceWith: "B_ins_AFmax",
                        dataSearch: "B_ins_AFmax",
                        value: "INS",
                    },
                        ],
                    },
                    ]}
                      tooltipCompact={ [
                    {
                        referenceTable: "AnnotSV reported pathogenic",
                        dataSearch: "SV_type",
                        rowSelect: [
                    {
                        replaceWith: "P_loss_source",
                        dataSearch: "P_loss_source",
                        value: "DEL",
                    },
                        ],
                    },
                    {
                        referenceTable: "AnnotSV reported pathogenic",
                        dataSearch: "SV_type",
                        rowSelect: [
                    {
                        replaceWith: "P_gain_source",
                        dataSearch: "P_gain_source",
                        value: "DUP",
                    },
                        ],
                    },
                    {
                        referenceTable: "AnnotSV reported pathogenic",
                        dataSearch: "SV_type",
                        rowSelect: [
                    {
                        replaceWith: "P_ins_source",
                        dataSearch: "P_ins_source",
                        value: "INS",
                    },
                        ],
                    },
                    {
                        referenceTable: "AnnotSV population AFMax",
                        dataSearch: "SV_type",
                        rowSelect: [
                    {
                        replaceWith: "B_loss_source",
                        dataSearch: "B_loss_source",
                        value: "DEL",
                    },
                        ],
                    },
                    {
                        referenceTable: "AnnotSV population AFMax",
                        dataSearch: "SV_type",
                        rowSelect: [
                    {
                        replaceWith: "B_gain_source",
                        dataSearch: "B_gain_source",
                        value: "DUP",
                    },
                        ],
                    },
                    {
                        referenceTable: "AnnotSV population AFMax",
                        dataSearch: "SV_type",
                        rowSelect: [
                    {
                        replaceWith: "B_ins_source",
                        dataSearch: "B_ins_source",
                        value: "INS",
                    },
                        ],
                    },
                    ]}
                      tooltipCompactSplit = {[
                    {
                        referenceTable: "AnnotSV reported pathogenic",
                        dataSearch: "SV_type",
                        rowSelect: [
                    {
                        replaceWith: "P_loss_source",
                        dataSearch: "P_loss_source",
                        value: "DEL",
                    },
                        ],
                    },
                    {
                        referenceTable: "AnnotSV reported pathogenic",
                        dataSearch: "SV_type",
                        rowSelect: [
                    {
                        replaceWith: "P_gain_source",
                        dataSearch: "P_gain_source",
                        value: "DUP",
                    },
                        ],
                    },
                    {
                        referenceTable: "AnnotSV reported pathogenic",
                        dataSearch: "SV_type",
                        rowSelect: [
                    {
                        replaceWith: "P_ins_source",
                        dataSearch: "P_ins_source",
                        value: "INS",
                    },
                        ],
                    },
                    {
                        referenceTable: "AnnotSV population AFMax",
                        dataSearch: "SV_type",
                        rowSelect: [
                    {
                        replaceWith: "B_loss_source",
                        dataSearch: "B_loss_source",
                        value: "DEL",
                    },
                        ],
                    },
                    {
                        referenceTable: "AnnotSV population AFMax",
                        dataSearch: "SV_type",
                        rowSelect: [
                    {
                        replaceWith: "B_gain_source",
                        dataSearch: "B_gain_source",
                        value: "DUP",
                    },
                        ],
                    },
                    {
                        referenceTable: "AnnotSV population AFMax",
                        dataSearch: "SV_type",
                        rowSelect: [
                    {
                        replaceWith: "B_ins_source",
                        dataSearch: "B_ins_source",
                        value: "INS",
                    },
                        ],
                    },

                    ]}
                      chipFull ={[
                    {
                        referenceColumn: "ACMG Classific.",
                        cells: [
                    {
                        data: "Likely pathogenic",
                        color: "#FD7645",
                        textColor: "white",
                    },
                    {
                        data: "Benign",
                        color: "#31A354",
                        textColor: "white",
                    },
                    {
                        data: "Uncertain significance",
                        color: "grey",
                        textColor: "white",
                    },
                    {
                        data: "Pathogenic",
                        color: "#DD4434",
                        textColor: "white",
                    },
                        ],
                    },
                    ]}
                      nestedInfo={additional_info_1}
                      split={cnv_split_data_header}
                      nestedInfoB={additional_info_2}
                      customFeatures = {[
                            { switchFS: true },
                            ]}
                      buttons={[
                        {
                          nameButton: "Population",
                          type: "popover",
                          template: "Population",
                          data: ["B_gain_AFmax", "B_loss_AFmax", "B_ins_AFmax", "B_inv_AFmax"],
                        },
                      ]}
                      queryID = {this.props.studySettings.current_query}
                      setLoading = {this.setLoadingCNVData}
                      loadingData = {this.state.loadingCNV}
                      // how can we make this more generic?
                      getAllTaggedVariants = {self.props.getAllTaggedVariants}
                      all_tagged_variants = {self.props.all_tagged_variants}
                      isSNVSelected = {isSNVSelected}
                    />
                    </Box>
                </AccordionDetails>

            </Accordion>
        </Box>
    }


    getParticipantTable(){

        console.log("render participant table");
        let data_table = {...this.props.participants};

       /* // selected experiments
        if(this.state.selectedExperiments.length!==0){

            // take the last entered
            let selectedExperiments = this.state.selectedExperiments; //e.g ["EOOOO1", "EOOOO4"]
            //filter the data;

            //
            let items = data_table.map_by_exp.filter(s => selectedExperiments.includes(s.ExperimentID));

            if(items.length!==0){
                data_table.participants = items;
            }

        }*/


        let query = getCurrentQuery(this.props.studyBucket.studies, this.props.studySettings);
        let query_id = (query) ? query.localId : "NA";

        return <ParticipantTable
            loadingParticipants = {this.props.loadingParticipants}
            selectParticipants = {this.selectParticipant}
            sampleList = {this.props.sampleList}
            participants = {data_table}
            query_id={query_id}
            selectedVariants = {this.state.selectedVariants}
        />



    }



    getTable(){

        let obj = this.getTableDataContent();


        let self = this;
        let { total, summary } =this.props;

        let type = (summary) ? "search_across" : "default";


        // Determine the display text for number of genomics variants
        let snvDisplayText;

        if (total === -1) {
            snvDisplayText = "genomic variants not available";
        } else if (total === 1) {
            snvDisplayText = "1 genomic variant";
        } else {

            snvDisplayText = total + " genomic variants";

    
        }

        let tableEl =  <SNV_Table
            viewMode={self.state.viewMode}
            annotate_variants = {self.annotate_variants}
            getAllTaggedVariants = {self.props.getAllTaggedVariants}
            all_tagged_variants = {self.props.all_tagged_variants}
            passAnnotations={ self.props.passAnnotations}
            loading={self.props.loading}
            queryRunner={self.props.queryRunner}
            fetchData={ self.props.fetchData}
            total={(self.state.selectedParticipants.length=== 0) ? total : obj.data.length}
            type={type}
            tagged_variants = {self.state.tagged_variants}
            data = {obj.data}
            participants = {this.state.selectedParticipants}
            selectedExperiments = {self.state.selectedExperiments}
            pageCount={Math.ceil(total/100)}
            pid_array = {(self.props.participants.participants!== undefined)
                ? self.props.participants.participants.map(s => s.report_id)
                : []}
        />


        let accordionEl = <Box p={1} style={{ float: "left", width: "100%" }}>
            <Accordion defaultExpanded={false}>
                <AccordionInfo
                    title="SNV and InDels"
                    total={snvDisplayText} 
                    type={type}
                    tooltipTitle= {<MappedText text="Note that the number of rows shown in the variants table can be higher than the number of genomic variants shown in the counter since one genomic variant can affect more than one gene." />}
                />
                <AccordionDetails>
                    {tableEl}
                </AccordionDetails>
            </Accordion>
        </Box>


        if(summary || this.props.cnv_data){
            return accordionEl;
        }
        else{
            return tableEl;
        }




    }

    setTableType(bool){

        this.setState({tableType:bool})  //true => variant table, false => Functional Mode

    }



    render(){

        let self  = this;

        let obj = this.getTableDataContent();
        let chrom_data = processVariantData(obj);
        let annotations = getAnalysisAnnotations(self.props.studySettings, self.props.studyBucket);

        let vertical ="bottom";
        let horizontal = "center";


        if(self.props.variants!== undefined)
        {
            return (
                <div className={'central-panel'}>
                    <Snackbar  open={this.state.snackMessage}
                               autoHideDuration={6000}
                               anchorOrigin={ {vertical, horizontal }}
                               onClose={this.handleSnackClose}>
                        <Alert onClose={this.handleSnackClose} severity="error">
                            CNV data could not be loaded
                        </Alert>
                    </Snackbar>
                    {(self.props.summary)
                        ? null
                        : <ChromosomeChart initData = { chrom_data } data = { chrom_data } total={ self.props.total }/>}
                    <LeftPanel
                        classStyle={"leftSidePanel"}
                        summary = {self.props.summary}
                        sample_agg = {self.props.sample_agg}
                        queryRunner={self.props.queryRunner}
                        runSearchAcrossQuery = {self.props.runSearchAcrossQuery}
                        participants = {this.props.participants}
                        passExperiments = {self.selectedExperiments}
                        passGenes = {self.passGenes}
                        totalVariants={self.props.total}
                    />
                    <div className="variant_table">
                        <AnalysesTabs
                            switchAnalysis = {self.props.switchAnalysis}
                            resetAll = {self.props.resetAll}
                            deleteButton={true}
                        />
                        <QueryTabs
                            switchQuery = {self.props.switchQuery}
                            total = {self.props.total}
                        />
                        <ToolBar_New
                            data = {obj.data}
                            setViewMode = {self.setViewMode}
                            setTableType = {self.setTableType}
                            annotations = {annotations}
                            annotate_variants = {self.annotate_variants}
                            tagged_variants = {self.props.all_tagged_variants}
                            getAllTaggedVariants = {self.props.getAllTaggedVariants}
                            passAnnotations={ self.props.passAnnotations}
                        />
                        {(self.props.summary)
                            ? this.getParticipantTable()
                            : null
                        }
                        {self.getTable()}
                        {(self.props.cnv_data)
                            ? this.getCNVTable()
                            : null
                        }
                    </div>


                </div>)
        }
        else if(self.props.requestStatus === STATIC_VARIABLES.UNAVAILABLE_SERVER){
            return <div className={'central-panel'}>
                <div className={"error_msg"}>
                    <CircularProgress />
                    The GPAP Genomics server is currently unavailable. We apologize for the the inconvenience. Please contact us at <a href="mailto:help@rd-connect.eu">help@rd-connect.eu</a> for more information.
                </div>
            </div>
        }
        else if(self.props.variants  === undefined ){
            return <div className={'central-panel'}>
                <div
                    className="error_msg"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh", // full height to center vertically
                        textAlign: "center",
                    }}
                >
                    <CircularProgress />
                    <p>Loading genomic variants</p>
                </div>
            </div>
        }

        else{
            return <div className={'central-panel'}>
                <div className={"error_msg"}>Ooops, the query returned an error;</div>
            </div>
        }

    }
}


function mapStateToProps (state) {
    if(state.variants!==undefined){

        return {
            token: state.authorization.token,
            selected_table_rows: state.selected_table_rows,
            studySettings: state.studySettings,
            studyBucket: state.studyBucket,
            patients: state.patients,
            genes_state : state.query.gene,
            // TODO: we need to get all the experiments in the analysis
            experiment: state.sample_list.samples.map(s => s.sample_id)
        }
    }
    else{
        return {snv: []}
    }
}

const mapDispatchToProps = (dispatch) => ({
    study_actions: bindActionCreators(StudyActions, dispatch),
    gpap_general_actions: bindActionCreators(GPAP_General_Actions, dispatch)
});



//export default Variant_Table


export default connect(mapStateToProps,mapDispatchToProps)(Variant_Table);